import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import withStyles from "@mui/styles/withStyles";
import TabScrollButton from "@mui/material/TabScrollButton";
import NewReportStyles from "./NewReportStyles";
import TypographyComponent from "../../../../components/TypographyComponent/TypographyComponent";
import Badge from "@mui/material/Badge";

import { ReportsConstants } from "../../../../config/constants";

// Import all chart customization components
import HighLevelSummary_Custom from "./Charts/HighLevelSummary_Custom";
import MatrixChart_Custom from "./Charts/MatrixChart_Custom";
import YesNoChart_Custom from "./Charts/YesNoChart_Custom";
import HotspotChart_Custom from "./Charts/HotspotChart_Custom";
import EngagementChart_Custom from "./Charts/EngagementChart_Custom";
import { reportAction, dashboardAction } from "../../../../redux/actions";
import TrendsOverTime_Custom from "./Charts/TrendsOverTime_Custom";
import DropdownQuestions_Custom from "./Charts/DropdownQuestions_Custom";

import EngagementImage from "../../../../assets/images/Charts2/Engagement.svg";
import Summary from "../../../../assets/images/Charts2/Summary.svg";
import DropdownImage from "../../../../assets/images/Charts2/Drodown.svg";
import Yes_No from "../../../../assets/images/Charts2/Yes_No.svg";
import Trends_Over_Time from "../../../../assets/images/Charts2/Trends_Over_Time.svg";
import Hotspot from "../../../../assets/images/Charts2/Hotspot.svg"
import Matrix from "../../../../assets/images/Charts2/Matrix.svg";

const CHARTS = {
  '0': 'Summary',
  '1': 'Comment Analysis',
  '2': 'Yes/No Question',
  '3': 'Matrix Questions',
  '4': 'Hotspot',
  '5': 'Engagement',
  '6': 'Trends Over Time',
  '8': 'Dropdown Questions'
};

const images = [
  { title: "Summary", src: Summary },
  { title: "Hotspot", src: Hotspot },
  { title: "Matrix Questions", src: Matrix },
  { title: "Engagement", src: EngagementImage },
  { title: "Yes/No Question", src: Yes_No },
  { title: "Trends Over Time", src: Trends_Over_Time },
  { title: "Dropdown Questions", src: DropdownImage },
];

function Charts(props) {
  const dispatch = useDispatch();
  const [selectedImage, setSelectedImage] = useState("");
  const [imageList, setImageList] = useState([]);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(false);
  const { classes } = props;
  const { customReportData, matrix, yesNo} = useSelector((state) => state.report);
  const customChartData = useSelector((state) => state.report.customChartData);
  const { queryDLA } = useSelector(state => state.profile.user)
  const scrollContainerRef = useRef(null);
  let imageArr = [];
  const [originalSurveys, setOriginalSurveys] = useState([]);
  const excludedCharts = ["Dashboard", "Sentiment Analysis", "Verbatim Analysis", "Sentiment & Verbatim Analysis"];
  const filteredCharts = (customReportData?.chartOrder || []).filter(chart => !excludedCharts.includes(chart));
  const isMaxChartsReached = filteredCharts?.length === 15;

  useEffect(() => {
    if (customReportData?.id) {
      dispatch(reportAction.getCustomReportChart(customReportData));
      dispatch(reportAction.getCustomDemographicData(customReportData));
      dispatch(reportAction.getDemographics(customReportData));
      dispatch(reportAction.getCustomYesnoData(customReportData))
      dispatch(reportAction.getCustomDropdownData(customReportData));
      dispatch(reportAction.getCustomMatrixData(customReportData));
      dispatch(reportAction.getSurveys("", (getSurveysRes) => {
        setOriginalSurveys(getSurveysRes);
      }));
      if (!queryDLA || queryDLA === "") {
        dispatch(reportAction.postKeyDriverData(customReportData))
      }
    }
  }, [customReportData?.surveys, customReportData?.date, customReportData?.demographics]);

  // Initialize allSelectedImages based on chartOrder
  useEffect(() => {
    if (customChartData.indexOf('0') !== -1) {
      imageArr.push(images[0]);
    }
    if (customChartData.indexOf('5') !== -1) {
      imageArr.push(images[3]);
    }
    if (customChartData.indexOf('4') !== -1) {
      imageArr.push(images[1]);
    }
    // && Object.keys(matrix).length > 0
    if (customChartData.indexOf('3') !== -1 && Object.keys(matrix).length > 0) {
      imageArr.push(images[2]);
    }
    if (customChartData.indexOf('6') !== -1) {
      imageArr.push(images[5]);
    }
    if (customChartData.indexOf('2') !== -1 && Object.keys(yesNo).length > 0) {
      imageArr.push(images[4]);
    }
    if (customChartData.indexOf('8') !== -1) {
      imageArr.push(images[6]);
    }
    setImageList(imageArr);
  }, [customChartData]);

  const checkScrollability = () => {
    const container = scrollContainerRef.current;
    if (container) {
      setCanScrollLeft(container.scrollLeft > 0);
      setCanScrollRight(
        container.scrollLeft < container.scrollWidth - container.clientWidth
      );
    }
  };

  useEffect(() => {
    const container = scrollContainerRef.current;
    if (container) {
      container.addEventListener('scroll', checkScrollability);
      // Initial check
      checkScrollability();
      return () => container.removeEventListener('scroll', checkScrollability);
    }
  }, [imageList]);

  useEffect(() => {
    setSelectedImage("")
  }, [customReportData?.chartOrder]);

  const handleScroll = (direction) => {
    const container = scrollContainerRef.current;
    if (container) {
      const scrollAmount = 300;
      container.scrollBy({
        left: direction === 'left' ? -scrollAmount : scrollAmount,
        behavior: 'smooth'
      });
    }
  };

  const getChartComponent = (title) => {
    const components = {
      'Summary': HighLevelSummary_Custom,
      'Hotspot': HotspotChart_Custom,
      'Matrix Questions': MatrixChart_Custom,
      'Engagement': EngagementChart_Custom,
      'Yes/No Question': YesNoChart_Custom,
      'Trends Over Time': TrendsOverTime_Custom,
      'Dropdown Questions': DropdownQuestions_Custom,
    };
    return components[title];
  };

  const handleImageClick = (title) => {
    setSelectedImage(selectedImage === title ? null : title);
  };

  return (
    <React.Fragment>
      <Grid container style={{ justifyContent: 'left' }}>
        <Grid container className={classes.lightOrangeBox}>
          <Box>
            <p>
              {ReportsConstants.CUSTOM_CHART_TEXT}
            </p>
          </Box>
        </Grid>
      </Grid>
      <Grid container direction="column" spacing={3} style={{ paddingLeft: "10px", paddingTop: "5px" }}>
        <Grid item style={{ paddingLeft: "0px" }}>
          <Box>
            <Grid item className="pl-1 pr-2 selfcenter">
              <Box display="flex" alignItems="center">
                <Badge
                  color="secondary"
                  className="badgebig"
                  badgeContent="1"
                  style={{ margin: '15px', marginRight: "0px" }}
                />
                <TypographyComponent variant="h6" style={{ marginLeft: '25px' }}>
                  {ReportsConstants.CUSTOM_SELECT_CHART}
                </TypographyComponent>
              </Box>
            </Grid>

            <Box style={{ position: 'relative', marginLeft: "50px", maxWidth: "50vw", overflowY: 'auto' }}>
              {canScrollLeft && (
                <TabScrollButton
                  direction="left"
                  onClick={() => handleScroll('left')}
                  style={{
                    position: 'absolute',
                    left: 0,
                    top: '50%',
                    transform: 'translateY(-50%)',
                    zIndex: 1,
                    background: '#fff',
                    width: '40px', // Circle diameter
                    height: '40px', // Circle diameter
                    border: "1px solid #E2E2E2",
                    borderRadius: '50%', // Makes it circular
                    display: 'flex', // Centers the content
                    alignItems: 'center', // Centers the content vertically
                    justifyContent: 'center', // Centers the content horizontally

                  }}
                />
              )}

              <Box
                ref={scrollContainerRef}
                display="flex"
                whiteSpace="nowrap"
                className={`${isMaxChartsReached ? 'opacity-50 cursor-not-allowed' : ''}`}
                style={{
                  padding: "7px",
                  overflow: "hidden",
                  // maxWidth: "90%",
                  scrollBehavior: "smooth"
                }}
              >
                {(imageList || [])?.map((image, index) => (
                  <Box
                    key={index}
                    marginRight={2}
                    onClick={() => !isMaxChartsReached && handleImageClick(image.title)}
                    className={`${classes.imageGrid} ${!isMaxChartsReached && selectedImage === image.title ? classes.selectedImage : ""
                      } ${isMaxChartsReached ? 'cursor-not-allowed' : 'cursor-pointer'}`}
                    style={{
                      filter: isMaxChartsReached ? 'grayscale(100%) opacity(50%)' : 'none',
                      transition: 'filter 0.2s ease'
                    }}
                  >
                    {/* <div class="image-container2"> */}
                    <img
                      src={image.src}
                      alt={image.title}
                      style={{
                        // maxWidth: "100%",
                        width: "150px",
                        // height: "auto",
                        display: "block",
                        objectFit: "contain",
                        borderRadius: '10px'
                      }}
                    />
                    {/* <p class="image-text"><b>{image.title}</b></p> */}
                    {/* </div> */}
                  </Box>
                ))}
              </Box>

              {canScrollRight && (
                <TabScrollButton
                  direction="right"
                  onClick={() => handleScroll('right')}
                  style={{
                    position: 'absolute',
                    right: 0,
                    top: '50%',
                    transform: 'translateY(-50%)',
                    zIndex: 1,
                    background: '#fff',
                    width: '40px', // Circle diameter
                    height: '40px', // Circle diameter
                    border: "1px solid #E2E2E2",
                    borderRadius: '50%', // Makes it circular
                    display: 'flex', // Centers the content
                    alignItems: 'center', // Centers the content vertically
                    justifyContent: 'center', // Centers the content horizontally
                  }}
                />
              )}
            </Box>
          </Box>
        </Grid>
        <Grid item style={{ padding: "10px" }}>
          {/* Chart Customization Grid */}
          {isMaxChartsReached ? (
            <Box display="flex" alignItems="center">
              <TypographyComponent variant="h6" className="txtBold fontSize15">
                {ReportsConstants.CUSTOM_CHART_LIMIT}
              </TypographyComponent>
            </Box>
          ) : (
            selectedImage && (
            <Box>

              <Box display="flex" alignItems="center">
                <Badge
                  color="secondary"
                  className="badgebig"
                  badgeContent="2"
                  style={{ margin: '15px', marginRight: "0px" }}
                />
                <TypographyComponent variant="h6" style={{ marginLeft: '25px' }}>
                  {ReportsConstants.CUSTOM_CUSTOMIZE_CHART}
                </TypographyComponent>
              </Box>

              <Box style={{ marginLeft: "40px" }}>
                {selectedImage && (
                  <Box key={selectedImage} mt={1}>
                    {/* <TypographyComponent variant="subtitle1" className="txtBold">
                      {selectedImage} {ReportsConstants.CUSTOM_CUSTOMIZATION}
                    </TypographyComponent> */}
                    {(() => {
                      const ChartComponent = getChartComponent(selectedImage);
                      return ChartComponent ? <ChartComponent originalSurveys={originalSurveys} /> : null;
                    })()}
                  </Box>
                )}
              </Box>
            </Box>)
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

Charts.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(NewReportStyles)(Charts);