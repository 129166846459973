/* eslint-disable quote-props */
/* eslint-disable object-curly-spacing */
/* eslint-disable dot-notation */
import React from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

// Import Helpers
import { getReducerUpdatedData } from "../../../../../helpers";

// Import Component
import InformationComponent from "../../../../../components/InformationComponent/InformationComponent";
import TypographyComponent from "../../../../../components/TypographyComponent/TypographyComponent";

// import constants
import { surveyQuestionsConstants } from "../../../../../config/constants";

// Import Actions
import { categoryAction } from "../../../../../redux/actions";

function AddQuestionsCheckBox(props) {
  const dispatch = useDispatch();

  // Get Props Values
  const { category, questionDataSet, item } = props;

  // change the check box data
  const handleChange = (event) => {
    if (event["name"] === "demographicConversion") {
      event["value"] = questionDataSet?.RGConversion ? !event["value"] : false;
    } else {
      event["value"] = !event["value"];
    }
    const reducerData = getReducerUpdatedData(event, questionDataSet);
    if (!item) {
      dispatch(categoryAction.addOrChangeQuestion(category, reducerData));
    } else {
      props.editQuestionsData(reducerData);
    }
  };

  return (
    <React.Fragment>
      {questionDataSet && questionDataSet.qusType && 
      <Grid item>
      <FormControlLabel
        control={
          <Checkbox
            checked={questionDataSet.excludeInDashboard}
            onChange={(e) => handleChange({ "name": "excludeInDashboard", "value": questionDataSet.excludeInDashboard })}
            name="excludeInDashboard"
            color="primary"
          />
        }
        label={surveyQuestionsConstants.EXCLUDE_FROM_REPORTS}
      />
      <InformationComponent><TypographyComponent variant="tooltipbody">{surveyQuestionsConstants.EXCLUDE_FROM_DASHBOARD_HELP}</TypographyComponent></InformationComponent>
    </Grid>
      }
      <Grid item>
        <FormControlLabel
          control={
            <Checkbox
              checked={questionDataSet.isRequired}
              onChange={(e) => handleChange({ "name": "isRequired", "value": questionDataSet.isRequired })}
              name="isRequired"
              color="primary"
            />
          }
          label={surveyQuestionsConstants.REQUIRE_THIS_QUEST}
        />
       
      </Grid>
     
      <Grid item>
        {(questionDataSet?.qusType === "Single Select" || questionDataSet?.qusType === "Yes/No" || questionDataSet?.qusType === "Dropdown") &&
          questionDataSet?.RGConversion &&
          <React.Fragment>
            <FormControlLabel
              control={
                <Checkbox
                  checked={questionDataSet?.demographicConversion ? questionDataSet?.demographicConversion : false}
                  onChange={(e) => handleChange({ "name": "demographicConversion", "value": questionDataSet?.demographicConversion })}
                  name="demographicConversion"
                  color="primary"
                />
              }
              label={surveyQuestionsConstants.APPLY_DEMOGRAPHIC_CONVERSION}
            />
            <InformationComponent>
              <TypographyComponent variant="tooltipbody">
                {surveyQuestionsConstants.APPLY_DEMOGRAPHIC_CONVERSION_HELP}
              </TypographyComponent>
            </InformationComponent>
          </React.Fragment>
        }
       
        {item && item?.baseQuesId && item?.baseQuesId === "52" &&
          <React.Fragment>
            <FormControlLabel
              control={
                <Checkbox
                  checked={questionDataSet?.demographicConversion ? questionDataSet?.demographicConversion : false}
                  onChange={(e) => handleChange({ "name": "demographicConversion", "value": questionDataSet?.demographicConversion })}
                  name="demographicConversion"
                  color="primary"
                />
              }
              label={surveyQuestionsConstants.APPLY_DEMOGRAPHIC_CONVERSION}
            />
            <InformationComponent>
              <TypographyComponent variant="tooltipbody">
                {surveyQuestionsConstants.APPLY_DEMOGRAPHIC_CONVERSION_HELP}
              </TypographyComponent>
            </InformationComponent>
          </React.Fragment>
        }
      </Grid>
    </React.Fragment>
  );
}

// default props
AddQuestionsCheckBox.defaultProps = {
  classes: {},
  category: null,
  item: null,
  questionDataSet: {},
  editQuestionsData: () => { }
};

// prop types
AddQuestionsCheckBox.propTypes = {
  classes: PropTypes.object,
  item: PropTypes.object,
  questionDataSet: PropTypes.object,
  category: PropTypes.string,
  editQuestionsData: PropTypes.func
};

export default AddQuestionsCheckBox;
