/**
 * Validates the Raw Data Automation form data
 * @param {Object} data - Raw data automation form data
 * @returns {Object} Validation result with success status and error message
 */
export const validateRawDataSetup = (data) => {
    // Validate Name
    if (!data.name || data.name.trim() === '') {
      return {
        isValid: false, 
        errorMessage: 'Please provide a name for the raw data report.'
      };
    }
  
    // Validate Surveys
    if (!data.surveys || data.surveys.length === 0) {
      return {
        isValid: false,
        errorMessage: 'Please select at least one survey.'
      };
    }
  
    // Validate Frequency
    if (!data.frequency) {
      return {
        isValid: false,
        errorMessage: 'Please select a frequency for the report.'
      };
    }
  
    // Validate Day
    if (!data.day) {
      return {
        isValid: false,
        errorMessage: 'Please select a day for the report.'
      };
    }
  
    // Validate Date (if applicable)
    console.log(data.date)
    if (!data.date) {
        if (!data.date.type && !data.date.startDate){
            return {
                isValid: false,
                errorMessage: 'Please select a date for the report.'
      };}
    }
  
    // Validate Selected Demographic (if applicable)
    if (!data.selectedDemographic || data.selectedDemographic.length === 0) {
      return {
        isValid: false,
        errorMessage: 'Please select demographic filters.'
      };
    }
  
    // If all validations pass
    return {
      isValid: true,
      errorMessage: null
    };
  };