import React, {useEffect} from "react";
import PropTypes from "prop-types";
import { Switch, Route } from "react-router-dom";
import Rawdatalist from "./Rawdatalist";
import Rawdatasetup from "./Rawdatasetup";

// import child router components


function RawdatasetupRouter(props) {
  const { user, match } = props;

  return (
    <>
      <Switch>
        <Route exact path={`${match.url}/list`} render={props => {
          return (
            <Rawdatalist
              match={match}
              user={user}
              {...props}
            />
          );
        }} />
        <Route exact path={`${match.url}/setup/new`} render={props => {
          return (
            <Rawdatasetup
              page={0}
              match={match}
              user={user}
              {...props}
            />
          );
        }} />
        <Route exact path={`${match.url}/setup/:id`} render={props => {
          return (
            <Rawdatasetup
              page={0}
              match={match}
              user={user}
              {...props}
            />
          );
        }} />
      </Switch>
    </>
  );
}

/**
 * Bind Component Property Types
 */
RawdatasetupRouter.propTypes = {
  match: PropTypes.object,
  user: PropTypes.object,
  classes: PropTypes.object
};

/**
 * Export Component
 */
export default RawdatasetupRouter;
