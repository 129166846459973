import React, { useEffect, useState, useMemo } from 'react';
import { Popover } from '@mui/material';
import MultiSelectChecboxWithSearch from "../../../../../components/MultiSelectComponent/MultiSelectCheckboxWithSearch";

const ViewBySelectionPopup = ({
  open,
  anchorEl,
  onClose,
  selectedDemographic,
  filtersApplied,
  selectedValue,
  viewBySelection,
  onApply,
  type
}) => {

  const [selectedItems, setSelectedItems] = useState(viewBySelection);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    setSelectedItems(viewBySelection)
    // onApply({});
  }, [selectedValue, selectedDemographic, viewBySelection]);

  const handleApply = ((triggerType, data) => {
    let appliedFilters = {};


    // Compare data (selected keys) with viewBySelection length
    if (data?.length === Object?.keys?.(viewBySelection)?.length) {
      // If all items are selected, keep filters empty
      appliedFilters = {};
    } else {
      // Map the selected keys to their corresponding text values from checkboxData
      const selectedTexts = (data || [])?.map(key => viewBySelection[key])?.filter(Boolean);
      if(triggerType === "keyDrivers" && type === "hotspot"){
        triggerType = "keyDriver";
      }
      // Create filters based on the triggerType
      appliedFilters = { [triggerType]: selectedTexts };
    }

    // Call onApply with the filters
    onApply(appliedFilters);
    onClose();
  });

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >

      <MultiSelectChecboxWithSearch
        checkboxData={selectedItems}
        defaultSelectAll={filtersApplied && _.isObject(filtersApplied) && Object.keys(filtersApplied).length > 0 ? false : true}
        handleApply={handleApply}
        handleCancel={onClose}
        triggerType={selectedValue}
        selectedCheckboxDatas={
          filtersApplied && Object.keys(filtersApplied).length > 0
            ? Object.keys(selectedItems).filter(key =>
              filtersApplied[selectedValue]?.includes(selectedItems[key])
            )
            : []
        }
      />
    </Popover>
  );
};

export default ViewBySelectionPopup;