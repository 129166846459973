import React, {useState} from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import withStyles from '@mui/styles/withStyles';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import { addEllipsis } from "../../../../helpers";



// Import Loadsh
import _ from "lodash";

// Import Styles
import DropdownTableStyle from "./DropdownTableStyle";

// import constants
import { ReportsConstants } from "../../../../config/constants";


// Import Component
import LoaderComponent from "../../../../components/LoaderComponent/LoaderComponent";
import TypographyComponent from "../../../../components/TypographyComponent/TypographyComponent";



function DropdownTable(props) {
  const {
    height,
    classes,
    showPagination,
    isRightTable,
    isLeftTable,
    loading,
    data,
    popup
  } = props;

  // Initial number of rows to show for each table
  const initialRowCount = 7;

  // State to keep track of the visible rows for each table
  const [visibleRows, setVisibleRows] = useState(data.reduce((acc, table, index) => {
                    acc[index] = initialRowCount;
                    return acc;
                  }, {}));
    
   

   // Toggle the visibility of rows for a specific table
  const toggleRows = (tableId) => {
    setVisibleRows((prevState) => {
      const currentRowCount = prevState[tableId];
      return {
        ...prevState,
        [tableId]: currentRowCount === initialRowCount ? data.find((table, index)=> index === tableId).response.length : initialRowCount,
      };
    });
  };

  /**
    * Table Header Row
    */
  const tableHeaderRow = () => {

    return (

      <TableRow className={classes.tableheadercolor}>

        <TableCell width="40%" className="fontSize13 txtSemiBold fontMontserrat" style={{ paddingLeft: "25px" }}>{ReportsConstants.DROPDOWN_TABLE_HEADERS[0]}</TableCell>
        <TableCell width="45%" className="fontSize13 txtSemiBold fontMontserrat">{ReportsConstants.DROPDOWN_TABLE_HEADERS[1]}</TableCell>
        <TableCell width="15%" className="fontSize13 txtSemiBold fontMontserrat txtcenter">{ReportsConstants.DROPDOWN_TABLE_HEADERS[3]}</TableCell>


      </TableRow >

    );
  };


  /**
       * Bind Table Row Cell Value or Value Component
       * Select Field
       */
  return (<React.Fragment>
    {loading &&
      <LoaderComponent></LoaderComponent>
    }
    {!loading && data?.length > 0 ? data?.map((item, index) => {
      return <div key={index}>
        <div style={{ padding: "20px" }} >{item?.question} (N={item?.totalCount})</div>
       <div className={`${classes.root}`}>
          <div className={`scrollx`} style={{ marginLeft: "0px" }}>
           <Table className={`dropdown-border  ${classes.tableContainer}`} >
              <TableHead>

                {tableHeaderRow()}
              </TableHead>
              <TableBody  >
                {item?.response && item?.response?.slice(0,  popup===false ? visibleRows[index] : item?.response?.length).map((result, k) => {
                  let text="";
                  if(!result?.selection.includes(" ") && result?.selection?.length > 70) {
                   let splitText = result?.selection?.match(/.{1,10}/g); 
                   text=splitText?.join("\n")
                } else {
                   text = result?.selection; 
                }
                  return <TableRow key={k}>
                    <TableCell width="40%" style={{ paddingLeft: "25px" }} >{result?.selection?.length > 70 ? <Tooltip arrow title={text} >{addEllipsis(result?.selection, 70)}</Tooltip> : result?.selection}</TableCell>
                    <TableCell width="45%"><div style={{ borderRadius: "5px", height: "10px", width: "450px" }} ><div style={{ backgroundColor: "#54C0FD", borderRadius: "5px", height: "10px", width: `${80 / Math.max(...item?.response?.map(o => o?.percentage)) * result?.percentage}%` }} className={"floatLeft"}></div><div className={`floatLeft`} style={{ marginLeft: "8px" }}>{result?.percentage}%</div></div></TableCell>
                    <TableCell width="15%" className={"txtcenter"}>{result?.total}</TableCell>

                    <div className="clearLeft"></div>

                  </TableRow>
                })}

              </TableBody>

            </Table>
             {/* View All Button */}
             {popup===false && item?.response?.length > 7 &&  
             <div onClick={()=>toggleRows(index)} className={"view-btn-div"}>
               {visibleRows[index] === initialRowCount ? 'View All' : 'Show Less'}
                </div>
                 } 

          </div>
        </div>

      </div>
    }) : !loading && <Grid container className="justifycenter dflex mt-3">
      <TypographyComponent variant="h3">{ReportsConstants.NO_DATA_CHART}</TypographyComponent>
    </Grid>
    }
  </React.Fragment>)

}

// prop types
DropdownTable.propTypes = {
  height: PropTypes.string,
  popup:  PropTypes.bool,
  data: PropTypes.array.isRequired,
  header: PropTypes.array.isRequired,
  field: PropTypes.array.isRequired,
  search: PropTypes.array.isRequired,
  actions: PropTypes.array,
  links: PropTypes.array,
  rowsPerPage: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  sortBy: PropTypes.string.isRequired,
  sort: PropTypes.string.isRequired,
  removeSortingHeader: PropTypes.array,
  ellipsis: PropTypes.bool,
  componentMap: PropTypes.object,
  deleteContent: PropTypes.string,
  isRightTable: PropTypes.bool,
  isLeftTable: PropTypes.bool,
  onSortRequest: PropTypes.func.isRequired,
  onChangeDate: PropTypes.func,
  onChangeDemographics: PropTypes.func,
  filtersApplied: PropTypes.object,
  applyFilters: PropTypes.func,
  demographics: PropTypes.object
};

// default types
DropdownTable.defaultProps = {
  height: "",
  popup: false,
  data: [],
  header: [],
  field: [],
  search: [],
  actions: [],
  links: [],
  rowsPerPage: 0,
  count: 0,
  sortBy: "",
  sort: "asc",
  dateFields: [],
  removeSortingHeader: [],
  classes: {},
  ellipsis: true,
  componentMap: {},
  demographics: {},
  isRightTable: false,
  isLeftTable: false,
  onSortRequest: () => { },
  onChangeDate: () => { },
  onChangeDemographics: () => { },
  filtersApplied: {},
  applyFilters: () => { },
};

export default withStyles(DropdownTableStyle)(DropdownTable);
