import React from 'react';
import { Grid, Box, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import TypographyComponent from "../../../../components/TypographyComponent/TypographyComponent";
import Chip from "@mui/material/Chip";
import CloseIcon from '@mui/icons-material/Close';
import Tooltip from '@mui/material/Tooltip';


// Constants
import { ReportsConstants } from "../../../../config/constants";

// Import Actions
import { reportAction } from "../../../../redux/actions";

import { dateFormatter } from '../../../../helpers';

const CHARTS = {
  '0': 'Summary',
  '2': 'Yes/No Question',
  '3': 'Matrix Questions',
  '4': 'Hotspot',
  '5': 'Engagement',
  '6': 'Trends Over Time'
};

const NewReportContent = (props) => {
  const { activeStep, classes } = props;
  const dispatch = useDispatch();
  // const classes = useStyles();
  const { metadata } = useSelector(state => state.metadata)
  const customReportData = useSelector((state) => state.report.customReportData)
  const { name, surveys = [], date, demographics, chartOrder = [], includeDashboard } = customReportData || {};
  const hasCharts = chartOrder && chartOrder?.length > 0 && (chartOrder || [])?.some((chart) => chart !== "Dashboard" && chart !== "Sentiment Analysis" && chart !== "Verbatim Analysis" && chart != "Sentiment & Verbatim Analysis");
  const hasCommentAnalysis = chartOrder && (chartOrder || [])?.some((chart) => chart === "Sentiment Analysis" || chart === "Verbatim Analysis" || chart == "Sentiment & Verbatim Analysis");
  const demoMeta = {}
  metadata.map(meta => {
    demoMeta[meta.key] = meta.value
  })

  const generateDemographics = () => {
    const totalValues = Object.values(demographics || {})?.reduce((sum, values) => sum + values?.length, 0);
    const displayLimit = activeStep === 2 ? totalValues : 2;
    let displayedValues = [];
    let displayedKeys = [];
    let hiddenValues = [];  // To store the hidden values

    Object.keys(demographics || {})?.forEach((key) => {
      demographics[key]?.forEach((value, idx) => {
        const formattedValue = `${demoMeta?.[key]} - ${value}`;
        if (displayedValues?.length < displayLimit) {
          displayedValues.push(formattedValue);
          displayedKeys.push({ key, idx });
        } else {
          hiddenValues.push(formattedValue);
        }
      });
    });

    const extraCount = totalValues - displayLimit;

    return (
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {(displayedValues || [])?.map((value, index) => (
          <Tooltip title={value} arrow key={index}>
            <Chip
              label={value.length > 25 ? `${value.substring(0, 25)}...` : value}
              color="primary"
              className={classes.demographicsChips}
              deleteIcon={activeStep === 2 ? (
                <CloseIcon style={{ color: "black", fontSize: '16px' }} />
              ) : undefined}
              onDelete={activeStep === 2 ? () => handleDelete(displayedKeys[index].key, displayedKeys[index].idx) : undefined}
            />
          </Tooltip>
        ))}
        {extraCount > 0 && (
          <Tooltip title={hiddenValues.join(', ')} arrow>
            <Chip
              label={`+${extraCount}`}
              color="primary"
              className={classes.demographicsChips_extra}
            />
          </Tooltip>
        )}
      </div>
    );
  };

  const generateSurveys = () => {
    if (!surveys || surveys?.length === 0) return null;

    const totalValues = surveys?.length;
    const displayLimit = activeStep === 0 ? totalValues : 2;
    const displayedSurveys = surveys.slice(0, displayLimit);
    const hiddenSurveys = surveys.slice(displayLimit);
    const extraCount = totalValues - displayLimit;

    return (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
        {displayedSurveys.map((survey, index) => (
          <Tooltip
            key={index}
            title={survey?.name?.length > 25 ? survey?.name : ''}
            arrow
          >
            <span >
              <TypographyComponent variant="h6" style={{ paddingBottom: '8px', display: 'block' }}>
                {survey?.name}
                {/* {survey?.name && survey?.name?.length > 25
                  ? `${survey?.name?.slice(0, 25)}...`
                  : survey?.name} */}
              </TypographyComponent>
            </span>
          </Tooltip>
        ))}

        {extraCount > 0 && (
          <Tooltip title={(hiddenSurveys || [])?.map(survey => survey?.name)?.join(', ')} arrow>
            <Chip
              label={`+${extraCount}`}
              color="primary"
              className={classes.demographicsChips_extra}
              style={{ paddingBottom: '7px' }}
            />
          </Tooltip>
        )}
      </div>
    );
  };

  const generateCharts = () => {
    if (!chartOrder) return null;

    // Filter out certain charts
    const filteredChartOrder = chartOrder.filter(
      (chart) =>
        chart !== "Dashboard" &&
        chart !== "Sentiment Analysis" &&
        chart !== "Verbatim Analysis" &&
        chart !== "Sentiment & Verbatim Analysis"
    );

    // Calculate total values based on filtered chartOrder length
    const totalValues = filteredChartOrder.length;

    // Set the display limit depending on activeStep
    const displayLimit = activeStep === 4 ? totalValues : 2;

    // Variables to track displayed and hidden charts
    let displayedValues = [];
    let hiddenValues = [];
    const extraCount = totalValues - displayLimit;

    // Assign charts to displayed/hidden arrays
    filteredChartOrder?.forEach?.((chart, index) => {
      if (displayedValues?.length < displayLimit) {
        displayedValues?.push(chart);
      } else {
        hiddenValues?.push(chart);
      }
    });

    return (
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {(displayedValues || [])?.map((chart, index) => (
          <Tooltip key={index} title={chart} arrow>
            <Chip
              label={chart?.length > 25 ? `${chart.substring(0, 25)}...` : chart}
              color="primary"
              className={classes.demographicsChips}
              deleteIcon={activeStep === 4 ? <CloseIcon style={{ color: "black", fontSize: '12px' }} /> : undefined}
              onDelete={activeStep === 4 ? () => handleChartDelete(chart) : undefined}
            />
          </Tooltip>
        ))}

        {/* Show extraCount if there are hidden charts */}
        {extraCount > 0 && (
          <Tooltip title={hiddenValues.join(', ')} arrow>
            <Chip
              label={`+${extraCount}`}
              color="primary"
              className={classes.demographicsChips_extra}
              sx={{ padding: '7px' }}
            />
          </Tooltip>
        )}
      </div>
    );
  };

  const handleChartDelete = (chartId) => {
    // Remove the chart from chartOrder
    const updatedChartOrder = chartOrder.filter(chart => chart !== chartId);

    const updatedDashboardPreference = { ...customReportData.dashboardPreference };

    Object.keys(updatedDashboardPreference || {})?.forEach((mainKey) => {
      const section = updatedDashboardPreference[mainKey];

      // Check if the section is an object (e.g., dropdownData, hotspot)
      if (typeof section === 'object' && section !== null) {
        Object.keys(section || {})?.forEach((key) => {
          if (key === chartId) {
            delete section[key];
          }
        });
      }
    });

    const updatedReportData = {
      ...customReportData,
      chartOrder: [...updatedChartOrder],
      dashboardPreference: updatedDashboardPreference,
    };
    dispatch(reportAction.updateCustomReport(updatedReportData));
  };
  const handleDelete = (item, index) => {
    const updatedDemographics = { ...demographics };
    updatedDemographics[item].splice(index, 1);
    if (updatedDemographics?.[item]?.length === 0) {
      delete updatedDemographics[item];
    }
    const updatedReportData = {
      ...customReportData,
      demographics: updatedDemographics,
    };
    dispatch(reportAction.updateCustomReport(updatedReportData));
  };

  return (
    <Box className={classes.roundedBox}>
      <Box style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
        <Grid item xs={12}>
          <TypographyComponent
            variant="h6"
            color="primary"
            style={{ fontWeight: 'bold' }}
          >
            {ReportsConstants.CUSTOM_CONTENTS}
          </TypographyComponent>
        </Grid>
        <Box className={classes.tablebox}>
          <TableContainer>
            <Table>
              <TableBody>
                {/* Title Row */}
                {name && <TableRow>
                  <TableCell className={`${classes.noBorder} ${classes.leftColumn}`} align="right">
                    <TypographyComponent
                      variant="h6"
                      className={classes.rightAlignText}
                      style={{ fontWeight: 'bold' }}
                    >
                      {ReportsConstants.CUSTOM_TITLE}:
                    </TypographyComponent>
                  </TableCell>
                  <TableCell className={`${classes.noBorder} ${classes.rightColumn}`}>
                    <TypographyComponent variant="h6">
                      {name}
                    </TypographyComponent>
                  </TableCell>
                </TableRow>}

                {/* Surveys Row */}
                {surveys?.length > 0 && <TableRow>
                  <TableCell className={`${classes.noBorder}`} align="right">
                    <TypographyComponent
                      variant="h6"
                      className={classes.rightAlignText}
                      style={{ fontWeight: 'bold' }}
                    >
                      {ReportsConstants.CUSTOM_SURVEY}:
                    </TypographyComponent>
                  </TableCell>
                  <TableCell className={`${classes.noBorder} ${classes.rightColumn}`}>
                    {generateSurveys()}

                  </TableCell>
                </TableRow>}

                {/* Date Row */}
                {date.type && <TableRow>
                  <TableCell className={`${classes.noBorder}`} align="right">
                    <TypographyComponent
                      variant="h6"
                      className={classes.rightAlignText}
                      style={{ fontWeight: 'bold' }}
                    >
                      {ReportsConstants.CUSTOM_DATE}:
                    </TypographyComponent>
                  </TableCell>
                  <TableCell className={`${classes.noBorder} ${classes.rightColumn}`}>
                    <TypographyComponent variant="h6">
                      {date.type === "Custom dates"
                                              ? `${dateFormatter(date.startDate)} - ${dateFormatter(date.endDate)}`
                                              // `${new Date(date.startDate.slice(0, 10)).toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' })}  
                                              //  - ${new Date(date.endDate.slice(0, 10)).toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' })}`
                                                : date.type}
                    </TypographyComponent>
                  </TableCell>
                </TableRow>}

                {/* Demographics Row */}
                {(demographics && Object.keys(demographics)?.length > 0 || activeStep > 1) && (<TableRow>
                  <>
                    <TableCell className={classes.noBorder} align="right">
                      <TypographyComponent
                        variant="h6"
                        className={classes.rightAlignText}
                        style={{ fontWeight: 'bold' }}
                      >
                        {ReportsConstants.CUSTOM_DEMOGRAPHICS}:
                      </TypographyComponent>
                    </TableCell>
                    {demographics && Object.keys(demographics)?.length > 0 ? (
                      <TableCell className={`${classes.noBorder} ${classes.rightColumn}`}>
                        {generateDemographics()}
                      </TableCell>
                    ) : (
                      <TableCell className={`${classes.noBorder} ${classes.rightColumn}`} colSpan={2}>
                        <TypographyComponent variant="h6">
                          {"Not " + ReportsConstants.CUSTOM_INCLUDE}
                        </TypographyComponent>
                      </TableCell>
                    )}
                  </>
                </TableRow>
                )}
              
                 {/* Dashboard Row */}
                 {(includeDashboard || activeStep > 2) && (<TableRow>
                  <TableCell className={`${classes.noBorder}`} align="right">
                    <TypographyComponent
                      variant="h6"
                      className={classes.rightAlignText}
                      style={{ fontWeight: 'bold' }}
                    >
                      {ReportsConstants.CUSTOM_DASHBOARD}:
                    </TypographyComponent>
                  </TableCell>
                  {includeDashboard ? (
                    <TableCell className={`${classes.noBorder} ${classes.rightColumn}`}>
                      <TypographyComponent variant="h6">
                        {ReportsConstants.CUSTOM_INCLUDE}
                      </TypographyComponent>
                    </TableCell>
                  ) : (
                    <TableCell className={`${classes.noBorder} ${classes.rightColumn}`}>
                      <TypographyComponent variant="h6">
                        {`Not ${ReportsConstants.CUSTOM_INCLUDE}`}
                      </TypographyComponent>
                    </TableCell>
                  )}
                </TableRow>
                )}

                {/* Charts Row */}
                {hasCharts && <TableRow>
                  <TableCell className={`${classes.noBorder}`} align="right">
                    <TypographyComponent
                      variant="h6"
                      className={classes.rightAlignText}
                      style={{ fontWeight: 'bold' }}
                    >
                      {ReportsConstants.CUSTOM_CHART}:
                    </TypographyComponent>
                  </TableCell>
                  <TableCell className={`${classes.noBorder} ${classes.rightColumn}`}>
                    {generateCharts()}
                  </TableCell>
                </TableRow>}

               
                {/* Comments Row */}
                {hasCommentAnalysis && <TableRow>
                  <TableCell className={`${classes.noBorder}`} align="right">
                    <TypographyComponent
                      variant="h6"
                      className={classes.rightAlignText}
                      style={{ fontWeight: 'bold' }}
                    >
                      {ReportsConstants.CUSTOM_COMMENT}:
                    </TypographyComponent>
                  </TableCell>
                  <TableCell className={`${classes.noBorder} ${classes.rightColumn}`}>
                    <TypographyComponent variant="h6">
                      {/* customReportData.is_verbatim && customReportData.is_sentiment */}
                      {customReportData?.is_verbatim && customReportData?.is_sentiment
                        ? "Sentiment & Verbatim"
                        : customReportData?.is_sentiment
                          ? "Sentiment"
                          : customReportData?.is_verbatim
                            ? "Verbatim"
                            : ""}
                    </TypographyComponent>
                  </TableCell>
                </TableRow>}

              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>

    </Box>
  );
};

// export default (NewReportContent);
export default (NewReportContent);