import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import withStyles from '@mui/styles/withStyles';
import IconButton from "@mui/material/IconButton";
import { Tooltip } from "@mui/material";

// Import Styles and Image
import { Plus_custom } from "../../../assets/images/svgComponents/ImageComponent";

import ReportsStyles from "../ReportsStyles";

function CustomReportIcon(props) {
  const { classes, onClick } = props
 

  return (
    <React.Fragment>
      <Tooltip title={"Create Custom Report"} arrow >
        {/* <Grid className={classes.IconSaveContainer}> */}
          <IconButton  onClick={onClick} size="large">
            <Grid className={`mt5 ${classes.IconContainer}`}>
            {Plus_custom()}
            </Grid>
          </IconButton>
        {/* </Grid> */}
      </Tooltip>
    </React.Fragment>
  );
}
// default props
CustomReportIcon.defaultProps = {
  classes: {},
};

// prop types
CustomReportIcon.propTypes = {
  classes: PropTypes.object,
};
export default withStyles(ReportsStyles)(CustomReportIcon);
