import React from "react";
import { useDispatch, useSelector } from "react-redux";

import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import withStyles from '@mui/styles/withStyles';
import TextFieldComponent from "../../../../components/TextFieldComponent/TextFieldComponent"; // Adjust this import if needed
import TypographyComponent from "../../../../components/TypographyComponent/TypographyComponent"; // Adjust this import if needed

import ReportsStyles from "../../ReportsStyles"; // Make sure this path is correct
import { ReportsConstants } from "../../../../config/constants";

import { reportAction } from "../../../../redux/actions";

function SaveReportName(props) {

  const { customReportData } = useSelector((state) => state.report);
  const dispatch = useDispatch();

  // update report name
  const updateReportName = (e) => {
    const updatedReports = { ...customReportData, name: e["value"] };
    dispatch(reportAction.updateCustomReport(updatedReports));
  }

  return (
    <React.Fragment>
      <Grid container direction="column">
        <Grid item>
          <TypographyComponent variant="h6" className="txtBold">
            <b>{ReportsConstants.ENTER_REPORTNAME}</b>
          </TypographyComponent>
        </Grid>
        <Grid item>
          <TextFieldComponent
            id={"report_name"}
            name={"report_name"}
            size={"small"}
            value={customReportData.name || ""}
            margin={"dense"}
            fullWidth // Ensures the TextField spans the full width of its container
            handleChange={(e) => updateReportName(e)}
            validators={["required", "isFileName"]}
          ></TextFieldComponent>
        </Grid>
      </Grid>
    </React.Fragment>


  );
}

// default props
SaveReportName.defaultProps = {
  classes: {},
  isDrawer: false
};

// prop types
SaveReportName.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(ReportsStyles)(SaveReportName);
