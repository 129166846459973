import React from "react";
import { useSelector } from "react-redux";
// import CircularProgress from "@mui/material/CircularProgress";

// Import Component
import TypographyComponent from "../TypographyComponent/TypographyComponent";

// Import css
import "./PercentageComponent.css";
import { Grid } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";

const PercentageComponent = (props) => {
  const { item , minWidth} = props;
  const UI = useSelector((state) => state.UI);

  return (
      <div className="grid-align">
      <Tooltip className="adjustment1" title={item.category.length >21? item.category : ""} >
      <span style={{"min-width":`${minWidth}px`}}>
        <TypographyComponent variant='h6' align={"right"} className={'fontSize12'} style={{'margin':"0 em"}}>{item.category.length >24? item.category.slice(0,21) + "..." : item.category }</TypographyComponent>
      </span>
      </Tooltip>
      <Grid className="adjustment2" 
      style={{
        background:"rgb(243, 244, 245)",
        height:"9px",
        borderRadius : "50px 50px",
        width : '100%',
        maxWidth : "100%",
        overflow: "hidden",
      }} >
        <Grid
          style={{
            background:"#54C0FD",
            height:"9px",
            borderRadius : "50px 0 0 50px",
            width : `${item.percentfavorable}%`,
            maxWidth : "100%"
          }}
        ></Grid>
      </Grid>
      <TypographyComponent  variant='h5' align={"right"} className={'fontSize12 fontWeight600 ml-15 adjustment3'} style={{minWidth: '30px'}}>{Math.round(item.percentfavorable)}%</TypographyComponent>
      </div>

  );
};

export default PercentageComponent;