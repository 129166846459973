/* eslint-disable no-unneeded-ternary */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { ValidatorForm } from "react-material-ui-form-validator";
import Grid from "@mui/material/Grid";

// Import Components
import AutoCompleteComponent from "../../components/AutoCompleteComponent/AutoCompleteComponent";

// Import Actions
import { clientAction, profileAction, metadataAction, adminsAction, filterAction } from "../../redux/actions";

// Import To Get Page Access Details
import { userRoles, getCurrentListPagePath } from "../../config/pageAccess";

// Import History
import history from "../../config/history";

function HeaderClients(props) {
  // Get Props
  const { classes, user, match } = props;

  // Set State
  // const [client, setClient] = useState({
  //   _id: user.client_id._id,
  //   name: user.client_id.name
  // })

  // Define Dispatch
  const dispatch = useDispatch();

  /**
   * Call GetAll Clients
   */
  useEffect(() => {
    dispatch(clientAction.getAll());
    // To get the API Integration Access based upon client
    dispatch(clientAction.getOne());
  }, [dispatch]);

  // Set Client 
  // useEffect(() => {
  //   setClient({
  //     _id: user.client_id._id,
  //     name: user.client_id.name
  //   })
  // }, [user]);

  // Get Clients List
  const clients = useSelector((state) => state.client.getAll);

  /**
   * Handle Auto Complete
   * @param {*} e
   */
  const handleAutoCompolete = (e) => {
    // Remove LocalStorage for summary widget on Reports
    localStorage.removeItem("summaryWidgetColumns")
    const { _id, name } = e;

    if (user?.['client_id']?.['_id'] === _id) {
      return;
    }
    // setClient({
    //   _id: _id,
    //   name: name
    // })
    const isContentLib = name === "pe Global"

    dispatch(profileAction.updatePreference({ _id: _id, name: name }, () => {
      const rPageList = "surveys"
      history.replace(`/${rPageList}`);
      dispatch(adminsAction.getReportPreference(() => {
        dispatch(clientAction.getOne());
        dispatch(profileAction.user(() => {
        dispatch(metadataAction.getMetadataWithRGI(() => {
          dispatch(metadataAction.getClientMetadata(() => {
            dispatch(filterAction.getAllSurveys(() => {
                const path = match.path.split("/")[1];
                // const rPageList = isContentLib ? `content-library` : (path === "content-library" ? `surveys` : getCurrentListPagePath(user, match))
              }));
            }))
          }));
        }));
      }))
    }))
  };

  // no need to client dropdown for caller admin
  if (user && user.role === userRoles.calleradmin) {
    return null
  }

  return (
    <Grid className={classes.headerSelectBox}>
      {clients.length > 0 &&
        <ValidatorForm onSubmit={() => { }}>
          <AutoCompleteComponent
            name={"clients"}
            suggestions={clients}
            handleAutoCompolete={handleAutoCompolete}
            value={{
              _id: user.client_id._id,
              name: user.client_id.name
            }}
            disabled={user.role === userRoles.superadmin ? false : true}
            placeholder={"Select client"}
            className={user.role === userRoles.superadmin ? "ht-adj" : "displayNone ht-adj"}
            validators={[]}
          ></AutoCompleteComponent>
        </ValidatorForm>
      }
    </Grid>
  );
}

// default props
HeaderClients.defaultProps = {
  classes: {},
  match: {},
  user: {}
};

// prop types
HeaderClients.propTypes = {
  classes: PropTypes.object,
  match: PropTypes.object,
  user: PropTypes.object
};

/**
 * Export Container
 */
export default HeaderClients;
