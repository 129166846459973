import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import {
  Grid,
  Paper,
  Box,
  TextField,
  Checkbox,
  FormControlLabel,
  Tooltip
} from "@mui/material";
import { reportAction, alertAction } from "../../../../../redux/actions";
import { surveyTypes } from "../../../../../config/constants";
import { ReportsConstants } from "../../../../../config/constants";
import TypographyComponent from "../../../../../components/TypographyComponent/TypographyComponent";
import ButtonComponent from "../../../../../components/ButtonComponent/ButtonComponent";
import SearchInputComponent from '../../../../../components/SearchInputCompoment/SearchInputComponent';

function TrendsOverTime({ originalSurveys }) {
  const dispatch = useDispatch();
  const { allSurveys, customReportData } = useSelector(state => state.report);
  // const [originalSurveys, setOriginalSurveys] = useState([]);
  // useEffect(() => {
  //   dispatch(reportAction.getSurveys("", (getSurveysRes) => {
  //     setOriginalSurveys(getSurveysRes);
  //   }));
  // }, []);

  const filteredSurveys = (originalSurveys || [])?.filter(s => s?.type !== surveyTypes.MULTI_RATER_FEEDBACK);

  const [searchText, setSearchText] = useState("");
  const [selectedSurveys, setSelectedSurveys] = useState(
    customReportData?.dashboardPreference?.tot?.surveys || []
  );
  const defaultvalues = () => {
    setSearchText("")
    setSelectedSurveys(customReportData?.dashboardPreference?.tot?.surveys || [])
  };

  const readOnlySurveys = customReportData?.surveys || [];

  const sortedSurveys = filteredSurveys.sort((a, b) => {
    const aReadOnly = readOnlySurveys?.map((item) => item._id).includes(a._id);
    const bReadOnly = readOnlySurveys?.map((item) => item._id).includes(b._id);

    if (aReadOnly && !bReadOnly) return -1;
    if (!aReadOnly && bReadOnly) return 1;

    // If search text is present, filter non-readonly surveys
    if (searchText && !aReadOnly && !bReadOnly) {
      const aMatches = a.name.toLowerCase().includes(searchText.toLowerCase());
      const bMatches = b.name.toLowerCase().includes(searchText.toLowerCase());
      if (aMatches && !bMatches) return -1;
      if (!aMatches && bMatches) return 1;
    }

    return a.name.localeCompare(b.name);
  }).filter(survey =>
    readOnlySurveys?.map((item) => item._id).includes(survey._id) ||
    survey.name.toLowerCase().includes(searchText.toLowerCase())
  );

  const handleQuestionToggle = (survey) => {
    let surveyId = survey?._id;

    setSelectedSurveys(prev => {

      //  if (readOnlySurveys?.map((item)=>item._id)?.includes(surveyId)) return prev;

      if (prev?.map((item) => item._id)?.includes(surveyId)) {
        return prev?.filter((item) => item._id !== surveyId);
      } else {
        if (prev.length >= 10) {
          dispatch(alertAction.error(ReportsConstants.TOT_SURVEY_EXCEEDED));
          return prev;
        }

        // Otherwise, add the new survey
        return [...prev, survey];
      }
    });

  };
  const checkDuplicateConfigs = (totPreferences) => {
    const isDuplicate = (existingConfigs, newPreferences) => {
      const deepEqual = (obj1, obj2) => {
        if (Object.keys(obj1).length !== Object.keys(obj2).length) return false;

        for (const key in obj1) {
          if (typeof obj1[key] === 'object' && obj1[key] !== null) {
            if (!deepEqual(obj1[key], obj2[key])) return false;
          } else if (obj1[key] !== obj2[key]) {
            return false;
          }
        }
        return true;
      };

      return Object.values(existingConfigs).some(config => deepEqual(config, newPreferences));
    };

    const existingtotConfigs = customReportData?.dashboardPreference?.tot || {};

    const isDuplicatetot = isDuplicate(existingtotConfigs, totPreferences);

    if (isDuplicatetot) {
      dispatch(alertAction.error('A chart with identical configuration already exists'));
      return true; // Return true if duplicates are found
    }

    return false; // Return false if no duplicates are found
  };

  const handleAddChart = () => {
    let chartNumber = 'Trends Over Time';
    const updatedChartOrder = customReportData.chartOrder || [];

    if (selectedSurveys?.length > 10) {
      dispatch(alertAction.error(ReportsConstants.TOT_SURVEY_EXCEEDED));
    }

    let count = 1;
    let uniqueChartNumber = chartNumber;

    while (updatedChartOrder.includes(uniqueChartNumber)) {
      uniqueChartNumber = `${chartNumber} ${count}`;
      count++;
    }


    const clickedSurveys = filteredSurveys?.filter(survey =>
      selectedSurveys?.map((item) => item?._id)?.includes(survey._id));
    let combinedArray = [...readOnlySurveys, ...clickedSurveys];

    const newChartPreferences = {
      trendsOverTime: selectedSurveys?.length === 0 ? readOnlySurveys : combinedArray
    };

    const hasDuplicates = checkDuplicateConfigs(newChartPreferences)
    if (hasDuplicates) {
      return;
    }


    const updatedDashboardPreference = {
      ...customReportData?.dashboardPreference,
      tot: {
        ...customReportData?.dashboardPreference?.tot,
        [uniqueChartNumber]: newChartPreferences
      }
    }

    const updatedReportData = {
      ...customReportData,
      chartOrder: [...updatedChartOrder, uniqueChartNumber],
      dashboardPreference: updatedDashboardPreference
    };
    dispatch(reportAction.updateCustomReport(updatedReportData));
    defaultvalues()

  };

  return (
    <Grid>
      <TypographyComponent variant="h6" className="txtBold">
        {ReportsConstants.CUSTOM_TOT}
      </TypographyComponent>
      <Grid item sx={{paddingTop: '16px'}}>
        <Grid display={'flex'} gap={'2rem'} >
          <Grid item xs>
            <Paper elevation={1}>
              <Box p={2}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={8}>
                    <SearchInputComponent
                      id="Totsearch"
                      placeholder="Search surveys.."
                      value={searchText}
                      field="search"
                      showClearIcon={true}
                      fullWidth={true}
                      onInputChangeRequest={(searchField, searchKey) => setSearchText(searchKey)}
                      handleClearSearch={() => setSearchText("")}
                    />
                  </Grid>
                  <Grid item xs={4} container justifyContent="flex-end">
                    <Box
                      component="span"
                      onClick={() => setSelectedSurveys([])}
                      sx={{
                        cursor: 'pointer',
                        color: 'primary.main',
                        textDecoration: 'underline'
                      }}
                    >
                      {ReportsConstants.CUSTOM_CLEAR_ALL}
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    <Box sx={{ maxHeight: '20vh', overflowY: 'auto', mt: 1 }}>
                      <Grid container spacing={1}>
                        {sortedSurveys.map((survey) => (
                          <Grid item xs={12} key={survey._id}>
                            <Tooltip title={survey.name} arrow>
                              <FormControlLabel
                                sx={{ ml: 0 }}
                                control={
                                  <Checkbox
                                    checked={readOnlySurveys?.map((item) => item._id).includes(survey._id) || selectedSurveys?.map((item) => item._id).includes(survey._id)}
                                    onChange={() => handleQuestionToggle(survey)}
                                    color="primary"
                                    sx={{ ml: 1 }}
                                    disabled={readOnlySurveys?.map((item) => item._id).includes(survey._id)}
                                  />
                                }
                                label={survey.name}
                              />
                            </Tooltip>
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          </Grid>

          <Grid item display={'inline-flex'} flexDirection={'column'} flexBasis={'200px'} justifyContent={'flex-end'}>
            <Grid display={'flex'} flexDirection={'column'} gap={'10px'}>
              <ButtonComponent
                color="primary"
                pageClassName="buttonOrange fontSize14"
                title="+ Add chart to report"
                handleClick={handleAddChart}
              />
                {/* <TypographyComponent variant="h6">
                {ReportsConstants.CUSTOM_ADD_CHART_TEXT}
                </TypographyComponent> */}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

TrendsOverTime.propTypes = {
  classes: PropTypes.object,
  allSelectedImages: PropTypes.array
};

export default TrendsOverTime;