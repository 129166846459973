import { alertConstant, uiConstant, reportConstant } from "../constants";
import { baseAction } from "./baseAction";
import { reportService, surveyService } from "../services";
import history from "../../config/history";
//import { saveCustomReport } from "../../MigrationController/dashboard_report_controllers/custom_reports_controller";

const { request, success, failure } = baseAction;

export const reportAction = {
  getDemographics,
  getSurveys,
  demographicsByName,
  updateReport,
  getCommentQuestions,
  saveOrUpdateReport,
  saveCustomReport,
  updateCustomReport,
  editCustomReport,
  getCustomYesnoData,
  getCustomDropdownData,
  getCustomMatrixData,
  getCustomDemographicData,
  copy,
  getSavedReports,
  updateCommentReport,
  getReportData,
  getCustomReportData,
  resetReportData,
  resetCustomReportData,
  getCustomReportDashboard,
  getCustomReportChart,
  remove,
  getBenchmarkIndustryList,
  reportLoading,
  getSubjectsList,
  postKeyDriverData
};

/**
 * get Saved Reports
 */
function getSavedReports(skip, limit, sortBy, sort, sFields, sValues) {
  return dispatch => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(reportConstant.GET_SAVED_REPORT_REQUEST));
    reportService.getSavedReports(skip, limit, sortBy, sort, sFields, sValues).then((data) => {
      dispatch(success(reportConstant.GET_SAVED_REPORT_SUCCESS, data));
      dispatch(request(uiConstant.LOADING_FALSE));
    }, (error) => {
      dispatch(request(uiConstant.LOADING_FALSE));
      dispatch(failure(reportConstant.GET_SAVED_REPORT_FAILURE, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
    });
  };
}

/**
 * Copy Report
 */
function copy(data, successCallback = () => { }) {
  return (dispatch) => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(reportConstant.REPORT_COPY_REQUEST));
    reportService.copy(data).then(
      (data) => {
        successCallback()
        dispatch(success(reportConstant.REPORT_COPY_SUCCESS, data));
        dispatch(request(uiConstant.LOADING_FALSE));
      },
      (error) => {
        dispatch(failure(reportConstant.REPORT_COPY_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(uiConstant.LOADING_FALSE));
      }
    );
  };
}


/**
 * Delete One  report
 */
function remove(id, successCallback = () => { }) {
  return dispatch => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(reportConstant.REMOVE_REPORT_REQUEST));
    reportService.remove(id).then((data) => {
      dispatch(success(reportConstant.REMOVE_REPORT_SUCCESS, data));
      dispatch(request(uiConstant.LOADING_FALSE));
      successCallback()
    }, (error) => {
      dispatch(request(uiConstant.LOADING_FALSE));
      dispatch(failure(reportConstant.REMOVE_REPORT_FAILURE, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
    });
  };
}

/**
 * get surveys with responses
 */
function getSurveys(search, successCallback = () => { }) {
  return dispatch => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(reportConstant.GET_SURVEYS_REQUEST));
    reportService.getSurveys(search).then((data) => {
      dispatch(success(reportConstant.GET_SURVEYS_SUCCESS, data));
      dispatch(request(uiConstant.LOADING_FALSE));
      successCallback(data.data)
    }, (error) => {
      dispatch(request(uiConstant.LOADING_FALSE));
      dispatch(failure(reportConstant.GET_SURVEYS_FAILURE, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
    });
  };
}

/**
 * get demographics filter data
 */
function getDemographics(filterData, successCallback = () => { }) {
  return dispatch => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(reportConstant.GET_DEMOGRAPHICS_REQUEST));
    reportService.getDemographics(filterData).then(data => {
      dispatch(request(uiConstant.LOADING_FALSE));
      dispatch(success(reportConstant.GET_DEMOGRAPHICS_SUCCESS, data));
      successCallback();
    }).catch(error => {
      dispatch(request(uiConstant.LOADING_FALSE));
      dispatch(failure(reportConstant.GET_DEMOGRAPHICS_FAILURE, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
    });
  };
}

/**
 * 
 * @param {*} filterData 
 * @param {*} successCallback 
 */
function demographicsByName(filterData, successCallback = () => { }) {
  return dispatch => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(reportConstant.GET_DEMOGRAPHICS_BY_NAME_REQUEST));
    reportService.demographicsByName(filterData).then(data => {
      dispatch(success(reportConstant.GET_DEMOGRAPHICS_BY_NAME_SUCCESS, data));
      dispatch(request(uiConstant.LOADING_FALSE));
      successCallback();
    }, error => {
        dispatch(request(uiConstant.LOADING_FALSE));
        dispatch(failure(reportConstant.GET_DEMOGRAPHICS_BY_NAME_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
      });
  };
}

/**
 * get comment questions
 */
function getCommentQuestions(filterData) {
  return dispatch => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(reportConstant.GET_COMMENT_QUESTIONS_REQUEST));
    reportService.getCommentQuestions(filterData).then(data => {
      dispatch(success(reportConstant.GET_COMMENT_QUESTIONS_SUCCESS, data));
      dispatch(request(uiConstant.LOADING_FALSE));
    },
      error => {
        dispatch(request(uiConstant.LOADING_FALSE));
        dispatch(failure(reportConstant.GET_COMMENT_QUESTIONS_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
      });
  };
}

/**
 * get comment questions
 */
function saveOrUpdateReport(filterData) {
  return dispatch => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(reportConstant.SAVE_REPORT_REQUEST));
    reportService.saveOrUpdateReport(filterData).then(data => {
      dispatch(success(reportConstant.SAVE_REPORT_SUCCESS, data));
      dispatch(request(uiConstant.LOADING_FALSE));
      dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
    },
      error => {
        dispatch(request(uiConstant.LOADING_FALSE));
        dispatch(failure(reportConstant.SAVE_REPORT_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
      });
  };
}

//update report Data
function updateReport(updatedData) {
  return {
    type: reportConstant.UPDATE_REPORT_DATA,
    data: updatedData
  }
}

//update Comment report Data
function updateCommentReport(updatedData) {
  return {
    type: reportConstant.UPDATE_COMMENT_REPORT_DATA,
    data: updatedData
  }
}

//reset report data to init state
function resetReportData() {
  return {type: reportConstant.RESET_REPORT_DATA}
}

/**
 * get selected report data
 */
function getReportData(id) {
  return dispatch => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(reportConstant.GET_REPORT_REQUEST));
    reportService.getReportData(id).then((data) => {
      dispatch(success(reportConstant.GET_REPORT_SUCCESS, data));
      dispatch(request(uiConstant.LOADING_FALSE));
    },
      error => {
        dispatch(request(uiConstant.LOADING_FALSE));
        dispatch(failure(reportConstant.GET_REPORT_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
      });
  };
}

/**
 * get selected report data
 */
function getCustomReportData(id) {
  return dispatch => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(reportConstant.GET_CUSTOM_REPORT_REQUEST));
    reportService.getCustomReportData(id).then((data) => {
      dispatch(success(reportConstant.GET_CUSTOM_REPORT_SUCCESS, data));
      dispatch(request(uiConstant.LOADING_FALSE));
    },
      error => {
        dispatch(request(uiConstant.LOADING_FALSE));
        dispatch(failure(reportConstant.GET_CUSTOM_REPORT_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
      });
  };
}

/**
 * get Benchmark Industry List
 */
function getBenchmarkIndustryList() {
  return dispatch => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(reportConstant.GET_BENCHMARK_INDUSTRY_LIST_REQUEST));
    reportService.getBenchmarkIndustryList().then(data => {
      dispatch(success(reportConstant.GET_BENCHMARK_INDUSTRY_LIST_SUCCESS, data));
      dispatch(request(uiConstant.LOADING_FALSE));
    },
      error => {
        dispatch(request(uiConstant.LOADING_FALSE));
        dispatch(failure(reportConstant.GET_BENCHMARK_INDUSTRY_LIST_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
      });
  };
}

//update Comment report Data
function reportLoading(type) {
  return dispatch => {
    if(type){
      dispatch(request(reportConstant.REPORT_LOADING_TRUE, true));
    } else{
      dispatch(request(reportConstant.REPORT_LOADING_FALSE, false));
    }
  };
}

// get subjects
function getSubjectsList(survey_ids = []) {
  return dispatch => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(reportConstant.GET_IR_ME_SUBJECTS_LIST_REQUEST));
    surveyService.getMESystemID({ survey_ids }).then(data => {
      dispatch(success(reportConstant.GET_IR_ME_SUBJECTS_LIST_SUCCESS, data));
      dispatch(request(uiConstant.LOADING_FALSE));
    },
      error => {
        dispatch(request(uiConstant.LOADING_FALSE));
        dispatch(failure(reportConstant.GET_IR_ME_SUBJECTS_LIST_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
      });
  };

}

  /**
 * get comment questions
 */
  function saveCustomReport(filterData) {
    return dispatch => {
      dispatch(request(uiConstant.LOADING_TRUE));
      dispatch(request(reportConstant.SAVE_CUSTOM_REPORT_REQUEST));
      reportService.saveCustomReport(filterData).then(data => {
        dispatch(success(reportConstant.SAVE_CUSTOM_REPORT_SUCCESS, data));
        dispatch(request(uiConstant.LOADING_FALSE));
        dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
        if (data.data && data.data._id) {
          history.push({
            pathname: `/reports/custom_report/${data.data._id}/dates`
          });
        }
      }).catch(error => {
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(uiConstant.LOADING_FALSE));
        dispatch(failure(reportConstant.SAVE_CUSTOM_REPORT_FAILURE, error));
        history.replace({
          pathname: '/reports/custom_report/new'
        });
        });
    };
  }

/**
 * get comment questions
 */
function editCustomReport(filterData) {
  return dispatch => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(reportConstant.UPDATE_CUSTOM_REPORT_REQUEST));
    reportService.editCustomReport(filterData).then(data => {
      dispatch(success(reportConstant.UPDATE_CUSTOM_REPORT_SUCCESS, data));
      dispatch(request(uiConstant.LOADING_FALSE));
      dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
    }).catch(error => {
        dispatch(request(uiConstant.LOADING_FALSE));
        dispatch(failure(reportConstant.UPDATE_CUSTOM_REPORT_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
      });
  };
}


  //update report Data
function updateCustomReport(updatedData) {
  return {
    type: reportConstant.UPDATE_CUSTOM_REPORT_DATA,
    data: updatedData
  }
  
}

//reset report data to init state
function resetCustomReportData() {
  return {type: reportConstant.RESET_CUSTOM_REPORT_DATA}
}

function getCustomReportDashboard(customData, successCallback = () => { }) {
  return dispatch => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(reportConstant.GET_CUSTOM_DASHBOARD_REQUEST));
    reportService.getCustomReportDashboard(customData).then((data) => {
      dispatch(success(reportConstant.GET_CUSTOM_DASHBOARD_SUCCESS, data));
      dispatch(request(uiConstant.LOADING_FALSE));
      successCallback(data.data)
    }, (error) => {
      dispatch(request(uiConstant.LOADING_FALSE));
      dispatch(failure(reportConstant.GET_CUSTOM_DASHBOARD_FAILURE, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
    });
  };
}

function getCustomReportChart(customData, successCallback = () => { }) {
  return dispatch => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(reportConstant.GET_CUSTOM_CHART_REQUEST));
    reportService.getCustomReportChart(customData).then((data) => {
      dispatch(success(reportConstant.GET_CUSTOM_CHART_SUCCESS, data));
      dispatch(request(uiConstant.LOADING_FALSE));
      successCallback(data.data)
    }, (error) => {
      dispatch(request(uiConstant.LOADING_FALSE));
      dispatch(failure(reportConstant.GET_CUSTOM_CHART_FAILURE, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
    });
  };


}

/**
 * get comment questions
 */
function getCustomYesnoData(filterData) {
  return dispatch => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(reportConstant.GET_CUSTOM_YESNO_CHART_REQUEST));
    reportService.getCustomYesnoData(filterData).then(data => {
      dispatch(success(reportConstant.GET_CUSTOM_YESNO_CHART_SUCCESS, data));
      dispatch(request(uiConstant.LOADING_FALSE));
    },
      error => {
        dispatch(request(uiConstant.LOADING_FALSE));
        dispatch(failure(reportConstant.GET_CUSTOM_YESNO_CHART_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
      });
  };
}

/**
 * get comment questions
 */
function getCustomDropdownData(filterData) {
  return dispatch => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(reportConstant.GET_CUSTOM_DROPDOWN_CHART_REQUEST));
    reportService.getCustomDropdownData(filterData).then(data => {
      dispatch(success(reportConstant.GET_CUSTOM_DROPDOWN_CHART_SUCCESS, data));
      dispatch(request(uiConstant.LOADING_FALSE));
    },
      error => {
        dispatch(request(uiConstant.LOADING_FALSE));
        dispatch(failure(reportConstant.GET_CUSTOM_DROPDOWN_CHART_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
      });
  };
}

/**
 * get comment questions
 */
function getCustomMatrixData(filterData) {
  return dispatch => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(reportConstant.GET_CUSTOM_MATRIX_CHART_REQUEST));
    reportService.getCustomMatrixData(filterData).then(data => {
      dispatch(success(reportConstant.GET_CUSTOM_MATRIX_CHART_SUCCESS, data));
      dispatch(request(uiConstant.LOADING_FALSE));
    },
      error => {
        dispatch(request(uiConstant.LOADING_FALSE));
        dispatch(failure(reportConstant.GET_CUSTOM_MATRIX_CHART_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
      });
  };
}

/**
 * get comment questions
 */
function getCustomDemographicData(filterData) {
  return dispatch => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(reportConstant.GET_CUSTOM_DEMOGRAPHIC_REQUEST));
    reportService.getCustomDemographicData(filterData).then(data => {
      dispatch(success(reportConstant.GET_CUSTOM_DEMOGRAPHIC_SUCCESS, data));
      dispatch(request(uiConstant.LOADING_FALSE));
    },
      error => {
        dispatch(request(uiConstant.LOADING_FALSE));
        dispatch(failure(reportConstant.GET_CUSTOM_DEMOGRAPHIC_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
      });
  };
}

  /**
   * get postKeyDriverData
   */
  function postKeyDriverData(filterData) {
    return dispatch => {

      dispatch(request(uiConstant.LOADING_TRUE));
      dispatch(request(reportConstant.GET_KEY_DRIVER_REQUEST));
      reportService.postKeyDriverData(filterData).then(resp => {
        dispatch(success(reportConstant.GET_KEY_DRIVER_SUCCESS, resp));
        dispatch(request(uiConstant.LOADING_FALSE));
      }, error => {
        dispatch(request(uiConstant.LOADING_FALSE));
        dispatch(failure(reportConstant.GET_KEY_DRIVER_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
      });
    };
  }   

/**
 * Get Key Driver Widget Data
 * @param {*} successCallback 
 */
function getKeyDriverData(successCallback = () => { }, loader = true) {
  return dispatch => {
    if (loader) {
      dispatch(request(uiConstant.LOADING_TRUE));
    }
    dispatch(request(reportConstant.GET_KEY_DRIVER_REQUEST));
    reportService.getKeyDriverData().then(resp => {
      dispatch(success(reportConstant.GET_KEY_DRIVER_SUCCESS, resp));
      dispatch(request(uiConstant.LOADING_FALSE));
      successCallback()
    }, error => {
      dispatch(request(uiConstant.LOADING_FALSE));
      dispatch(failure(reportConstant.GET_KEY_DRIVER_FAILURE, error));
    });
  };
}