import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Grid,
  Checkbox,
  FormControlLabel,
  Tooltip,
  TextField,
  Paper,
  Box
} from "@mui/material";
import { ReportsConstants } from "../../../../../config/constants";
import AutoCompleteComponent from "../../../../../components/AutoCompleteComponent/AutoCompleteComponent";
import TypographyComponent from "../../../../../components/TypographyComponent/TypographyComponent";
import { reportAction, alertAction } from "../../../../../redux/actions";
import ButtonComponent from '../../../../../components/ButtonComponent/ButtonComponent';
import SearchInputComponent from '../../../../../components/SearchInputCompoment/SearchInputComponent';

const DEFAULT_METADATA = {
  no_breakout: "No Breakout",
  "No Breakout": "no_breakout"
};

const YesNoChart = (props) => {
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState("");
  const [selectedQuestions, setSelectedQuestions] = useState([]);



  // Redux selectors
  const { customReportData } = useSelector((state) => state.report);
  const { yesNo: { yesno: yesnoData, metadataKeyname, metadataDispname } } = useSelector((state) => state.report);

  // Combine metadata with defaults
  const enrichedMetadataKeyname = { ...DEFAULT_METADATA, ...metadataKeyname };
  const enrichedMetadataDispname = { ...DEFAULT_METADATA, ...metadataDispname };

  // Process demographics data
  const demographicsList = yesnoData?.demographics || [];
  const sortedDemographics = demographicsList
    .map(demo => enrichedMetadataKeyname[demo])
    .sort((a, b) => a.localeCompare(b));
  const metadataWithRGI = ["No Breakout", ...sortedDemographics];
  const [selectedValue, setSelectedValue] = useState(metadataWithRGI[0] || "");

  const defaultvalues = () => {
    setSearchText("")
    setSelectedValue(metadataWithRGI[0] || "")
    setSelectedQuestions((questionTexts || [])?.map(q => q?.id))
  };

  // useEffect(() => {
  //   setSelectedValue(metadataWithRGI[0] || "");
  // }, [metadataWithRGI]);

  // Extract and process questions
  const questionTexts = yesnoData ?
    Object.entries(yesnoData)
      .filter(([key, value]) => key !== "demographics" && value?.OverallYesNo?.questionText)
      .map(([id, value]) => ({
        id,
        text: value.OverallYesNo.questionText
      })) : [];

  // Initialize selected questions and selected value when questions are loaded
  useEffect(() => {
    if (questionTexts?.length > 0) {
      setSelectedQuestions((questionTexts || [])?.map(q => q?.id));
      // setSelectedValue(metadataWithRGI[0] || "");
    }
  }, [yesnoData]);

  const filteredQuestions = (questionTexts || [])?.filter(question =>
    (question?.text?.toLowerCase())?.includes(searchText?.toLowerCase())
  );

  const handleDemographicChange = (value) => {
    setSelectedValue(value);
  };

  const handleQuestionToggle = (questionId) => {
    setSelectedQuestions(prev =>
      prev.includes(questionId)
        ? prev.filter(id => id !== questionId)
        : [...prev, questionId]
    );
  };

  const handleSelectAllQuestions = () => {
    const filteredIds = (filteredQuestions || [])?.map(q => q?.id);
    const allSelected = (filteredIds || [])?.every(id => selectedQuestions.includes(id));

    setSelectedQuestions(prev =>
      allSelected
        ? prev.filter(id => !filteredIds.includes(id))
        : [...new Set([...prev, ...filteredIds])]
    );
  };

  const handleClearAll = () => {
    setSelectedQuestions([]);
  };

  const checkDuplicateConfigs = (yesnoPreferences) => {
    const isDuplicate = (existingConfigs, newPreferences) => {
      const normalizeObject = (obj) => JSON.stringify(obj, Object.keys(obj).sort());
      const normalizedNewPreferences = normalizeObject(newPreferences);
      return Object.values(existingConfigs).some(config =>
        normalizeObject(config) === normalizedNewPreferences
      );
    };

    const existingyesnoConfigs = customReportData?.dashboardPreference?.yesno || {};

    const isDuplicateyesno = isDuplicate(existingyesnoConfigs, yesnoPreferences);

    if (isDuplicateyesno) {
      dispatch(alertAction.error('A chart with identical configuration already exists'));
      return true; // Return true if duplicates are found
    }

    return false; // Return false if no duplicates are found
  };

  const handleAddChart = () => {
    try {
      if (!selectedQuestions || selectedQuestions?.length === 0) {
        dispatch(alertAction.error(ReportsConstants.CUSTOM_QUESTION_INCLUDE_TEXT));
        return;
      }

      let chartNumber = `Yes/No by ${selectedValue}`;
      const updatedChartOrder = customReportData?.chartOrder || [];

      let count = 1;
      let uniqueChartNumber = chartNumber;
      while (updatedChartOrder.includes(uniqueChartNumber)) {
        uniqueChartNumber = `${chartNumber} ${count}`;
        count++;
      }
      const newChartPreferences = {
        rg: enrichedMetadataDispname[selectedValue] || "no_breakout",
        selectedQuestions: selectedQuestions
      };

      const hasDuplicates = checkDuplicateConfigs(newChartPreferences)
      if (hasDuplicates) {
        return;
      }


      const updatedDashboardPreference = {
        ...customReportData?.dashboardPreference,
        yesno: {
          ...customReportData?.dashboardPreference?.yesno,
          [uniqueChartNumber]: newChartPreferences
        }
      };

      const updatedReportData = {
        ...customReportData,
        chartOrder: [...updatedChartOrder, uniqueChartNumber],
        dashboardPreference: updatedDashboardPreference
      };
      dispatch(reportAction.updateCustomReport(updatedReportData));
      defaultvalues()
    } catch (error) {
      console.error('Error in handleAddChart:', error);
    }
  };

  const isQuestionSelected = (questionId) => {
    return selectedQuestions.includes(questionId);
  };

  const areAllFilteredQuestionsSelected = () => {
    return filteredQuestions.length > 0 &&
      filteredQuestions.every(question => selectedQuestions.includes(question.id));
  };


  return (
    <Grid display={'flex'} gap={'2rem'}>
      <Grid item flex={'1'}>
        <Grid>
          <TypographyComponent variant="h6" className="txtBold">
            {ReportsConstants.CUSTOM_QUESTION_INCLUDE_TEXT}
          </TypographyComponent>
        </Grid>

        <Grid item xs={12} style={{ paddingTop: '16px' }}>
          <Paper elevation={1}>
            <Box p={2}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <SearchInputComponent
                    id="Yes?Nosearch"
                    placeholder="Search"
                    value={searchText}
                    field="search"
                    showClearIcon={true}
                    fullWidth={true}
                    onInputChangeRequest={(searchField, searchKey) => setSearchText(searchKey)}
                    handleClearSearch={() => setSearchText("")}
                  />
                </Grid>

                <Grid item xs={12}>
                  {!searchText &&
                    <Grid container justifyContent="space-between" alignItems="center">
                      <Grid item>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={areAllFilteredQuestionsSelected()}
                              onChange={handleSelectAllQuestions}
                              color="primary"
                            />
                          }
                          label="Select all"
                        />
                      </Grid>
                      <Grid item>
                        <Box
                          component="span"
                          onClick={handleClearAll}
                          sx={{
                            cursor: 'pointer',
                            color: 'primary.main',
                            textDecoration: 'underline'
                          }}
                        >
                          {ReportsConstants.CUSTOM_CLEAR_ALL}
                        </Box>
                      </Grid>
                    </Grid>
                  }

                  <Box sx={{ maxHeight: '300px', overflowY: 'auto', mt: 1 }}>
                    <Grid container spacing={1}>
                      {filteredQuestions.map(({ id, text }) => (
                        <Grid item xs={12} key={id}>
                          <Tooltip title={text} arrow>
                            <FormControlLabel
                              sx={{ ml: 0 }} // Remove default margin
                              control={
                                <Checkbox
                                  checked={isQuestionSelected(id)}
                                  onChange={() => handleQuestionToggle(id)}
                                  color="primary"
                                  sx={{ ml: 1 }} // Add specific left margin to checkbox
                                />
                              }
                              label={text}
                            />
                          </Tooltip>
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={4} sx={{ display: 'flex', alignItems: 'center' }}>
              <TypographyComponent variant="h6" className="txtBold">
                {ReportsConstants.CUSTOM_VIEW_BY}
              </TypographyComponent>
            </Grid>
            <Grid item xs={8}>
              <AutoCompleteComponent
                name="demographics"
                suggestions={metadataWithRGI}
                handleAutoCompolete={handleDemographicChange}
                suggestionType="array"
                value={selectedValue}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item display={'inline-flex'} flexDirection={'column'} flexBasis={'200px'} justifyContent={'flex-end'}>
        <Grid display={'flex'} flexDirection={'column'} gap={'10px'}>
          <ButtonComponent
            color="primary"
            pageClassName="buttonOrange fontSize14"
            title="+ Add chart to report"
            handleClick={handleAddChart}
          />
            {/* <TypographyComponent variant="h6">
            {ReportsConstants.CUSTOM_ADD_CHART_TEXT}
            </TypographyComponent> */}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default YesNoChart;