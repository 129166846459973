import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import withStyles from '@mui/styles/withStyles';
import { Link } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import _ from "lodash";

// Import Styles and Image
import ReportsStyles from "../../../ReportsStyles";

// Import Component
import TypographyComponent from "../../../../../components/TypographyComponent/TypographyComponent";
import SelectFieldComponent from "../../../../../components/SelectFieldComponent/SelectFieldComponent";
import AutoCompleteComponent from "../../../../../components/AutoCompleteComponent/AutoCompleteComponent";
import MultiSelectComponent from "../../../../../components/MultiSelectComponent/MultiSelectComponent";
import ComentQuestions from "./ComentQuestions";

// import constants
import { ReportsConstants, errorsConstants, REPORT_TYPE_CONSTANTS, surveyTypes } from "../../../../../config/constants";
import { accessReportsList } from '../../../../../config/pageAccess'

//Import Actions
import { reportAction } from "../../../../../redux/actions";

const autoCompleteReports = [REPORT_TYPE_CONSTANTS.RAW_DATA, REPORT_TYPE_CONSTANTS.RED_FLAG_REPORT, REPORT_TYPE_CONSTANTS.REHIRE_REPORT]
const ME_REPORTS = [REPORT_TYPE_CONSTANTS.GROUP_REPORT, REPORT_TYPE_CONSTANTS.INDIVIDUAL_REPORT]


export const getReportsList = (user, report,isSaveReport) => {

  const { reportData, allSurveys } = report;
  const { surveys } = reportData;

  //check rehire and compliance question flag
  let hasRehireBaseQues = false;
  let hasComplianceBaseQues = false;
  if (surveys && allSurveys) {
    surveys.map(i => {
      let selSurvey = allSurveys.filter(f => (f._id + "" === i._id + ""))
      if (selSurvey.length && selSurvey[0]["hasRehireBaseQues"]) {
        hasRehireBaseQues = true;
      }

      if (selSurvey.length && selSurvey[0]["hasComplianceBaseQues"]) {
        hasComplianceBaseQues = true;
      }
    })
  }

  const hasMESurvey = surveys.filter(f => f.type === surveyTypes.MULTI_RATER_FEEDBACK).length > 0

  const { R_REPORTS } = accessReportsList(null, user)
  let reportsList = [...R_REPORTS]

  // if selected survey has ME Survey 
  if (hasMESurvey) {
    reportsList = [
      REPORT_TYPE_CONSTANTS.GROUP_REPORT,
      REPORT_TYPE_CONSTANTS.INDIVIDUAL_REPORT,
      REPORT_TYPE_CONSTANTS.PARTICIPATION_REPORT,
      // REPORT_TYPE_CONSTANTS.RAW_DATA
    ]
    if (reportsList.indexOf(REPORT_TYPE_CONSTANTS.DASHBOARD_REPORT) === -1 && reportData.reportType === "Dashboard Report") {
      reportsList.push(REPORT_TYPE_CONSTANTS.DASHBOARD_REPORT);
    }
    return reportsList
  } else {
    reportsList = reportsList.filter(f => ME_REPORTS.indexOf(f) === -1)
  }

  //raw data report
  if (reportsList.indexOf(REPORT_TYPE_CONSTANTS.RAW_DATA) === -1 && surveys.length === 1 && (user.role === "superadmin" || user.access.indexOf("Raw Data") > -1)) {
    reportsList.push(REPORT_TYPE_CONSTANTS.RAW_DATA);
  } else if (reportsList.indexOf(REPORT_TYPE_CONSTANTS.RAW_DATA) > -1 && (surveys.length > 1 || (user.role !== "superadmin" && user.access.indexOf("Raw Data") === -1))) {
    const rawDataIndex = reportsList.indexOf(REPORT_TYPE_CONSTANTS.RAW_DATA);
    reportsList.splice(rawDataIndex, 1);
  }

  //red flag
  if (reportsList.indexOf(REPORT_TYPE_CONSTANTS.RED_FLAG_REPORT) === -1 && (user.role === "superadmin" || user.access.indexOf("Compliance") > -1)) {
    reportsList.push(REPORT_TYPE_CONSTANTS.RED_FLAG_REPORT);
  } else if (reportsList.indexOf(REPORT_TYPE_CONSTANTS.RED_FLAG_REPORT) > -1 && (user.role !== "superadmin" && user.access.indexOf("Compliance") === -1)) {
    const rawDataIndex = reportsList.indexOf(REPORT_TYPE_CONSTANTS.RED_FLAG_REPORT);
    reportsList.splice(rawDataIndex, 1);
  }

  //rehire repot
  if (reportsList.indexOf(REPORT_TYPE_CONSTANTS.REHIRE_REPORT) === -1 && (user.role === "superadmin" || user.access.indexOf("Rehire Potential") > -1)) {
    reportsList.push(REPORT_TYPE_CONSTANTS.REHIRE_REPORT);
  } else if (reportsList.indexOf(REPORT_TYPE_CONSTANTS.REHIRE_REPORT) > -1 && (user.role !== "superadmin" && user.access.indexOf("Rehire Potential") === -1)) {
    const rawDataIndex = reportsList.indexOf(REPORT_TYPE_CONSTANTS.REHIRE_REPORT);
    reportsList.splice(rawDataIndex, 1);
  }

  //dashboard report
  if (reportsList.indexOf(REPORT_TYPE_CONSTANTS.DASHBOARD_REPORT) === -1 && reportData.reportType === "Dashboard Report") {
    reportsList.push(REPORT_TYPE_CONSTANTS.DASHBOARD_REPORT);
  } else if (reportsList.indexOf(REPORT_TYPE_CONSTANTS.DASHBOARD_REPORT) > -1 && reportData.reportType !== "Dashboard Report") {
    const index = reportsList.indexOf(REPORT_TYPE_CONSTANTS.DASHBOARD_REPORT);
    reportsList.splice(index, 1);
  }

  // filter out rehire if no base question
  if (!hasRehireBaseQues) {
    const index = reportsList.indexOf(REPORT_TYPE_CONSTANTS.REHIRE_REPORT);
    if (index >= 0) {
      reportsList.splice(index, 1);
    }
  }

  // filter out red flag if no hasComplianceBaseQues base question
  if (!hasComplianceBaseQues) {
    const index = reportsList.indexOf(REPORT_TYPE_CONSTANTS.RED_FLAG_REPORT);
    if (index >= 0) {
      reportsList.splice(index, 1);
    }
  }

  if(!user.access.includes("Comments") && !isSaveReport) {
    const index = reportsList.indexOf("Comment Report")
    if (index > -1) {
      reportsList.splice(index, 1);
    }
  }
  
  return reportsList;
}

function SelectReport(props) {
  // Define Dispatch
  const dispatch = useDispatch();

  // get props
  const { classes, isSaveReport, isDrawer } = props;

  // Get surveys Data
  const {
    reportData,
    commentQuestions,
    allDemographics,
    commentReport,
    benchmarkIndustryList,
    subjects,
    loading_r
  } = useSelector((state) => state.report);
  
  
  //get reducer data
 const { user } = useSelector((state) => state.profile);
 const { metadata } = useSelector((state) => state.metadata);
 const { allSurveys } = useSelector((state) => state.filter);

  const reportsList = getReportsList(user, { reportData, allSurveys },isSaveReport)

  const hasMESurvey = (reportData.surveys || []).filter(f => f.type === surveyTypes.MULTI_RATER_FEEDBACK).length > 0

  // call indunstry becnmark
  useEffect(() => {
    if (reportData.reportType === REPORT_TYPE_CONSTANTS.BENCHMARK_REPORT) {
      dispatch(reportAction.getBenchmarkIndustryList());
    }
    if (hasMESurvey) {
      dispatch(
        reportAction.getDemographics({
          metadata: [],
          surveys: reportData.surveys,
          date: reportData.date,
        })
      );
    }
  }, [hasMESurvey])

  useEffect(() => {
    if (reportData.reportType === REPORT_TYPE_CONSTANTS.INDIVIDUAL_REPORT) {
      dispatch(reportAction.getSubjectsList(
        reportData.surveys.map(m => m._id)
      ));
    }
    if(reportData.reportType === REPORT_TYPE_CONSTANTS.COMMENT_REPORT && !isSaveReport) {
      reportData.selectedDemographic = "No breakout"
    } else if(reportData.reportType === REPORT_TYPE_CONSTANTS.COMMENT_REPORT && isSaveReport){
      dispatch(reportAction.getCommentQuestions(reportData));
    }
  }, [reportData.reportType])

  //demographics dropdown datas for different reports
  let defaultData = [];
  if (reportData.reportType === REPORT_TYPE_CONSTANTS.COMMENT_REPORT) {
    defaultData = [
      { key: "No breakout", value: "No breakout", name: "No breakout" },
      { key: "Topics", value: "Topics", name: "Topics" },
    ];
  } else if (reportData.reportType === REPORT_TYPE_CONSTANTS.PARTICIPATION_REPORT) {
    defaultData = [];
  } else {
    defaultData = [{ key: "No breakout", value: "No breakout", name: "No breakout" }];
  }
  let demographicsData = [];
  if(allDemographics) {
    Object.keys(allDemographics).map((item) => {
      if (item !== "_id") {
        const currentMData = metadata.filter((e) => e.key === item)[0];
        if (currentMData && currentMData.visible === true && currentMData.visibleInFilter === true) {
          demographicsData.push({ key: item, value: currentMData["value"], name: currentMData["value"] });
        }
      }
    });
  }
  demographicsData = _.sortBy(demographicsData, "value");
  demographicsData = [...defaultData, ...demographicsData];

  //update report type
  const updateReportType = (e) => {
    let updatedReportData;
    // set default demographic to 'no breakout' for multi select
    if ([REPORT_TYPE_CONSTANTS.RAW_DATA, REPORT_TYPE_CONSTANTS.RED_FLAG_REPORT, REPORT_TYPE_CONSTANTS.REHIRE_REPORT].indexOf(e.value) !== -1) {
      updatedReportData = { ...reportData, reportType: e["value"], selectedDemographic: ['No breakout'] }
    } else {
      updatedReportData = { ...reportData, reportType: e["value"] }
    }

    dispatch(reportAction.updateReport(updatedReportData));
    if (updatedReportData.reportType === REPORT_TYPE_CONSTANTS.COMMENT_REPORT) {
      dispatch(reportAction.getCommentQuestions(updatedReportData));
    }

    if (updatedReportData.reportType === REPORT_TYPE_CONSTANTS.BENCHMARK_REPORT) {
      dispatch(reportAction.getBenchmarkIndustryList());
    }

  };

  // Clear all data 
  const handleClear = (e) => {
    let clearData = { reportType: "", selectedDemographic: "" }
    if (isDrawer) {
      clearData = { selectedDemographic: "" }
    }
    const updatedReportData = { ...reportData, ...clearData };
    dispatch(reportAction.updateReport(updatedReportData))
  }

  //change selected demographic data for reports
  const handleDemographicsChange = (e) => {
    const updatedReportData = { ...reportData, selectedDemographic: e["value"] };
    dispatch(reportAction.updateReport(updatedReportData))
  }

  // change selected subject
  const handleSubjectChange = (e) => {
    const updatedReportData = { ...reportData, selectedSubject: e["value"], subject_name: e["name"] };
    dispatch(reportAction.updateReport(updatedReportData))
  }

  //autocomplete component change
  const handleAutoCompleteChange = (event) => {
    let updatedReportData = { ...reportData, };

    if (!Array.isArray(updatedReportData.selectedDemographic)) {
      updatedReportData.selectedDemographic = [];
    }
    if (event.value && Array.isArray(event.value) && event.value.length) {
      let resArr = []
      for (let i = 0; i < event.value.length; i++) {
        let keyName = demographicsData.filter(d => (d.value === event.value[i] || d.key === event.value[i]))
        if (keyName.length) {
          resArr.push(keyName[0]["key"])
        } else {
          resArr.push(event.value[i])
        }
      }
      event.value = resArr;
    }
    // if (event.event.target.getAttribute('aria-selected') === 'false' && event.event.target.getAttribute('data-option-index') === '0') {
    if (event && event.value && event.value.includes("No breakout") && autocompleteValue()[0] && autocompleteValue()[0] !== "No breakout" || event && event.value && event.value.includes("No breakout") && autocompleteValue().length === 0) {
      updatedReportData = { ...reportData, selectedDemographic: ['No breakout'] };
    } else {
      updatedReportData = {
        ...reportData,
        selectedDemographic: event.value.filter(d => d !== 'No breakout')
      };
    }
    dispatch(reportAction.updateReport(updatedReportData))
  }

  //update benchmark data
  const handleAutoCompleteChangeBM = (event) => {
    let updatedReportData = { ...reportData, };
    if (event.name === 'Industry') {
      updatedReportData = { ...reportData, selectType: event["value"] };
    } else {
      updatedReportData = { ...reportData, selectedIndustry: event["value"] };
    }
    dispatch(reportAction.updateReport(updatedReportData))
  }

  //multiselect dropdown value
  const autocompleteValue = () => {
    let keyArr = Array.isArray(reportData.selectedDemographic) ? reportData.selectedDemographic : []
    if (keyArr.length) {
      let resArr = []
      for (let i = 0; i < keyArr.length; i++) {
        let keyName = demographicsData.filter(d => (d.value === keyArr[i] || d.key === keyArr[i]))
        if (keyName.length) {
          resArr.push(keyName[0]["value"])
        } else {
          resArr.push(keyArr[i])
        }
      }
      keyArr = resArr;
    }
    return keyArr;
  }

  const renderSelectDemographic = () => {
    if (reportData.reportType === REPORT_TYPE_CONSTANTS.BENCHMARK_REPORT) {
      return null;
    } else if (reportData.reportType === REPORT_TYPE_CONSTANTS.PARTICIPATION_REPORT && hasMESurvey) {
      return null;
    } else {
      return (
        <Grid item xs={isDrawer ? 12 : 3}>
          {reportData.reportType !== "Dashboard Report" &&
            <React.Fragment>
              <TypographyComponent variant="h6">
                {ReportsConstants.DEMOGRAPHIC}
              </TypographyComponent>
              {autoCompleteReports.indexOf(reportData.reportType) === -1 ? (
                <AutoCompleteComponent
                  id="demographicsss"
                  name="SelectDemographics"
                  className="heightone"
                  suggestions={[
                    { value: null, name: "Select a demographic" },
                    ...demographicsData
                  ]}
                  handleAutoCompolete={(e) => handleDemographicsChange({ value: e.key || "" })}
                  value={reportData.selectedDemographic && !Array.isArray(reportData.selectedDemographic) ? {
                    ...demographicsData.filter(f => reportData.selectedDemographic === f.key)[0]
                  } : { value: null, name: "Select a demographic" }}
                  extraProps={{
                    classes: {
                      popper: 'select-demographics-popper'
                    }
                  }}
                >
                </AutoCompleteComponent>
              ) : (
                <MultiSelectComponent
                  name={"demographic"}
                  isArrayObjects={true}
                  suggestions={demographicsData.map(item => {
                    return item.value
                  })}
                  handleAutoCompolete={(name, value, event) => {
                    handleAutoCompleteChange({ name, value, event });
                  }}
                  value={autocompleteValue()}
                />
              )
              }
            </React.Fragment>
          }
        </Grid>
      )
    }
  }

  // Show select subject for Individual report alone
  const renderSelectSubject = () => {
    if (reportData.reportType === REPORT_TYPE_CONSTANTS.INDIVIDUAL_REPORT) {
      return (
        <Grid item xs={isDrawer ? 12 : 3}>
          <TypographyComponent variant="h6">
            {ReportsConstants.SELECT_SUBJECT}
          </TypographyComponent>
          <AutoCompleteComponent
            id="subject_id"
            name="SelectSubject"
            className="heightone"
            suggestions={[
              { value: null, name: "Select a Subject" },
              ...subjects
            ]}
            handleAutoCompolete={(e) => handleSubjectChange({ value: e.key || "", name: e.value })}
            value={reportData.selectedSubject && !Array.isArray(reportData.selectedSubject) ? {
              ...subjects.filter(f => reportData.selectedSubject === f.key)[0]
            } : { value: null, name: "Select a Subject" }}
            extraProps={{
              classes: {
                popper: 'select-demographics-popper'
              }
            }}
          />
        </Grid>
      )
    } else {
      return null
    }
  }

  return (
    <React.Fragment>
      {
        !isDrawer && (
          <Grid container>
            {!isSaveReport ? 
            <Grid item sm={isDrawer ? 12 : 2} className={`${classes.clearAllPosition} ${isDrawer ? 'drawer' : ''}`}>
              <Link
                className={`cursor-pointer colorTheme right `}
                onClick={(e) => handleClear(e)}
              >
                {"Clear all"}
              </Link>
            </Grid>
            :""}
            <Grid item sm={isDrawer ? 12 : 10}>
              {/* {reportData.reportType} */}
            </Grid>
          </Grid>
        )
      }
      <Grid container className="pt-1 pb-1" spacing={2}>
        {
          !isDrawer && (
            <Grid item xs={isDrawer ? 12 : 3}>
              <TypographyComponent variant="h6">
                {ReportsConstants.REPORT_TYPE}
              </TypographyComponent>
              <SelectFieldComponent
                select
                name="reportType"
                value={reportData ? reportData.reportType : ""}
                fullWidth={true}
                disabled={isSaveReport || false}
                handleChange={(e) => updateReportType(e)}
                options={reportsList}
                validators={['required']}
                errorMessages={['Required']}
              />
            </Grid>
          )
        }
        {reportData.reportType === REPORT_TYPE_CONSTANTS.COMMENT_REPORT &&
          commentQuestions.length > 0 && (
            <ComentQuestions
              classes={classes}
              commentQuestions={commentQuestions}
              commentReport={commentReport}
              isDrawer={isDrawer}
              handleClear={handleClear}
            />
          )}
        {!hasMESurvey || reportData.reportType === REPORT_TYPE_CONSTANTS.PARTICIPATION_REPORT ? renderSelectDemographic() : renderSelectSubject()}
        {reportData.reportType === REPORT_TYPE_CONSTANTS.BENCHMARK_REPORT &&
          <Grid item xs={isDrawer ? 12 : 3}>
            <TypographyComponent variant="h6">
              {"Type"}
            </TypographyComponent>
            {
               loading_r['industry'] && (
                <Grid justifyContent={"center"} style={{display: 'flex'}}>
                  <CircularProgress size={30} />
                </Grid>
              )
            }
            <AutoCompleteComponent
              name="Industry"
              className="heightone"
              suggestions={benchmarkIndustryList}
              handleAutoCompolete={(e, n) => handleAutoCompleteChangeBM({ value: e, name: n })}
              suggestionType="array"
              value={reportData.selectType || "Global"}
              validators={["required"]}
              errorMessages={[errorsConstants.required]}
            ></AutoCompleteComponent>
          </Grid>
        }
        {
          reportData.reportType === REPORT_TYPE_CONSTANTS.RED_FLAG_REPORT &&
          <Grid item xs={12}>
            <TypographyComponent title={ReportsConstants.RED_FLAG_INFO} variant='p' />
          </Grid>
        }
      </Grid>
    </React.Fragment>
  );
}
// default props
SelectReport.defaultProps = {
  classes: {},
  isSaveReport: false,
  isDrawer: false
};

// prop types
SelectReport.propTypes = {
  classes: PropTypes.object,
  isSaveReport: PropTypes.bool,
  isDrawer: PropTypes.bool,
};
export default withStyles(ReportsStyles)(SelectReport);
