import React, { useState, useMemo, useCallback, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { Grid, Radio, RadioGroup, FormControlLabel, IconButton } from "@mui/material";
import AutoCompleteComponent from "../../../../../components/AutoCompleteComponent/AutoCompleteComponent";
import TypographyComponent from "../../../../../components/TypographyComponent/TypographyComponent";
import SelectFieldComponent from '../../../../../components/SelectFieldComponent/SelectFieldComponent';
import { reportAction, alertAction } from '../../../../../redux/actions';
import { ReportsConstants } from "../../../../../config/constants";
import _ from 'lodash';
import ButtonComponent from '../../../../../components/ButtonComponent/ButtonComponent';
import { Filter } from '../../../../../assets/images/svgComponents/ImageComponent';
import ViewBySelectionPopup from "./ViewBySelectionPopup";
import { generateKey } from '../../../../../helpers';

const HotspotChart_Custom = (props) => {
  const dispatch = useDispatch();
  const report = useSelector((state) => state.report);
  const { metadata } = useSelector((state) => state.metadata);
  const { role } = useSelector(state => state.profile.user)
  const { allDemographics, allDemographicsItems, customReportData, hlsDemographic } = report
  const keydriver = useSelector(state => state.dashboard.dashboardData.keydriver);
  const isKeyDriver = keydriver && keydriver?.length > 0;

  // Filter metadata based on keys from allDemographics
  const filteredMetadata = (metadata || [])?.filter((e) => Object.keys(allDemographics || {}).includes(e.key));

  const preferences = customReportData?.dashboardPreference?.hotspot;
  const metadataWithRGI = _.orderBy(filteredMetadata, ["value"], ["asc"]);

  const viewByOptions = ReportsConstants?.VIEW_BY_NAME.filter(item => {
    if (!isKeyDriver) {
      // Filter out keyDrivers if isKeyDriver is false
      return ["category", "question"].includes(item.value);
    }
    // Otherwise, include "keyDrivers" along with the other options
    return ["category", "question", "keyDrivers"].includes(item.value);
  });

  // Initialize state with default or existing values
  const defaultDemographic = metadataWithRGI?.some((item) => item.value === "Tenure Groupings") ? "Tenure Groupings" : metadataWithRGI?.[0]?.value ?? "";
  const defaultViewBy = viewByOptions?.[0] ?? "";
  const defaultSortOrder = preferences?.sort_by ?? 'asc';
  const defaultSelectedRG = metadataWithRGI.find(item => item.value === defaultDemographic)?.key ?? "";
  const defaultOptions = hlsDemographic?.filterCountObject?.[defaultSelectedRG] ?? [];
  const defaultSort = defaultOptions?.[0]?._id ?? "";

  const [selectedValue, setSelectedValue] = useState(defaultDemographic);
  const [selectView, setSelectView] = useState(defaultViewBy.name);
  const [sortOrder, setSortOrder] = useState(defaultSortOrder);
  const [selectedSort, setSelectedSort] = useState(defaultSort || "Overall");
  const [popupOpen, setPopupOpen] = useState(false);
  const [activeFilter, setActiveFilter] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [filtersApplied, setFiltersApplied] = useState({});

  const defaultvalues = () => {
    setSelectedValue(defaultDemographic)
    setSelectView(defaultViewBy.name)
    setSortOrder(defaultSortOrder)
    setSelectedSort(defaultSort)
    setFiltersApplied({})
  };

  const view_by_selection = useMemo(() => {
    const selectValue = ReportsConstants.VIEW_BY_NAME.find(view => view.name === selectView)?.value;
    switch (selectValue) {
      case "category":
        const category = hlsDemographic?.categoryResult?.category;
        // Transform the array into a single object with unique keys
        const categoryObject = {};
        category?.forEach(cat => {
          const key = generateKey(cat); // Generate a unique key for each question
          categoryObject[key] = cat;   // Map the key to the question
        });
        return categoryObject
      case "question":
        const questions = hlsDemographic?.questionResult?.question;
        // Transform the array into a single object with unique keys
        const questionObject = {};
        questions?.forEach(question => {
          const key = generateKey(question); // Generate a unique key for each question
          questionObject[key] = question;   // Map the key to the question
        });
        return questionObject;
      case "keyDrivers":
        const keyDriversObject = {};
        keydriver?.forEach(driver => {
          const key = generateKey(driver.question); // Generate a unique key for each question
          keyDriversObject[key] = driver.question;  // Map the key to the question
        });
        return keyDriversObject
      default:
        return [];
    }
  }, [hlsDemographic, selectView]);

  // Initialize default values in Redux store
  useEffect(() => {
    // Dispatch initial demographics data if needed
    if (defaultSelectedRG) {
      dispatch(reportAction.demographicsByName({
        metadata: [],
        surveys: customReportData.surveys,
        date: customReportData.date,
        demographic: defaultSelectedRG,
        search: "",
      }));
    }
  }, []);

  const selectedDemographic = (metadataWithRGI || [])?.find(item => item?.value === selectedValue)?.key;
  const demooptions = allDemographicsItems?.[selectedDemographic] ?? [];

  const handleDemographicChange = (value) => {
    setSelectedValue(value);
    const selectedRG = (metadataWithRGI || [])?.find(item => item?.value === value)?.key;

    dispatch(reportAction.demographicsByName({
      metadata: [],
      surveys: customReportData.surveys,
      date: customReportData.date,
      demographic: selectedRG,
      search: "",
    }));
    setSelectedSort("Overall");
  };

  const handleFilterClick = useCallback((filterType, event) => {
    setActiveFilter(filterType);
    setAnchorEl(event.currentTarget);
    setPopupOpen(true);
  }, []);

  const handlePopupClose = useCallback(() => {
    setPopupOpen(false);
    setAnchorEl(null);
    setActiveFilter(null);
  }, []);

  const categoryViewSelection = useMemo(() => {
    return hlsDemographic?.categoryResult?.category?.reduce((obj, cat) => ({
      ...obj,
      [generateKey(cat)]: cat
    }), {});
  }, [hlsDemographic?.categoryResult?.category]);


  const handleViewByChange = (type) => {
    setSelectView(type.value);
  };

  const handleSortOrderChange = (event) => {
    const newSortOrder = event.target.value;
    setSortOrder(newSortOrder);
  };

  const handleSortByChange = ({ value }) => {
    setSelectedSort(value);
  };
  const getSortByOptions = () => {
    const selectValue = ReportsConstants.VIEW_BY_NAME.find(view => view.name === selectView)?.value;
    let temp = []
    const surveyTypes = [...new Set(customReportData?.surveys?.map((item) => item.type))];
    const surveyType = surveyTypes?.[0];
    if (selectValue === 'keyDrivers' || selectValue === 'question') {
      temp = [selectView, 'Category', 'Overall']
      if (surveyType === 'Engagement' && customReportData?.surveys?.length === 1 && role !== "clientadmin") {
        temp.splice(1, 0, 'Key Driver')
      }
    }
    else {
      temp = [selectView, 'Overall']
    }
    // Uncomment to get all demography values
    // const sort = [...temp, ...demooptions.map(option => option._id)];
    const sort = [...temp];
    return sort;
  };

  const checkDuplicateConfigs = (hotspotPreferences) => {
    const isDuplicate = (existingConfigs, newPreferences) => {
      const normalizeObject = (obj) => JSON.stringify(obj, Object.keys(obj).sort());
      const normalizedNewPreferences = normalizeObject(newPreferences);
      return Object.values(existingConfigs).some(config =>
        normalizeObject(config) === normalizedNewPreferences
      );
    };

    const existinghotspotConfigs = customReportData?.dashboardPreference?.hotspot || {};

    const isDuplicatehotspot = isDuplicate(existinghotspotConfigs, hotspotPreferences);

    if (isDuplicatehotspot) {
      dispatch(alertAction.error('A chart with identical configuration already exists'));
      return true; // Return true if duplicates are found
    }

    return false; // Return false if no duplicates are found
  };


  const handleAddChart = () => {
    try {

      let chartNumber = 'HotSpot by ' + selectedValue;
      const updatedChartOrder = customReportData.chartOrder || [];
      const sortoption = getSortByOptions().map(option => {
        const match = ReportsConstants.VIEW_BY_NAME.find(viewBy => viewBy.name === option);
        return match ? 'text' : option; // Replace with 'text' if match found, otherwise keep original
      });

      const sorting = sortoption.reduce((acc, option) => {
        acc[option] = option === selectedSort
          ? "asc" // If the option matches selectedSort, set "asc"
          : ""; // Otherwise, set an empty string
        return acc;
      }, {});

      // Check if selectedSort has no match in sortoption
      if (!sortoption.includes(selectedSort)) {
        sorting["text"] = "asc"; // Add "text" with value "asc" if no match is found
      }

      let count = 1;
      let uniqueChartNumber = chartNumber;

      while (updatedChartOrder.includes(uniqueChartNumber)) {
        uniqueChartNumber = `${chartNumber} ${count}`;
        count++;
      }
      const select_sort = ReportsConstants.VIEW_BY_NAME.find(view => view.name === selectedSort)?.value || selectedSort;
      let select_sort_convert = select_sort.charAt(0).toUpperCase() + select_sort.slice(1)
      const newChartPreferences = {
        type: (ReportsConstants?.VIEW_BY_NAME || [])?.find(item => item?.name === selectView)?.value === 'keyDrivers' ? 'keyDriver' : (ReportsConstants?.VIEW_BY_NAME || [])?.find(item => item?.name === selectView)?.value,
        rg: (metadataWithRGI || [])?.find(item => item?.value === selectedValue)?.key,
        sorting: sorting,
        filtersApplied: filtersApplied
      };

      const hasDuplicates = checkDuplicateConfigs(newChartPreferences)
      if (hasDuplicates) {
        return;
      }

      const updatedDashboardPreference = {
        ...customReportData?.dashboardPreference,
        hotspot: {
          ...customReportData?.dashboardPreference?.hotspot,
          [uniqueChartNumber]: newChartPreferences
        }
      };
      const updatedReportData = {
        ...customReportData,
        chartOrder: [...updatedChartOrder, uniqueChartNumber],
        dashboardPreference: updatedDashboardPreference
      };
      dispatch(reportAction.updateCustomReport(updatedReportData));
      defaultvalues()

    } catch (error) {
      console.error("Error in handleAddChart:", error);
    }
  };

  const renderFilterSection = () => {
    const filtervalue = ReportsConstants?.VIEW_BY_NAME?.find(item => item.name === selectView)?.value

    return (
      <>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Grid flex={'1'}>
            <SelectFieldComponent
              select
              name="viewBy"
              value={selectView || defaultViewBy.name || ""}
              fullWidth
              handleChange={handleViewByChange}
              options={viewByOptions.map(option => option.name)}
              validators={['required']}
              errorMessages={['Required']}
            />
          </Grid>
          <IconButton
            aria-describedby={'hlsViewByPopover'}
            onClick={(e) => handleFilterClick(filtervalue, e)}
            className={`icon-hover ${filtersApplied?.[ReportsConstants?.VIEW_BY_NAME?.find(item => item.name === selectView)?.value === "keyDrivers" ? "keyDriver" : ReportsConstants?.VIEW_BY_NAME?.find(item => item.name === selectView)?.value] ? 'active' : ''}`}
          >
            {Filter()}
          </IconButton>
        </div>

        {(filtervalue === 'question' || filtervalue === 'keyDrivers') && (
          <>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Grid flex={'1'}>
                <TypographyComponent variant="h6" className="txtBold">
                  Category filter
                </TypographyComponent>
              </Grid>
              <IconButton
                aria-describedby={'hlsCategoryPopover'}
                onClick={(e) => handleFilterClick('category', e)}
                className={`icon-hover ${filtersApplied?.['category'] ? 'active' : ''}`}
              >
                {Filter()}
              </IconButton>
            </div>
          </>
        )}

        {/* Single ViewBySelectionPopup instance */}
        <ViewBySelectionPopup
          open={popupOpen}
          anchorEl={anchorEl}
          onClose={handlePopupClose}
          selectedDemographic={selectedDemographic}
          selectedValue={activeFilter || filtervalue}
          viewBySelection={
            activeFilter === 'category'
              ? categoryViewSelection
              : view_by_selection
          }
          filtersApplied={filtersApplied}
          onApply={(appliedFilters) => {
            setFiltersApplied(appliedFilters);
          }}
          type={"hotspot"}
        />
      </>
    );
  };

  return (
    <Grid container width={'100%'} gap={'2rem'}>
      <Grid item xs>
        {/* X-axis Selection */}
        <Grid display={'flex'} alignItems={'center'} width={'100%'}>
          <Grid flex={'1'}>
            <TypographyComponent variant="h6" className="txtBold">
              {ReportsConstants.CUSTOM_VIEW_BY_X}
            </TypographyComponent>
          </Grid>
          <Grid flex={'1'}>
            <AutoCompleteComponent
              name="demographics"
              suggestions={metadataWithRGI.map(item => item.value)}
              handleAutoCompolete={handleDemographicChange}
              suggestionType="array"
              value={selectedValue}
            />
          </Grid>
        </Grid>

        {/* Y-axis Selection */}
        <Grid display={'flex'} alignItems={'center'} width={'100%'}>
          <Grid flex={'1'}>
            <TypographyComponent variant="h6" className="txtBold">
              {ReportsConstants.CUSTOM_VIEW_BY_Y}
            </TypographyComponent>
          </Grid>
          <Grid flex={'1'}>
            {renderFilterSection()}
          </Grid>
        </Grid>

        <Grid display={'flex'} flexWrap={'wrap'} alignItems={'center'} width={'100%'}>
          <Grid flex={'1'}>
            <TypographyComponent
              className="txtBold"
              title={ReportsConstants.CUSTOM_SORT_BY}
              variant="h6" />
          </Grid>
          <Grid flex={'1'}>
            <SelectFieldComponent
              select
              name="sortBy"
              value={selectedSort}
              fullWidth
              handleChange={handleSortByChange}
              options={getSortByOptions()}
              validators={['required']}
              errorMessages={['Required']}
            />
          </Grid>
          <Grid flexBasis={'100%'}>
            {selectedSort && (
              <Grid>
                <RadioGroup
                  row
                  value={sortOrder}
                  onChange={handleSortOrderChange}
                >
                  <Grid display={'flex'} direction="column" spacing={3}>
                    <FormControlLabel
                      value="asc"
                      control={<Radio color="primary" />}
                      label="Ascending"
                    />
                    <FormControlLabel
                      value="desc"
                      control={<Radio color="primary" />}
                      label="Descending"
                    />
                  </Grid>
                </RadioGroup>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item display={'inline-flex'} flexDirection={'column'} flexBasis={'200px'} justifyContent={'flex-end'}>
        <Grid display={'flex'} flexDirection={'column'} gap={'10px'}>
          <ButtonComponent
            color="primary"
            pageClassName="buttonOrange fontSize14"
            title="+ Add chart to report"
            handleClick={handleAddChart}
          />
            {/* <TypographyComponent variant="h6">
            {ReportsConstants.CUSTOM_ADD_CHART_TEXT}
            </TypographyComponent> */}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default HotspotChart_Custom;