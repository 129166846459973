import React, { useEffect, useMemo } from 'react';
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import { Grid, List, ListItem, ListItemText, IconButton } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import withStyles from '@mui/styles/withStyles';

// Import Styles and Image
import ReportsStyles from "../../ReportsStyles";
import { DashboardClose } from "../../../../assets/images/svgComponents/ImageComponent";

// Import Component
import TypographyComponent from "../../../../components/TypographyComponent/TypographyComponent";
import ButtonComponent from '../../../../components/ButtonComponent/ButtonComponent';
import ToolTipComponent from '../../../../components/ToolTipComponent/ToolTipComponent';

// Constants
import { ReportsConstants } from "../../../../config/constants"

//Redux Actions
import { dashboardAction, updateStateReducerAction } from '../../../../redux/actions'
import { dashboardConstant } from "../../../../redux/constants";

// Import History
import history from "../../../../config/history";

function SAOAWidget(props) {
    // Define Dispatch
    const dispatch = useDispatch();
    // props
    const { classes, saoa, hasMESurvey, isCached, adminPreference } = props;
    const { access, role } = useSelector(state => state.profile.user)
    const { saoaCache } = useSelector(state => state.dashboard.dashboardData)

    // getting realtime data and cache data logic
    if (window.cachedCharts === undefined) {
        window.cachedCharts = {
            saoa: false
        };
    }

    const fetchDashboardData = useMemo(() => {
        return () => {
            if (window.cachedCharts["saoa"] === false) {
                window.cachedCharts = { "saoa": true };
                dispatch(dashboardAction.getDashboardActionData(() => {
                    dispatch(updateStateReducerAction.update(dashboardConstant.SAOA_CACHE_UPDATE));
                }, false));
            }
        };
    }, [dispatch]); // Dependency array ensures it's stable across renders

    useEffect(() => {
        const timer = setTimeout(fetchDashboardData, 20000);
        return () => clearTimeout(timer); // Cleanup on unmount
    }, [fetchDashboardData]);

    // suggested area of actions
    const generateSaoa = () => {
        let jsxElem = [];
        for (let i = 0; i < 3; i++) {
            typeof saoa[i] !== 'undefined' && jsxElem.push(
                <ListItem >
                    <ListItemText primary={saoa[i].category} secondary={saoa[i].questionText} />
                </ListItem>
            )
        }
        return jsxElem;
    }

    /**
     * Handle Navigations
     */
    const handleNavigage = () => {
        history.push({
            pathname: `/actions`
        });
    }

    const handleClose = () => {
        if (typeof adminPreference?.data?.hideDashWidgets?.suggestedAreaAction === 'boolean') {
            adminPreference['data']['hideDashWidgets']['suggestedAreaAction'] = true;
            adminPreference.updatePreference(adminPreference?.data)
        }
    }

    return (
        <Grid className={`${classes.dashboardTile} ${hasMESurvey ? "me me_saoa" : ""}`} style={{ alignItems: 'flex-start' }}>
            <Grid container className={'height100'}>
                <Grid item className={'width100 height100 dflexOnly'} flexDirection={'column'}>
                    <Grid container alignItems="center">
                        <Grid item className='dflexCenter width100 spacebetween'>
                            <TypographyComponent variant="h5" className={'fontWeight600 dflexOnly'}>
                                {ReportsConstants.SUGGESTED_AREAACTION}
                                {!saoaCache && isCached &&
                                    <div className={"pl5"}>
                                        <CircularProgress size={18} color={"primary"} className={classes.circularBarPrimary} />
                                    </div>
                                }
                            </TypographyComponent>
                            {
                                !hasMESurvey &&
                                    <ToolTipComponent title={ReportsConstants.CLOSE}>
                                        <IconButton onClick={handleClose} size="large">
                                            {DashboardClose()}
                                        </IconButton>
                                    </ToolTipComponent>
                            }
                        </Grid>
                    </Grid>
                    <List className={classes.SuggestedActionList} style={{ display: 'inline-grid', placeContent: 'center', height: '100%', width: '100%', justifyContent: 'start' }}>
                        {generateSaoa()}
                    </List>
                    {(role === "superadmin" || access.indexOf("Actions") > -1) &&
                        <Grid item className={classes.viewActionButton} style={{ marginTop: 'auto' }}>
                            <ButtonComponent
                                title={ReportsConstants.VIEW_ACTIONPLAN}
                                color="primary"
                                size="small"
                                handleClick={() => { handleNavigage() }}
                            >
                            </ButtonComponent>
                        </Grid>
                    }
                </Grid>
            </Grid>
        </Grid>
    )
}
// default props
SAOAWidget.defaultProps = {
    classes: {},
    saoa: [],
    hasMESurvey: false,
    adminPreference: {},
};

// prop types
SAOAWidget.propTypes = {
    classes: PropTypes.object,
    saoa: PropTypes.array,
    hasMESurvey: PropTypes.bool,
    adminPreference: PropTypes.object,
};
export default withStyles(ReportsStyles)(SAOAWidget);
