import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import withStyles from '@mui/styles/withStyles';
import Checkbox from "@mui/material/Checkbox";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Link } from "react-router-dom";

import { ReportsConstants } from "../../../../config/constants";

// Import Styles and Image
import NewReportStyles from "../../ReportsStyles";

// Import Helpers
import { addEllipsis } from "../../../../helpers";

// Import Actions
import { reportAction } from "../../../../redux/actions";

function CommentQuestions(props) {
  const dispatch = useDispatch();
  const { customReportData } = useSelector((state) => state.report);

  // Destructure props
  const {
    classes,
    commentQuestions,
    handleClear,
    customquestions
  } = props;

  // Effect to automatically select questions when drawer is opened
  useEffect(() => {
    // Safely handle null or undefined customquestions
    const questionsToSelect = customquestions && customquestions?.length > 0
      ? customquestions
      : [];
  }, [customquestions]);

  // Toggle individual question selection
  const updateQuestions = (id) => {
    const currentSelectedQuestions = customReportData?.commentQuestions || [];
    const updatedCommentQuestions = currentSelectedQuestions?.includes(id)
      ? (currentSelectedQuestions || [])?.filter(qId => qId !== id)
      : [...currentSelectedQuestions, id];

    const updatedReportData = {
      ...customReportData,
      commentQuestions: updatedCommentQuestions
    };

    dispatch(reportAction.updateCustomReport(updatedReportData));
  };

  // Check if a specific question is selected
  const isQuestionSelected = (id) => {
    return (customReportData.commentQuestions || []).includes(id);
  };

  // Select or unselect all questions
  const toggleAllQuestions = () => {
    const currentSelectedQuestions = customReportData?.commentQuestions || [];
    const allQuestionIds = (commentQuestions || [])?.map((item) => item?.baseQusId);

    const updatedReportData = {
      ...customReportData,
      commentQuestions:
        currentSelectedQuestions?.length === allQuestionIds?.length
          ? []
          : [...new Set([...currentSelectedQuestions, ...allQuestionIds])]
    };

    dispatch(reportAction.updateCustomReport(updatedReportData));
  };

  // Clear all selected questions
  const clearAllQuestions = () => {
    const updatedReportData = {
      ...customReportData,
      commentQuestions: []
    };

    dispatch(reportAction.updateCustomReport(updatedReportData));
    handleClear();
  };

  // Determine if all questions are selected
  const areAllQuestionsSelected =
    commentQuestions?.length > 0 &&
    (customReportData?.commentQuestions || [])?.length === commentQuestions?.length;

  return (
    <React.Fragment>
      <Grid item xs={6}>
        {/* <Grid container>
          <Grid item sm={6} className={`${classes.clearAllPosition} txt-right`}>
            <Link
              href="#"
              className={`cursor-pointer colorTheme right fontSize14`}
              onClick={(e) => {
                e.preventDefault();
                clearAllQuestions();
              }}
            >
              {ReportsConstants.CUSTOM_CLEAR_ALL}
            </Link>
          </Grid>
        </Grid> */}
        <Grid className={`pl5 ${classes.commentQuesHeight}`} style={{ width: "200%" }}>
          {/* Select All Checkbox */}
          <FormControlLabel
            control={
              <Checkbox
                key="allSelection"
                id="allSelect"
                name="allQuesSelection"
                color="primary"
                checked={areAllQuestionsSelected}
                onChange={toggleAllQuestions}
                indeterminate={false}
              />
            }
            label="Select all"
          />

          {/* Individual Question Checkboxes */}
          {commentQuestions.map((data) => (
            <Grid item xs={12} key={`div_${data.baseQusId}`} style={{ paddingLeft: "10px" }}>
              <Tooltip title={data.question.length >= 100 ? data.question : ''} arrow>
                <FormControlLabel
                  control={
                    <Checkbox
                      key={data.baseQusId}
                      id={data.baseQusId}
                      name={data.question}
                      color="primary"
                      checked={isQuestionSelected(data.baseQusId)}
                      onChange={() => updateQuestions(data.baseQusId)}
                    />
                  }
                  style={{ overflowY: "auto" }}
                  label={addEllipsis(data.question, 100)}
                />
              </Tooltip>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

// Default props
CommentQuestions.defaultProps = {
  classes: {},
  commentQuestions: [],
  customquestions: []
};

// Prop types
CommentQuestions.propTypes = {
  classes: PropTypes.object,
  commentQuestions: PropTypes.array.isRequired,
  customquestions: PropTypes.array,
  handleClear: PropTypes.func,
};

export default withStyles(NewReportStyles)(CommentQuestions);