import React from "react";
import PropTypes from "prop-types";
import { Switch, Route } from "react-router-dom";

// import child router components
import Dashboard from "./Dashboard"
import Insight from "./Insight"
import InsightComment from "./InsightComment"
import Report from "./Report/Report"

// Import constants
import { userAccess, rolesAccess, reportAccess } from "../../config/pageAccess";
import CustomReportBuilder from "./CustomReportBuilder";

function ReportsRouter(props) {
  const { match, user } = props;
  const CUSTOM_REPORT_STEPS = [
    'surveys',
    'dates',
    'filters', 
    'dashboard', 
    'charts', 
    'comments', 
    'organize', 
    'schedule'
  ];
  return (
    <Switch>
      <Route
        exact
        path={match.url}
        render={(props) => {
          window.cachedCharts = {
            saoa: false
          };
          if (user.page.indexOf(userAccess.reports) > -1 && (user.access.indexOf(rolesAccess.reports) === -1 && user.access.some(r => reportAccess.indexOf(r) > -1))) {
            return <Report {...props} user={user} />
          } else {
            return <Dashboard {...props} user={user} />
          }
        }}
      />

      <Route
        exact
        path={`${match.url}/insight`}
        render={(props) => {
          window.cachedCharts = {
            hotspot: false,
            comment: false
          };
          return <Insight {...props} user={user} />
        }}
      />

      <Route
        exact
        path={`${match.url}/report`}
        render={(props) => {
          return <Report {...props} user={user} />
        }}
      />

      <Route
        exact
        path={`${match.url}/comment`}
        render={(props) => {
          return <InsightComment {...props} user={user} />
        }}
      />

      <Route
        exact
        path={`${match.url}/custom_report/new`}
        render={(props) => {
          return (
            <CustomReportBuilder
            step={0}
              match={match}
              {...props} 
              user={user} 
              isSaveReport={false}
            />
          )
        }}
      />

    <Route
            exact
            path={`${match.url}/custom_report/:id`}
            render={(props) => {
              return (
                <CustomReportBuilder 
                step={0}
                  match={match}
                  {...props} 
                  user={user} 
                  isSaveReport={true}  // Changed to true for editing
                />
              )
            }}
          />

      <Route
        exact
        path={`${match.url}/custom_report/:id/dates`}
        render={(props) => {
          return (
            <CustomReportBuilder 
            step={1}
              match={match}
              {...props} 
              user={user} 
              isSaveReport={true}  // Changed to true for editing
            />
          )
        }}
      />

    <Route
            exact
            path={`${match.url}/custom_report/:id/filters`}
            render={(props) => {
              return (
                <CustomReportBuilder 
                step={2}
                  match={match}
                  {...props} 
                  user={user} 
                  isSaveReport={true}  // Changed to true for editing
                />
              )
            }}
          />

    <Route
            exact
            path={`${match.url}/custom_report/:id/dashboard`}
            render={(props) => {
              return (
                <CustomReportBuilder 
                step={3}
                  match={match}
                  {...props} 
                  user={user} 
                  isSaveReport={true}  // Changed to true for editing
                />
              )
            }}
          />

    <Route
            exact
            path={`${match.url}/custom_report/:id/charts`}
            render={(props) => {
              return (
                <CustomReportBuilder 
                step={4}
                  match={match}
                  {...props} 
                  user={user} 
                  isSaveReport={true}  // Changed to true for editing
                />
              )
            }}
          />

    <Route
            exact
            path={`${match.url}/custom_report/:id/comments`}
            render={(props) => {
              return (
                <CustomReportBuilder 
                step={5}
                  match={match}
                  {...props} 
                  user={user} 
                  isSaveReport={true}  // Changed to true for editing
                />
              )
            }}
          />

    <Route
            exact
            path={`${match.url}/custom_report/:id/organize`}
            render={(props) => {
              return (
                <CustomReportBuilder 
                step={6}
                  match={match}
                  {...props} 
                  user={user} 
                  isSaveReport={true}  // Changed to true for editing
                />
              )
            }}
          />
          <Route
            exact
            path={`${match.url}/custom_report/:id/schedule`}
            render={(props) => {
              return (
                <CustomReportBuilder 
                step={7}
                  match={match}
                  {...props} 
                  user={user} 
                  isSaveReport={true}  // Changed to true for editing
                />
              )
            }}
          />

    </Switch>
  );
}

/**
 * Bind Component Property Types
 */
ReportsRouter.propTypes = {
  match: PropTypes.object,
  classes: PropTypes.object
};

/**
 * Export Component
 */
export default ReportsRouter;