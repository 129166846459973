import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import withStyles from '@mui/styles/withStyles';
import { Link } from "react-router-dom";

// Import Styles and Image
// import ReportsStyles from "ReportsStyles";

// Import Component
import TypographyComponent from "../../components/TypographyComponent/TypographyComponent";
import SelectFieldComponent from "../../components/SelectFieldComponent/SelectFieldComponent";
import DatePickerComponent from "../../components/DatePickerComponent/CustomDatePicker";
import AutoCompleteComponent from '../../components/AutoCompleteComponent/AutoCompleteComponent'

// import constants
import { ReportsConstants } from "../../config/constants";

//Import Actions
import { rawdataAutomationAction } from "../../redux/actions";

// import helpers
import { dateFormatter, dateValidator } from "../../helpers/appHelper";

function ChooseDates(props) {
  const dispatch = useDispatch();

  // get props
  const { classes } = props;

  // Get saved reports Data
  const { allSurveys, surveys, date, datetype } = useSelector((state) => state.rawdataAutomation);

  const pulseFound = surveys.filter((d) => d.frequency === "Pulse").length > 0;
  let TIME_FRAMES = [
    "Last 30 days",
    "Last 60 days",
    "Last 90 days",
    "Last 180 days",
    "Custom dates"
  ];

  if (TIME_FRAMES.indexOf("Pulse dates") === -1 && pulseFound) {
    TIME_FRAMES.push("Pulse dates");
  } else if (TIME_FRAMES.indexOf("Pulse dates") > -1 && !pulseFound) {
    const pulseIndex = TIME_FRAMES.indexOf("Pulse dates");
    TIME_FRAMES.splice(pulseIndex, 1);
  }

  let pulseDateArray = [];
  let survey = []
  if (surveys && surveys.length === 1 && surveys[0].frequency === "Pulse") {
    survey = allSurveys.filter(obj => obj._id + "" === surveys[0]._id + "")
    if (survey[0] && survey[0].pulse_obj && survey[0].pulse_obj.pulseDetails) {
      Object.keys(survey[0].pulse_obj.pulseDetails).map((key) => {
        pulseDateArray.push(survey[0].pulse_obj.pulseDetails[key].displayName)
      })
    }
  }

  const pulse_date = pulseFound
    ? survey?.[0]?.pulse_obj?.pulseDetails?.[date?.pulseDate]?.displayName
    || survey?.[0]?.pulse_obj?.currentPulse_details?.displayName
    || ""
    : "";

  //Get demographics List
  useEffect(() => {
    if (date.type !== "") {
      getDemographics(date);
    }
  }, [dispatch]);

  // Get Demographics Data
  const getDemographics = (dateUpdated) => {
    dispatch(
      rawdataAutomationAction.getDemographics({
        metadata: [],
        surveys: surveys,
        date: dateUpdated,
      })
    );
  };

  //change date type and update reducer
  const changeDateType = (e) => {
    let updatedReportData;
    if (e["value"] !== "Custom dates" && e["value"] !== "Pulse dates") {
      updatedReportData = {
        date: { ...date, startDate: e["value"], [e.name]: e["value"] },
        };
    } else if (e.value === "Custom dates") {
        const startDate = pulseFound
            ? surveys?.[0]?.pulse_obj?.currentPulse_details?.startDate
            : surveys?.[0]?.startDate;
        const endDate = pulseFound
            ? surveys?.[0]?.pulse_obj?.currentPulse_details?.endDate
            : surveys?.[0]?.endDate;

        updatedReportData = {
            date: {
                [e.name]: e.value,
                startDate: dateFormatter(startDate),
                endDate: dateFormatter(endDate),
            },
        };
    } else if (e.value === "Pulse dates") {

        const pulseEntry = Object.entries(survey?.[0]?.pulse_obj?.pulseDetails || {}).find(
            ([, details]) => details?.displayName === pulse_date
        );

        if (pulseEntry) {
            const [key] = pulseEntry;
            updatedReportData = {
                date: { ...date, startDate: pulse_date, [e.name]: e.value, pulseDate: key },
            };
        }
    }
    dispatch(rawdataAutomationAction.updatedate(updatedReportData));
    if (e["value"] !== "Pulse Dates" || e["value"] !== "Custom dates") {
      getDemographics(updatedReportData["date"]);
    }
  };

  //change date ranges
  const changeDates = (e) => {
    console.log(e)
    const updatedReportData = {
      // ...reportData,
      date: { ...date, [e.name]: dateFormatter(e["value"]) },
    };
    dispatch(rawdataAutomationAction.updatedate(updatedReportData));

    if (dateValidator(date["startDate"]) && (e["name"] === "endDate" && dateValidator(e["value"]))) {
      getDemographics(updatedReportData["date"]);
    }
  };

  // Clear all data
  const handleClear = (e) => {
    const updatedReportData = {
      // ...reportData,
      date: { type: "", startDate: "", endDate: "", pulseDate: "" },
    };
    dispatch(rawdataAutomationAction.updatedate(updatedReportData));
  };

  // Handle auto complete change
  const handleAutoCompleteChange = (event) => {
    const Pulse_value = event["value"]
    if (survey[0] && survey[0].pulse_obj && survey[0].pulse_obj.pulseDetails) {
      Object.keys(survey[0].pulse_obj.pulseDetails).map((key) => {
        if (survey[0].pulse_obj.pulseDetails[key].displayName && survey[0].pulse_obj.pulseDetails[key].displayName === event.value) {
          event.value = key
        }
      })
    } else {
      event.value = ""
    }
    const updatedReportData = {
      date: { ...date, startDate: Pulse_value,  [event.name]: event["value"] },
    };
    dispatch(rawdataAutomationAction.updatedate(updatedReportData));

    if (event["value"]) {
      getDemographics(updatedReportData["date"]);
    }
  }

  return (
    <React.Fragment>
      <Grid container>
        <Grid sm={10}>
          <TypographyComponent variant="h6">
            {"Select the dates you wish to view response data from for your report"}
          </TypographyComponent>

        </Grid>
        {date && date.type !== "" && (
          <Grid item sm={10}>
            {/* {displyDates()} */}
          </Grid>
        )}
      </Grid>
      <Grid container className="pt-1 pb-1" spacing={2} alignItems="center">
        {/* Date Type Selector */}
        <Grid item xs={3}>
          <TypographyComponent variant="h6">
            {ReportsConstants.DATE_TYPE}
          </TypographyComponent>
          <SelectFieldComponent
            select
            name="type"
            options={TIME_FRAMES}
            value={date ? date.type : ""}
            fullWidth={true}
            handleChange={(e) => changeDateType(e)}
            validators={['required']}
            errorMessages={['Required']}
          />
        </Grid>

        {/* Custom Dates Section */}
        {date.type === "Custom dates" && (
          <Grid item xs={4}>
            <TypographyComponent variant="h6">
              {ReportsConstants.START_DATE}
            </TypographyComponent>
            <DatePickerComponent
              name="startDate"
              value={date.startDate || ""}
              className={classes.textField}
              handleChange={(e) => changeDates(e)}
              inputVariant="outlined"
              margin="dense"
              size="small"
              fullWidth={true}
              disabled={false}
              validators={['required']}
              errorMessages={['Required']}
            />
          </Grid>
        )}

        {/* Pulse Dates Section */}
        {date.type === "Pulse dates" && (
          <Grid item xs={4}>
            <TypographyComponent variant="h6">
              {ReportsConstants.PULSE_DATE}
            </TypographyComponent>
            <AutoCompleteComponent
              name="pulseDate"
              suggestions={pulseDateArray || []}
              handleAutoCompolete={(e, n) =>
                handleAutoCompleteChange({ value: e, name: n })
              }
              suggestionType="array"
              value={pulse_date}
              validators={["required"]}
              errorMessages={['Required']}
            />
          </Grid>
        )}
      </Grid>

    </React.Fragment>
  );
}
// default props
ChooseDates.defaultProps = {
  classes: {},
};

// prop types
ChooseDates.propTypes = {
  classes: PropTypes.object,
};
export default ChooseDates;
