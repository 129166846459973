import React, { useEffect, useState } from "react";
import {
  Stepper,
  Step,
  StepLabel,
  StepConnector,
  styled,
  Grid,
  StepButton,
  Dialog,
  DialogContent,
  DialogActions,
  Button
} from "@mui/material";
import TypographyComponent from "../../components/TypographyComponent/TypographyComponent";
import TextFieldComponent from "../../components/TextFieldComponent/TextFieldComponent";
import { ValidatorForm } from "react-material-ui-form-validator";
import SurveySelection from "./SurveySelection";
import SelectedContent from "./SelectedContent";
import ButtonComponent from "../../components/ButtonComponent/ButtonComponent";
import ChooseDate from "./ChooseDate";
import Breakout from "./Breakout";
import { rawdataAutomationConstant } from "../../redux/constants";
import { useDispatch, useSelector } from "react-redux";
import { rawdataAutomationAction } from "../../redux/actions";
import { RadioButtonChecked, RadioButtonUnchecked } from "@mui/icons-material";
import SelectFieldComponent from "../../components/SelectFieldComponent/SelectFieldComponent";
import RawdataStyles from "./RawdataStyles";
import withStyles from "@mui/styles/withStyles";
import history from "../../config/history";
import { BackArrow, BackPage } from "../../assets/images/svgComponents/ImageComponent";
import { alertAction } from "../../redux/actions";
import { validateRawDataSetup } from './Validation';

// Custom styles for the stepper
const CustomConnector = styled(StepConnector)(({ theme }) => ({
  "& .MuiStepConnector-line": {
    borderColor: "#ccc",
    borderTopWidth: 2,
  },
}));

const steps = ["Reports & Survey", "Date & Breakout"];
const frequencylist = ["Weekly", "Monthly"];
// const daylist = frequency == "Weekly" ?  : ["First Sunday","First Monday","First Tuesday","First Wednesday","First Thursday","First friday","First Saturday"]
const daylist = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
const monthlydaylist = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const Rawdatasetup = (props) => {
  const [activeStep, setActiveStep] = useState(0);
  let { rawdataAutomation } = useSelector((state) => state);
  const { classes, match } = props;
  const [openSurveyCancelDialog, setOpenSurveyCancelDialog] = useState(false)
  const { id } = match?.params;
  let { name, frequency, day } = rawdataAutomation;
  const dispatch = useDispatch();

  const handlefrequencyChange = (e) => {
    dispatch(rawdataAutomationAction.updatefrequency(e.value));
    dispatch(
      rawdataAutomationAction.updateday(
        e.value == "Weekly" ? "Sunday" : "Sunday"
      )
    );
  };

  const handledayChange = (e) => {
    dispatch(rawdataAutomationAction.updateday(e.value));
  };

  useEffect(() => {
    dispatch((rawdataAutomationAction.setbacktoinitial()))
    if (id) {
      dispatch(rawdataAutomationAction.getOne(id));
    }
  }, []);

  const handleSubmit = () => {
    if (activeStep == 1) {
      let payload = {
        _id: id,
        name: rawdataAutomation.name,
        surveys: rawdataAutomation.surveys,
        frequency: rawdataAutomation.frequency,
        day: rawdataAutomation.day,
        date: rawdataAutomation.date,
        selectedDemographic: rawdataAutomation.selectedDemographic,
      };
      const validationResult = validateRawDataSetup(payload);
      if (validationResult.isValid) {
        dispatch(
          rawdataAutomationAction.createAndUpdateRawdataAutomation(payload)
        );
        // history.replace("/raw-data/list");
      } else {
        // Dispatch error alert if validation fails
        dispatch(alertAction.error(validationResult.errorMessage));
      }
      // history.replace("/raw-data/list");
    } else {
      setActiveStep(1); // If activeStep is falsy (0), set it to 1
    }
  };

  const handleCancel = () => {
    setOpenSurveyCancelDialog(true)
  }

  const handleDialogCancelAction = () => {
    setOpenSurveyCancelDialog(false)
  }

  const GotoRawdataListPage = () => {
    history.replace("/raw-data/list");
    dispatch(rawdataAutomationAction.setbacktoinitial())
  }

  const handleError = () => {
    return false;
  };

  const handleChange = (e) => {
    dispatch(rawdataAutomationAction.updatename(e.value));
  };

  const StepIconComponent = ({ active, completed }) =>
    active || completed ? (
      <RadioButtonChecked className="colorTheme" />
    ) : (
      <RadioButtonUnchecked className="colorTheme" />
    );

  return (
    <Grid>
      <ValidatorForm
        id="surveyPanelForm"
        name="surveyPanelForm"
        useref="surveyPanelForm"
        autoComplete="off"
        onSubmit={handleSubmit}
      // onError={handleError}
      >
        <Grid container className={classes.page} style={{ position: "relative" }}>
          {/* Back Arrow Section */}
          <div style={{ position: "absolute", left: 0, top: "50%", transform: "translateY(-50%)" }}>
            <Button
              onClick={handleCancel}
              style={{
                background: "none",
                border: "none",
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
              }}
            >
              <TypographyComponent variant={"h3"} className="ml-1">
                {BackPage()} <span style={{ marginLeft: "8px" }}>Raw Data Setup</span>
              </TypographyComponent>
            </Button>
          </div>

          {/* Stepper Section */}
          <Stepper
            alternativeLabel
            activeStep={activeStep}
            connector={<CustomConnector />}
            sx={{ width: "35%" }}
          >
            {steps.map((label, index) => (
              <Step key={label}>
                <StepButton
                  onClick={() => {
                    setActiveStep(index);
                  }}
                  disabled={false}
                >
                  <StepLabel StepIconComponent={StepIconComponent}>
                    {label}
                  </StepLabel>
                </StepButton>
              </Step>
            ))}
          </Stepper>
        </Grid>
        {activeStep == 0 ? (
          <>
            <Grid sx={{ padding: "20px" }}>
              <TypographyComponent variant="h5" className="txtBold fontSize20" style={{ padding: "5px 0" }}>
                {'Reports & Survey'}
              </TypographyComponent>
              <Grid sx={{ backgroundColor: "#FFFFFF", height: '60vh' }} >
                <Grid container sx={{ padding: "20px 40px", marginTop: '10px', justifyContent: "space-between" }}>
                  <Grid item xs={6} className={classes.leftBox}>
                    <Grid sx={{ padding: "10px 0" }}>
                      <TypographyComponent
                        title={" Name Your Raw Data Report*"}
                        variant="body1"
                        style={{ fontSize: "14px" }}
                        align={"Left"}
                      ></TypographyComponent>
                      <Grid
                        item
                        align={"center"}
                        justifyContent="center"
                        alignItems="center"
                      >
                        <TextFieldComponent
                          id={"survery_name"}
                          name={"name"}
                          value={name}
                          placeholder={
                            rawdataAutomationConstant.NAME_YOUR_SURVEY
                          }
                          maxLength={60}
                          margin={"none"}
                          size={"small"}
                          validators={["required"]}
                          showErrorMessages={true}
                          handleChange={(e) => handleChange(e)}
                        />
                      </Grid>
                    </Grid>
                    <Grid sx={{ padding: "10px 0" }}>
                      <SurveySelection></SurveySelection>
                    </Grid>
                    <Grid sx={{ padding: "10px 0" }}>
                      <Grid container>
                        <Grid xs={10}>
                          <TypographyComponent
                            title="Frequency of Report*"
                            variant="body1"
                            style={{ fontSize: "14px" }}
                            align={"Left"}
                          ></TypographyComponent>
                        </Grid>
                      </Grid>
                      <SelectFieldComponent
                        select
                        name="Day"
                        value={frequency}
                        validators={["required"]}
                        showErrorMessages={true}
                        variant="outlined"
                        fullWidth={true}
                        handleChange={handlefrequencyChange}
                        options={frequencylist}
                      />
                    </Grid>
                    <Grid sx={{ padding: "10px 0" }}>
                      <Grid container>
                        <Grid xs={10}>
                          <TypographyComponent
                            title="Select a Day*"
                            variant="body1"
                            style={{ fontSize: "14px" }}
                            align={"Left"}
                          ></TypographyComponent>
                        </Grid>
                      </Grid>
                      <SelectFieldComponent
                        select
                        name="day"
                        value={day}
                        variant="outlined"
                        validators={["required"]}
                        showErrorMessages={true}
                        fullWidth={true}
                        handleChange={handledayChange}
                        options={
                          frequency == "Weekly" ? daylist : monthlydaylist
                        }
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    className={classes.rightBox}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <SelectedContent rawdataAutomation={rawdataAutomation} props={props}></SelectedContent>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              sx={{
                backgroundColor: "#FFFFFF",
                height: "75px",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end", // Right-align content
                position: "fixed", // Fixes footer to the bottom
                bottom: 0, // Positions it at the bottom of the viewport
                left: 0, // Ensures it stretches across the screen
                right: 0, // Full width
                boxShadow: "0 -2px 5px rgba(0,0,0,0.1)", // Subtle shadow for better visibility
              }}
            >
              <ButtonComponent
                title="Continue"
                color="primary"
                style={{ marginRight: "20px" }}
                type="submit"
              // handleClick={handleApply}
              />
              <ButtonComponent
                title="Cancel"
                style={{ marginRight: "30px" }}
                // color='secondary'
                handleClick={handleCancel}
              />
            </Grid>
          </>
        ) : (
          <>
            <Grid sx={{ padding: "20px" }}>
              <TypographyComponent variant="h5" className="txtBold fontSize20" style={{ padding: "5px 0" }}>
                {'Raw Data Automation - Date & Filters'}
              </TypographyComponent>
              <Grid sx={{ backgroundColor: "#FFFFFF", height: '60vh' }} >
                <Grid container sx={{ padding: "20px 40px", marginTop: '10px', justifyContent: "space-between" }}>
                  <Grid item xs={6} className={classes.leftBox}>
                    <Grid>
                      <Grid
                        item
                        align={"center"}
                        justifyContent="center"
                        alignItems="center"
                      >
                        <ChooseDate></ChooseDate>
                      </Grid>
                      <Grid>
                        <Breakout />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    className={classes.rightBox}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <SelectedContent rawdataAutomation={rawdataAutomation} props={props}></SelectedContent>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              sx={{
                backgroundColor: "#FFFFFF",
                height: "75px",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end", // Right-align content
                position: "fixed", // Fixes footer to the bottom
                bottom: 0, // Positions it at the bottom of the viewport
                left: 0, // Ensures it stretches across the screen
                right: 0, // Full width
                boxShadow: "0 -2px 5px rgba(0,0,0,0.1)", // Subtle shadow for better visibility
              }}
            >
              <ButtonComponent
                title="Save"
                color="primary"
                style={{ marginRight: "20px" }}
                type="submit"
              // handleClick={handleApply}
              />
              <ButtonComponent
                title="Cancel"
                style={{ marginRight: "30px" }}
                // color='secondary'
                handleClick={handleCancel}
              />
            </Grid>
          </>
        )}
      </ValidatorForm>
      <Dialog
        open={openSurveyCancelDialog}
        onClose={handleDialogCancelAction}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent style={{ margin: "auto" }}>
          Do you want to cancel?
        </DialogContent>
        <DialogActions>
          <ButtonComponent
            title={"Yes"}
            color={"primary"}
            size={"small"}
            handleClick={GotoRawdataListPage}
            componentClassName={"marginLR5"}
          ></ButtonComponent>
          <ButtonComponent
            title={"No"}
            // color={"secondary"}
            size={"small"}
            className={classes.cancelBtn}
            handleClick={handleDialogCancelAction}
            componentClassName={"marginLR5"}
          ></ButtonComponent>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default withStyles(RawdataStyles)(Rawdatasetup);
