import { rawdataAutomationConstant } from "../constants/rawdataAutomationConstants";

const tableDatas = (sortName) => {
  return {
    data: [],
    page: {
      fieldFilters: {
        headerCols: [],
        fieldCols: [sortName],
        searchCols: [],
      },
    },
  };
};

const initialState = {
  _id: "",
  name: "",
  rawdata_folder: "",
  allSurveys: [],
  surveys: [],
  date: {
    type: "",
    startDate: "",
    endDate: "",
  },
  frequency : "Weekly",
  day : "Sunday",
  breakout: "",
  admin_id: "",
  search: { ...tableDatas() },
  allDemographics: [],
  selectedDemographic: [ "No breakout" ],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case rawdataAutomationConstant.POST_RAW_DATA_AUTOMATION_CONFIG:
      return {
        ...state,
      };
    case rawdataAutomationConstant.GET_RAW_DATA_ALL_SURVEYS:
      return {
        ...state,
        allSurveys: action.data.data,
      };
    case rawdataAutomationConstant.UPDATE_SURVEYS_DATA:
      return {
        ...state,
        surveys: action.data.surveys,
      };
    case rawdataAutomationConstant.UPDATE_NAME:
      return {
        ...state,
        name: action.data,
      };
    case rawdataAutomationConstant.UPDATE_FREQUENCY:
      return {
        ...state,
        frequency: action.data,
      };
    case rawdataAutomationConstant.UPDATE_DAY:
      return {
        ...state,
        day: action.data,
      };
    case rawdataAutomationConstant.GET_DEMOGRAPHICS_REQUEST:
      return {
        ...state,
      };
    case rawdataAutomationConstant.GET_DEMOGRAPHICS_SUCCESS:
      return {
        ...state,
        allDemographics: action.data.data,
      };
    case rawdataAutomationConstant.GET_DEMOGRAPHICS_FAILURE:
      return {
        ...state,
      };
    case rawdataAutomationConstant.UPDATE_DATE:
      return {
        ...state,
        date: action.data.date,
      };
    case rawdataAutomationConstant.UPDATE_DEMOGRAPHICS:
      return {
        ...state,
        selectedDemographic: action.data,
      };
    case rawdataAutomationConstant.UPDATE_FOLDER_NAME_REQUEST:
      return {
        ...state,
      };
    case rawdataAutomationConstant.UPDATE_FOLDER_NAME_SUCCESS:
      return {
        ...state,
        rawdata_folder: action.data,
      };
    case rawdataAutomationConstant.UPDATE_FOLDER_NAME_FAILURE:
      return {
        ...state,
      };
    case rawdataAutomationConstant.CREATE_RAW_DATA_AUTOMATION_REQUEST:
      return {
        ...state,
      };
    case rawdataAutomationConstant.CREATE_RAW_DATA_AUTOMATION_SUCCESS:
      return {
        ...state,
      };
    case rawdataAutomationConstant.CREATE_RAW_DATA_AUTOMATION_FAILURE:
      return {
        ...state,
      };
    case rawdataAutomationConstant.RAW_DATA_LIST_SEARCH_REQUEST:
      return {
        ...state
      };
    case rawdataAutomationConstant.RAW_DATA_LIST_SEARCH_SUCCESS:
      return {
        ...state,
        search: {
          data: action.data.data,
          page: action.data.page,
        },
      };
    case rawdataAutomationConstant.RAW_DATA_LIST_SEARCH_FAILURE:
      return {
        ...state,
        search: initialState.search,
      };
    case rawdataAutomationConstant.RAW_DATA_GET_ONE_REQUEST:
      return {
        ...state,
        _id: initialState._id,
        surveys:initialState.surveys,
        date : initialState.date,
        frequency:initialState.frequency,
        day:initialState.day,
        selectedDemographic:initialState.selectedDemographic
      };
    case rawdataAutomationConstant.RAW_DATA_GET_ONE_SUCCESS:
      return {
        ...state,
        _id: action.data._id,
        name:action.data.name,
        surveys:action.data.surveys,
        date : action.data.date,
        frequency:action.data.frequency,
        day:action.data.day,
        selectedDemographic:action.data.selectedDemographic ? action.data.selectedDemographic : []
      };
    case rawdataAutomationConstant.RESET_INITIAL_STATE:
      return {
        ...initialState, // Restore all keys to their initial state
        date: { // Explicitly set date to empty values
          type: "",
          startDate: "",
          endDate: "",
        },    
        search: state.search, // Keep the current value of the `search` key
      };
    default:
      return state;
  }
}
