import { makeStyles } from "@mui/styles";

const NewReportStyles = ((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  headerContainer: {
    backgroundColor: "white",
    alignItems: "center",
    padding: `0 ${theme.spacing(3)}`,
  },
  outerBox: {
    border: "1px solid #e0e0e0",
    padding: theme.spacing(3),
    borderRadius: "4px",
    backgroundColor: "#f5f5f5",
    height: '82vh',
    overflowY: 'auto',
  },
  innerBox: {
    display: "flex",
    alignItems: "stretch",
    justifyContent: "space-between",
    border: "1px solid #e0e0e0",
    padding: theme.spacing(2),
    borderRadius: "4px",
    backgroundColor: "#ffffff",
    maxHeight: '70vh',
    overflowY: "auto",
  },
  leftBox: {
    flex: 1,
    // maxWidth: "48%",
    padding: theme.spacing(2),
    boxSizing: "border-box",
    // overflowY: "auto",
    maxHeight: '100%',
  },
  rightBox: {
    flex: '0 0 36%',
    maxHeight: '100%',
    display: 'flex',
    // maxWidth: "45%",
    boxSizing: "border-box",
    // overflowX: "auto",
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
  },
  navigationButtons: {
    boxShadow: "none",
    background: "#fff",
    position: 'fixed',
    bottom: '0',
    left: '0',
    right: '0',
    display: "flex",
    justifyContent: "flex-end",
    padding: theme.spacing(2),
    gap: theme.spacing(2),
  },
  error: {
    color: theme.palette.error.main,
    marginBottom: theme.spacing(2),
  },


  demographyCheckbox: {
    padding: "0px 5px",
  },
  checkboxContainer: {
    "&.MuiFormControlLabel-root": {
      height: "-moz-fit-content",
      width: "-moz-fit-content",
    },
  },
  expansionPlus: {
    display: "block",
  },
  mainContainer: {
    maxHeight: '43vh', // Restrict overall container height (adjust as needed)
    overflowY: 'auto', // Scroll within this container if content overflows
    padding: theme.spacing(1), // Reduce padding inside the Grid
  },

  lightOrangeBox: {
    backgroundColor: "#F5E5D1",
    borderRadius: "8px",
    marginBottom: "10px",
    padding: "0 16px",
    border: "2px solid rgb(255, 174, 75)",
    width: 'fit-content'
  },
  demographicWIdth: {
    minHeight: "10vh",
    maxHeight: "45vh",
    paddingLeft: "20px",
    width: "80%"
  },
  demographyCheckbox: {
    padding: "0px 5px",
  },
  peaccordion_nocolor_demography: {
    "&.MuiPaper-root": {
      borderBottom: "none !important",
    },
    "&.MuiAccordion-root.Mui-expanded": {
      margin: "0px !important",
      boxShadow: "none !important",
    },
    "& .MuiAccordionSummary-root.Mui-expanded": {
      color: "#292929 !important",
      background: "#fff !important",
      borderBottom: "none !important",
      padding: "0px !important",
    },
    "& .panel-active": {
      color: "#292929 !important",
      "& .expansionPlus": {
        display: "none",
      },
      "& .expansionMinus": {
        display: "block",
      },
    },
    "& .MuiAccordionDetails-root": {
      flexDirection: "column !important",
      padding: "0px !important",
    },
    "& .MuiAccordionSummary-root": {
      padding: "0px !important",
    },
  },
  expansionPlus: {
    display: "block",
  },
  expansionMinus: {
    display: "none",
  },
  roundedBox: {
    width: '90%',
    maxHeight: 'fit-content',
    margin: theme.spacing(4),
    margin: '20px',
    backgroundColor: `#fbfdff`,
    borderRadius: '8px',
    padding: theme.spacing(4),
    paddingBottom: theme.spacing(1),
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    backgroundImage: `linear-gradient(to right, ${theme.palette.primary.main} 50%, transparent 50%), 
                     linear-gradient(to bottom, ${theme.palette.primary.main} 50%, transparent 50%), 
                     linear-gradient(to left, ${theme.palette.primary.main} 50%, transparent 50%), 
                     linear-gradient(to top, ${theme.palette.primary.main} 50%, transparent 50%)`,
    backgroundSize: '20px 2px, 2px 20px, 20px 2px, 2px 20px',
    backgroundPosition: 'top left, top right, bottom right, bottom left',
    backgroundRepeat: 'repeat-x, repeat-y, repeat-x, repeat-y',
    boxSizing: 'border-box',
    flex: '0 0 auto',

  },
  tablebox: {
    width: '100%',
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  demographicsChips: {
    "&.MuiChip-root": {
      background: "#EDF9FF",
      border: "1px solid #cbecff",
      padding: "10px",
      height: "28px",
      maxWidth: "320px",
      backgroundColor: "#ecf8fe !important",
      color: "black"
    },
  },

  demographicsChips_extra: {
    "&.MuiChip-root": {
      padding: '0px',
      background: "#b4e6ff", // Chip background color
      backgroundColor: "#ecf8fe !important", // Ensure precedence
      border: "1px solid #cbecff",
      height: "28px",
      borderRadius: "50px",
      color: "black",
      display: "inline-flex",
      alignItems: "center",
      marginBottom: '10px'
    },
    "& .MuiChip-label": { // Target the label within the Chip
      padding: "7px", // Override default padding
    },

  },
  demographicsChipClose: {
    color: "black",
    fontSize: '16px',
  },
  noBorder: {
    verticalAlign: 'top',
    borderBottom: 'none',

  },
  rightAlignText: {
    textAlign: 'center',  // Change from right to left
  },
  rightColumn: {
    textAlign: 'left',  // Ensure left alignment
  },
  leftColumn: {
    textAlign: 'right',  // Add explicit left alignment
    width: '7pc'
  },

  commentQuesHeight: {
    width: "200%",
    maxHeight: "100%",
    overflow: "auto",
  },

  chipInput: {
    "& .MuiInputBase-root": {
      lineHeight: "20px",
      padding: "1rem",
    },
    "& .MuiChip-root": {
      background: `${theme.palette.primary.main}`,
      color: "white",
    },
    "& .MuiChip-deleteIcon": {
      color: "white",
      opacity: "0.75",
      "&:focus": {
        opacity: "1"
      }
    }
  },
  frequencyWidth: {
    minWidth: "50%",
    maxWidth: "100%",
    "&.drawer": {
      width: "100%"
    }
  },

  errorChipReport: {
    background: "#F64242 !important",
    color: "white",
    "& .MuiChip-deleteIcon": {
      color: "white",
      opacity: "0.75",
      "&:focus": {
        opacity: "1"
      }
    }
  },
  clearAllPosition: {
    position: "absolute",
    left: "280px",
    top: "6px",
    "&.drawer": {
      position: "relative",
      top: "auto",
      left: "auto"
    }
  },

  ImagesGrid: {
    width: "100%",  // Make sure it uses full width
    flexWrap: 'wrap',  // Allow items to wrap into rows
    boxSizing: "border-box",  // Include padding and margins in width calculations
    margin: "0",
    marginTop: "8px",
    padding: "0",  // Remove unnecessary padding
    display: "flex",  // Let Material-UI's Grid handle the flex layout
    // justifyContent: "space-between",  // Optional: evenly space items
    alignItems: "center",  // Optional: align items vertically in the center
    // justifyContent: "center"
  },


  imageGrid: {
    cursor: 'pointer',
    marginRight: '8px',
    marginBottom: '8px',  // Add bottom margin to prevent vertical gaps between rows
    borderRadius: '8px',
    textAlign: 'center',
    transition: 'border 0.1s ease',
    border: '2px solid transparent', // Default border color
  },

  selectedImage: {
    border: `2px solid ${theme.palette.primary.main}`, // Use theme's primary color when selected
  },

}));

export default NewReportStyles;