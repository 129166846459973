import { makeStyles } from '@mui/material/styles';

const RawdataStyles = (theme) => ({
  // const RawdataStyles = () => ({
  page: {
    backgroundColor: "#FFFFFF",
    display: "flex",
    justifyContent: "center",
  },
  roundedBox: {
    width: '60%',
    height: 'fit-content',
    margin: '20px',
    backgroundColor: `#fbfdff`,
    borderRadius: '8px',
    padding: theme.spacing(4),
    // justifyContent: 'center',
    flexDirection: 'column',
    backgroundImage: `linear-gradient(to right, ${theme.palette.primary.main} 50%, transparent 50%), 
                             linear-gradient(to bottom, ${theme.palette.primary.main} 50%, transparent 50%), 
                             linear-gradient(to left, ${theme.palette.primary.main} 50%, transparent 50%), 
                             linear-gradient(to top, ${theme.palette.primary.main} 50%, transparent 50%)`,
    backgroundSize: '20px 2px, 2px 20px, 20px 2px, 2px 20px',
    backgroundPosition: 'top left, top right, bottom right, bottom left',
    backgroundRepeat: 'repeat-x, repeat-y, repeat-x, repeat-y',
    boxSizing: 'border-box',
  },
  row: {
    display: 'flex',
    width: '100%'
  },
  tablebox: {
    width: '100%',
    // paddingTop: theme.spacing(1),
    // paddingLeft: theme.spacing(1),
    display: 'flex',
    // justifyContent: 'center',
    // flexDirection: 'column',
  },
  innerBox: {
    display: "flex",
    alignItems: "stretch",
    justifyContent: "space-between",
    border: "1px solid #e0e0e0",
    // padding: theme.spacing(2),
    padding: "20px 30px", margin: "20px",
    borderRadius: "4px",
    backgroundColor: "#ffffff",
    maxHeight: '70vh',
    overflowY: "auto",
  },
  leftBox: {
    flex: 1,
    // maxWidth: "48%",
    padding: theme.spacing(2),
    boxSizing: "border-box",
    // overflowY: "auto",
    maxWidth: "50%",
    maxHeight: '100%',
  },
  rightBox: {
    flex: '0 0 45%',
    maxHeight: '100%',
    display: 'flex',
    maxWidth: "50%",
    boxSizing: "border-box",
    // overflowX: "auto",
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
  },
  noBorder: {
    verticalAlign: 'top',
    borderBottom: 'none',
    // textAlign: 'left',  // Change from right to left
    // width: 'max-content',  // Set width to 2vh

  },
  rightAlignText: {
    textAlign: 'right',  // Change from right to left
  },
  rightColumn: {
    // paddingLeft: theme.spacing(4),
    // textAlign: 'left',  // Ensure left alignment
    // flex: 1
  },
  leftColumn: {
    // textAlign: 'right',  // Add explicit left alignment
    // width: '7pc'
    width: '100px'
  },
});

export default RawdataStyles;
