/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import MenuItem from "@mui/material/MenuItem";
import { TextValidator } from "react-material-ui-form-validator";

// Import Config Constants
import { errorsConstants } from "../../config/constants";

/**
 * Select Field Component
 * @param {*} props
 */
function SelectFieldComponent (props) {
  /**
   * Handle
   * Select Field Events
   */
  const handleChange = (event) => {
    props.handleChange({ name: props.name, value: event.target.value });
  };

  /**
   * Get Props Values
   */
  const {
    name,
    label,
    value,
    options,
    validators,
    margin,
    disabled,
    variant,
    fullWidth,
    className,
    showErrorMessages,
    panel,
    extraProps
  } = props;
  const errorMessages = showErrorMessages ? validators.map(s => errorsConstants[s]) : [];

  return (
    <div>
      {name === "lang" &&
        <TextValidator
          select
          name={name}
          value={value}
          label={label}
          variant={variant}
          fullWidth={fullWidth}
          className={className}
          onChange={handleChange}
          margin={margin}
          validators={validators}
          errorMessages={errorMessages}
          disabled={disabled}
          panel={panel}
        >
          {options.map((item, keyIndex) => (
            <MenuItem className={"colorBlack"} key={keyIndex} name={item.key} value={item.name} >
              {item.name}
            </MenuItem>
          ))}
        </TextValidator>
      }
      {name === "surveys" &&
        <TextValidator
          select
          name={name}
          value={value}
          label={label}
          variant={variant}
          fullWidth={fullWidth}
          className={className}
          onChange={handleChange}
          margin={margin}
          validators={validators}
          errorMessages={errorMessages}
          disabled={disabled}
          panel={panel}
        >
          {options.map((item, keyIndex) => (
            <MenuItem className={"colorBlack"} key={keyIndex} name={item.key} value={item._id} >
              {item.name}
            </MenuItem>
          ))}
        </TextValidator>
      }
      {name !== "lang" && name !== "RGConversion" && name !== "surveys"&&
        <TextValidator
          select
          name={name}
          value={value}
          label={label}
          variant={variant}
          fullWidth={fullWidth}
          className={className}
          onChange={handleChange}
          margin={margin}
          validators={validators}
          errorMessages={errorMessages}
          disabled={disabled}
          panel={panel}
          {...extraProps}
        >
          {options.map((item, keyIndex) => (
            <MenuItem className={"colorBlack"} key={keyIndex} value={item} >
              {item}
            </MenuItem>
          ))}
        </TextValidator>
      }
      {name === "RGConversion" &&
        <TextValidator
          select
          name={name}
          value={value ? value : "none"}
          label={label}
          variant={variant}
          fullWidth={fullWidth}
          className={className}
          onChange={handleChange}
          margin={margin}
          validators={validators}
          errorMessages={errorMessages}
          disabled={disabled}
          panel={panel}
        >
         <MenuItem className={"colorGrey"} key="0" value="none" >Select a frequency</MenuItem>
          {options.map((item, keyIndex) => (
            <MenuItem className={"colorBlack"} key={keyIndex} name={item.name} value={item.name} >
              {item.displayName}
            </MenuItem>
          ))}
        </TextValidator>
      }
    </div>
  );
}

// default props
SelectFieldComponent.defaultProps = {
  name: "",
  className: "",
  value: "",
  label: "",
  fullWidth: false,
  variant: "outlined",
  margin: "dense",
  errorMessages: [],
  options: [],
  showErrorMessages: true,
  validators: [],
  disabled: false,
  handleChange: () => { },
  extraProps: {}
};

// prop types
SelectFieldComponent.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.any,
  fullwidth: PropTypes.bool,
  variant: PropTypes.string,
  margin: PropTypes.string,
  className: PropTypes.string,
  showErrorMessages: PropTypes.bool,
  options: PropTypes.array,
  errorMessages: PropTypes.array,
  validators: PropTypes.array,
  disabled: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  extraProps: PropTypes.object
};

/** Export Component */
export default SelectFieldComponent;
