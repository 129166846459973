import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import withStyles from '@mui/styles/withStyles';
import Popover from "@mui/material/Popover";
import Grid from "@mui/material/Grid";
import TypographyComponent from "../../../components/TypographyComponent/TypographyComponent";
import {  RadioGroup, Radio } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Button from "@mui/material/Button";
import { useHistory, useLocation } from "react-router-dom";

//import table Dimentsions
import { getHeaderTabTableHeight, getReportTableRowSize } from "../../../config/layout";

// Import History
import history from "../../../config/history";

// Import helper
import { getQueryVariable } from "../../../helpers";

// Import Component
import TableComponent from "../../../components/TableComponent/TableComponent";
import DialogComponent from "../../../components/DialogComponent/DialogComponent";
import CreateReportPopup from "./CreateReport/CreateReportPopup";
import CreateCustomReportPopup from "../CustomReportBuilder";

// Import Actions
import { reportAction, excelAction, pdfAction, pptAction} from "../../../redux/actions";

// Import Styles and Image
import ReportsStyles from "../ReportsStyles";

// import constants
import { excelConstants, pdfConstants, pptConstants, ReportsConstants, REPORT_TYPE_CONSTANTS } from "../../../config/constants";

function SavedReports(props) {
  const excelReports = [REPORT_TYPE_CONSTANTS.RED_FLAG_REPORT, REPORT_TYPE_CONSTANTS.REHIRE_REPORT,
    REPORT_TYPE_CONSTANTS.PARTICIPATION_REPORT, REPORT_TYPE_CONSTANTS.RAW_DATA]
    
  const dispatch = useDispatch();
  const client_logo = useSelector((state) => state.client.getOne.logo);

  const routerDomHistory = useHistory();
  const routerDomLocation = useLocation();

  //table dimensions
  const tableHeight = getHeaderTabTableHeight();
  const tableRowSize = getReportTableRowSize();

  // get props
  const { classes, location } = props;
  //state for open close popup
  const [savePopup, setSavePopup] = useState(false);
  const [saveCustomPopup, setSaveCustomPopup] = useState(false);
  const [reportType, setReportType] = useState("");
  //const [open, setOpen] = useState(false);
  const [downloadOption, setDownloadOption] = useState(1);
  const [report, setReport] = React.useState("");
  useEffect(()=>{
    setDownloadOption(1)
  },[window.location.hash])
 
   //open close save popup
   const [anchorEl, setAnchorEl] = React.useState(null);
   const handleClick = (event) => {
      // setIncludeDashboad(false)
       setAnchorEl(event.currentTarget);
   };
   const handleClose = () => {
       setAnchorEl(null);
   };
   const open = Boolean(anchorEl);
   const id = open ? "simple-popover" : undefined;
   const handleDownload = () => {
    if(downloadOption == 1){
      handleExport(report);
      
    }
    else if(downloadOption == 2){
      handleExportPPT(report)
     
    }
    setAnchorEl(null);
}



  const openPopup = () => {
    setSavePopup(true);
  };
  const closePopup = () => {
    setSavePopup(false);
    dispatch(reportAction.resetReportData());
  };

  const openCustomPopup = () => {
    setSaveCustomPopup(true);
  };
  const closeCustomPopup = () => {
    setSaveCustomPopup(false);
    dispatch(reportAction.resetCustomReportData());
  };

  // Get reports List
  const reportList = useSelector((state) => state.report.savedReports);
  const { data, page } = reportList;
  

  //Reset all search text box to empty
  const isPageReset = getQueryVariable(props.location.search, "reset") === "true";
  useEffect(() => {
    if (isPageReset) {
      dispatch(reportAction.getSavedReports(1, tableRowSize, "status", "desc", [], []));
      history.replace(props.location.pathname)
    }
  }, [isPageReset]);

  //Handle Table pagination Event
  const onChangePagination = (skip, limit) => {
    dispatch(reportAction.getSavedReports(skip,limit,page.sortBy,page.sort,page.sFields,page.sValues));
  };

  //Handle Table Sort Request Event
  const onSortRequest = (sortBy, sort) => {
    dispatch(
      reportAction.getSavedReports(page.skip,page.limit,sortBy,sort,page.sFields,page.sValues));
  };

  //Handle Table Search Input Change Event
  const onInputChangeRequest = (searchField, searchKey) => {
    const { sFields, sValues } = page;
    if (sFields.indexOf(searchField) >= 0) {
      if (searchKey === "") {
        const index = sFields.indexOf(searchField);
        sValues.splice(index, 1);
        sFields.splice(index, 1);
      } else {
        sValues[sFields.indexOf(searchField)] = searchKey;
      }
    } else {
      sFields.push(searchField);
      sValues.push(searchKey);
    }
    dispatch(reportAction.getSavedReports(1,tableRowSize,page.sortBy,page.sort,sFields,sValues));
  };

  // handle export
  const handleExport = (item) => {
    const { download: reportData, customReportData, reportType} = item;
    const { selectedDemographic: demographics, commentQuestions } = reportData;
    // reportData.surveys = reportData.surveysData
    if (excelReports.indexOf(reportType) > -1) {
      if (reportType === REPORT_TYPE_CONSTANTS.RED_FLAG_REPORT) {
        dispatch(excelAction.download({
          ...reportData,
          excelType: excelConstants.RED_FLAG_REPORT
        }, true));
      } else if (reportType === REPORT_TYPE_CONSTANTS.RAW_DATA) {
        dispatch(excelAction.download({
          ...reportData,
          excelType: excelConstants.RAW_DATA_REPORT
        }, true));
      } else if (reportType === REPORT_TYPE_CONSTANTS.PARTICIPATION_REPORT) {
        dispatch(excelAction.download({ filters: reportData, excelType: excelConstants.PARTICIPATION_REPORT }, true));
      } else if (reportType === REPORT_TYPE_CONSTANTS.REHIRE_REPORT) {
        dispatch(excelAction.download({
          ...reportData,
          excelType: excelConstants.REHIRE_POTENTIAL_REPORT
        }, true));
      }
    } else {
      //comment Report
      if (reportType === REPORT_TYPE_CONSTANTS.COMMENT_REPORT) {
        const filters = { ...reportData, commentQuestions: commentQuestions }
        if (demographics === "Topics") {
          dispatch(pdfAction.download({ filters: filters, pdfType: pdfConstants.COMMENT_REPORT_NLP }))
        } else {
          dispatch(pdfAction.download({ filters: filters, pdfType: pdfConstants.COMMENT_REPORT }))
        }
      }

      //benchmark Report
      else if (reportType === pdfConstants.BENCHMARK_REPORT) {
        const filters = { ...reportData}
        dispatch(pdfAction.download({ filters: filters, pdfType: pdfConstants.BENCHMARK_REPORT, reqType: "report" }))
      }

      //dashboard report
      else if (reportType === pdfConstants.DASHBOARD_REPORT){
        const filters = { ...reportData}
        dispatch(pdfAction.download({ filters: filters, pdfType: pdfConstants.DASHBOARD_SAVED_REPORT, reqType: "report" }))
      }

       //dashboard report
       else if (reportType === pdfConstants.CUSTOM_REPORT){
        const filters = { ...reportData}
        // if(filters?.doc_type === 'ppt'){
        //  dispatch(pptAction.download({ filters: filters, client_logo: client_logo, pdfType: pptConstants.CUSTOM_REPORT, reqType: "report" }))
        // }else{
        dispatch(pdfAction.download({ filters: filters, pdfType: pdfConstants.CUSTOM_REPORT, reqType: "report" }))
       // }
      

      }
    }
    
  };

  // handle export ppt
  const handleExportPPT= (item) => {
    const { download: reportData, reportType} = item;
    const { selectedDemographic: demographics, commentQuestions } = reportData;
    // reportData.surveys = reportData.surveysData
    if (excelReports.indexOf(reportType) > -1) {
      if (reportType === REPORT_TYPE_CONSTANTS.RED_FLAG_REPORT) {
        dispatch(excelAction.download({
          ...reportData,
          excelType: excelConstants.RED_FLAG_REPORT
        }, true));
      } else if (reportType === REPORT_TYPE_CONSTANTS.RAW_DATA) {
        dispatch(excelAction.download({
          ...reportData,
          excelType: excelConstants.RAW_DATA_REPORT
        }, true));
      } else if (reportType === REPORT_TYPE_CONSTANTS.PARTICIPATION_REPORT) {
        dispatch(excelAction.download({ filters: reportData, excelType: excelConstants.PARTICIPATION_REPORT }, true));
      } else if (reportType === REPORT_TYPE_CONSTANTS.REHIRE_REPORT) {
        dispatch(excelAction.download({
          ...reportData,
          excelType: excelConstants.REHIRE_POTENTIAL_REPORT
        }, true));
      }
    } else {
      //comment Report
      if (reportType === REPORT_TYPE_CONSTANTS.COMMENT_REPORT) {
        const filters = { ...reportData, commentQuestions: commentQuestions }
        if (demographics === "Topics") {
          dispatch(pptAction.download({ filters: filters,client_logo: client_logo, pdfType: pptConstants.COMMENT_REPORT_NLP }))
        } else {
          dispatch(pptAction.download({ filters: filters,client_logo: client_logo, pdfType: pptConstants.COMMENT_REPORT }))
        }
      }

      //benchmark Report
      else if (reportType === pptConstants.BENCHMARK_REPORT) {
        const filters = { ...reportData}
        dispatch(pptAction.download({ filters: filters, client_logo: client_logo, pdfType: pptConstants.BENCHMARK_REPORT, reqType: "report" }))
      }

      //dashboard report
      else if (reportType === pptConstants.DASHBOARD_REPORT){
        const filters = { ...reportData}
        dispatch(pptAction.download({ filters: filters, client_logo: client_logo, pdfType: pptConstants.DASHBOARD_SAVED_REPORT, reqType: "report" }))
      }

        //dashboard report
        else if (reportType === pptConstants.CUSTOM_REPORT){
          const filters = { ...reportData}
          dispatch(pptAction.download({ filters: filters, client_logo: client_logo, pdfType: pptConstants.CUSTOM_REPORT, reqType: "report" }))
        }
    }
    
  };

 
  //Handle DOWNLOAD Button Event
  const handelActionButtonClick = (field, id, item) => {
    switch (field) {
      case "EXPORT":
        if(item.reportType ===  pdfConstants.DASHBOARD_REPORT || item.reportType ===  pdfConstants.CUSTOM_REPORT || reportType === REPORT_TYPE_CONSTANTS.COMMENT_REPORT){
        setAnchorEl(event.currentTarget);
        setDownloadOption(1)
        setReport(item)
         }else{
         handleExport(item);
        }
        break;
        case "COPY":
          dispatch(reportAction.copy({ _id: id, name: item }, () => {
            dispatch(reportAction.getSavedReports(page.skip, page.limit, page.sortBy, page.sort, page.sFields, page.sValues));
          })
          );
          break;
      default:
        break;
    }
  };

   //Handle PPT DOWNLOAD Button Event
   const handlePPTActionButtonClick = (field, id, item) => {
    switch (field) {
      case "EXPORT PPT":
        handleExportPPT(item);
        break;
      default:
        break;
    }
  };

  //Delete Report
  const handleTableDeleteAction = (id) => {
    dispatch(reportAction.remove({ id: id }, () => {
        //retain old search values
        if (page.skip) {
          dispatch(reportAction.getSavedReports(page.skip,page.limit,page.sortBy,page.sort,page.sFields,page.sValues));
        } else {
          dispatch(reportAction.getSavedReports(1,tableRowSize,"status","desc",[],[]));
        }
      })
    );
  };

  /**
   * Clear Search Text By Field
   * @param {*} searchField
   * @param {*} searchKey
   */
  const handleClearSearchByField = (searchField, searchKey) => {
    const { sFields, sValues } = page;
    sValues[sFields.indexOf(searchField)] = searchKey;
    dispatch(reportAction.getSavedReports(1,tableRowSize,page.sortBy,page.sort,sFields,sValues));
  };

  //Handle Table Navigation Event
  const handleNavigateAction = (id, field, item) => {
    setReportType(item.reportType)
   if(item.reportType === "Custom Report"){
    // dispatch(reportAction.getCustomReportData({ id: id }));
    routerDomHistory.push(`/reports/custom_report/${id}`, {from: routerDomLocation.pathname});
    openCustomPopup();
   }else{
    dispatch(reportAction.getReportData({ id: id }));
    openPopup();
   }
    
  };

  return (
    <React.Fragment>
      <TableComponent
        data={data}
        header={page.fieldFilters.headerCols}
        field={page.fieldFilters.fieldCols}
        search={page.fieldFilters.searchCols}
        actions={["EXPORT","COPY","DELETE"]}
        removeTooltipCols={["date", "frequency"]}
        height={tableHeight}
        page={page.skip}
        count={page.count}
        rowsPerPage={page.limit}
        links={[page.fieldFilters.fieldCols[0]]}
        sort={page.sort}
        sortBy={page.sortBy}
        sFields={page.sFields}
        sValues={page.sValues}
        noDataErrorMessage={"No Reports Found"}
        paginationVisible={!page.lastPage}
        alignCenterFields={[]}
        customIconFields={'reportType'}
        deleteContent={ReportsConstants.DELETE_REPORT}
        handleNavigateAction={handleNavigateAction}
        onChangePagination={onChangePagination}
        onSortRequest={onSortRequest}
        onInputChangeRequest={onInputChangeRequest}
        handelActionButtonClick={handelActionButtonClick}
       //handlePPTActionButtonClick={handlePPTActionButtonClick}
        handleTableDeleteAction={handleTableDeleteAction}
        handleClearSearchByField={handleClearSearchByField}
        resetSearchText={isPageReset}
      ></TableComponent>
           <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "center",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "center",
                    horizontal: "left",
                }}
            >
                
                <Grid className={`${classes.download_popup} p-2`}>
                    <Grid item className="pb5">
                        <TypographyComponent variant="h5" className="txtBold">
                            {ReportsConstants.DOWNLOADREPORT}
                        </TypographyComponent>
                    </Grid>

                    <Grid item className="mb-2">
                        <TypographyComponent variant="body1">
                            {ReportsConstants.DOWNLOADREPORT_TEXT}
                        </TypographyComponent>
                    </Grid>
              
                        <Grid className={classes.radioGroup}>
                            <RadioGroup
                                row
                                value={downloadOption}
                                onChange={(e)=>{setDownloadOption(e.target.value)}}
                            >
                                <FormControlLabel value={1} control={<Radio />} label="PDF" />
                                <FormControlLabel value={2} control={<Radio />} label="PowerPoint" />
                            </RadioGroup>
                        </Grid>
                  
                    <Grid item className="mb-2">
                        <Grid
                            container
                            justifyContent="space-between"
                            alignItems="center"
                            className="mt-2"
                        >
                          
                            <Grid>
                                <Button
                                 variant="contained"
                                    color="primary"
                                    size="small"
                                    className="mr-1"
                                    onClick={handleDownload}
                                >{ReportsConstants.DOWNLOAD}</Button>
                                <Button
                                 variant="contained"
                                    color="secondary"
                                    size="small"
                                    onClick={handleClose}
                                >{ReportsConstants.CANCEL}</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Popover>
      {savePopup && (
        <DialogComponent open={true} fullScreen={true}>
         <CreateReportPopup title={ReportsConstants.EDITA_REPORT} closePopup={closePopup} classes={classes} isSaveReport={true} />
        </DialogComponent>
      )}
        {saveCustomPopup && (
        <DialogComponent open={true} fullScreen={true}>
          <CreateCustomReportPopup title={ReportsConstants.EDITA_REPORT} closePopup={closeCustomPopup} classes={classes} isSaveReport={true} />
        </DialogComponent>
      )}
    </React.Fragment>
  );
}
// default props
SavedReports.defaultProps = {
  classes: {},
  location:{},
};

// prop types
SavedReports.propTypes = {
  classes: PropTypes.object,
  location:PropTypes.object
};
export default withStyles(ReportsStyles)(SavedReports);
