import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import withStyles from "@mui/styles/withStyles";
import { Link } from "react-router-dom";

// Import Styles and Image
import NewReportStyles from "./NewReportStyles"

// Constants
import { ReportsConstants } from "../../../../config/constants";

// Import components
import TypographyComponent from "../../../../components/TypographyComponent/TypographyComponent";
import SearchInputComponent from "../../../../components/SearchInputCompoment/SearchInputComponent";

import DemographicList from "./NewReportDemographicList";

// Import Actions
import { reportAction } from "../../../../redux/actions";

function Filters(props) {
  const dispatch = useDispatch();

  // Get props
  const { classes } = props;
  // Get Reducer Data
  let { allDemographics, allDemographicsItems, customReportData, loaders: { reportloader } } = useSelector((state) => state.report);
  const { metadata } = useSelector((state) => state.metadata);
  const [search, setSearch] = useState("");

  // Fetch demographics data whenever search or date changes
  useEffect(() => {
    dispatch(
      reportAction.getDemographics({
        metadata: [],
        surveys: customReportData.surveys,
        date: customReportData.date
      })
    );
    // }
  }, [customReportData.surveys, customReportData.date]);

  // Clear demographic filter
  const handleClear = () => {
    const updatedReportData = { ...customReportData, demographics: {} };
    dispatch(reportAction.updateCustomReport(updatedReportData));
  };

  // Handle search input change
  const handleSearch = (name, value) => {
    setSearch(value);
    dispatch(
      reportAction.getDemographics({
        metadata: [],
        surveys: customReportData.surveys,
        date: customReportData.date,
        search: value ? value : ""
      })
    );
  };

  const handleClearSearch = () => {
    setSearch("");
    dispatch(
      reportAction.getDemographics({
        metadata: [],
        surveys: customReportData.surveys,
        date: customReportData.date,
        search: ""
      })
    );
  };

  return (
    <React.Fragment>
      <Grid container style={{ maxHeight: "60vh" }}>
        <Grid container style={{ justifyContent: 'left' }}>
          <Grid container className={classes.lightOrangeBox}>
            <Box
            >
              {/* Content inside the box */}
              <p>{ReportsConstants.CUSTOM_SELECT_DEMOGRAPHIC}</p>
            </Box>
          </Grid>
        </Grid>

        <Grid container style={{ maxHeight: "70%", paddingTop: "15px" }} >
          <Grid container justifyContent="space-between" >
            <Grid item sm={10.5}>
              <SearchInputComponent
                id="DemoGraphicsearch"
                placeholder="Search"
                value={search}
                field="search"
                showClearIcon={true}
                fullWidth={true}
                onInputChangeRequest={handleSearch}
                handleClearSearch={handleClearSearch}
              />
            </Grid>
            <Grid item sm={1}>
              <Link
                className="cursor-pointer colorTheme right"
                onClick={handleClear}
              >
                {ReportsConstants.CUSTOM_CLEAR_ALL}
              </Link>
            </Grid>


          </Grid>

          {allDemographics && Object.keys(allDemographics || {})?.length > 0 ? (
            <Grid container style={{
              maxHeight: "100%",
              height: "auto",
              overflow: "auto",
              marginTop: "10px"
            }}>
              {/* <Grid item sm={6}> */}
              <Grid className={classes.demographicWIdth}>
                {Object.keys(allDemographics || {})?.map((key) => (
                  <React.Fragment key={key}>
                    {allDemographics[key] > 0 && (
                      <DemographicList
                        classes={classes}
                        search={search}
                        reportloader={reportloader}
                        currentDemographic={key}
                        currentDemographicCount={Number(allDemographics[key])}
                        allDemographicsItems={allDemographicsItems}
                        customReportData={customReportData}
                        metadata={metadata}
                      />
                    )}
                  </React.Fragment>
                ))}
              </Grid>
            </Grid>
          ) : (
            <Grid container>
              <TypographyComponent className="p-2" variant="h6">
                {ReportsConstants.CUSTOM_NO_DEMOGRAPHIC}
              </TypographyComponent>
            </Grid>
          )}

        </Grid>

      </Grid>
    </React.Fragment>
  );
}

// Default props
Filters.defaultProps = {
  classes: {},
};

// Prop types
Filters.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(NewReportStyles)(Filters);
