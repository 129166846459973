import React from "react";
import { Box, Grid } from "@mui/material";
import TypographyComponent from "../../components/TypographyComponent/TypographyComponent";
import RawdataStyles from "./RawdataStyles";
import withStyles from "@mui/styles/withStyles";


const SelectedContent = ({ rawdataAutomation, props }) => {
  const { classes } = props;

  const renderRow = (label, value, condition = true) => {
    if (!condition) return null;

    return (
      <Grid item className={`${classes.row}`}>
        <Grid className={`${classes.noBorder} ${classes.leftColumn}`} align="right">
          <TypographyComponent variant="h6" style={{ fontWeight: 'bold' }}>
            {label}:
          </TypographyComponent>
        </Grid>
        <Grid className={`${classes.noBorder} ${classes.rightColumn}`}>
          <TypographyComponent variant="h6">
            {value}
          </TypographyComponent>
        </Grid>
      </Grid>
    );
  };

  const formatDate = () => {
    const { date } = rawdataAutomation;
    if (!date.type) return null;

    switch (date.type) {
      case "Custom dates":
        return new Date(date.startDate.slice(0, 10)).toLocaleDateString('en-US', { 
          month: '2-digit', 
          day: '2-digit', 
          year: 'numeric' 
        }) + " - Most Recent";
      case "Pulse dates":
        return date.pulseDate;
      default:
        return date.type;
    }
  };

  return (
    <Box className={classes.roundedBox}>
      <Grid item xs={12} sx={{ padding: '0 0 10px 0' }}>
        <TypographyComponent
          variant="h6"
          color="primary"
          style={{ fontWeight: 'bold' }}
        >
          Your Raw Data Report Contents
        </TypographyComponent>
      </Grid>
      <Box className={classes.tablebox}>
        <Grid container rowGap={1}>
          {renderRow("Title", rawdataAutomation.name, rawdataAutomation.name)}
          {renderRow("Surveys", rawdataAutomation.surveys?.[0]?.name, rawdataAutomation.surveys?.length > 0)}
          {renderRow("Date", formatDate(), rawdataAutomation.date.type)}
          {renderRow("Frequency", rawdataAutomation.frequency, rawdataAutomation.frequency)}
          {renderRow("Day", rawdataAutomation.day, rawdataAutomation.day)}
        </Grid>
      </Box>
    </Box>
  );
};

export default withStyles(RawdataStyles)(SelectedContent);