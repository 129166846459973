/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import withStyles from '@mui/styles/withStyles';
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
 
// Import Styles and Image
import ReportsStyles from "../../ReportsStyles";
 
//import table Dimentsions
import { getTableRowSize } from "../../../../config/layout";
 
// Import Component
import ButtonComponent from "../../../../components/ButtonComponent/ButtonComponent";
import TypographyComponent from "../../../../components/TypographyComponent/TypographyComponent";
import SelectSurvey from "./ReportSteppers/SelectSurvey";
import ChooseDates from "./ReportSteppers/ChooseDates";
import Demographic from "./ReportSteppers/Demographic";
import SelectReport from "./ReportSteppers/SelectReport";
import SaveReport from "./ReportSteppers/SaveReport";

 
//Import Actions
import { excelAction, reportAction, pdfAction, alertAction } from "../../../../redux/actions";
 
//import validations
import { stepperValidation } from "./validation";
 
// import constants
import { ReportsConstants, excelConstants, pdfConstants,BENCHMARK_LIST, REPORT_TYPE_CONSTANTS, surveyTypes } from "../../../../config/constants";
 
const tableRowSize = getTableRowSize();
 
const totalSteps = [0, 1, 2, 3, 4]
 
function getStepContent(step, isSaveReport) {
  switch (step) {
    case 0:
      return <SelectSurvey isSaveReport={isSaveReport}></SelectSurvey>;
    case 1:
      return <ChooseDates></ChooseDates>;
    case 2:
      return <Demographic></Demographic>;
    case 3:
      return <SelectReport isSaveReport={isSaveReport}></SelectReport>;
    case 4:
      return <SaveReport></SaveReport>;
    default:
      return <SelectSurvey></SelectSurvey>;
  }
}
 
const getNextStep = (currentStep, skippedSteps) => {
  const availableSteps = totalSteps.filter(f => f > currentStep && skippedSteps.indexOf(f) === -1)
  return availableSteps[0]
}
 
const excelReports = [REPORT_TYPE_CONSTANTS.RED_FLAG_REPORT, REPORT_TYPE_CONSTANTS.REHIRE_REPORT,
REPORT_TYPE_CONSTANTS.PARTICIPATION_REPORT, REPORT_TYPE_CONSTANTS.RAW_DATA];
 
// handle export
export const handleExport = (reportType, demographics, { reportData, commentReport, dispatch }) => {
  if (excelReports.indexOf(reportType) > -1) {
    //red flag
    if (reportType === REPORT_TYPE_CONSTANTS.RED_FLAG_REPORT) {
      dispatch(excelAction.download({
        ...reportData,
        excelType: excelConstants.RED_FLAG_REPORT,
        reqType: "report"
      }, true));
    }
 
    //raw data
    else if (reportType === REPORT_TYPE_CONSTANTS.RAW_DATA) {
      dispatch(excelAction.download({
        ...reportData,
        excelType: excelConstants.RAW_DATA_REPORT,
        reqType: "report"
      }, true));
    }
 
    //participation report
    else if (reportType === REPORT_TYPE_CONSTANTS.PARTICIPATION_REPORT) {
      dispatch(excelAction.download({
        filters: reportData, excelType: excelConstants.PARTICIPATION_REPORT, reqType: "report"
      }, true));
    }
 
    //rehire report
    else if (reportType === REPORT_TYPE_CONSTANTS.REHIRE_REPORT) {
      dispatch(excelAction.download({
        ...reportData,
        excelType: excelConstants.REHIRE_POTENTIAL_REPORT,
        reqType: "report"
      }, true));
    }
  } else {
    //comment Report
    if (reportType === REPORT_TYPE_CONSTANTS.COMMENT_REPORT) {
      const filters = { ...reportData, commentQuestions: commentReport.commentQuestions }
      if (demographics === "Topics") {
        dispatch(pdfAction.download({ filters: filters, pdfType: pdfConstants.COMMENT_REPORT_NLP, reqType: "report" }))
      } else {
        dispatch(pdfAction.download({ filters: filters, pdfType: pdfConstants.COMMENT_REPORT, reqType: "report" }))
      }
    } else if (reportType === pdfConstants.BENCHMARK_REPORT) {
      let surveyTypeArray = []
      reportData.surveys.map((survey) => {
        const benchmark_types = Object.keys(BENCHMARK_LIST)
        for(let i =0; i < benchmark_types.length;i++){
          if(BENCHMARK_LIST[benchmark_types[i]].includes(survey.type)){
            if(!surveyTypeArray.includes(benchmark_types[i])){
              surveyTypeArray.push(benchmark_types[i])
            }
          }
      }       
      })
      if (surveyTypeArray.length > 1) {
        dispatch(alertAction.error("Selected survey should be from same benchmark type."));
      }
      else if (surveyTypeArray.length === 1) {
        const updatedReportData = { ...reportData, chosensurveytype: surveyTypeArray[0] };
        dispatch(reportAction.updateReport(updatedReportData))
        dispatch(pdfAction.download({ filters: updatedReportData, pdfType: pdfConstants.BENCHMARK_REPORT, reqType: "report" }))
      }
    }
    //dashboard report
    else if (reportType === pdfConstants.DASHBOARD_REPORT) {
      const filters = { ...reportData }
      dispatch(pdfAction.download({ filters: filters, pdfType: pdfConstants.DASHBOARD_SAVED_REPORT, reqType: "report" }))
    } else if (reportType === pdfConstants.INDIVIDUAL_REPORT) {
      const filters = { ...reportData }
      dispatch(pdfAction.download({ filters: filters, pdfType: pdfConstants.INDIVIDUAL_REPORT, reqType: "report" }))
    } else if (reportType === pdfConstants.GROUP_REPORT) {
      const filters = { ...reportData }
      dispatch(pdfAction.download({ filters: filters, pdfType: pdfConstants.GROUP_REPORT, reqType: "report" }))
    } else if (reportType === REPORT_TYPE_CONSTANTS.COMMENT_EXCEL_REPORT) {
      const filters = { ...reportData, commentQuestions: commentReport.commentQuestions }
      if (demographics === "Topics") {
        dispatch(excelAction.download({ filters: filters, excelType: excelConstants.COMMENT_EXCEL_REPORT_NLP, reqType: "report" }, true))
      } else {
        dispatch(excelAction.download({ filters: filters, excelType: excelConstants.COMMENT_EXCEL_REPORT, reqType: "report" }, true))
      }
    }
 
  }
};
 
export const validateReportForm = (reportForm) => {
  if (reportForm && reportForm.current) {
    return reportForm.current.isFormValid(false)
  }
  return Promise.resolve()
}
 
//handle report save
export const handleSave = ({ reportData, commentReport }, dispatch) => {
  if (reportData.reportType === pdfConstants.BENCHMARK_REPORT) {
    let surveyTypeArray = []
    surveyTypeArray = reportData.surveys.map((surveys) => {
      return (surveys.type)
    })
    surveyTypeArray = [...new Set(surveyTypeArray)]
    if (surveyTypeArray.length > 1) {
      dispatch(alertAction.error("Selected survey should be from same survey type."));
      return false
    }
    else if (surveyTypeArray.length === 1) {
      const updatedReportData = { ...reportData, chosensurveytype: surveyTypeArray[0] };
      dispatch(reportAction.updateReport(updatedReportData))
      dispatch(reportAction.saveOrUpdateReport(updatedReportData));
      dispatch(reportAction.getSavedReports(1, tableRowSize, "name", "asc", [], []));
      return true
    }
  }
  else if (reportData.reportType === REPORT_TYPE_CONSTANTS.COMMENT_REPORT) {
    dispatch(reportAction.saveOrUpdateReport({ ...reportData, commentQuestions: commentReport.commentQuestions }));
    dispatch(reportAction.getSavedReports(1, tableRowSize, "name", "asc", [], []));
    return true
  } else {
    dispatch(reportAction.saveOrUpdateReport(reportData));
    dispatch(reportAction.getSavedReports(1, tableRowSize, "name", "asc", [], []));
    return true
  }
}
 
function CreateReportStepper(props) {
  // get props
  const { classes, closePopup, reportData, commentReport, isSaveReport, reportForm, emailIds } = props;
  // Define Dispatch
  const dispatch = useDispatch();
 
  //get saved reports
  const { hideDashWidgets } = useSelector((state) => state.admin.preference);
  const { reportName } = useSelector((state) => state.report.savedReports);
  const { reportData: { reportType, surveys, subjects } } = useSelector((state) => state.report)
  let reportLoader = useSelector((state) => state.report.loaders.reportloader);
  // const loading = useSelector((state) => state.excel.loaders.reportexcel);
  
  const alert = useSelector((state) => state.alert.message);
  const hasMESurvey = (surveys || []).filter(f => f.type === surveyTypes.MULTI_RATER_FEEDBACK).length > 0
 
  //set state for Stepper
  const [activeStep, setActiveStep] = useState(0);
  let skippedSteps = []
 
  //stepper steps
  let steps = [
    "Select Survey",
    "Choose Dates",
    "Apply Demographic Filters (Optional)",
    "Select Report",
    "Save Report",
  ];
 
  // if ME has survey
  if (hasMESurvey) {
    skippedSteps = [1, 2]
    // disable save
    if (reportData.reportType === REPORT_TYPE_CONSTANTS.INDIVIDUAL_REPORT || reportData.reportType === REPORT_TYPE_CONSTANTS.GROUP_REPORT) {
      skippedSteps.push(4)
    }
  }
 
  const needContinueBtn = (activeStep <= 3 && !hasMESurvey) || (hasMESurvey && activeStep < 3)
 
  return (
    <React.Fragment>
      {reportLoader && reportType !== REPORT_TYPE_CONSTANTS.RAW_DATA &&
        (<div className="caller_detail_loader">
          <CircularProgress />
        </div>)
      }
      <Grid item xs={12} className={"mb-1"}>
        <Stepper
          activeStep={activeStep}
          orientation="vertical"
          className={`${classes.stepperLabel}`}
        >
          {steps.map((label, index) => (
            <Step
              key={label} className="relative" completed={isSaveReport || (index < activeStep)}
              disabled={skippedSteps.indexOf(index) >= 0}>
             
              <StepLabel
                optional={label}
                style={{ cursor: "pointer" }}
                className={`p-0 m-0 ${skippedSteps.indexOf(index) >= 0 ? 'disabled' : ''}`}
                onClick={() => setActiveStep(index)}
                disabled={skippedSteps.indexOf(index) >= 0} />
              {
                reportType === 'Benchmark Report' && label === 'Apply Demographic Filters (Optional)' &&
                <Grid item sm={6} className={`${classes.warningPosition} leftPos`}>
                  <TypographyComponent
                    title={`***Filters apply only to your company's data***`}
                    color={'error'}
                    variant={'p'}
                  />
                </Grid>
              }
             
 
              <StepContent>
                <Grid>{getStepContent(index, isSaveReport)}</Grid>
              </StepContent>
            </Step>
          ))}
        </Stepper>
 
      </Grid>
      <Grid
        container
        justifyContent="flex-end"
        className={classes.footerBtns_container}
      >
        <Grid item className={"mr-2"}>
          {needContinueBtn && (
            <ButtonComponent
              title={"Continue"}
              color={"primary"}
              size={"small"}
              pageClassName="mr-1"
              handleClick={() => {
                const validation = stepperValidation(activeStep, reportData, commentReport.commentQuestions, reportName, "continue", isSaveReport);
                validateReportForm(reportForm).then(isValid => {
                  if (isValid && validation === true) {
                    setActiveStep(getNextStep(activeStep, skippedSteps));
                  } else {
                    // Added the validation to avoid the boolean field
                    if (typeof validation !== 'boolean') {
                      dispatch(alertAction.error(validation));
                    }
                  }
                })
              }}
            />
          )}
          {activeStep + 1 === steps.length && (
            <ButtonComponent
              title={ReportsConstants.SAVE}
              color={"primary"}
              size={"small"}
              type="button"
              pageClassName="mr-1"
              // disabled={reportData && reportData.reportType === "Raw Data"}
              handleClick={() => {
                const validation = stepperValidation(activeStep, reportData, commentReport.commentQuestions, reportName, "save", isSaveReport);
                validateReportForm(reportForm).then(isValid => {
                  if (isValid && validation === true) {
                    if (handleSave({ reportData, commentReport, hideDashWidgets: {...hideDashWidgets} }, dispatch)) {
                      setActiveStep(activeStep + 1)
                      closePopup()
                    }
                  } else {
                    // Added the validation to avoid the boolean field
                    if (typeof validation !== 'boolean') {
                      dispatch(alertAction.error(validation));
                    }
                  }
                })
              }}
            />
          )}
          {activeStep > 2 && (
            <ButtonComponent
              title={ReportsConstants.EXPORT}
              color={"primary"}
              size={"small"}
              type={"button"}
              pageClassName="buttonOrange mr-1 buttonOrange-hover"
              handleClick={() => {
                const validation = stepperValidation(activeStep, reportData, commentReport.commentQuestions, reportName, "export", isSaveReport);
                if (validation === true) {
                  handleExport(reportData.reportType, reportData.selectedDemographic, {
                    reportData, commentReport, dispatch
                  })
                } else {
                  // Added the validation to avoid the boolean field
                  if (typeof validation !== 'boolean') {
                    dispatch(alertAction.error(validation));
                  }
                }
              }}
            />
          )}
          <ButtonComponent
            title={ReportsConstants.CANCEL}
            // color={"secondary"}
            className={classes.cancelBtn}
            size={"small"}
            type={"button"}
            handleClick={closePopup}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
// default props
CreateReportStepper.defaultProps = {
  classes: {},
  reportData: {},
  closePopup: () => { },
  commentReport: {},
  isSaveReport: false,
};
 
// prop types
CreateReportStepper.propTypes = {
  classes: PropTypes.object,
  reportData: PropTypes.object,
  closePopup: PropTypes.object,
  commentReport: PropTypes.object,
  isSaveReport: PropTypes.bool,
};
export default withStyles(ReportsStyles)(CreateReportStepper);