import React from "react";
import { Switch } from "react-router-dom";
import { PublicRoute, PrivateRoute, AuthenticationRoute } from "../auth_routes";

import Login from "../containers/Authentication/Login";
import SamlLogin from "../containers/Authentication/SamlLogin";
import ForgotPassword from "../containers/Authentication/ForgotPassword";
import ResetPassword from "../containers/Authentication/ResetPassword";
import Page404 from "../containers/Page404/Page404";
import SamlErrorPage from "../containers/SamlErrorPage/SamlErrorPage";
import PageExpired from "../containers/PageExpired/PageExpired";
import PageNotHaveAccess from "../containers/PageNotHaveAccess/PageNotHaveAccess";
import Profile from "../containers/Profile/Profile";
import SurveysRouter from "../containers/Surveys/Surveys.Router";
import PeopleRouter from "../containers/People/People.Router";
import ClientRouter from "../containers/Client/Client.Router";
import ReportsPanels from "../containers/Reports/Reports";
import ContentLibrary from "../containers/ContentLibrary/ContentLibrary";
import ActionsRouter from "../containers/Actions/Actions.Router";
import SpCallerRouter from "../containers/SpCaller/SpCaller.Router";
import TakeSurvey from "../containers/TakeSurvey/TakeSurvey.Router";
import TakeSurveyPreview from "../containers/TakeSurvey/TakeSurveyPreview";
import MeSurvey from "../containers/MeSurvey/MeSurvey.Router"
import ExternalApiIntegration from "../containers/ExternalApiIntegration";
import InternalAPIIntegration from "../containers/APIIntegration/InternalAPIIntegration/InternalAPIIntegration";
import RawdatasetupRouter from "../containers/RawDataSetup/Rawdata.Router";

const routes = () => {
  return (
    <Switch>
      <AuthenticationRoute exact path="/" component={Login} />
      <AuthenticationRoute exact path="/login" component={Login} />
      <AuthenticationRoute exact path="/forgotpassword" component={ForgotPassword} />
      <AuthenticationRoute exact path="/resetpassword/:hash/:key" component={ResetPassword} />
      <PublicRoute exact path="/saml/login/:hash" component={SamlLogin} />

      <PrivateRoute exact path="/profile" component={Profile} />
      <PrivateRoute path="/surveys" component={SurveysRouter} />
      <PrivateRoute path="/manage_people" component={PeopleRouter} />
      <PrivateRoute path="/client" component={ClientRouter} />
      <PrivateRoute path="/reports" component={ReportsPanels} />
      <PrivateRoute path="/content-library" component={ContentLibrary} />
      <PrivateRoute path="/actions" component={ActionsRouter} />
      <PrivateRoute path="/spcaller" component={SpCallerRouter} />
      <PrivateRoute path="/takesurveyprev" component={TakeSurveyPreview} />
      {/* API Integration Routes */}
      <PrivateRoute path="/external-api-integration" component={ExternalApiIntegration} />
      {/* <PrivateRoute path="/internal-api-integration" component={InternalAPIIntegration} /> */}
      <PrivateRoute path="/raw-data" component={RawdatasetupRouter} />
      {/* TakeSurvey Routes */}
      <PublicRoute path="/takesurvey/:client_id/:survey_id/:participant_id" component={(props) => <TakeSurvey {...props} takeSurveyUrl="existing" surveyDeviceType="Internet" />} />
      <PublicRoute path="/takesurvey/:survey_url" component={(props) => <TakeSurvey {...props} takeSurveyUrl="shortUrl" surveyDeviceType="Internet" />} />
      <PublicRoute path="/st/:survey_url" component={(props) => <TakeSurvey {...props} takeSurveyUrl="shortUrl" surveyDeviceType="Mobile" />} />
      <PublicRoute path="/PE/:survey_url" component={(props) => <TakeSurvey {...props} takeSurveyUrl="anonymous" surveyDeviceType="Internet" />} />
      <PublicRoute path="/kiosk/:survey_url" component={(props) => <TakeSurvey {...props} takeSurveyUrl="kiosk" surveyDeviceType="Internet" />} />
      <PublicRoute path="/takesurvey" component={(props) => <TakeSurvey {...props} takeSurveyUrl="existing" surveyDeviceType="Internet" />} />
      <PublicRoute path="/takesurvey_spcaller/:client_id/:survey_id/:participant_id/:caller_id" component={(props) => <TakeSurvey {...props} takeSurveyUrl="sp_caller" surveyDeviceType="Phone" />} />
      <PublicRoute path="/takesurvey_spcaller/:survey_url" component={(props) => <TakeSurvey {...props} takeSurveyUrl="sp_caller" surveyDeviceType="Internet" />} />
      <PublicRoute path="/takesurvey_spcaller" component={(props) => <TakeSurvey {...props} takeSurveyUrl="sp_caller" surveyDeviceType="Phone" />} />
      {/* TakeSurvey Preview Route */}
      <PublicRoute path="/previewsurvey/:survey_url/:req_by" component={(props) => <TakeSurvey {...props} takeSurveyUrl="preview" surveyDeviceType="Internet" />} />
      
      {/* ME Survey Routes */}
      <PublicRoute path="/me-takesurvey/:survey_url" component={(props) => <MeSurvey {...props} takeSurveyUrl="shortUrl" surveyDeviceType="Internet" />} />

      <PublicRoute exact path="/expired" component={PageExpired} />
      <PublicRoute exact path="/noaccess" component={PageNotHaveAccess} />
      <PublicRoute exact path="/error/saml/login" component={SamlErrorPage} />
      <PublicRoute component={Page404} />
    </Switch>
  );
};

export default routes;
