import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Grid, Box } from "@mui/material";
import { withStyles } from '@mui/styles';
import ReportsStyles from "../../ReportsStyles";
import _ from "lodash";

// Import Helpers
import { addEllipsis } from "../../../../helpers";

// Components
import TypographyComponent from "../../../../components/TypographyComponent/TypographyComponent";
import AutoCompleteComponent from "../../../../components/AutoCompleteComponent/AutoCompleteComponent";
import SwitchComponent from "../../../../components/SwitchComponent/SwitchComponent";
import SelectCommentReport from "./SelectCommentReport";

// Constants
import { ReportsConstants } from "../../../../config/constants";

// Actions
import { reportAction, updateStateReducerAction } from "../../../../redux/actions";

function NewReportComments({ classes, isSaveReport, isDrawer }) {

  const {
    customReportData,
    commentQuestions,
    commentReport,
    allDemographics
  } = useSelector((state) => state.report);

  const dispatch = useDispatch();
  const [search, setSearch] = useState("");

  const [is_sentimentIncluded, setis_sentimentIncluded] = useState(false);
  const [verbatimIncluded, setVerbatimIncluded] = useState(false);


  const { user } = useSelector((state) => state.profile);
  const { metadata } = useSelector((state) => state.metadata);

  // Check if user has comments permission
  const hasCommentsPermission = user?.access?.includes("Comments") || isSaveReport;

  // If no comments permission, don't render anything
  if (!hasCommentsPermission) {
    return null;
  }

  const handleCommentAnalysisChange = () => {
    const updatedChartOrder = customReportData.chartOrder || [];
    const newCommentAnalysisState = !is_sentimentIncluded;

    setis_sentimentIncluded(newCommentAnalysisState);

    // Remove existing sentiment-related chart orders
    const filteredChartOrder = (updatedChartOrder || [])?.filter(
      item => item !== "Verbatim Analysis" &&
        item !== "Sentiment & Verbatim Analysis" &&
        item !== "Sentiment Analysis"
    );

    if (newCommentAnalysisState) {
      // If verbatim is also true, add "Sentiment & Verbatim Analysis"
      // Otherwise, add "Sentiment Analysis"
      if (verbatimIncluded) {
        filteredChartOrder.push("Sentiment & Verbatim Analysis");
      } else {
        filteredChartOrder.push("Sentiment Analysis");
      }
    }
    else if (verbatimIncluded) {
      filteredChartOrder.push("Verbatim Analysis");
    }

    const updatedReportData = {
      ...customReportData,
      chartOrder: filteredChartOrder,
      is_sentiment: newCommentAnalysisState
    };
    dispatch(reportAction.updateCustomReport(updatedReportData));
  };

  const handleVerbatimChange = () => {
    const updatedChartOrder = customReportData?.chartOrder || [];
    const newVerbatimState = !verbatimIncluded;

    setVerbatimIncluded(newVerbatimState);

    // Remove existing verbatim-related chart orders
    const filteredChartOrder = (updatedChartOrder || [])?.filter(
      item => item !== "Verbatim Analysis" &&
        item !== "Sentiment & Verbatim Analysis" &&
        item !== "Sentiment Analysis"
    );

    if (newVerbatimState) {
      customReportData.selectedDemographic = "No breakout";
      // If sentiment is also true, add "Sentiment & Verbatim Analysis"
      // Otherwise, add "Verbatim Analysis"
      if (is_sentimentIncluded) {
        filteredChartOrder.push("Sentiment & Verbatim Analysis");
      } else {
        filteredChartOrder.push("Verbatim Analysis");

      }
    } else if (is_sentimentIncluded) {
      filteredChartOrder.push("Sentiment Analysis");
    }

    const updatedReportData = {
      ...customReportData,
      chartOrder: filteredChartOrder,
      is_verbatim: newVerbatimState
    };
    dispatch(reportAction.updateCustomReport(updatedReportData));
  };

  // Handle fetching comment questions when report type changes
  useEffect(() => {
    setVerbatimIncluded(customReportData.is_verbatim || false);
    setis_sentimentIncluded(customReportData.is_sentiment || false);
    if (customReportData.is_verbatim) {
      if (Array.isArray(customReportData.selectedDemographic)) {
        customReportData.selectedDemographic = customReportData.selectedDemographic[0];
      } else {
        customReportData.selectedDemographic = customReportData.selectedDemographic
     }
    }
    dispatch(reportAction.getCommentQuestions(customReportData));
  }, [customReportData]);

  const handleClear = () => {
    const clearData = isDrawer ?
      { selectedDemographic: "" } :
      { selectedDemographic: "" };
    const updatedReportData = {
      ...customReportData, ...clearData
    };

    dispatch(reportAction.updateCustomReport({ ...customReportData, ...clearData }));
  };
  const handleDemographicsChange = (e) => {
    const updatedReportData = {
      ...customReportData,
      selectedDemographic: e.key || ""
    }
    dispatch(reportAction.updateCustomReport(updatedReportData));
  };
  const handleSearch = (name, value) => {
    value = value || ''

    let searchSurveys = (allSurveys || [])?.filter(obj => obj?.name && (obj?.name?.toLowerCase())?.includes(value?.toLowerCase()))
    const data = { data: searchSurveys }
    dispatch(updateStateReducerAction.update(ReportsConstants.GET_SURVEYS_SUCCESS, data))
    setSearch(value)
  };

  const handleClearSearch = () => {
    const data = { data: allSurveys }

    dispatch(updateStateReducerAction.update(ReportsConstants.GET_SURVEYS_SUCCESS, data))
    setSearch('')
  };

  const getDemographicsData = () => {
    const defaultData = [
      { key: "No breakout", value: "No breakout", name: "No breakout" },
      { key: "Topics", value: "Topics", name: "Topics" }
    ];

    if (!allDemographics) return defaultData;

    const customDemographics = Object.keys(allDemographics || {})?.filter(item => item !== "_id")?.map(item => {
      const currentMData = metadata.find(e => e.key === item);
      if (currentMData?.visible && currentMData?.visibleInFilter) {
        return {
          key: item,
          value: currentMData.value,
          name: currentMData.value
        };
      }
      return null;
    })?.filter(Boolean);
    const demographicArr = _.sortBy([...customDemographics], "value")
    return [...defaultData, ...demographicArr];
  };



  return (
    <Grid container>
      {/* Info Box */}
      <Grid container style={{ justifyContent: 'left' }}>
        <Grid container className={classes.lightOrangeBox}>
          <Box>
            <p>
              {ReportsConstants.CUSTOM_SELECT_COMMENT}
            </p>

          </Box>
        </Grid>
      </Grid>

      {/* Comment Controls */}
      <Grid container>
        {/* Comment Analysis Switch */}
        <Grid item xs={12} className={classes.switchContainer} style={{
          // maxHeight: "30vh",
          margin: "15px 0 0 7px",
          // marginLeft: "7px",
          // marginTop:"4px"
        }}>
          <SwitchComponent
            color="primary"
            size="small"
            checked={is_sentimentIncluded}
            handleChange={handleCommentAnalysisChange}
            label={
              <TypographyComponent variant="h6">
                {ReportsConstants.CUSTOM_SENTIMENT}
              </TypographyComponent>
            }
            style={{
              margin: "0px",
              marginLeft: "4px"
            }}
          />
        </Grid>

        {/* Verbatim Comments Switch */}
        <Grid item xs={12} className={classes.switchContainer} style={{
          margin: "15px 0 0 7px",
        }}>
          <SwitchComponent
            color="primary"
            size="small"
            checked={verbatimIncluded}
            handleChange={handleVerbatimChange}
            label={
              <TypographyComponent variant="h6">
                {ReportsConstants.CUSTOM_VERBATIM}
              </TypographyComponent>
            }
            style={{
              margin: "0px"
            }}
          />
        </Grid>
        {verbatimIncluded && (
          <><Grid item style={{ paddingLeft: "37px" }}>
            <Grid>
              <TypographyComponent variant="h6">
                {ReportsConstants.CUSTOM_COMMENT_BREAKOUT}
              </TypographyComponent>
              <AutoCompleteComponent
                id="demographics"
                name="SelectDemographics"
                className="heightone"
                suggestions={[
                  { value: null, name: "Select a demographic" },
                  ...getDemographicsData()
                ]}
                handleAutoCompolete={handleDemographicsChange}
                value={customReportData.selectedDemographic && !Array.isArray(customReportData.selectedDemographic)
                  ? getDemographicsData().find(f => customReportData.selectedDemographic === f.key)
                  : { value: null, name: "Select a demographic" }
                }
                extraProps={{
                  classes: {
                    popper: 'select-demographics-popper'
                  }
                }}
              />
            </Grid>
          </Grid>


          </>
        )}

        {/* Comment Reports */}
        {(is_sentimentIncluded || verbatimIncluded) && (customReportData?.commentQuestions?.length > 0 || commentQuestions.length > 0) && (
          <Grid container style={{ maxHeight: "37vh", paddingTop: "15px" }}>
            <SelectCommentReport
              customquestions={customReportData?.commentQuestions}
              classes={classes}
              commentQuestions={commentQuestions}
              commentReport={commentReport}
              handleClear={handleClear}
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

NewReportComments.defaultProps = {
  classes: {},
  isSaveReport: false,
  isDrawer: false
};

NewReportComments.propTypes = {
  classes: PropTypes.object,
  isSaveReport: PropTypes.bool,
  isDrawer: PropTypes.bool
};

export default withStyles(ReportsStyles)(NewReportComments);