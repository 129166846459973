import React, { useState, useEffect, useRef } from "react";
import {
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormControl,
} from "@mui/material";

import { useSelector, useDispatch } from "react-redux";
//Import Actions
import { rawdataAutomationAction } from "../../redux/actions";
import TypographyComponent from "../../components/TypographyComponent/TypographyComponent";
import SearchInputComponent from "../../components/SearchInputCompoment/SearchInputComponent";

const Breakout = () => {
  const dispatch = useDispatch();
  // Get surveys Data
  let { allDemographics, selectedDemographic, date, surveys } = useSelector(
    (state) => state.rawdataAutomation
  );
  const prevValuesRef = useRef({ allDemographics, surveys, date });
  const { metadata } = useSelector((state) => state.metadata);

  const [searchQuery, setSearchQuery] = useState("");

useEffect(() => {
  dispatch(rawdataAutomationAction.updateDemographics(selectedDemographic || ["No breakout"]));
}, []);

useEffect(() => {
    const hasStructuralChange = 
      JSON.stringify(prevValuesRef.current.allDemographics) !== JSON.stringify(allDemographics) ||
      JSON.stringify(prevValuesRef.current.surveys) !== JSON.stringify(surveys) ||
      JSON.stringify(prevValuesRef.current.date) !== JSON.stringify(date);

    if (hasStructuralChange) {
      dispatch(rawdataAutomationAction.updateDemographics(["No breakout"]));
      prevValuesRef.current = { allDemographics, surveys, date };
    }
  }, [allDemographics, surveys, date]);

  const handleSearchChange = (event, value) => {
    setSearchQuery(value);
  };

  // Generate demographics data
  let demographicsData = [{ key: "No breakout", value: "No breakout", name: "No breakout" }];

  if (allDemographics && Object.keys(allDemographics).length) {
    Object.keys(allDemographics).forEach((item) => {
      if (item !== "_id") {
        const currentMData = metadata.filter((e) => e.key === item)[0];
        if (
          currentMData &&
          currentMData.visible === true &&
          currentMData.visibleInFilter === true
        ) {
          demographicsData.push({
            key: item,
            value: currentMData["value"],
            name: currentMData["value"],
          });
        }
      }
    });
    demographicsData = _.sortBy(
      demographicsData.filter(item => item.key !== "No breakout"),
      "value"
    );
    demographicsData = [{ key: "No breakout", value: "No breakout", name: "No breakout" }, ...demographicsData];
  }

  const handleDemographicChange = (option) => {
    let updatedSelected;
    const availableOptions = demographicsData
      .filter(opt => opt.key !== "No breakout")
      .map(opt => opt.key);

    if (option.key === "No breakout") {
      // Toggle "No breakout" selection
      if (selectedDemographic.includes("No breakout")) {
        updatedSelected = []; // Unselect "No breakout"
      } else {
        updatedSelected = ["No breakout"]; // Select only "No breakout"
      }
    } else {
      if (selectedDemographic.includes(option.key)) {
        // Removing an option
        updatedSelected = selectedDemographic.filter(item =>
          item !== option.key && item !== "No breakout"
        );
      } else {
        // Adding an option
        updatedSelected = [
          ...selectedDemographic.filter(item => item !== "No breakout"),
          option.key
        ];

        // Check if all options are now selected
        if (availableOptions.every(key =>
          updatedSelected.includes(key) || key === option.key
        )) {
          // Add all available options to ensure complete selection
          updatedSelected = [...availableOptions];
        }
      }
    }

    dispatch(rawdataAutomationAction.updateDemographics(updatedSelected));
  };

  // Compute whether all options are selected for the Select All checkbox
  const availableOptions = demographicsData
    .filter(opt => opt.key !== "No breakout")
    .map(opt => opt.key);

  const allSelected = availableOptions.length > 0 &&
    availableOptions.every(key => selectedDemographic.includes(key));

  // Handle Select All checkbox change
  const handleSelectAll = () => {
    const updatedSelected = allSelected
      ? []  // If all are selected, deselect all
      : [...availableOptions];  // If not all selected, select all

    dispatch(rawdataAutomationAction.updateDemographics(updatedSelected));
  };

  const filteredOptions = demographicsData.filter((option) =>
    option.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleClearSearch = () => {
    setSearchQuery("");
  };

  return (
    <>
      <TypographyComponent variant="h6">
        {"Select the demographic you wish to view response data from for your report"}
      </TypographyComponent>
      <FormControl component="fieldset" style={{ width: "80%" }}>
        <SearchInputComponent
          id="searchCategoryTex"
          placeholder={"Search"}
          value={searchQuery}
          field={"searchCategory"}
          showClearIcon={true}
          fullWidth={true}
          className="mt-1 mb-1"
          onInputChangeRequest={handleSearchChange}
          handleClearSearch={() => handleClearSearch}
          onEnter={() => { }}
        />
        <FormGroup
          style={{
            maxHeight: "130px",
            overflowY: "auto",
            overflowX: "hidden",
            display: "flex",
            flexWrap: "nowrap",
            paddingLeft: "10px"
          }}
        >
          {filteredOptions.length > 0 ? (
            <>
              {/* No breakout option */}
              <FormControlLabel
                key="No breakout"
                control={
                  <Checkbox
                    checked={selectedDemographic.includes("No breakout")}
                    onChange={() => handleDemographicChange({ key: "No breakout" })}
                    name="No breakout"
                  />
                }
                label="No breakout"
              />

              {/* Select All option (only show if there are demographics) */}
              {demographicsData.length > 1 && (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={allSelected}
                      onChange={handleSelectAll}
                      name="select-all"
                    />
                  }
                  label="Select All"
                />
              )}

              {/* Rest of the demographics options */}
              {filteredOptions
                .filter(option => option.key !== "No breakout")
                .map((option) => (
                  <FormControlLabel
                    key={option.name}
                    control={
                      <Checkbox
                        checked={selectedDemographic.includes(option.key)}
                        onChange={() => handleDemographicChange(option)}
                        name={option.name}
                      />
                    }
                    label={option.name}
                  />
                ))}
            </>
          ) : (
            <TypographyComponent variant="h6">
              {"No Demographics Found"}
            </TypographyComponent>
          )}

        </FormGroup>
      </FormControl>
    </>
  );
};

export default Breakout;
