import React, { useRef, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { withStyles } from "@mui/styles";

import {
  Grid,
  Link,
  Step,
  StepButton,
  StepLabel,
  Stepper,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent
} from "@mui/material";
import { RadioButtonChecked, RadioButtonUnchecked } from "@mui/icons-material";
import { ValidatorForm } from "react-material-ui-form-validator";

import { getCustomReportPrevHistory } from "../../../../helpers/appHelper";

import { ReportsConstants } from "../../../../config/constants";

import { BackArrow } from "../../../../assets/images/svgComponents/ImageComponent";
import TypographyComponent from "../../../../components/TypographyComponent/TypographyComponent";
import { stepperValidation } from "./validation";
import { REPORT_TYPE_CONSTANTS, STEPS_WITHOUT_COMMENTS, STEPS_WITH_COMMENTS } from "../../../../config/constants";
import {
  reportAction,
  alertAction
} from "../../../../redux/actions";
import NewReportStyles from "./NewReportStyles";

// Step Components
import Survey from "./NewReportSelectSurvey";
import Dates from "./NewReportChooseDates";
import Filters from "./NewReportDemographic";
import Dashboard from "./NewReportDashboard";
import Charts from "./NewReportCharts";
import Organize from "./NewReportOrganize";
import Comments from "./NewReportComments";
import SaveReport from "./NewReportSaveReport";
import NewReportContent from "./NewReportContents";

// Custom Step Icon Component
const StepIconComponent = ({ active, completed }) => {
  if (!active && !completed) {
    return <RadioButtonUnchecked style={{ color: 'grey' }} />;
  }
  if (active) {
    return <RadioButtonChecked className="colorTheme" />;
  }

  if (completed) {
    return <RadioButtonChecked className="colorGrey" />;
  }


};

const NewReport = ({
  closePopup,
  commentReport,
  isSaveReport,
  step,
  previousHistory,
  ...props
}) => {
  const { classes } = props;
  const dispatch = useDispatch();

  // State Management
  const [activeStep, setActiveStep] = useState(step || 0);
  const [completed, setCompleted] = useState({ 0: true });
  const [validationError, setValidationError] = useState(null);
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const report_form_ref = useRef();

  // Redux Selectors
  const { user } = useSelector(state => state.profile);
  const { customReportData, savedReports: { reportName } } = useSelector((state) => state.report);
  const reportLoader = useSelector((state) => state.report.loaders.reportloader);
  // Derived State
  const STEPS = user?.access?.includes("Comments")
    ? STEPS_WITH_COMMENTS
    : STEPS_WITHOUT_COMMENTS;

  const routerDomHistory = useHistory();

  // Helper Functions
  function checkIfEmpty(filteredCustomReportData) {
    const {
      demographics,
      selectedDemographic,
      chartOrder,
      includeDashboard,
      commentQuestions
    } = filteredCustomReportData;

    return (
      (!demographics || Object.keys(demographics)?.length === 0) &&
      (!selectedDemographic || selectedDemographic?.length?.[0] == null) &&
      (!chartOrder || chartOrder?.length === 0) &&
      !includeDashboard &&
      (!commentQuestions || commentQuestions?.length === 0)
    );
  }

  // Effect Hooks
  useEffect(() => {
    window.scrollTo(0, 0);
    setValidationError(null);
  }, [activeStep]);

  useEffect(() => {
    const filteredCustomReportData = {
      demographics: customReportData?.demographics,
      selectedDemographic: customReportData?.selectedDemographic,
      chartOrder: customReportData?.chartOrder,
      includeDashboard: customReportData?.includeDashboard,
      commentQuestions: customReportData?.commentQuestions
    };
    setDisabled(checkIfEmpty(filteredCustomReportData));

    let completedObj = {}

    if (isSaveReport) {
      completedObj[0] = true
      completedObj[1] = true
      if (!checkIfEmpty(filteredCustomReportData)) {
        if (customReportData?.demographics !== null) {
          completedObj[2] = true
        }
        if (customReportData?.includeDashboard) {
          completedObj[3] = true
        }
        if (customReportData?.chartOrder) {
          if (customReportData?.chartOrder?.length > 0 && customReportData.chartOrder.some(chart =>
            chart !== "Dashboard" &&
            chart !== "Sentiment Analysis" &&
            chart !== "Verbatim Analysis" &&
            chart !== "Sentiment & Verbatim Analysis")) {
            completedObj[4] = true
          }
        }
        if (customReportData.commentQuestions !== null || customReportData.is_sentiment || customReportData.is_verbatim) {
          completedObj[5] = true
        }

        if (customReportData?.downloadType || customReportData?.frequency || (customReportData?.emailIds !== null && customReportData?.emailIds?.length != 0)) {
          completedObj[7] = true
          if (customReportData?.chartOrder && customReportData?.chartOrder?.length > 0) {
            completedObj[6] = true
          }
        }
      }

      setCompleted(completedObj)
    }
  }, [isSaveReport, customReportData?.surveys, customReportData?.date]);

  // Function to compare if two arrays are in the same order
  const compareArrays = (arr1, arr2) => {
    // First, check if the arrays have the same length
    if (arr1?.length !== arr2?.length) {
      return false;  // Arrays of different lengths are not the same
    }

    // Now, compare each element at corresponding indices
    for (let i = 0; i < arr1?.length; i++) {
      if (arr1[i] !== arr2[i]) {
        return false;  // If any element is different, arrays are not the same
      }
    }

    // If all checks passed, arrays are the same
    return true;
  }

  const validateReportForm = (reportForm) => {
    if (reportForm && reportForm.current) {
      return reportForm.current.isFormValid(false)
    }
    return Promise.resolve()
  }
  // Validation and Navigation Methods
  const validateCurrentStep = () => {
    const validationResult = stepperValidation(
      activeStep,
      customReportData,
      commentReport,
      reportName,
      'continue',
      isSaveReport
    );

    if (validationResult === true) {
      setValidationError(null);
      return true;
    } else {
      setValidationError(validationResult);
      dispatch(alertAction.error(validationResult));
      return false;
    }
  };

  const handleNext = () => {
    if (validateCurrentStep()) {
      setCompleted(prevCompleted => ({
        ...prevCompleted,
        [activeStep]: true
      }));

      if (activeStep === STEPS.length - 1) {
        gotoReportsListPage();
      } else {
        setActiveStep((prevStep) => prevStep + 1);
        const stepName = STEPS[activeStep + 1].toLowerCase();
        const redirect = stepName === 'survey'
        ? `/reports/custom_report/${customReportData.id}`
        : `/reports/custom_report/${customReportData.id}/${stepName}`;
        routerDomHistory.push(redirect, { from: previousHistory });
      }
    }
  };

  const handleUpdate = async () => {
    const updatedReportData = {
      ...customReportData,
      reportType: REPORT_TYPE_CONSTANTS.CUSTOM_REPORT
    };
    try {
      dispatch(reportAction.editCustomReport(updatedReportData));
      handleNext();
    } catch (error) {
      dispatch(alertAction.error("Failed to update report"));
    }
  };

  const handleSave = async () => {
    if (validateCurrentStep()) {
      const updatedReportData = {
        ...customReportData,
        reportType: REPORT_TYPE_CONSTANTS.CUSTOM_REPORT
      };
      try {
        dispatch(reportAction.saveCustomReport(updatedReportData));
      } catch (error) {
        dispatch(alertAction.error("Failed to save report"));
      }
    }
  };

  const handleSaveOrUpdate = async () => {
    if (validateCurrentStep()) {
      if (activeStep === STEPS.length - 1) {
        handleUpdate();
      } else if (activeStep === 0 && !isSaveReport) {
        if (!isSaveReport) {
          handleSave();
          handleNext();
        } else {
          handleUpdate();
        }
      } else {
        handleUpdate();
      }
    }
  };

  // Dialog Handlers
  const handleCancelClick = () => {
    if (isSaveReport) {
      dispatch(alertAction.error("Your unsaved changes will be lost"));
      setOpenCancelDialog(true);
    } else {
      setOpenCancelDialog(true);
    }
  };

  const handleDialogCancelAction = () => {
    setOpenCancelDialog(false);
  };

  const handleDialogYesAction = () => {
    setOpenCancelDialog(false);
    gotoReportsListPage();
  };

  const handleStep = (step) => () => {
    const stepName = STEPS[step].toLowerCase();

    setActiveStep(step);
    const redirect = stepName === 'survey'
    ? customReportData.id ? `/reports/custom_report/${customReportData.id}` : `/reports/custom_report/new`
    : `/reports/custom_report/${customReportData.id}/${stepName}`
    routerDomHistory.push(redirect, { from: previousHistory });
  };

  const gotoReportsListPage = () => {
    dispatch(reportAction.resetCustomReportData());
    routerDomHistory.replace(getCustomReportPrevHistory());
    localStorage.removeItem("customReportPrevHistory");
  };

  // Dynamic Step Content Renderer
  const getStepContent = (step) => {
    const hasCommentsAccess = user?.access?.includes("Comments");

    const stepComponentsWithComments = {
      0: <Survey isSaveReport={isSaveReport} report_form_ref={report_form_ref} />,
      1: <Dates report_form_ref={report_form_ref} />,
      2: <Filters />,
      3: <Dashboard />,
      4: <Charts {...props} />,
      5: <Comments {...props} />,
      6: <Organize {...props} />,
      7: <SaveReport {...props} />
    };

    const stepComponentsWithoutComments = {
      0: <Survey isSaveReport={isSaveReport} />,
      1: <Dates />,
      2: <Filters />,
      3: <Dashboard />,
      4: <Charts {...props} />,
      5: <Organize {...props} />,
      6: <SaveReport {...props} />
    };

    const stepComponents = hasCommentsAccess
      ? stepComponentsWithComments
      : stepComponentsWithoutComments;

    return stepComponents[step] || "Unknown step";
  };

  return (
    <ValidatorForm
      id="reportForm"
      name="reportForm"
      ref={report_form_ref}
      className={"width100"}
      autoComplete="off"
      onSubmit={(e) => {
        e.preventDefault(); // Prevent default form submission
        handleSaveOrUpdate();
      }}
      onError={(error) => {
        console.log(error);
      }}
    >
      <div className={classes.root}>
        {/* {reportLoader && (
          <div className="caller_detail_loader">
            <CircularProgress />
          </div>
        )} */}

        <div className="stepperContainer">
          <Grid container className={classes.headerContainer}>
            <Grid item xs={2}>
              <Button
                onClick={handleCancelClick}
                style={{
                  background: 'none',
                  border: 'none',
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <TypographyComponent variant="h6" style={{ display: 'flex', alignItems: 'center' }}>
                  {BackArrow()} <span style={{ marginLeft: '8px' }}>{ReportsConstants.CUSTOM_BACK}</span>
                </TypographyComponent>
              </Button>
            </Grid>
            <Grid item xs={8} style={{ height: '55px' }}>
              <Stepper activeStep={activeStep} alternativeLabel nonLinear style={{ padding: '5px 0 0 0' }}>
                {STEPS.map((label, index) => (
                  <Step key={label} completed={completed[index]} disabled={disabled && (activeStep < 2) && !completed[index]}>
                    <StepButton onClick={handleStep(index)}>
                      <StepLabel StepIconComponent={StepIconComponent}>
                        {label}
                      </StepLabel>
                    </StepButton>
                  </Step>
                ))}
              </Stepper>
            </Grid>
          </Grid>
        </div>

        <Box style={{ padding: "20px 15px", height: '580px' }}>
          {/* <TypographyComponent variant="h5" className="txtBold fontSize20" style={{ padding: "15px 0" }}>
            {STEPS[activeStep]}
          </TypographyComponent> */}

          <Box className={classes.innerBox}>
            <Box className={classes.leftBox}>
              {getStepContent(activeStep)}
            </Box>
            <Box className={classes.rightBox}>
              <NewReportContent
                classes={classes}
                activeStep={activeStep}
              />
            </Box>
          </Box>
        </Box>
        <div className={classes.navigationButtons}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              validateReportForm(report_form_ref).then(isValid => {
                if (isValid) {
                  handleSaveOrUpdate()
                }
              })
            }}
            style={{ boxShadow: 'none' }}
          >
            {activeStep === STEPS.length - 1 ? ReportsConstants.CUSTOM_SAVE : ReportsConstants.CUSTOM_SAVE_AND_CONTINUE}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleCancelClick}
            style={{ boxShadow: 'none' }}
          >
            {ReportsConstants.CUSTOM_CANCEL}
          </Button>
        </div>

        <Dialog
          open={openCancelDialog}
          onClose={handleDialogCancelAction}
          aria-labelledby="cancel-dialog-title"
          aria-describedby="cancel-dialog-description"
        >
          <DialogContent>
            {ReportsConstants.CUSTOM_CANCEL_DIALOG}
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={handleDialogYesAction}
              style={{ boxShadow: 'none' }}
            >
              {ReportsConstants.CUSTOM_YES}
            </Button>
            <Button
              color="secondary"
              variant="contained"
              onClick={handleDialogCancelAction}
              style={{ boxShadow: 'none' }}
            >
              {ReportsConstants.CUSTOM_NO}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </ValidatorForm>
  );
};

export default withStyles(NewReportStyles)(NewReport);