import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { Grid, Box, FormControlLabel, Checkbox, } from "@mui/material";
import AutoCompleteComponent from "../../../../../components/AutoCompleteComponent/AutoCompleteComponent";
import TypographyComponent from "../../../../../components/TypographyComponent/TypographyComponent";
import { reportAction, alertAction } from '../../../../../redux/actions';
import { ReportsConstants } from "../../../../../config/constants";
import ButtonComponent from '../../../../../components/ButtonComponent/ButtonComponent';

const EngagementChartCustom = (props) => {
  const dispatch = useDispatch();
  const { report, metadata } = useSelector(state => ({
    report: state.report,
    metadata: state.metadata.metadata
  }));

  const handleColumnToggle = (columnName) => {
    setSelectedColumns((prev) => {
      const columnIndex = prev.indexOf(columnName);
      return columnIndex > -1
        ? prev.filter((col) => col !== columnName) // Remove column
        : [...prev, columnName]; // Add column
    });
  };

  const { keyArr } = useSelector((state) => state.dashboard.engagementIndex);

  const [selectedColumns, setSelectedColumns] = useState(keyArr);

  const { allDemographics, customReportData } = report;

  const filteredMetadata = metadata
    .filter(item => Object.keys(allDemographics).includes(item.key))
    .sort((a, b) => a.value.localeCompare(b.value));

  const defaultDemographic = filteredMetadata.some(item => item.value === 'Tenure Groupings') ? 'Tenure Groupings' : filteredMetadata[0]?.value || "";
  const defaultSelectedRG = filteredMetadata.find(item => item.value === defaultDemographic)?.key || "";

  // State for form values
  const [selectedValue, setSelectedValue] = useState(defaultDemographic);
  const [selectedSort, setSelectedSort] = useState(ReportsConstants.EICHART_SORT_OPTIONS?.[0]);
  const [selectedRG, setSelectedRG] = useState(defaultSelectedRG);

  const defaultvalues = () => {
    setSelectedColumns(keyArr)
    setSelectedValue(defaultDemographic)
    setSelectedSort(ReportsConstants.EICHART_SORT_OPTIONS?.[0])
    setSelectedRG(defaultSelectedRG)
  };

  const getSelectedSortOption = (options) => {
    const selectedOption = options.find((item) => item.value === selectedSort?.sortField);
    return selectedOption || options[0];
  };

  useEffect(() => {
    if (!customReportData?.dashboardPreference?.EI) {
      // Only fetch demographics data initially
      if (defaultSelectedRG) {
        dispatch(reportAction.demographicsByName({
          surveys: customReportData.surveys,
          date: customReportData.date,
          demographic: defaultSelectedRG,
          search: "",
        }));
      }
    }
  }, []);

  const handleDemographicChange = (value) => {
    const newSelectedRG = filteredMetadata.find(item => item.value === value)?.key;
    setSelectedValue(value);
    setSelectedRG(newSelectedRG);
  };

  const handleSorting = (sortObject) => {
    setSelectedSort(sortObject);
  };

  const checkDuplicateConfigs = (EIPreferences) => {
    const isDuplicate = (existingConfigs, newPreferences) => {
      const normalizeObject = (obj) => JSON.stringify(obj, Object.keys(obj).sort());
      const normalizedNewPreferences = normalizeObject(newPreferences);
      return Object.values(existingConfigs).some(config =>
        normalizeObject(config) === normalizedNewPreferences
      );
    };

    const existingEIConfigs = customReportData?.dashboardPreference?.EI || {};

    const isDuplicateEI = isDuplicate(existingEIConfigs, EIPreferences);

    if (isDuplicateEI) {
      dispatch(alertAction.error('A chart with identical configuration already exists'));
      return true; // Return true if duplicates are found
    }

    return false; // Return false if no duplicates are found
  };

  const handleAddChart = () => {
    try {
      let chartNumber = `Engagement Chart by ` + selectedValue; // Changed from template literal since selectView wasn't defined
      const updatedChartOrder = customReportData.chartOrder || [];

      let count = 1;
      let uniqueChartNumber = chartNumber;
      while (updatedChartOrder.includes(uniqueChartNumber)) {
        uniqueChartNumber = `${chartNumber} ${count}`;
        count++;
      }

      const newChartPreferences = {
        rg: selectedRG,
        keyArr: selectedColumns,
        yAxisSort: {
          sortField: selectedSort.sortField,
          sortBy: selectedSort.sortBy
        },
      };
      const hasDuplicates = checkDuplicateConfigs(newChartPreferences)
      if (hasDuplicates) {
        return;
      }

      const updatedDashboardPreference = {
        ...customReportData?.dashboardPreference,
        EI: {
          ...customReportData?.dashboardPreference?.EI,
          [uniqueChartNumber]: newChartPreferences
        },
      };

      const updatedReportData = {
        ...customReportData,
        chartOrder: [...updatedChartOrder, uniqueChartNumber],
        dashboardPreference: updatedDashboardPreference
      };
      dispatch(reportAction.updateCustomReport(updatedReportData));
      defaultvalues()
    } catch (error) {
      console.error('Error in handleAddChart:', error);
    }
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} style={{ maxWidth: "100%" }}>
        <Grid display={'flex'} justifyContent={'space-between'} gap={'2rem'}>
          {/* Left side - View By and Sort By */}
          <Grid item flex={'1'}>
            <Grid container spacing={3} direction="column">
              {/* View By Section */}
              <Grid item>
                <Grid container spacing={2}>
                  <Grid item xs={4} style={{ display: 'flex', alignItems: 'center' }}>
                    <TypographyComponent
                      className="txtBold"
                      title={ReportsConstants.CUSTOM_VIEW_BY}
                      variant="h6"
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <AutoCompleteComponent
                      name="demographics"
                      suggestions={filteredMetadata.map(item => item.value)}
                      handleAutoCompolete={handleDemographicChange}
                      suggestionType="array"
                      value={selectedValue}
                    />
                  </Grid>
                </Grid>
              </Grid>

              {/* Sort By Section */}
              <Grid item>
                <Grid container spacing={2}>
                  <Grid item xs={4} style={{ display: 'flex', alignItems: 'center' }}>
                    <TypographyComponent
                      className="txtBold"
                      title={ReportsConstants.CUSTOM_SORT_BY}
                      variant="h6"
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <AutoCompleteComponent
                      name="gridSort"
                      suggestions={ReportsConstants.EICHART_SORT_OPTIONS}
                      handleAutoCompolete={(val) => {
                        handleSorting({
                          sortField: val.value,
                          sortBy: val.sortBy
                        });
                      }}
                      suggestionType="gridSortOptions"
                      value={getSelectedSortOption(ReportsConstants.EICHART_SORT_OPTIONS)}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {/* Right side - Customize Columns */}
          <Grid item flex={'1'}>
            <TypographyComponent variant="h6" className="txtBold">
              {ReportsConstants.CUSTOMIZE_COLUMN_TEXT}
            </TypographyComponent>
            <Box mt={1} className="pl-2">
              <Grid spacing={0.5}>
                {keyArr.map((label, index) => (
                  <Grid item xs={12} key={index}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedColumns.includes(label)}
                          onChange={() => handleColumnToggle(label)}
                          color="primary"
                        />
                      }
                      label={label}
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Grid>

          {/* Add Chart Button */}
          <Grid item display={'inline-flex'} flexDirection={'column'} flexBasis={'200px'} justifyContent={'flex-end'}>
            <Grid display={'flex'} flexDirection={'column'} gap={'10px'}>
              <ButtonComponent
                color="primary"
                pageClassName="buttonOrange fontSize14"
                title="+ Add chart to report"
                handleClick={handleAddChart}
              />
                {/* <TypographyComponent variant="h6">
                {ReportsConstants.CUSTOM_ADD_CHART_TEXT}
                </TypographyComponent> */}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default EngagementChartCustom;