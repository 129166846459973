import React, { useEffect } from 'react';
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import { Grid, List, ListItem, ListItemText, IconButton } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

import withStyles from '@mui/styles/withStyles';

// Import Styles and Image
import ReportsStyles from "../../ReportsStyles";
import { DashboardClose } from "../../../../assets/images/svgComponents/ImageComponent";

// Import Component
import TypographyComponent from "../../../../components/TypographyComponent/TypographyComponent";
import ButtonComponent from '../../../../components/ButtonComponent/ButtonComponent';
import ToolTipComponent from '../../../../components/ToolTipComponent/ToolTipComponent';

import { keyDriver } from "../../../../assets/images/svgComponents/ImageComponent"

// Constants
import { ReportsConstants } from "../../../../config/constants"

//Redux Actions
import { dashboardAction } from '../../../../redux/actions'

// Import History
import history from "../../../../config/history";

function KeyDriverWidget(props) {
    // Define Dispatch
    const dispatch = useDispatch();
    // props
    const { classes, keydriver, saoa, hasMESurvey, isCached, adminPreference } = props;
    const { access, role } = useSelector(state => state.profile.user)


    // suggested area of actions
    const generateKeydriver = () => {
        let jsxElem = [];
        for (let i = 0; i < 5; i++) {
            typeof keydriver[i] !== 'undefined' && jsxElem.push(
                <ListItem >
                    {keyDriver()}
                    <ListItemText
                        style={{ maxWidth: "425px", paddingLeft: "10px", flex: 1 }}
                        secondary={keydriver[i].question}
                    />
                </ListItem>
            )
        }
        return jsxElem;
    }

    /**
     * Handle Navigations
     */
    const handleNavigage = () => {
        history.push({
            pathname: `/actions`
        });
    }

    const handleClose = () => {
        if (typeof adminPreference?.data?.hideDashWidgets?.keydriver === 'boolean') {
            adminPreference['data']['hideDashWidgets']['keydriver'] = true;
            adminPreference.updatePreference(adminPreference?.data)
        }
    }

    return (
        <Grid className={`${classes.dashboardTile} ${hasMESurvey ? "me me_keydriver" : ""}`}>
            <Grid container className={'height100'}>
                <Grid item  className={'width100 height100'} display={'flex'} flexDirection={'column'}>
                    <Grid container alignItems="center">
                        <Grid item className='dflexCenter width100 spacebetween'>
                            <TypographyComponent variant="h5" className="fontWeight600 dflex">
                                {ReportsConstants.KEY_DRIVER}
                            </TypographyComponent>
                            <ToolTipComponent title={ReportsConstants.CLOSE}>
                                <IconButton onClick={handleClose} size="large">
                                    {DashboardClose()}
                                </IconButton>
                            </ToolTipComponent>
                        </Grid>
                    </Grid>
                    <List className={`${classes.SuggestedActionList} pb-0`} style={{display: 'inline-grid', placeContent: 'center', height: '100%', width: '100%', justifyContent: 'start'}}>
                        {generateKeydriver()}
                    </List>
                    {(role === "superadmin" || access.indexOf("Actions") > -1) &&
                            <Grid item className={`${classes.viewActionButton} mt-5`} style={{marginTop: 'auto'}}>
                                <ButtonComponent
                                    title={ReportsConstants.VIEW_ALL}
                                    color="primary"
                                    size="small"
                                    handleClick={() => {
                                        handleNavigage()
                                    }}
                                >
                                </ButtonComponent>
                            </Grid>
                        }
                </Grid>
            </Grid>
        </Grid>
    )
}
// default props
KeyDriverWidget.defaultProps = {
    classes: {},
    keydriver: [],
    hasMESurvey: false,
    adminPreference: {},
};

// prop types
KeyDriverWidget.propTypes = {
    classes: PropTypes.object,
    keydriver: PropTypes.array,
    adminPreference: PropTypes.object,
};
export default withStyles(ReportsStyles)(KeyDriverWidget);