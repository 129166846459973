import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import { IconButton } from "@mui/material";
import { Tooltip } from "@mui/material";

// Import Styles and Image
import { Expand } from "../../../../assets/images/svgComponents/ImageComponent";

// Import Actions
import { reportAction, dashboardAction } from "../../../../redux/actions";

//import report constants
import { ReportsConstants } from "../../../../config/constants";

// Import Components
import DialogComponent from "../../../../components/DialogComponent/DialogComponent";
import TypographyComponent from "../../../../components/TypographyComponent/TypographyComponent";
import SentimentAnalysis from "./SentimentAnalysis";
import CommentAnalysisPopup from "./CommentAnalysisPopup";
import SelectQuestion from "./SelectQuestion";
import WordCloudPanel from "./WordCloudPanel";
import LoaderComponent from "../../../../components/LoaderComponent/LoaderComponent";
import CacheComponent from "../../../../components/CacheComponent"
import ButtonComponent from "../../../../components/ButtonComponent/ButtonComponent";
import AdditionalReports from "../AdditionalReports/AdditionalReports";
import { noData } from '../../../../assets/images/svgComponents/ImageComponent';

function CommentAnalysis(props) {
  const dispatch = useDispatch();

  const {
    report,
    profile: { user },
    filter: { filterData, allSurveys }
  } = useSelector(state => state);

  // set default options
  useEffect(() => {
    if (preference && preference.surveys) {
      report.reportData.surveys = preference.surveys;
      report.reportData.date = preference.date;
      report.reportData.demographics = preference.demographics;
    }
  }, [preference])

  //Get props
  const { classes, updateReportPreference, preference } = props;

  //state for popup open/close
  const [commentPopup, setCommentPopup] = useState(false);

  // state for Toggle Drawer
  const [drawer, setDrawer] = useState(null);

  /**
   * toggle drawer
   */
  const toggleDrawer = () => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }
    setDrawer(null);
  };

  // Comment Report Handle Click
  const handleClick = (e) => {
    let reportData = {
      surveys: [...filterData.surveys],
      demographics: { ...filterData.demographics },
      date: { ...filterData.date },
      reportType: ReportsConstants.COMMENT_REPORT
    }
    if ((reportData.date && ((reportData.date.type === "Custom dates" && reportData.date.startDate && reportData.date.endDate) || (reportData.date.type && reportData.date.type !== "Custom dates")))) {
      dispatch(
        reportAction.getDemographics({
          metadata: [],
          surveys: reportData.surveys,
          date: reportData.date,
        })
      );
    }
    dispatch(reportAction.updateReport(reportData));
    dispatch(reportAction.getCommentQuestions(reportData));
    setTimeout(() => {
      setDrawer(ReportsConstants.COMMENT_REPORT);
    }, 0);
  };

  //get reducer data
  const { questions, nlpData, loading, questionLoading, chartLoad } = useSelector((state) => state.dashboard.commentAnalysis);
  

  const reloadChart = () => {
    //handling if question is empty
    if (questions.length) {
      const firstQues = questions && questions[0] ? questions[0]["baseQusId"] : "";
      let checkQuesExists = questions && questions.length ? questions.filter(e => e.baseQusId === preference.commentAnalysis.baseQuesId).length > 0 : false;
      dispatch(dashboardAction.getCommentAnalysis({ baseQusId: firstQues, quesExists: checkQuesExists }));
    } else {
      dispatch(dashboardAction.gethighCommentQuestions((commQues) => {
        const firstQues = commQues && commQues[0] ? commQues[0]["baseQusId"] : "";
        let checkQuesExists = commQues && commQues.length ? commQues.filter(e => e.baseQusId === preference.commentAnalysis.baseQuesId).length > 0 : false;
        dispatch(dashboardAction.getCommentAnalysis({ baseQusId: firstQues, quesExists: checkQuesExists }));
      }))
    }
    dispatch(dashboardAction.updateCommentChartStatus(false));
  }

  // Initial API call during page loading
  useEffect(() => {
    if (chartLoad) {
      reloadChart();
    }
  }, [preference.commentAnalysis.baseQuesId, chartLoad, dispatch]);

  //using spread to prevent reducer update on value chage//address error
  const { commentAnalysis } = { ...preference };

  // Get NLP Data Values
  const {
    frequency,
    sentiment,
    topics,
    topicCount,
    wordCloud,
    allAns,
    isCached
  } = nlpData;

  window.cachedCharts['comment'] = isCached ? isCached : true;

  // popup close
  const onClose = () => {
    setCommentPopup(false);
  };

  // open popup
  const openPopup = () => {
    setCommentPopup(true);
  };

  // Save Report Preference on type change
  const saveReportPreferences = (selectedQues) => {
    //update default data
    const updateItem = {
      item: "",
      word: "",
      data: [],
      wordCount: 0,
      type: "Topics",
      mainWord: ""
    };
    dispatch(dashboardAction.updateNLPSelected(updateItem))

    const data = {
      ...preference,
      surveys: preference.surveys.map((e) => (e._id ? e._id : e)),
      commentAnalysis: {
        baseQuesId: selectedQues,
      },
    };
    // Save Preferences data
    updateReportPreference(data);
  };

  return (
    <React.Fragment>
      <Grid item sm={12} className={"mb-14"}>
        {
          (preference.surveys.length > 0) ?
            (((!questions || questions.length === 0) && !loading && !questionLoading) ? (
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                className={`${classes.insightTile} ${classes.noDataContainer}`}
              // direction={"column"}
              >
                <Grid item xs textAlign={'center'}>
                  {noData()}
                  <TypographyComponent variant="h3" align={'center'} className={"mt-1"}>
                    {ReportsConstants.NO_COMMENT_DATA_CHART}
                  </TypographyComponent>
                </Grid>
              </Grid>
            ) : (
              <Grid item className={classes.insightTile}>
                <Grid container alignItems="center" className="mb-1">
                  <Grid item>
                    <TypographyComponent
                      variant="h4"
                      className="txtBold fontSize16 ml-1"
                    >
                      {"Comment Analysis"}
                    </TypographyComponent>
                  </Grid>
                  <Grid item className={classes.viewActionButton}>
                    <Grid container>
                      <CacheComponent
                        isCached={isCached}
                        chart="comment"
                        timeout={30000}
                        onUpdate={() => {
                          reloadChart()
                        }}
                      />
                      <ButtonComponent
                        title={ReportsConstants.COMMENT_REPORT}
                        color={"primary"}
                        handleClick={(e) => handleClick(e)}
                      />
                      <Tooltip title={"Expand"} arrow >
                        <IconButton onClick={() => { openPopup(); }} size="large">
                          {Expand()}
                        </IconButton>
                      </Tooltip>
                      <AdditionalReports
                        open={drawer}
                        toggleDrawer={toggleDrawer}
                        exportCsv={true}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                {loading && <LoaderComponent></LoaderComponent>}
                {!loading && (
                  <React.Fragment>
                    {questions && questions.length > 0 && (
                      <Grid container spacing={3} className="spacearound">
                        <Grid container>
                          {questions && (questions.length > 0) &&
                            <SelectQuestion
                              classes={classes}
                              questions={questions}
                              commentAnalysis={commentAnalysis}
                              saveReportPreferences={saveReportPreferences}
                            />
                          }
                        </Grid>
                        <React.Fragment>
                          <Grid md={6} style={{ position: "relative" }}>
                            <SentimentAnalysis
                              classes={classes}
                              frequency={frequency}
                              sentiment={sentiment}
                              zoom={false}
                            />
                          </Grid>
                          <Grid md={5}>
                            <WordCloudPanel
                              classes={classes}
                              topics={topics}
                              topicCount={topicCount}
                              wordCloud={wordCloud}
                              allAns={allAns}
                            />
                          </Grid>
                        </React.Fragment>
                      </Grid>
                    )}
                  </React.Fragment>
                )}
                {commentPopup && (
                  <React.Fragment>
                    <DialogComponent open={true} fullScreen={true}>
                      <CommentAnalysisPopup
                        onClose={onClose}
                        classes={classes}
                        frequency={frequency}
                        sentiment={sentiment}
                        preference={preference}
                        questions={questions}
                      />
                    </DialogComponent>
                  </React.Fragment>
                )}
              </Grid>
            )) : ''
        }
      </Grid>
    </React.Fragment>
  );
}

// default props
CommentAnalysis.defaultProps = {
  classes: {},
  handleClose: () => { },
  isUpdate: false,
  preference: {},
  updateReportPreference: () => { },
  stopChartReload: () => { }
}

// prop types
CommentAnalysis.propTypes = {
  classes: PropTypes.object,
  handleClose: PropTypes.func,
  isUpdate: PropTypes.bool,
  preference: PropTypes.object,
  updateReportPreference: PropTypes.func,
  stopChartReload: PropTypes.func
};

export default CommentAnalysis;