import {
  alertConstant,
  uiConstant,
  rawdataAutomationConstant,
} from "../constants";
import { baseAction } from "./baseAction";
import { rawdataAutomationService } from "../services";
import { clientAction } from "./clientAction";
import history from "../../config/history";

const { request, success, failure } = baseAction;

export const rawdataAutomationAction = {
  getDemographics,
  getSurveys,
  demographicsByName,
  updateSurveys,
  updatename,
  updatedate,
  updateDemographics,
  updateFolderName,
  createAndUpdateRawdataAutomation,
  search,
  updatefrequency,
  updateday,
  getOne,
  remove,
  setbacktoinitial
};

/**
 * get surveys with responses
 */
function getSurveys(search, successCallback = () => {}) {
  return (dispatch) => {
    dispatch(request(uiConstant.LOADING_TRUE));
    rawdataAutomationService.getSurveys(search).then(
      (data) => {
        dispatch(
          success(rawdataAutomationConstant.GET_RAW_DATA_ALL_SURVEYS, data)
        );
        dispatch(request(uiConstant.LOADING_FALSE));
        successCallback(data.data);
      },
      (error) => {
        dispatch(request(uiConstant.LOADING_FALSE));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
      }
    );
  };
}

/**
 * get demographics filter data
 */
function getDemographics(filterData, successCallback = () => {}) {
  return (dispatch) => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(rawdataAutomationConstant.GET_DEMOGRAPHICS_REQUEST));
    rawdataAutomationService.getDemographics(filterData).then(
      (data) => {
        dispatch(request(uiConstant.LOADING_FALSE));
        dispatch(
          success(rawdataAutomationConstant.GET_DEMOGRAPHICS_SUCCESS, data)
        );
        successCallback();
      },
      (error) => {
        dispatch(request(uiConstant.LOADING_FALSE));
        dispatch(
          failure(rawdataAutomationConstant.GET_DEMOGRAPHICS_FAILURE, error)
        );
        dispatch(failure(alertConstant.ALERT_ERROR, error));
      }
    );
  };
}

/**
 *
 * @param {*} filterData
 * @param {*} successCallback
 */
function demographicsByName(filterData, successCallback = () => {}) {
  return (dispatch) => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(
      request(rawdataAutomationConstant.GET_DEMOGRAPHICS_BY_NAME_REQUEST)
    );
    reportService.demographicsByName(filterData).then(
      (data) => {
        dispatch(
          success(
            rawdataAutomationConstant.GET_DEMOGRAPHICS_BY_NAME_SUCCESS,
            data
          )
        );
        dispatch(request(uiConstant.LOADING_FALSE));
        successCallback();
      },
      (error) => {
        dispatch(request(uiConstant.LOADING_FALSE));
        dispatch(
          failure(
            rawdataAutomationConstant.GET_DEMOGRAPHICS_BY_NAME_FAILURE,
            error
          )
        );
        dispatch(failure(alertConstant.ALERT_ERROR, error));
      }
    );
  };
}

//update report Data
function updateSurveys(updatedData) {
  return {
    type: rawdataAutomationConstant.UPDATE_SURVEYS_DATA,
    data: updatedData,
  };
}

function updatename(name) {
  return {
    type: rawdataAutomationConstant.UPDATE_NAME,
    data: name,
  };
}

function updatefrequency(frequency) {
  return {
    type: rawdataAutomationConstant.UPDATE_FREQUENCY,
    data: frequency,
  };
}

function updateday(day) {
  return {
    type: rawdataAutomationConstant.UPDATE_DAY,
    data: day,
  };
}

function updatedate(data) {
  return {
    type: rawdataAutomationConstant.UPDATE_DATE,
    data: data,
  };
}

function updateFolderName(data, successCallback = () => {}) {
  return (dispatch) => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(rawdataAutomationConstant.UPDATE_FOLDER_NAME_REQUEST));
    rawdataAutomationService.updateFolderName(data).then(
      (data) => {
        dispatch(clientAction.getOne())
        dispatch(
          success(rawdataAutomationConstant.UPDATE_FOLDER_NAME_SUCCESS, data)
        );
        dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
        dispatch(request(uiConstant.LOADING_FALSE));
        successCallback();
      },
      (error) => {
        dispatch(request(uiConstant.LOADING_FALSE));
        dispatch(
          failure(rawdataAutomationConstant.UPDATE_FOLDER_NAME_FAILURE, error)
        );
        dispatch(failure(alertConstant.ALERT_ERROR, error));
      }
    );
  };
}

function updateDemographics(data) {
  return {
    type: rawdataAutomationConstant.UPDATE_DEMOGRAPHICS,
    data: data,
  };
}

function createAndUpdateRawdataAutomation(data, successCallback = () => {}) {
  return (dispatch) => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(
      request(rawdataAutomationConstant.CREATE_RAW_DATA_AUTOMATION_REQUEST)
    );
    rawdataAutomationService.createandupdate(data).then(
      (data) => {
        dispatch(
          success(
            rawdataAutomationConstant.CREATE_RAW_DATA_AUTOMATION_SUCCESS,
            data
          )
        );
        dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
        dispatch(request(clientAction.getOne))
        dispatch(request(uiConstant.LOADING_FALSE));
        dispatch(request(rawdataAutomationConstant.RESET_INITIAL_STATE));
        history.replace("/raw-data/list");
        successCallback();
      },
      (error) => {
        dispatch(request(uiConstant.LOADING_FALSE));
        dispatch(
          failure(
            rawdataAutomationConstant.CREATE_RAW_DATA_AUTOMATION_FAILURE,
            error
          )
        );
        dispatch(failure(alertConstant.ALERT_ERROR, error));
      }
    );
  };
}

function search(skip, limit, sortBy, sort, sFields, sValues) {
  return (dispatch) => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(rawdataAutomationConstant.RAW_DATA_LIST_SEARCH_REQUEST));

    rawdataAutomationService.search(skip, limit, sortBy, sort, sFields, sValues).then(
      (data) => {
        dispatch(success(rawdataAutomationConstant.RAW_DATA_LIST_SEARCH_SUCCESS, data));
        dispatch(request(uiConstant.LOADING_FALSE));
      },
      (error) => {
        dispatch(failure(rawdataAutomationConstant.RAW_DATA_LIST_SEARCH_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(uiConstant.LOADING_FALSE));
      }
    );
  };
}

function getOne(id){
  return (dispatch) => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(rawdataAutomationConstant.RAW_DATA_GET_ONE_REQUEST));

    rawdataAutomationService.getOne(id).then(
      (data) => {
        dispatch(success(rawdataAutomationConstant.RAW_DATA_GET_ONE_SUCCESS, data.data));
        dispatch(request(uiConstant.LOADING_FALSE));
      },
      (error) => {
        dispatch(failure(rawdataAutomationConstant.RAW_DATA_GET_ONE_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(uiConstant.LOADING_FALSE));
      }
    );
  };
}

/**
 * Remove Admin User --soft Delete
 *
 * @param {*} id  id to Remove
 * @returns
 */
function remove(id, successCallback = () => { }) {
  return (dispatch) => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(rawdataAutomationConstant.RAWDATA_REMOVE_REQUEST));
    rawdataAutomationService.remove(id).then(
      (data) => {
        dispatch(success(rawdataAutomationConstant.RAWDATA_REMOVE_SUCCESS, data));
        dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
        dispatch(request(uiConstant.LOADING_FALSE));
        successCallback()
      },
      (error) => {
        dispatch(failure(rawdataAutomationConstant.RAWDATA_REMOVE_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(uiConstant.LOADING_FALSE));
      }
    );
  };
}

function setbacktoinitial(){
  return (dispatch) => {
    dispatch(request(rawdataAutomationConstant.RESET_INITIAL_STATE));
  };
}
