import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { withStyles } from "@mui/styles";
import NewReportStyles from "./NewReportStyles";
import { reportAction } from "../../../../redux/actions";

import { ReportsConstants } from "../../../../config/constants";

import ParticipationImg from "../../../../assets/images/Dashboard2/Participation.svg"
import Key_DriversImg from "../../../../assets/images/Dashboard2/Key_Drivers.svg"
import Overall_FavourabilityImg from "../../../../assets/images/Dashboard2/Overall_Favorability.svg"
import Favourability_By_CategoryImg from "../../../../assets/images/Dashboard2/Favorability_by_Category.svg"
import Highest_Lowest_Rated_ItemsImg from "../../../../assets/images/Dashboard2/Highest_Lowest_Items.svg"
import eNPSImg from "../../../../assets/images/Dashboard2/eNPS.svg"
import Engaged_EmployeesImg from "../../../../assets/images/Dashboard2/Engaged_Employees.svg"
import Engagement_BenchmarkImg from "../../../../assets/images/Dashboard2/Engagement_Benchamrk.svg"
import Likely_To_StayImg from "../../../../assets/images/Dashboard2/Likely_To_Stay.svg"
import Likely_to_ReturnImg from "../../../../assets/images/Dashboard2/Likely_to_Return.svg"
import Suggested_Areas_of_ActionImg from "../../../../assets/images/Dashboard2/Suggested_Areas_of_Action.svg"
import TurnoverImg from "../../../../assets/images/Dashboard2/Turnover.svg"
import ProjectedTurnoverImg from "../../../../assets/images/Dashboard2/Projected_Turnover.svg"
import Turnover_combineImg from "../../../../assets/images/Dashboard2/Turnover_combine.svg"

const images = [
  { key: "participation", title: "Participation", src: ParticipationImg },
  { key: "keydriver", title: "Key Drivers", src: Key_DriversImg },
  { key: "overallFavorability", title: "Overall Favorability", src: Overall_FavourabilityImg },
  { key: "favorableByCategory", title: "Favorability By Category", src: Favourability_By_CategoryImg },
  { key: "highLowRatedItems", title: "Highest & Lowest Rated Items", src: Highest_Lowest_Rated_ItemsImg },
  { key: "overalleNPS", title: "eNPS", src: eNPSImg },
  { key: "engagedEmployee", title: "Engaged Employees", src: Engaged_EmployeesImg },
  { key: "engagementBenchMark", title: "Engagement Benchmark", src: Engagement_BenchmarkImg },
  { key: "likelyToStay", title: "Likely To Stay", src: Likely_To_StayImg },
  { key: "LikelyToReturn", title: "Likely To Return", src: Likely_to_ReturnImg },
  { key: "suggestedAreaAction", title: "Suggested Areas of Action", src: Suggested_Areas_of_ActionImg },
  { key: "turnOver", title: "Turnover", src: TurnoverImg },
  { key: "projectedTurnover", title: "projectedTurnover", src: ProjectedTurnoverImg },
  { key: "turnover_combine", title: "turnover_combine", src: Turnover_combineImg }
];

const Dashboard = ({ classes }) => {
  const dispatch = useDispatch();
  const { hideDashWidgets = {} } = useSelector((state) => state.report.customReportData || {});
  const customReportData = useSelector((state) => state.report.customReportData || {});
  const customChartData = useSelector((state) => state.report.customChartData || []);
  const { queryDLA, role } = useSelector(state => state.profile.user)
  const customDashboardData = useSelector((state) => state.report.customDashboardData || []);

  const [selectedImages, setSelectedImages] = useState([]);
  const [imageList, setImageList] = useState([]);

  useEffect(() => {
    if (customReportData?.id) {
      // dispatch(reportAction.getCustomReportDashboard(customReportData));
      dispatch(reportAction.getCustomReportChart(customReportData));
    }
  }, [customReportData?.surveys, customReportData?.date, customReportData?.demographics]);

  useEffect(() => {
    const imageArr = [];

    const surveyTypes = [...new Set(customReportData?.surveys?.map((item) => item.type))];
    const surveyType = surveyTypes?.[0];
    let conditions = {
      participation: true,
      overallFavorability: true,
      favorableByCategory: true,
      overalleNPS: true,
      highLowRated: true
    };

    if (customReportData?.surveys?.length === 1) {
      if (surveyType === 'Engagement') {
        if (customReportData?.surveys?.length === 1 && role=== "superadmin") {
          conditions = {
            ...conditions,
            keydriver: true,
            suggestedAreaAction: false,
            engagementBenchMark: true,
            engagedEmployee: true
          }

        } else {
          conditions = {
            ...conditions,
            keydriver: false,
            suggestedAreaAction: true,
            engagementBenchMark: true,
            engagedEmployee: true
          }
         
        }
      } else if (surveyType === 'Exit') {
        conditions = {
          ...conditions,
          suggestedAreaAction: true,
          LikelyToReturn: true,
          turnOver: true,
        }
      } else if (surveyType === 'New Hire' || surveyType === 'Stay') {
        conditions = {
          ...conditions,
          suggestedAreaAction: true,
          likelyToStay: true,
          projectedTurnover: true,
        }
      } else {
        conditions = {
          ...conditions,
          suggestedAreaAction: true,
        }
      }

    } else {
      conditions = {
        ...conditions,
        turnover_combine: surveyTypes?.indexOf('Other') === -1 &&
        (
          surveyTypes?.indexOf("Stay") !== -1 ||
          (
            surveyTypes?.length === 2 &&
            (
              _.isEqual(['Exit', 'New Hire'], surveyTypes.sort())
            )
          )
        ),
      
        suggestedAreaAction: true,
        overalleNPS: (surveyTypes?.indexOf('Other') === -1 &&
          ['New Hire', 'Exit', 'Engagement', 'Stay'].some(type => surveyTypes?.indexOf(type) !== -1)),
        likelyToStay: (
          surveyTypes?.length === 1 && surveyTypes?.indexOf('Stay') !== -1 ||
          surveyTypes?.length === 2 && _.isEqual(['Engagement', 'New Hire'], surveyTypes.sort())
        ),
        projectedTurnover: surveyTypes?.indexOf('Other') === -1 &&
          (
            surveyTypes?.indexOf("Stay") !== -1 ||
            (
              surveyTypes?.length === 2 &&
              (
                _.isEqual(['Engagement', 'New Hire'], surveyTypes.sort())
              )
            )
          ),
          turnOver: false,
          LikelyToReturn: (
            surveyTypes?.length === 1 && surveyTypes?.indexOf('Exit') !== -1 ||
            surveyTypes?.length === 2 && _.isEqual(['Engagement', 'Exit'], surveyTypes.sort())
          ),
          engagementBenchMark: (
            surveyTypes?.length === 1 && surveyTypes?.indexOf('Engagement') !== -1 
          ),
          engagedEmployee: (
            surveyTypes?.length === 1 && surveyTypes?.indexOf('Engagement') !== -1 
          ),
      }
    }


    if (conditions["participation"]) {
      imageArr.push(images[0]);
    }
    if (conditions["favorableByCategory"]) {
      imageArr.push(images[3]);
    }
    if (conditions["keydriver"]) {
      imageArr.push(images[1]);
    }
    if (conditions["suggestedAreaAction"]) {
      imageArr.push(images[10]);
    }
    if (conditions["overallFavorability"]) {
      imageArr.push(images[2]);
    }
    if (conditions["overalleNPS"]) {
      imageArr.push(images[5]);
    }
    if (conditions["engagedEmployee"]) {
      imageArr.push(images[6]);
    }
    if (conditions["engagementBenchMark"]) {
      imageArr.push(images[7]);
    }
    if (conditions["likelyToStay"]) {
      imageArr.push(images[8]);
    }
    if (conditions["LikelyToReturn"]) {

      imageArr.push(images[9]);
    }
    if (conditions["turnOver"]) {
      imageArr.push(images[11]);
    }
    if (conditions["projectedTurnover"]) {
      imageArr.push(images[12]);
    }
    if (conditions["highLowRated"]) {
      imageArr.push(images[4]);
    }
    if (conditions["turnover_combine"]) {
      imageArr.push(images[13]);
    }

    setImageList(imageArr);
  }, [customDashboardData, customChartData]);

  useEffect(() => {
    const initiallySelectedImages = customReportData?.includeDashboard ? images
      .filter((image) => hideDashWidgets?.[image.key] === false)
      .map((image) => image?.title) : images
        .filter((image) => hideDashWidgets?.[image.key] === false)
        .map((image) => image?.title);
    setSelectedImages(initiallySelectedImages);
  }, [customReportData?.includeDashboard, hideDashWidgets]);


  const handleImageClick = (image) => {

    const updatedSelection = selectedImages?.includes(image?.title)
      ? (selectedImages || [])?.filter((item) => item !== image.title)
      : [...selectedImages, image.title];
    setSelectedImages(updatedSelection);

    let updatedHideDashWidgets = {
      ...hideDashWidgets,
      [image.key]: !updatedSelection?.includes(image.title),
    };
    if(customReportData?.surveys?.length === 1 &&  customReportData?.surveys?.[0]?.type === 'Engagement' && updatedHideDashWidgets["suggestedAreaAction"] === false && role === "clientadmin"){
      updatedHideDashWidgets={
        ...updatedHideDashWidgets, keydriver : false
      }
    }
    const updatedChartOrder = updatedSelection?.length > 0 ?
      [...new Set([
        ...(customReportData.chartOrder && customReportData.chartOrder.length > 0
          ? customReportData.chartOrder
          : []),
        "Dashboard"
      ])] // Prevent duplicates
      : (customReportData.chartOrder && customReportData.chartOrder.length > 0
        ? customReportData.chartOrder.filter((item) => item !== "Dashboard")
        : []);


    const updatedReportData = {
      ...customReportData,
      includeDashboard: updatedSelection?.length > 0,
      hideDashWidgets: updatedHideDashWidgets,
      chartOrder: updatedChartOrder,
    };
    dispatch(reportAction.updateCustomReport(updatedReportData));
  };

  return (
    <React.Fragment>
      <Grid container >

        <Grid container style={{ justifyContent: 'left' }}>
          <Grid container className={classes.lightOrangeBox}>
            <Box>
              <p>
                {ReportsConstants.CUSTOM_PDF_STATS}
              </p>
            </Box>
          </Grid>
        </Grid>

        <Grid container spacing={2.5} className={classes.ImagesGrid}>
          {(imageList || [])?.map((image, index) => (

            <Grid
              item
              xs={2.5}
              sm={2.5}
              md={2.6}
              key={index}
              onClick={() => handleImageClick(image)}
              className={`${classes.imageGrid} ${selectedImages?.includes(image.title) ? classes.selectedImage : ""
                }`}
              style={{ padding: "0px", margin: "10px 0 0 10px", maxWidth: 'fit-content' }}
            >
              {/* <div class="image-container"> */}
              <img
                src={image.src}
                alt={image.title}
                style={{
                  // maxWidth: "100%",
                  width: "205px",
                  // height: "auto",
                  display: "block",
                  objectFit: "contain",
                  borderRadius: '10px'
                }}
              />
              {/* {image.title !==  "Suggested Areas of Action" && <p class="image-text"><b>{image.title}</b></p>} */}
              {/* </div> */}
            </Grid>

          ))}
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(NewReportStyles)(Dashboard);