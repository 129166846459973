import { alertConstant, pdfConstant, uiConstant, reportConstant } from "../constants";
import { pdfService } from "../services";
import { baseAction } from "./baseAction";
import { saveAs } from 'file-saver';

const { request, success, failure } = baseAction;

// Export All Pdf Actions Functions
export const pdfAction = {
  download,
};

/**
 * pdf Download
 */
function download(data) {
  return dispatch => {
    // dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(pdfConstant.PDF_DOWNLOAD_REQUEST));
    if(data.reqType === "report"){
      dispatch(request(reportConstant.REPORT_LOADING_TRUE))
    }
    pdfService.download(data)
      .then(
        (data) => {
          if (!data) {
            data = { data: { error: "Internal Server Error" }, status: "failed" }
          }
          if((data && data.data && (data.data.error || data.status === "failed")) || data && data.status === "failed"){
            dispatch(failure(alertConstant.ALERT_ERROR, data.data.error));
            // dispatch(request(uiConstant.LOADING_FALSE));            
            dispatch(request(reportConstant.REPORT_LOADING_FALSE));
          }else{
            const blob = new Blob([data.data],{type : 'application/pdf'});
            saveAs(blob, data.filename);
            dispatch(success(alertConstant.ALERT_SUCCESS, "Successfully downloaded"));
            // dispatch(request(uiConstant.LOADING_FALSE));
            dispatch(request(reportConstant.REPORT_LOADING_FALSE));
          }
        },
        (error) => {
          // dispatch(failure(pdfConstant.PDF_DOWNLOAD_FAILURE, error));
          // dispatch(failure(alertConstant.ALERT_ERROR, error));
          // dispatch(request(uiConstant.LOADING_FALSE));
          dispatch(request(reportConstant.REPORT_LOADING_FALSE));
        }
      );
  }
}