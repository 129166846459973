import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import withStyles from '@mui/styles/withStyles';
import { Button } from '@mui/material';

// Import Styles and Image
import ReportsStyles from "../../ReportsStyles";

// Import Component
import DialogComponent from "../../../../components/DialogComponent/DialogComponent";
import CreateReportPopup from "./CreateReportPopup";

// import CreateReportPopup from "./NewReportPopup";

// import constants
import { ReportsConstants } from "../../../../config/constants";

// Import Actions
import { reportAction } from "../../../../redux/actions";

function CreateReport(props) {
  // Define Dispatch
  const dispatch = useDispatch();

  // get props
  const { classes } = props;

  const routerDomHistory = useHistory();
  const routerDomLocation = useLocation();

  //state for open close popup
  const [savePopup, setSavePopup] = useState(false);
  
  const openPopup = () => {
    setSavePopup(true);
  };
  const closePopup = () => {
    setSavePopup(false);
    dispatch(reportAction.resetReportData())
  };

  return (
    <React.Fragment>
        <Button
      variant="contained" // Button with background color
      color="primary"     // Use MUI's primary color (blue by default)
      onClick={() => routerDomHistory.push(`custom_report/new`, { from: routerDomLocation.pathname })}// Adds the Plus Icon before the text
    >
     <svg xmlns="http://www.w3.org/2000/svg" 
     width="15.199" height="20.199" 
     style={{ marginRight: '10px', fill: '#FFFFFF' }}
     viewBox="0 0 15.199 19.199"><g id="symbol_add-mini" data-name="symbol/add-mini" transform="translate(-3.9 -1.9)"><path id="Union_17" 
     data-name="Union 17" d="M11114.6-1406.8a.6.6,0,0,1-.6-.6v-18a.6.6,0,0,1,.6-.6h10a.607.607,0,0,1,.427.176l4,4a.6.6,0,0,1,.176.425v14a.6.6,0,0,1-.6.6Zm.6-1.2h12.8v-12.8h-3.4a.6.6,0,0,1-.6-.6v-3.4h-8.8Zm10-14h1.952l-1.952-1.95Zm-4.2,9.6v-2.4h-2.4a.6.6,0,0,1-.6-.6.6.6,0,0,1,.6-.6h2.4v-2.4a.6.6,0,0,1,.6-.6.6.6,0,0,1,.6.6v2.4h2.4a.6.6,0,0,1,.6.6.6.6,0,0,1-.6.6h-2.4v2.4a.6.6,0,0,1-.6.6A.6.6,0,0,1,11121-1412.4Z" 
     transform="translate(-11110.1 1427.9)" fill="#FFFFFF"></path></g></svg> 
     Create Custom Report
    </Button>

    
      {savePopup && (
        <DialogComponent open={true} fullScreen={true}>
          <CreateReportPopup {...props} title={ReportsConstants.CREATEA_REPORT} closePopup={closePopup} classes={classes} isSaveReport={false} />
        </DialogComponent>
      )}
    </React.Fragment>
  );
}
// default props
CreateReport.defaultProps = {
  classes: {},
};

// prop types
CreateReport.propTypes = {
  classes: PropTypes.object,
};
export default withStyles(ReportsStyles)(CreateReport);
