import {
  Grid,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import {
  BackPage,
  folderIcon,
} from "../../assets/images/svgComponents/ImageComponent";
import Tooltip from "@mui/material/Tooltip";
import Container from "../Containers/Container";
import TypographyComponent from "../../components/TypographyComponent/TypographyComponent";
import ButtonComponent from "../../components/ButtonComponent/ButtonComponent";
import history from "../../config/history";
import TextFieldComponent from "../../components/TextFieldComponent/TextFieldComponent";
import { ValidatorForm } from "react-material-ui-form-validator";
import { rawdataAutomationAction, baseAction, excelAction, clientAction } from "../../redux/actions";
import { useSelector, useDispatch } from "react-redux";
import { getQueryVariable } from "../../helpers";
import TableComponent from "../../components/TableComponent/TableComponent";
// Import Table Size
import { getTableHeight, getTableRowSize } from "../../config/layout";
import { adminConstants, excelConstants } from "../../config/constants";
import SwitchComponent from "../../components/SwitchComponent/SwitchComponent";
import { alertConstant } from "../../redux/constants";

const Rawdatalist = (props) => {
  // State variables
  const dispatch = useDispatch();
  let { rawdata_folder } = useSelector((state) => state.client.getOne);
  const { failure } = baseAction;
  const [open, setOpen] = useState(false);
  const [folderName, setFolderName] = useState(rawdata_folder);

  const componentMap = {
    status: StatusSwitch,
  };

  useEffect(() => {
    setFolderName(rawdata_folder)
  }, [rawdata_folder])

  const StatusSwitch = ({ data, handleChange }) => {
    return (
      <Grid item xs={12}>
        <SwitchComponent
          item
          xs={12}
          name={"status"}
          color="primary"
          checked={data.status}
          handleChange={({ value }) => {
            handleChange("status", data, value);
          }}
          label={
            <span>
              <TypographyComponent variant="h6">
                {data.label}
              </TypographyComponent>
            </span>
          }
        />
      </Grid>
    );
  };

  const rawdatalist = useSelector((state) => state.rawdataAutomation.search);

  const { page, data } = rawdatalist;

  // Get Page Reset
  const isPageReset =
    getQueryVariable(props.location.search, "reset") === "true";
  const isPageRefresh =
    getQueryVariable(props.location.search, "refresh") === "true";

  // Table size
  const tableHeight = getTableHeight();
  const tableRowSize = getTableRowSize();

  /**
   * Get Users admins
   */
  useEffect(() => {
    if (!isPageRefresh) {
      let { sFields, sValues } = page;
      if (typeof sFields === "undefined") sFields = [];
      if (typeof sValues === "undefined") sValues = [];
      dispatch(
        rawdataAutomationAction.search(1, tableRowSize, "active", "desc", sFields, sValues)
      );
    }
  }, [dispatch]);

  useEffect(() => {
    if (isPageReset) {
      history.replace(props.location.pathname);
      dispatch(rawdataAutomationAction.search(1, tableRowSize, "active", "desc", [], []));
    }
    if (isPageRefresh) history.replace(props.location.pathname);
  }, [isPageRefresh, isPageReset]);

  /**
   * Handle Table pagination Event
   */
  const onChangePagination = (skip, limit) => {
    dispatch(
      rawdataAutomationAction.search(
        skip,
        limit,
        page.sortBy,
        page.sort,
        page.sFields,
        page.sValues
      )
    );
  };

  /**
   * Handle Table Sort Request Event
   */
  const onSortRequest = (sortBy, sort) => {
    dispatch(
      rawdataAutomationAction.search(
        page.skip,
        page.limit,
        sortBy,
        sort,
        page.sFields,
        page.sValues
      )
    );
  };

  /**
   * Handle Table Search Input Change Event
   */
  const onInputChangeRequest = (searchField, searchKey) => {
    const { sFields, sValues } = page;
    if (sFields.indexOf(searchField) >= 0) {
      if (searchKey === "") {
        const index = sFields.indexOf(searchField);
        sValues.splice(index, 1);
        sFields.splice(index, 1);
      } else {
        sValues[sFields.indexOf(searchField)] = searchKey;
      }
    } else {
      sFields.push(searchField);
      sValues.push(searchKey);
    }

    if (isPageRefresh)
      dispatch(
        rawdataAutomationAction.search(
          page.skip,
          tableRowSize,
          page.sortBy,
          page.sort,
          sFields,
          sValues
        )
      );
    else
      dispatch(
        rawdataAutomationAction.search(
          1,
          tableRowSize,
          page.sortBy,
          page.sort,
          sFields,
          sValues
        )
      );
  };
  /**
   * Handle Field Component On Change Event
   */
  const fieldComponentOnChange = (field, data, value) => {
    switch (field) {
      case "status":
        const updatedData = { ...data, [field]: value };
        dispatch(
          rawdataAutomationAction.updateList(updatedData, () => {
            // retain old search values
            if (page.skip) {
              dispatch(
                rawdataAutomationAction.search(
                  page.skip,
                  page.limit,
                  page.sortBy,
                  page.sort,
                  page.sFields,
                  page.sValues
                )
              );
            } else {
              dispatch(
                rawdataAutomationAction.search(1, tableRowSize, "status", "desc", [], [])
              );
            }
          })
        );
        break;
      case "ACTION_SETTING":
        handleNavigateAction(data._id);
        break;
      default:
    }
  };

  /**
   * Handle Table Navigation Event
   */
  const handleNavigateAction = (id) => {
    history.push({
      pathname: `/raw-data/setup/${id}`,
    });
  };

  /**
   * Handle Action Button Event
   * @param {*} field
   * @param {*} id
   */
  const handelActionButtonClick = (field, id, name) => {
    switch (field) {
      case "DELETE":
        break;
      case "EXPORT":
        let rawdata = data.find(e => e._id == id)
        dispatch(excelAction.download({
          ...rawdata,
          excelType: excelConstants.RAW_DATA_REPORT_AUTOMATION,
          reqType: "report"
        }, true));
        break;
      default:
        break;
    }
  };

  const actionComponent = {
    SETTING: DataLevelAccessIcon,
  };

  const DataLevelAccessIcon = ({ data, handleChange, classes }) => {
    let hasQuery = data.queryDLA;
    return (
      <div className={classes.tableactions}>
        <Tooltip title="Settings" arrow>
          <Avatar
            alt="Setting"
            className={`tableIcon MuiAvatar-root MuiAvatar-circle ml5 mr5 scrollIntial cursor-pointer`}
            onClick={() => handleChange("ACTION_SETTING", data, null)}
          >
            {hasQuery ? (
              <Grid>{SettingOrange()}</Grid>
            ) : (
              <Grid>{SettingGrey()}</Grid>
            )}
          </Avatar>
        </Tooltip>
      </div>
    );
  };

  /** Delete Function*
   * @param {*} field
   * @param {*} id
   */
  const handleTableDeleteAction = (id) => {
    dispatch(
      rawdataAutomationAction.remove(id, () => {
        // retain old search values
        if (page.skip) {
          dispatch(
            rawdataAutomationAction.search(
              page.skip,
              page.limit,
              page.sortBy,
              page.sort,
              page.sFields,
              page.sValues
            )
          );
        } else {
          dispatch(
            rawdataAutomationAction.search(1, tableRowSize, "status", "desc", [], [])
          );
        }
      })
    );
  };

  /**
   * Set Saml Field Help Text
   */
  const getInfoHeaders = () => {
    const { fieldCols, selectedCols } = page.fieldFilters;
    let infoHeaders = {};
    if (selectedCols) {
      const samlFieldIndex =
        Object.keys(selectedCols).indexOf("samlFieldValue");
      if (samlFieldIndex >= 0) {
        infoHeaders[fieldCols[samlFieldIndex - 1]] =
          clientConstants.SSO_IDENTIFIER_HELP;
      }
    }

    infoHeaders["Active Status"] = adminConstants.ACTIVE_STATUS_HELP;
    return infoHeaders;
  };

  // Handle opening the popup
  const handleClickOpen = () => {
    setOpen(true);
  };

  // Handle closing the popup
  const handleClose = () => {
    setOpen(false);
  };

  // Handle save
  const handleSave = () => {
    // You can add the logic to save the folder name here
    dispatch(rawdataAutomationAction.updateFolderName(folderName));
    setOpen(false);
  };

  // Handle cancel action
  const handleCancel = () => {
    setFolderName(rawdata_folder); // Optional: Reset folder name on cancel
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Container align={"baseline"}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <Grid container alignItems="center">
                  <Grid onClick={() => history.goBack()}>
                    {BackPage()}
                  </Grid>
                  <TypographyComponent variant={"h3"} className="ml-1">
                    Raw Data
                  </TypographyComponent>
                </Grid>
              </Grid>
              <Grid item>
                <Tooltip arrow title="Folder Name">
                  <IconButton
                    variant="contained"
                    color="primary"
                    className="p-0 mr-1"
                    onClick={handleClickOpen}
                    size="large"
                  >
                    {folderIcon()}
                  </IconButton>
                </Tooltip>
                <Dialog open={open}
                  PaperProps={{
                    sx: {
                      width: '300px',
                      position: 'absolute',
                      top: '75px', // Position below the header
                      right: '160px', // Align with the icon button
                    }
                  }}
                  onClose={handleClose}>
                  <ValidatorForm
                    id="surveyPanelForm"
                    name="surveyPanelForm"
                    useref="surveyPanelForm"
                    autoComplete="off"
                    onSubmit={handleSave}
                  // onError={handleError}
                  >
                    <DialogContent>
                      <TypographyComponent
                        title={"Folder Name"}
                        variant="body1"
                        style={{ fontSize: "16px" }}
                        align={"Left"}
                      ></TypographyComponent>
                      <TextFieldComponent
                        id={"folder_name"}
                        name={"name"}
                        className={"fontSize14"}
                        value={folderName}
                        placeholder={"Name your sftp folder"}
                        maxLength={60}
                        margin={"none"}
                        validators={["required"]}
                        showErrorMessages={false}
                        handleChange={(e) => setFolderName(e.value)}
                      />
                    </DialogContent>
                    <DialogActions>
                      <ButtonComponent
                        title={"Save"}
                        size={"small"}
                        type="submit"
                        pageClassName="mr-1"
                        color="primary"
                      ></ButtonComponent>
                      <ButtonComponent
                        title={"Cancel"}
                        size={"small"}
                        type="button"
                        pageClassName="mr-1"
                        handleClick={handleCancel}
                      ></ButtonComponent>
                    </DialogActions>
                  </ValidatorForm>
                </Dialog>
                <ButtonComponent
                  title={"Create Raw Data"}
                  color={"primary"}
                  size={"small"}
                  type="button"
                  pageClassName="mr-1"
                  handleClick={() => {
                    if (rawdata_folder) {
                      dispatch(rawdataAutomationAction.setbacktoinitial());
                      history.replace(`/raw-data/setup/new`);
                    } else {
                      dispatch(failure(alertConstant.ALERT_ERROR, "First Setup the Folder Name"));
                    }
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <TableComponent
              data={data}
              header={page.fieldFilters.headerCols}
              field={page.fieldFilters.fieldCols}
              search={page.fieldFilters.searchCols}
              actions={["EXPORT", "DELETE"]}
              height={tableHeight}
              page={page.skip}
              count={page.count}
              rowsPerPage={page.limit}
              links={[page.fieldFilters.fieldCols[0]]}
              dateFields={page.fieldFilters.dateCols}
              sort={page.sort}
              sortBy={page.sortBy}
              alignCenterFields={["Active Status", "status"]}
              sFields={page.sFields}
              sValues={page.sValues}
              noDataErrorMessage={"No Raw Data Found"}
              removedSortingRow={[]}
              moreInfoIcons={[]}
              componentMap={{ ...componentMap }}
              columnsMap={{}}
              showLoading={false}
              prevNext={false}
              paginationVisible={!page.lastPage}
              deleteContent={adminConstants.DELETE_CONTENT}
              handleNavigateAction={handleNavigateAction}
              onChangePagination={onChangePagination}
              onSortRequest={onSortRequest}
              onInputChangeRequest={onInputChangeRequest}
              fieldComponentOnChange={fieldComponentOnChange}
              handelActionButtonClick={handelActionButtonClick}
              handleTableDeleteAction={handleTableDeleteAction}
              resetSearchText={isPageReset}
              actionComponent={actionComponent}
              infoHeaders={getInfoHeaders()}
            ></TableComponent>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
};

export default Rawdatalist;
