export const reportConstant = {

    //get all surveys
    GET_SURVEYS_REQUEST: 'GET_SURVEYS_REQUEST',
    GET_SURVEYS_SUCCESS: 'GET_SURVEYS_SUCCESS',
    GET_SURVEYS_FAILURE: 'GET_SURVEYS_FAILURE',

    //get all demographics
    GET_DEMOGRAPHICS_REQUEST: 'GET_DEMOGRAPHICS_REQUEST',
    GET_DEMOGRAPHICS_SUCCESS: 'GET_DEMOGRAPHICS_SUCCESS',
    GET_DEMOGRAPHICS_FAILURE: 'GET_DEMOGRAPHICS_FAILURE',

    //get all demographic items for name
    GET_DEMOGRAPHICS_BY_NAME_REQUEST: 'GET_DEMOGRAPHICS_BY_NAME_REQUEST',
    GET_DEMOGRAPHICS_BY_NAME_SUCCESS: 'GET_DEMOGRAPHICS_BY_NAME_SUCCESS',
    GET_DEMOGRAPHICS_BY_NAME_FAILURE: 'GET_DEMOGRAPHICS_BY_NAME_FAILURE',

    //get comment questions
    GET_COMMENT_QUESTIONS_REQUEST: 'GET_COMMENT_QUESTIONS_REQUEST',
    GET_COMMENT_QUESTIONS_SUCCESS: 'GET_COMMENT_QUESTIONS_SUCCESS',
    GET_COMMENT_QUESTIONS_FAILURE: 'GET_COMMENT_QUESTIONS_FAILURE',

    //save reports
    SAVE_REPORT_REQUEST: 'SAVE_REPORT_REQUEST',
    SAVE_REPORT_SUCCESS: 'SAVE_REPORT_SUCCESS',
    SAVE_REPORT_FAILURE: 'SAVE_REPORT_FAILURE',

    //save reports
    SAVE_CUSTOM_REPORT_REQUEST: 'SAVE_CUSTOM_REPORT_REQUEST',
    SAVE_CUSTOM_REPORT_SUCCESS: 'SAVE_CUSTOM_REPORT_SUCCESS',
    SAVE_CUSTOM_REPORT_FAILURE: 'SAVE_CUSTOM_REPORT_FAILURE',

    //save reports
    UPDATE_CUSTOM_REPORT_REQUEST: 'UPDATE_CUSTOM_REPORT_REQUEST',
    UPDATE_CUSTOM_REPORT_SUCCESS: 'UPDATE_CUSTOM_REPORT_SUCCESS',
    UPDATE_CUSTOM_REPORT_FAILURE: 'UPDATE_CUSTOM_REPORT_FAILURE',
 
    UPDATE_CUSTOM_REPORT_DATA: 'UPDATE_CUSTOM_REPORT_DATA',
    RESET_CUSTOM_REPORT_DATA : 'RESET_CUSTOM_REPORT_DATA',

    GET_CUSTOM_REPORT_REQUEST: 'GET_CUSTOM_REPORT_REQUEST',
    GET_CUSTOM_REPORT_SUCCESS: 'GET_CUSTOM_REPORT_SUCCESS',
    GET_CUSTOM_REPORT_FAILURE: 'GET_CUSTOM_REPORT_FAILURE',

    GET_CUSTOM_DASHBOARD_REQUEST: 'GET_CUSTOM_DASHBOARD_REQUEST',
    GET_CUSTOM_DASHBOARD_SUCCESS: 'GET_CUSTOM_DASHBOARD_SUCCESS',
    GET_CUSTOM_DASHBOARD_FAILURE: 'GET_CUSTOM_DASHBOARD_FAILURE',

    GET_CUSTOM_CHART_REQUEST: 'GET_CUSTOM_CHART_REQUEST',
    GET_CUSTOM_CHART_SUCCESS: 'GET_CUSTOM_CHART_SUCCESS',
    GET_CUSTOM_CHART_FAILURE: 'GET_CUSTOM_CHART_FAILURE',

    GET_CUSTOM_YESNO_CHART_REQUEST : 'GET_CUSTOM_YESNO_CHART_REQUEST',
    GET_CUSTOM_YESNO_CHART_SUCCESS : 'GET_CUSTOM_YESNO_CHART_SUCCESS',
    GET_CUSTOM_YESNO_CHART_FAILURE : 'GET_CUSTOM_YESNO_CHART_FAILURE',

    GET_CUSTOM_DROPDOWN_CHART_REQUEST : 'GET_CUSTOM_DROPDOWN_CHART_REQUEST',
    GET_CUSTOM_DROPDOWN_CHART_SUCCESS : 'GET_CUSTOM_DROPDOWN_CHART_SUCCESS',
    GET_CUSTOM_DROPDOWN_CHART_FAILURE : 'GET_CUSTOM_DROPDOWN_CHART_FAILURE',

    GET_CUSTOM_MATRIX_CHART_REQUEST: 'GET_CUSTOM_MATRIX_CHART_REQUEST',
    GET_CUSTOM_MATRIX_CHART_SUCCESS: 'GET_CUSTOM_MATRIX_CHART_SUCCESS',
    GET_CUSTOM_MATRIX_CHART_FAILURE: 'GET_CUSTOM_MATRIX_CHART_FAILURE',

    GET_CUSTOM_DEMOGRAPHIC_REQUEST: 'GET_CUSTOM_DEMOGRAPHIC_REQUEST',
    GET_CUSTOM_DEMOGRAPHIC_SUCCESS: 'GET_CUSTOM_DEMOGRAPHIC_SUCCESS',
    GET_CUSTOM_DEMOGRAPHIC_FAILURE: 'GET_CUSTOM_DEMOGRAPHIC_FAILURE',

    //copy reports
    REPORT_COPY_REQUEST: 'REPORT_COPY_REQUEST',
    REPORT_COPY_SUCCESS: 'REPORT_COPY_SUCCESS',
    REPORT_COPY_FAILURE: 'REPORT_COPY_FAILURE',

    //REMOVE report
    REMOVE_REPORT_REQUEST: 'REMOVE_REPORT_REQUEST',
    REMOVE_REPORT_SUCCESS: 'REMOVE_REPORT_SUCCESS',
    REMOVE_REPORT_FAILURE: 'REMOVE_REPORT_FAILURE',

    //get selected report
    GET_REPORT_REQUEST: 'GET_REPORT_REQUEST',
    GET_REPORT_SUCCESS: 'GET_REPORT_SUCCESS',
    GET_REPORT_FAILURE: 'GET_REPORT_FAILURE',

    // get saved reports
    GET_SAVED_REPORT_REQUEST: 'GET_SAVED_REPORT_REQUEST',
    GET_SAVED_REPORT_SUCCESS: 'GET_SAVED_REPORT_SUCCESS',
    GET_SAVED_REPORT_FAILURE: 'GET_SAVED_REPORT_FAILURE',

    //update Report Data
    UPDATE_REPORT_DATA: "UPDATE_REPORT_DATA",


    //reset Report Data
    RESET_REPORT_DATA: "RESET_REPORT_DATA",
    RESET_CUSTOM_REPORT_DATA: "CUSTOM RESET_REPORT_DATA",

    //update Comment Report Data
    UPDATE_COMMENT_REPORT_DATA: "UPDATE_COMMENT_REPORT_DATA",

    //Raw Data
    GET_RAW_DATA_REQUEST: 'GET_RAW_DATA_REQUEST',
    GET_RAW_DATA_SUCCESS: 'GET_RAW_DATA_SUCCESS',
    GET_RAW_DATA_FAILURE: 'GET_RAW_DATA_FAILURE',

    //BENCHMARK_INDUSTRY_LIST CONSTANT
    GET_BENCHMARK_INDUSTRY_LIST_REQUEST: 'GET_BENCHMARK_INDUSTRY_LIST_REQUEST',
    GET_BENCHMARK_INDUSTRY_LIST_SUCCESS: 'GET_BENCHMARK_INDUSTRY_LIST_SUCCESS',
    GET_BENCHMARK_INDUSTRY_LIST_FAILURE: 'GET_BENCHMARK_INDUSTRY_LIST_FAILURE',

    //Report Page Loading component show and hide
    REPORT_LOADING_TRUE: 'REPORT_LOADING_TRUE',
    REPORT_LOADING_FALSE: 'REPORT_LOADING_FALSE',

    // SUbjects List for Individual ME Report
    GET_IR_ME_SUBJECTS_LIST_REQUEST: 'GET_IR_ME_SUBJECTS_LIST_REQUEST',
    GET_IR_ME_SUBJECTS_LIST_SUCCESS: 'GET_IR_ME_SUBJECTS_LIST_SUCCESS',
    GET_IR_ME_SUBJECTS_LIST_FAILURE: 'GET_IR_ME_SUBJECTS_LIST_FAILURE',

    //Key Driver
    GET_KEY_DRIVER_REQUEST: 'GET_KEY_DRIVER_REQUEST',
    GET_KEY_DRIVER_SUCCESS: 'GET_KEY_DRIVER_SUCCESS',
    GET_KEY_DRIVER_FAILURE: 'GET_KEY_DRIVER_FAILURE',
};