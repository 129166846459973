import React from 'react';
import { TextField, ListSubheader, MenuItem } from '@mui/material';
import Select from "@mui/material/Select";
import TypographyComponent from '../../../../../components/TypographyComponent/TypographyComponent';
import { ReportsConstants, surveyTypes } from '../../../../../config/constants';


const SurveySelector = ({
  surveySearchName,
  setSurveySearchName,
  selectHistorical,
  onHistoricalTypeChange,
  onSurveyChange,
  surveyData,
  historicalSurvey
}) => {
  const { selectedSurvey, hlsSurveyData } = React.useMemo(() => {
    let selectedSurveyLocal = [];
    let filteredData = surveyData;

    if (surveySearchName) {
      const cleanedSearchName = surveySearchName.toLowerCase();

      const selectedSurveyIndex = surveyData.findIndex(
        (s) =>
          s.name.toLowerCase().includes(cleanedSearchName) &&
          s.name === historicalSurvey
      );

      selectedSurveyLocal =
        historicalSurvey && selectedSurveyIndex >= 0
          ? [surveyData[selectedSurveyIndex]]
          : [];

      filteredData = (surveyData || [])?.filter(
        (s) =>
          (s?.name?.toLowerCase())?.includes(cleanedSearchName) &&
          s?.name !== historicalSurvey
      );
    }

    return { selectedSurvey: selectedSurveyLocal, hlsSurveyData: filteredData };
  }, [surveySearchName, surveyData, historicalSurvey]);

  return (
    <Select
      value={selectHistorical || ''}
      variant="standard"
      disableUnderline={true}
      onClose={() => setSurveySearchName('')}
      onChange={(e) => onHistoricalTypeChange(e.target.value)}
      sx={{
        width: '150px',
        borderRadius: '16px',
        border: '1px solid #c4c4c4',
        '& .MuiSelect-select': {
          height: '0 !important', // Remove padding with !important
          borderRadius: '8px',
        },
        '& .MuiSelect-nativeInput': {
          display: 'none', // Hide the input
          margin: '0', // Remove any margin around the input
        },
        '&:hover': {
          backgroundColor: '#e0e0e0',
        },
      }}
    >
      <ListSubheader disableSticky={true} className="text-gray-600">
        {ReportsConstants.CUSTOM_DATE_RANGE}
      </ListSubheader>
      {ReportsConstants.HISTORICAL_DATES.map((item, keyIndex) => (
        <MenuItem
          className="ml-2"
          key={keyIndex}
          value={item || ''}
          disabled={item === selectHistorical}
        >
          {item}
        </MenuItem>
      ))}

      <ListSubheader
        disableSticky={true}
        className="text-gray-600"
        sx={{ display: 'flex', alignItems: 'center' }}
      >
        {ReportsConstants.CUSTOM_SELECT_SURVEY}
        <TextField
          variant="outlined"
          value={surveySearchName}
          placeholder="Type to search"
          className="h-7 pl-2 m-0"
          onChange={(e) => setSurveySearchName(e.target.value)}
          onKeyDown={(e) => e.stopPropagation()}
        />
      </ListSubheader>

      {surveySearchName && selectedSurvey.length === 1 && (
        <MenuItem
          className="ml-2"
          key={selectedSurvey[0]?._id}
          value={selectedSurvey[0]?.name || ''}
          disabled={true}
        >
          {selectedSurvey[0]?.name}
        </MenuItem>
      )}

      {hlsSurveyData.map((item) => (
        <MenuItem
          className="ml-2"
          key={item?._id}
          value={item?.name || ''}
          disabled={item?.name === historicalSurvey}
          onClick={() => onSurveyChange(item)}
        >
          {item?.name || 'Unnamed Survey'}
        </MenuItem>
      ))}

      {selectedSurvey.length === 0 && hlsSurveyData.length === 0 && (
        <TypographyComponent
          variant={'h6'}
          title={"No Search Results Found"}
          className={`ml-2`}
          color={'#B1B2B3'}
        />
      )}
    </Select>
  );
};

export default SurveySelector;
