import React from "react";
import PropTypes from "prop-types";
import { TextValidator } from "react-material-ui-form-validator";
import Autocomplete from '@mui/material/Autocomplete';
import withStyles from '@mui/styles/withStyles';

// import Styles
import AutoCompleteComponentStyle from "./AutoCompleteComponentStyles";

// Import Config Constants
import { errorsConstants } from "../../config/constants";

/**
 * Autocomplete Component
 */
function AutoCompleteComponent (props) {
  /**
   * render html
   */
  const { suggestions, name, disabled, value, placeholder, className, validators, showErrorMessages, onInputChange, onClose, suggestionType, freeSolo, extraProps, panel, disableClearable, autoHighlight, onKeyDown, disablePortal, blurOnSelect, selectOnFocus, componentsProps, popoverProps, optionProps, readOnly} = props;
  const errorMessages = showErrorMessages ? validators.map(s => errorsConstants[s]) : [];

  return (
    <div style={{}}>
      {freeSolo ? <React.Fragment>
        {suggestionType === "array"
          ? <Autocomplete
            name={`ac-${name}`}
            variant={"outlined"}
            disableClearable={disableClearable}
            autoHighlight={autoHighlight}
            disablePortal={disablePortal}
            componentsProps={componentsProps}
            blurOnSelect={blurOnSelect}
            selectOnFocus={selectOnFocus}
            popoverProps={popoverProps}
            freeSolo={freeSolo}
            value={value}
            disabled={disabled}
            className={className}
            options={suggestions}
            getOptionLabel={(option) => option}
            isOptionEqualToValue={(option, value) => option === value}
            onChange={(event, newValue, reason) => {
              props.handleAutoCompolete(newValue, name, reason);
            }}
            onInputChange={(value, eValue, reason) => {
              onInputChange(eValue, name, reason);
            }}
            onClose={(event) => {
              onClose(event.currentTarget.name);
            }}
            sx={optionProps}
            onKeyDown={onKeyDown}
            renderInput={(params) => {
              params.inputProps = { ...(params.inputProps || {}), autoComplete: "no" }
              return (
                <TextValidator
                  {...params}
                  name={name}
                  variant={"outlined"}
                  value={value}
                  placeholder={placeholder}
                  margin={"dense"}
                  validators={validators}
                  errorMessages={errorMessages}
                  InputProps={{ ...params.InputProps, type: "text", className: "headerSearch pr-3",readOnly: readOnly }}
                />
              )
            }}
            {...extraProps.options}
            panel={panel}
          />
          : <Autocomplete
            name={`ac-${name}`}
            variant={"outlined"}
            disableClearable={disableClearable}
            autoHighlight={autoHighlight}
            disablePortal={disablePortal}
            componentsProps={componentsProps}
            blurOnSelect={blurOnSelect}
            selectOnFocus={selectOnFocus}
            popoverProps={popoverProps}
            value={value.name ? value : null}
            freeSolo={freeSolo}
            disabled={disabled}
            className={className}
            options={suggestions}
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, value) => option.name === value.name || value._id}
            onChange={(event, newValue) => {
              props.handleAutoCompolete(newValue, name);
            }}
            onInputChange={(value, eValue, reason) => {
              onInputChange(value, name, reason);
            }}
            onClose={(event, value) => {
              onClose(value, name);
            }}
            sx={optionProps}
            renderInput={(params) => (
              <TextValidator
                {...params}
                name={name}
                variant={"outlined"}
                placeholder={placeholder}
                value={value.name ? value : ""}
                onKeyDown={onKeyDown}
                margin={"dense"}
                validators={validators}
                errorMessages={errorMessages}
                InputProps={{ ...params.InputProps, type: "text", className: "headerSearch pr-3", readOnly: readOnly}}
              />
            )}
            {...extraProps.options}
            panel={panel}
          />
        }
      </React.Fragment> : suggestions && suggestions.length > 0 &&
        <React.Fragment>
          {suggestionType === "array"
            ? <Autocomplete
              name={`ac-${name}`}
              variant={"outlined"}
             disableClearable={disableClearable}
             autoHighlight={autoHighlight}
            disablePortal={disablePortal}
            componentsProps={componentsProps}
              blurOnSelect={blurOnSelect}
              selectOnFocus={selectOnFocus}
              popoverProps={popoverProps}
             // menuProps={{maxHeight: 10}}
            // disableListWrap="true"
              freeSolo={freeSolo}
              value={value}
              disabled={disabled}
              className={className}
              options={suggestions}
              getOptionLabel={(option) => option}
              isOptionEqualToValue={(option, value) => option === value}
              onChange={(event, newValue) => {
               props.handleAutoCompolete(newValue, name);
                
              }}
              onInputChange={(value, eValue, reason) => {
                onInputChange(value, name, reason);
              }}
              onClose={(event, value) => {
                onClose(value, name);
              }}
              sx={optionProps}
              renderInput={(params) => (
                <TextValidator
                  {...params}
                  name={name}
                  variant={"outlined"}
                  value={value}
                  placeholder={placeholder}
                  margin={"dense"}
                  onKeyDown={onKeyDown}
                  validators={validators}
                  errorMessages={errorMessages}
                  panel={panel}
                  InputProps={{ ...params.InputProps, type: "text", className: "headerSearch pr-3", readOnly: readOnly }}
                />
              )}
              {...extraProps.options}
              panel={panel}
            />
            : <Autocomplete
              name={`ac-${name}`}
              variant={"outlined"}
              disableClearable={disableClearable}
              autoHighlight={autoHighlight}
              disablePortal={disablePortal}
              componentsProps={componentsProps}
              blurOnSelect={blurOnSelect}
              selectOnFocus={selectOnFocus}
              popoverProps={popoverProps}
              value={value.name ? value : null}
              freeSolo={freeSolo}
              disabled={disabled}
              className={className}
              options={suggestions}
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={(option, value) => option.name === value.name}
              onChange={(event, newValue) => {
                props.handleAutoCompolete(newValue, name);
              }}
              onInputChange={(value, eValue, reason) => {
                onInputChange(value, name, reason);
              }}
              onClose={(event, value) => {
                props.onClose(value, name);
              }}
              sx={optionProps}
              renderInput={(params) => (
                <TextValidator
                  {...params}
                  name={name}
                  variant={"outlined"}
                  placeholder={placeholder}
                  value={value.name ? value : ""}
                  margin={"dense"}
                  onKeyDown={onKeyDown}
                  validators={validators}
                  errorMessages={errorMessages}
                  InputProps={{ ...params.InputProps, type: "text", className: "headerSearch pr-3",readOnly: readOnly }}
                />
              )}
              {...extraProps.options}
              panel={panel}
            />
          }
        </React.Fragment>
      }
    </div>
  );
}

// default props
AutoCompleteComponent.defaultProps = {
  suggestions: [],
  value: "",
  disabled: false,
  name: "autocomplete",
  placeholder: "",
  freeSolo: false,
  blurOnSelect: true,
  selectOnFocus: true,
  disableClearable: false,
  disablePortal: false,
  readOnly: false,
  componentsProps: {},
  popoverProps: {},
  optionProps: {},
  validators: [],
  showErrorMessages: true,
  fieldErrors: [],
  onInputChange: () => {},
  onClose: () => {},
  warningFieldName: "",
  margin: "dense",
  className: "",
  suggestionType: "arrayObject",
  handleAutoCompolete: () => { },
  onKeyDown: () => { },
  extraProps: {},
  disableClearable: true,
  autoHighlight: true,
};

// prop types
AutoCompleteComponent.propTypes = {
  suggestions: PropTypes.any,
  value: PropTypes.any,
  disabled: PropTypes.bool,
  suggestionType: PropTypes.string,
  name: PropTypes.string,
  freeSolo: PropTypes.bool,
  blurOnSelect: PropTypes.bool,
  selectOnFocus: PropTypes.bool,
  disableClearable: PropTypes.bool,
  disablePortal: PropTypes.bool,
  readOnly: PropTypes.bool,
  componentsProps: PropTypes.object,
  popoverProps: PropTypes.object,
  optionProps: PropTypes.object,
  placeholder: PropTypes.string,
  validators: PropTypes.array,
  showErrorMessages: PropTypes.bool,
  fieldErrors: PropTypes.array,
  margin: PropTypes.string,
  className: PropTypes.string,
  handleAutoCompolete: PropTypes.func.isRequired,
  onInputChange: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  onKeyDown: PropTypes.func,
  extraProps: PropTypes.object,
  disableClearable: PropTypes.bool,
  autoHighlight: PropTypes.bool
};

/** Export Component */
export default withStyles(AutoCompleteComponentStyle)(AutoCompleteComponent);
