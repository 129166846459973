export const rawdataAutomationConstant = {
    NAME_YOUR_SURVEY : "Raw Data name",
    POST_RAW_DATA_AUTOMATION_CONFIG : "POST_RAW_DATA_AUTOMATION_CONFIG",
    GET_RAW_DATA_ALL_SURVEYS : "GET_RAW_DATA_ALL_SURVEYS",
    UPDATE_SURVEYS_DATA:"UPDATE_SURVEYS_DATA",
    UPDATE_NAME: "UPDATE_NAME",
    UPDATE_FREQUENCY: "UPDATE_FREQUENCY",
    UPDATE_DAY: "UPDATE_DAY",
    GET_DEMOGRAPHICS_REQUEST:"GET_DEMOGRAPHICS_REQUEST",
    GET_DEMOGRAPHICS_SUCCESS:"GET_DEMOGRAPHICS_SUCCESS",
    GET_DEMOGRAPHICS_FAILURE:"GET_DEMOGRAPHICS_FAILURE",
    GET_DEMOGRAPHICS_BY_NAME_REQUEST:"GET_DEMOGRAPHICS_BY_NAME_REQUEST",
    GET_DEMOGRAPHICS_BY_NAME_SUCCESS:"GET_DEMOGRAPHICS_BY_NAME_SUCCESS",
    GET_DEMOGRAPHICS_BY_NAME_FAILURE:"GET_DEMOGRAPHICS_BY_NAME_FAILURE",
    UPDATE_DATE : "UPDATE_DATE",
    UPDATE_DEMOGRAPHICS : "UPDATE_DEMOGRAPHICS",
    UPDATE_FOLDER_NAME_REQUEST : "UPDATE_FOLDER_NAME_REQUEST",
    UPDATE_FOLDER_NAME_SUCCESS : "UPDATE_FOLDER_NAME_SUCCESS",
    UPDATE_FOLDER_NAME_FAILURE : "UPDATE_FOLDER_NAME_FAILURE",
    CREATE_RAW_DATA_AUTOMATION_REQUEST : "CREATE_RAW_DATA_AUTOMATION_REQUEST",
    CREATE_RAW_DATA_AUTOMATION_SUCCESS: "CREATE_RAW_DATA_AUTOMATION_SUCCESS",
    CREATE_RAW_DATA_AUTOMATION_FAILURE :"CREATE_RAW_DATA_AUTOMATION_FAILURE",
    RAW_DATA_LIST_SEARCH_REQUEST : "RAW_DATA_LIST_SEARCH_REQUEST",
    RAW_DATA_LIST_SEARCH_SUCCESS : "RAW_DATA_LIST_SEARCH_SUCCESS",
    RAW_DATA_LIST_SEARCH_FAILURE  : "RAW_DATA_LIST_SEARCH_REQUEST_FAILURE",
    RAW_DATA_EDIT_ID_UPDATE:"RAW_DATA_EDIT_ID_UPDATE",
    RAW_DATA_GET_ONE_SUCCESS:"RAW_DATA_GET_ONE_SUCCESS",
    RAW_DATA_GET_ONE_REQUEST:"RAW_DATA_GET_ONE_REQUEST",
    RAW_DATA_GET_ONE_FAILURE : "RAW_DATA_GET_ONE_FAILURE",
    RAWDATA_REMOVE_REQUEST :"RAWDATA_REMOVE_REQUEST",
    RAWDATA_REMOVE_FAILURE : "RAWDATA_REMOVE_FAILURE",
    RAWDATA_REMOVE_SUCCESS : "RAWDATA_REMOVE_SUCCESS",
    RESET_INITIAL_STATE : "RESET_INITIAL_STATE"
};