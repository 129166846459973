import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import withStyles from '@mui/styles/withStyles';
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";

// Import Styles and Image
import NewReportStyles from "./NewReportStyles";

// Import Component
import TypographyComponent from "../../../../components/TypographyComponent/TypographyComponent";
import SelectFieldComponent from "../../../../components/SelectFieldComponent/SelectFieldComponent";
import DatePickerComponent from "../../../../components/DatePickerComponent/CustomDatePicker";
import AutoCompleteComponent from '../../../../components/AutoCompleteComponent/AutoCompleteComponent'

// import constants
import { ReportsConstants } from "../../../../config/constants";

//Import Actions
import { reportAction } from "../../../../redux/actions";

// import helpers
import { dateFormatter } from "../../../../helpers/appHelper";

function Dates(props) {
  const dispatch = useDispatch();

  // get props
  const { classes } = props;

  // Get saved reports Data
  const { customReportData, allSurveys } = useSelector((state) => state.report);
  let { surveys, date } = customReportData;
  const pulseFound = surveys.filter((d) => d.frequency === "Pulse").length > 0;
  const pulse_date = pulseFound
    ? surveys?.[0]?.pulse_obj?.pulseDetails?.[date?.pulseDate]?.displayName || surveys?.[0]?.pulse_obj?.currentPulse_details?.displayName || ""
    : "";

  let TIME_FRAMES = [
    "Last month",
    "Last 3 months",
    "Last 6 months",
    "Last year",
    "Custom dates",
  ];



  if (TIME_FRAMES.indexOf("Pulse dates") === -1 && pulseFound && surveys.length === 1) {
    TIME_FRAMES.push("Pulse dates");
  } else if (TIME_FRAMES.indexOf("Pulse dates") > -1 && !pulseFound) {
    const pulseIndex = TIME_FRAMES.indexOf("Pulse dates");
    TIME_FRAMES.splice(pulseIndex, 1);
  }

  let pulseDateArray = [];
  let survey = []
  if (surveys && surveys?.length === 1 && surveys?.[0]?.frequency === "Pulse") {
    survey = (allSurveys || [])?.filter(obj => obj?._id + "" === surveys?.[0]?._id + "")
    if (survey?.[0] && survey?.[0]?.pulse_obj && survey?.[0]?.pulse_obj?.pulseDetails) {
      Object.keys(survey?.[0]?.pulse_obj?.pulseDetails || {})?.map((key) => {
        pulseDateArray.push(survey?.[0]?.pulse_obj?.pulseDetails?.[key]?.displayName)
      })
    }
  }

  useEffect(() => {

    if (surveys && surveys?.length > 0) {
      let updatedDate = { ...date };
       const sortSurveys = allSurveys?.sort((a, b) => (a.endDate > b.endDate) ? -1 : 1)
    
      sortSurveys.map((eachSurvey) => {
        const surveydetails = surveys.filter(obj => obj._id === eachSurvey._id)
        if (surveydetails.length > 0) {
           if (!date.startDate || !date.endDate) {
            updatedDate.type = date.type || "Custom dates"
            updatedDate.startDate =  eachSurvey.startDate 
            updatedDate.endDate =   eachSurvey.endDate 
         }
        }
    })

        // Dispatch the updated date
        const updatedReportData = {
          ...customReportData,
          date: updatedDate
        };
        dispatch(reportAction.updateCustomReport(updatedReportData));
     
    }

  }, [surveys]);


  //change date type and update reducer
  const changeDateType = (e) => {
    let updatedReportData = {}
    let updatedDate = { ...date };
    if (e?.value === "Custom dates") {
      const sortSurveys = allSurveys?.sort((a, b) => (a.endDate > b.endDate) ? -1 : 1)
  
      sortSurveys.map((eachSurvey) => {
        const surveydetails = surveys.filter(obj => obj._id === eachSurvey._id)
        if (surveydetails.length > 0) {
          updatedDate.type = "Custom dates"
          updatedDate.startDate =  eachSurvey.startDate 
          updatedDate.endDate = eachSurvey.endDate
        }
      
    })

      updatedReportData = {
        ...customReportData,
        date: updatedDate
      };
    }
    else {
      updatedReportData = {
        ...customReportData,
        date: { ...date, [e.name]: e["value"] },
      };
    }

    dispatch(reportAction.updateCustomReport(updatedReportData));

  };

  //change date ranges
  const changeDates = (e) => {
    const updatedReportData = {
      ...customReportData,
      date: { ...date, [e.name]: e["value"] },
    };
    dispatch(reportAction.updateCustomReport(updatedReportData));
  };

  // Clear all data
  const handleClear = (e) => {
    const surveyWithDates = (surveys || [])?.find(s => s?.startDate && s?.endDate);
    const start_date = pulseFound ? surveys?.[0]?.pulse_obj?.currentPulse_details?.startDate : surveyWithDates?.startDate
    const end_date = pulseFound ? surveys?.[0]?.pulse_obj?.currentPulse_details?.endDate : surveyWithDates?.endDate
    if (surveyWithDates || pulseFound) {
      const newDate = {
        ...date,
        type: "Custom dates",
        startDate: dateFormatter(start_date),
        endDate: dateFormatter(end_date),
        pulseDate: ""
      };
      // Dispatch the updated date
      const updatedReportData = {
        ...customReportData,
        date: newDate
      };
      dispatch(reportAction.updateCustomReport(updatedReportData));
    }
    // const updatedReportData = {
    //   ...customReportData,
    //   date: { type: "Last 3 months", startDate: "", endDate: "", pulseDate: "" },
    // };
    // dispatch(reportAction.updateCustomReport(updatedReportData));
  };

  // Handle auto complete change
  const handleAutoCompleteChange = (event) => {
    if (survey?.[0] && survey?.[0]?.pulse_obj && survey?.[0]?.pulse_obj?.pulseDetails) {
      Object.keys(survey?.[0]?.pulse_obj?.pulseDetails || {})?.map((key) => {
        if (survey?.[0].pulse_obj?.pulseDetails?.[key]?.displayName && survey?.[0]?.pulse_obj?.pulseDetails?.[key]?.displayName === event?.value) {
          event.value = key
        }
      })
    } else {
      event.value = ""
    }
    const updatedReportData = {
      ...customReportData,
      date: { ...date, [event.name]: event["value"] },
    };
    dispatch(reportAction.updateCustomReport(updatedReportData));
  }

  return (
    <React.Fragment>
      <Grid container>
        <Grid container style={{ justifyContent: 'left' }}>
          <Grid container className={classes.lightOrangeBox}>
            <Box>
              <p>
                {ReportsConstants.CUSTOM_SELECT_DATES}
              </p>
            </Box>
          </Grid>
        </Grid>
        <Grid container alignItems="center" justifyContent="right">
          {/* <TypographyComponent variant="h6">
            <b>{ReportsConstants.CUSTOM_SELECT_DATES}</b>
          </TypographyComponent> */}
          <Grid item xs={8} sm={11} >
          </Grid>
          <Grid item xs={4} sm={1} >
            <Link
              className={`cursor-pointer colorTheme right`}
              onClick={(e) => handleClear(e)}
            >
              {"Clear all"}
            </Link>
          </Grid>
        </Grid>
      </Grid>
      <Grid container className="pl-3 pt-1 pb-1">
        <Grid item xs={12} sm={6} className="sm-30 mr-3">
          <TypographyComponent variant="h6" className="txtBold">
            <b>{ReportsConstants.DATE_TYPE}</b>
          </TypographyComponent>
          <SelectFieldComponent
            select
            name="type"
            options={TIME_FRAMES}
            value={date ? date.type : ""}
            fullWidth={true}
            handleChange={(e) => changeDateType(e)}
            validators={['required']}
            errorMessages={['Required']}
          />
        </Grid>
        {date.type && date.type === "Custom dates" && (
          <React.Fragment>
            <Grid item xs={12} sm={5} className="sm-30 mr-2">
              <TypographyComponent variant="h6">
                <b>{ReportsConstants.START_DATE}</b>
              </TypographyComponent>
              <DatePickerComponent
                name="startDate"
                value={dateFormatter(date.startDate) || ""}
                className={classes.textField}
                handleChange={(e) => changeDates(e)}
                inputVariant="outlined"
                margin="dense"
                size="small"
                fullWidth={true}
                disabled={false}
                validators={['required']}
                errorMessages={['Required']}
              />
            </Grid>
            <Grid item xs={12} sm={5} className="sm-30">
              <TypographyComponent variant="h6">
                <b>{ReportsConstants.END_DATE}</b>
              </TypographyComponent>
              <DatePickerComponent
                name="endDate"
                value={dateFormatter(date.endDate) || ""}
                className={classes.textField}
                handleChange={(e) => changeDates(e)}
                inputVariant="outlined"
                margin="dense"
                size="small"
                fullWidth={true}
                disabled={false}
                validators={['required']}
                errorMessages={['Required']}
              />
            </Grid>
          </React.Fragment>
        )}
        {date.type && date.type === "Pulse dates" && (
          <React.Fragment>
            <Grid className="sm-30" >
              <TypographyComponent variant="h6">
                {"Pulse"}
              </TypographyComponent>
              <AutoCompleteComponent
                name="pulseDate"
                suggestions={pulseDateArray || []}
                handleAutoCompolete={(e, n) => handleAutoCompleteChange({ value: e, name: n })}
                suggestionType="array"
                className={classes.textField}
                value={pulse_date}
                validators={["required"]}
                errorMessages={['Required']}
              ></AutoCompleteComponent>
            </Grid>
          </React.Fragment>
        )}
      </Grid>
    </React.Fragment>
  );
}

Dates.defaultProps = {
  classes: {},
};

Dates.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(NewReportStyles)(Dates);
