import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Grid,
  FormControlLabel,
  Checkbox,
  Paper,
  Box,
  Tooltip
} from "@mui/material";
import TypographyComponent from "../../../../../components/TypographyComponent/TypographyComponent";
import AutoCompleteComponent from "../../../../../components/AutoCompleteComponent/AutoCompleteComponent";
import ButtonComponent from '../../../../../components/ButtonComponent/ButtonComponent';
import SearchInputComponent from '../../../../../components/SearchInputCompoment/SearchInputComponent';
import { ReportsConstants } from "../../../../../config/constants";
import { reportAction, alertAction } from "../../../../../redux/actions";

const MatrixChartCustom = () => {
  const dispatch = useDispatch();
  const { customReportData, matrix } = useSelector((state) => state.report);

  // Local state
  const [sortField, setSortField] = useState(1);
  const [sortby, setSortby] = useState(ReportsConstants.MATRIX_SORT_OPTIONS.find((option) => option.value === sortField)?.sortBy || "");
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [selectedColumns, setSelectedColumns] = useState({});
  const [searchText, setSearchText] = useState("");

  // Fetch matrix data on component mount
  useEffect(() => {
    if (customReportData?.id) {
      dispatch(reportAction.getCustomMatrixData(customReportData));
    }
  }, [customReportData.surveys]);

  // Initialize selected questions and columns when matrix data is loaded
  useEffect(() => {
    if (matrix) {
      setSortField(1)
      setSortby(ReportsConstants.MATRIX_SORT_OPTIONS.find((option) => option.value === sortField)?.sortBy || "")
      const initialSelectedQuestions = Object.keys(matrix);
      setSelectedQuestions(initialSelectedQuestions);

      const initialSelectedColumns = initialSelectedQuestions.reduce((acc, questionId) => {
        acc[questionId] = matrix[questionId]?.finanlResponse?.groupArr || [];
        return acc;
      }, {});
      setSelectedColumns(initialSelectedColumns);
    }
  }, [matrix, customReportData]);

  // Derived state
  const questionTexts = Object.entries(matrix || {}).reduce((acc, [key, item]) => {
    acc[key] = item?.questionText || 'No question available';
    return acc;
  }, {});

  const filteredQuestions = Object.entries(questionTexts || {})
    .filter(([_, text]) => text.toLowerCase().includes(searchText?.toLowerCase()))
    .map(([key, text]) => ({ id: key, text }));

  // Helper functions
  const isQuestionSelected = (questionId) => selectedQuestions.includes(questionId);
  const isColumnSelected = (questionId, columnName) => selectedColumns[questionId]?.includes(columnName);
  const areAllFilteredQuestionsSelected = () =>
    filteredQuestions.length > 0 && filteredQuestions.every(q => selectedQuestions.includes(q.id));

  // Event handlers
  const handleQuestionToggle = (questionId) => {
    setSelectedQuestions(prev => {
      const newSelected = prev.includes(questionId)
        ? prev.filter(id => id !== questionId)
        : [...prev, questionId];

      // Initialize columns for newly selected question
      if (!prev.includes(questionId)) {
        setSelectedColumns(prevColumns => ({
          ...prevColumns,
          [questionId]: matrix[questionId]?.finanlResponse?.groupArr || []
        }));
      }

      return newSelected;
    });
  };

  const handleSelectAllQuestions = () => {
    const filteredIds = filteredQuestions.map(q => q.id);
    setSelectedQuestions(prev => {
      const allSelected = filteredIds.every(id => prev.includes(id));
      return allSelected
        ? prev.filter(id => !filteredIds.includes(id))
        : [...new Set([...prev, ...filteredIds])];
    });
  };

  const handleColumnToggle = (questionId, columnName) => {
    setSelectedColumns(prev => {
      const currentColumns = prev[questionId] || [];
      const newColumns = currentColumns.includes(columnName)
        ? currentColumns.filter(col => col !== columnName)
        : [...currentColumns, columnName];
      return { ...prev, [questionId]: newColumns };
    });
  };

  const handleSorting = (sortOption) => {
    setSortField(sortOption.value);
    setSortby(sortOption.sortBy)
  };

  const checkDuplicateConfigs = (MatrixPreferences) => {
    const isDuplicate = (existingConfigs, newPreferences) => {
      const deepEqual = (obj1, obj2) => {
        if (Object.keys(obj1).length !== Object.keys(obj2).length) return false;

        for (const key in obj1) {
          if (typeof obj1[key] === 'object' && obj1[key] !== null) {
            if (!deepEqual(obj1[key], obj2[key])) return false;
          } else if (obj1[key] !== obj2[key]) {
            return false;
          }
        }
        return true;
      };

      return Object.values(existingConfigs).some(config => deepEqual(config, newPreferences));
    };

    const existingMatrixConfigs = customReportData?.dashboardPreference?.gridChart || {};

    const isDuplicateMatrix = isDuplicate(existingMatrixConfigs, MatrixPreferences);

    if (isDuplicateMatrix) {
      dispatch(alertAction.error('A chart with identical configuration already exists'));
      return true; // Return true if duplicates are found
    }

    return false; // Return false if no duplicates are found
  };

  const handleAddChart = () => {
    if (!selectedQuestions?.length) {
      dispatch(alertAction.error(ReportsConstants.CUSTOM_QUESTION_INCLUDE_TEXT));
      return;
    }

    try {
      let chartNumber = 'Matrix';
      const updatedChartOrder = customReportData.chartOrder || [];

      // Generate unique chart number
      let count = 1;
      while (updatedChartOrder.includes(chartNumber)) {
        chartNumber = `Matrix${count++}`;
      }

      // Filter selected columns for selected questions only
      const filteredColumns = Object.entries(selectedColumns)
        .filter(([key]) => selectedQuestions.includes(key))
        .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
        
      // yAxisSort: {sortField: sortField, sortBy: sortby}
      const newChartPreferences = {
        yAxisSort: { sortField: sortField, sortBy: sortby },
        selectedQuestions: filteredColumns,
      };

      const hasDuplicates = checkDuplicateConfigs(newChartPreferences)
      if (hasDuplicates) {
        return;
      }

      const updatedReportData = {
        ...customReportData,
        chartOrder: [...updatedChartOrder, chartNumber],
        dashboardPreference: {
          ...customReportData?.dashboardPreference,
          gridChart: {
            ...customReportData?.dashboardPreference?.gridChart,
            [chartNumber]: newChartPreferences
          }
        }
      };

      dispatch(reportAction.updateCustomReport(updatedReportData));
    } catch (error) {
      console.error('Error in handleAddChart:', error);
    }
  };

  if (!matrix || Object.keys(matrix).length === 0) {
    return (
      <TypographyComponent variant="h6" className="txtBold">
        {ReportsConstants.NO_DATA_CHART}
      </TypographyComponent>
    );
  }

  return (
    <Grid display={'flex'} gap={'2rem'}>
      <Grid item flex={'1'} display={'inline-flex'} flexDirection={'column'} gap={'20px'}>
        {/* Question Selection Section */}
        <Grid item xs={12}>
          <TypographyComponent variant="h6" className="txtBold">
            {ReportsConstants.CUSTOM_QUESTION_INCLUDE_TEXT}
          </TypographyComponent>
          <Paper elevation={1}>
            <Box p={2}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <SearchInputComponent
                    id="MatrixSearch"
                    placeholder="Search"
                    value={searchText}
                    field="search"
                    showClearIcon={true}
                    fullWidth={true}
                    onInputChangeRequest={(_, searchKey) => setSearchText(searchKey)}
                    handleClearSearch={() => setSearchText("")}
                  />
                </Grid>

                <Grid item xs={12}>
                  {!searchText && (
                    <Grid container justifyContent="space-between" alignItems="center">
                      <Grid item>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={areAllFilteredQuestionsSelected()}
                              onChange={handleSelectAllQuestions}
                              color="primary"
                            />
                          }
                          label="Select all"
                        />
                      </Grid>
                      <Grid item>
                        <Box
                          component="span"
                          onClick={() => setSelectedQuestions([])}
                          sx={{
                            cursor: 'pointer',
                            color: 'primary.main',
                            textDecoration: 'underline'
                          }}
                        >
                          {ReportsConstants.CUSTOM_CLEAR_ALL}
                        </Box>
                      </Grid>
                    </Grid>
                  )}

                  <Box sx={{ maxHeight: '300px', overflowY: 'auto', mt: 1 }}>
                    <Grid container spacing={1}>
                      {filteredQuestions.map(({ id, text }) => (
                        <Grid item xs={12} key={id}>
                          <Tooltip title={text} arrow>
                            <FormControlLabel
                              sx={{ ml: 0 }}
                              control={
                                <Checkbox
                                  checked={isQuestionSelected(id)}
                                  onChange={() => handleQuestionToggle(id)}
                                  color="primary"
                                  sx={{ ml: 1 }}
                                />
                              }
                              label={text}
                            />
                          </Tooltip>
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Grid>

        {/* Sort By and Customize Columns Headers Section */}
        <Grid item xs={12}>
          <Grid container spacing={1}>
            {/* Sort By Section */}
            <Grid item xs display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
              <TypographyComponent className="txtBold" title={ReportsConstants.CUSTOM_SORT_BY} variant="h6" />
              <Box>
                <AutoCompleteComponent
                  name="gridSort"
                  suggestions={ReportsConstants.MATRIX_SORT_OPTIONS}
                  handleAutoCompolete={handleSorting}
                  suggestionType="gridSortOptions"
                  value={ReportsConstants.MATRIX_SORT_OPTIONS.find(item => item.value === sortField)}
                />
              </Box>
            </Grid>

            {/* Customize Section */}
            <Grid item xs={12}>
              <TypographyComponent variant="h6" className="txtBold">
                {ReportsConstants.CUSTOMIZE_COLUMN_TEXT}
              </TypographyComponent>
              <Box mt={1}>
                {selectedQuestions.map((questionId) => (
                  <Box key={questionId} mb={2}>
                    <TypographyComponent variant="h6" className="txtBold">
                      {matrix[questionId]?.questionText}
                    </TypographyComponent>
                    <Grid container spacing={0.5}>
                      {matrix[questionId]?.finanlResponse?.groupArr.map((option) => (
                        <Grid item xs={6} key={`${questionId}-${option}`}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={isColumnSelected(questionId, option)}
                                onChange={() => handleColumnToggle(questionId, option)}
                                color="primary"
                              />
                            }
                            label={option}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                ))}
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* Add Chart Button */}
      <Grid item display={'inline-flex'} flexDirection={'column'} flexBasis={'200px'} justifyContent={'flex-end'}>
        <Grid display={'flex'} flexDirection={'column'} gap={'10px'}>
          <ButtonComponent
            color="primary"
            pageClassName="buttonOrange fontSize14"
            title="+ Add chart to report"
            handleClick={handleAddChart}
          />
          {/* <TypographyComponent variant="h6">
            {ReportsConstants.CUSTOM_ADD_CHART_TEXT}
          </TypographyComponent> */}
        </Grid>
      </Grid>

      {/* Add Chart Button Section */}
      {/* <Grid item xs={12}>
        <ButtonComponent
          color="primary"
          pageClassName="buttonOrange"
          title="+ Add chart to report"
          handleClick={handleAddChart}
        />
        <TypographyComponent variant="h6"
        >
          {ReportsConstants.CUSTOM_ADD_CHART_TEXT}
        </TypographyComponent>
      </Grid> */}
    </Grid>
  );
};


export default MatrixChartCustom;