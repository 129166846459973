import React from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import withStyles from "@mui/styles/withStyles";
import { ValidatorForm } from "react-material-ui-form-validator";
import IconButton from "@mui/material/IconButton";
import Popover from "@mui/material/Popover";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Tooltip } from "@mui/material";

// Import Styles and Image
import { HeartIn } from "../../../assets/images/svgComponents/ImageComponent";

import ReportsStyles from "../ReportsStyles";

// Import Component
import TypographyComponent from "../../../components/TypographyComponent/TypographyComponent";
import TextFieldComponent from "../../../components/TextFieldComponent/TextFieldComponent";
import ButtonComponent from "../../../components/ButtonComponent/ButtonComponent";

// import constants
import { ReportsConstants, surveyTypes } from "../../../config/constants";

//Import Actions
import { reportAction, adminsAction } from "../../../redux/actions";

function InsightSaveReport(props) {
  // Define Dispatch
  const dispatch = useDispatch();
  const { classes, reportType } = props;

  const favReport = props?.favReport

  //get reducer data
  const { preference } = useSelector((state) => state.admin);
  const selectedItem = useSelector((state) => state.dashboard.topicSelected);
  const { questions } = useSelector((state) => state.dashboard.commentAnalysis);
  const totPreference = useSelector(
    (state) => state.dashboard.trendsOverTime.preference
  );

  const { engagementIndex, gridChart, hotspot, highlevelSummary } = useSelector(
    (state) => state.dashboard
  );
  const { type, word } = selectedItem;

  const dashboardOnly = {}

  //set state for report name and checkbox
  const [reportName, setReportName] = React.useState("");
  const [includeDashboad, setIncludeDashboad] = React.useState(false);

  //open close save popup
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setReportName("");
  };
  const handleClose = () => {
    setAnchorEl(null);
    setReportName("");
  };

  const hasME =
    preference &&
    preference.surveys &&
    preference.surveys.filter(
      (val) => val.type === surveyTypes.MULTI_RATER_FEEDBACK
    ).length > 0;
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  //save report
  const saveReport = () => {
    let gridLegend = {};
    if (gridChart.gridData && Object.keys(gridChart.gridData).length) {
      for (let key in gridChart.gridData) {
        gridLegend[key] = gridChart.gridData[key]["finanlResponse"]["keyArr"];
      }
    }
    let commentData = preference.commentAnalysis;
    if (type === "" || type === "topics" || type === "Topics") {
      commentData["type"] = "Topics";
      commentData["word"] = word;
    } else {
      commentData["type"] = "Words";
      commentData["word"] = "";
    }
    let checkQuesExists =
      questions && questions.length
        ? questions.filter((e) => e.baseQusId === commentData["baseQuesId"])
            .length > 0
        : false;
    if (!commentData["baseQuesId"] || (!checkQuesExists && questions.length)) {
      commentData["baseQuesId"] =
        questions[0] && questions[0]["baseQusId"]
          ? questions[0]["baseQusId"]
          : "";
    }

    // replace dot
    const updatedSortingKeys = {};
    Object.keys(hotspot.sorting || {}).map((k) => {
      updatedSortingKeys[k.replace(/\./g, "[dot]")] = hotspot.sorting[k];
    });

    let hlsFiltersApplied = highlevelSummary?.['filtersApplied'] || {};
    const isHlsFiltersApplied = hlsFiltersApplied && _.isObject(hlsFiltersApplied) && Object.keys(hlsFiltersApplied).length > 0;
  
    let hotspotFiltersApplied = hotspot?.['filtersApplied'] || {};
    const isHotspotFiltersApplied = hotspotFiltersApplied && _.isObject(hotspotFiltersApplied) && Object.keys(hotspotFiltersApplied).length > 0;
  
    let hlsFiltersAppliedType = Object.keys(hlsFiltersApplied)?.[0];
    hlsFiltersAppliedType = hlsFiltersAppliedType === 'keyDrivers' ? 'question' : hlsFiltersAppliedType;
  
    if (isHlsFiltersApplied && hlsFiltersAppliedType) {
      const hlsDataPresent = highlevelSummary?.['hlsData']?.[hlsFiltersAppliedType];
      const isHlsDataPresent = hlsDataPresent && _.isObject(hlsDataPresent) && Object.keys(hlsDataPresent).length > 0;
  
      hlsFiltersApplied = isHlsDataPresent ? (highlevelSummary?.['filtersApplied'] || {}) : {};
    }
  
    if (isHotspotFiltersApplied) {
      const isHotspotDataPresent = hotspot?.['data'] && _.isArray(hotspot?.['data']) && hotspot?.['data'].length > 0;
  
      hotspotFiltersApplied = isHotspotDataPresent ? (hotspot?.['filtersApplied'] || {}) : {};
    }


    const commentChartVisbility = {"1": "Comment Analysis"}

    const updatedData = {
      dashboardPreference: {
        historical: preference.historical,
        hls: {
          ...preference.hls,
          selectedSummaryWidgetColumnsDropdown: highlevelSummary.selectedSummaryWidgetColumnsDropdown,
          filtersApplied: hlsFiltersApplied,
        },
        hotspot: {
          ...preference.hotspot,
          sorting: updatedSortingKeys,
          filtersApplied: hotspotFiltersApplied,
        },
        EI: { ...preference.EI, keyArr: engagementIndex.keyArr },
        yesno: preference.yesno,
        dropdown: preference.dropdown,
        commentAnalysis: commentData,
        grid: gridLegend,
        trendsOverTime:  preference.surveys.length === totPreference.length ? [] : totPreference,
      },

      charts: reportType === "Dashboard Report Comment" ? commentChartVisbility : favReport ? dashboardOnly : preference?.reportVisibility,
      chartOrder: reportType === "Dashboard Report Comment" ? ["1"] : preference.chartOrder,

      surveys: preference.surveys,
      demographics: preference.demographics,
      date: preference.date,
      name: reportName,
      includeDashboard: reportType === "Dashboard Report Comment" ? false : (favReport ? favReport : includeDashboad),
      reportType:
        reportType === "Dashboard Report Comment"
          ? "Dashboard Report"
          : reportType,
      hideDashWidgets: reportType === "Dashboard Report Comment" ? null : preference?.hideDashWidgets,
      gridChart: {
        ...preference?.gridChart
      },
    };

    const commentUpdatedData = {
      dashboardPreference: {
        historical: preference.historical,
        hls: preference.hls,
        hotspot: { ...preference.hotspot, sorting: updatedSortingKeys },
        EI: { ...preference.EI, keyArr: engagementIndex.keyArr },
        yesno: preference.yesno,
        dropdown: preference.dropdown,
        commentAnalysis: commentData,
      },
      commentQuestions: preference.commentAnalysis.baseQuesId,
      surveys: preference.surveys,
      chartOrder: preference.chartOrder,
      surveys: preference.surveys,
      date: preference.date,
      name: reportName,
      includeDashboard: false,
      reportType: "Comment Report",
      hideDashWidgets: preference?.hideDashWidgets,
    };

    dispatch(
      reportAction.saveOrUpdateReport(
        reportType === ReportsConstants.COMMENT_REPORT
          ? commentUpdatedData
          : updatedData
      )
    );
    handleClose();
  };

  return (
    <React.Fragment>
      {!hasME && (
        <Tooltip title={"Save"} arrow>
          <Grid
            className={`${hasME ? "cursor-not_allowed" : ""} ${
              classes.IconSaveContainer
            }`}
          >
            <IconButton
              className={classes.IconSaveContainer}
              onClick={handleClick}
              aria-describedby={id}
              disabled={hasME}
              size="large"
            >
              <Grid className={classes.IconContainer}>{HeartIn()}</Grid>
            </IconButton>
          </Grid>
        </Tooltip>
      )}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Grid className="p-2">
          <Grid item className="pb5">
            <TypographyComponent variant="h5" className="txtBold">
              {ReportsConstants.SAVEREPORT}
            </TypographyComponent>
          </Grid>

          <Grid item className="mb-2">
            <TypographyComponent variant="body1">
              {ReportsConstants.SAVEREPORT_TEXT}
            </TypographyComponent>
          </Grid>

          <Grid item className="mb-2">
            <TypographyComponent variant="h6">
              {ReportsConstants.ENTERREPORT_NAME}
            </TypographyComponent>
            <ValidatorForm
              name="reportName"
              useref="form"
              autoComplete="off"
              onSubmit={saveReport}
              onError={(errors) => console.log(errors)}
            >
              <TextFieldComponent
                id={"reportName"}
                name={"reportName"}
                value={reportName}
                size={"small"}
                margin={"dense"}
                validators={["required", "isFileName"]}
                handleChange={(e) => {
                  setReportName(e["value"]);
                }}
              ></TextFieldComponent>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                className="mt-2"
              >
                {reportType !== "Dashboard Report Comment" && !favReport && (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={includeDashboad}
                        onChange={(e) => {
                          setIncludeDashboad(!includeDashboad);
                        }}
                        name="includeInDashboard"
                        color="primary"
                      />
                    }
                    label={ReportsConstants.INCLUDE_DASHBOARD}
                  />
                )}
                <Grid>
                  <ButtonComponent
                    color="primary"
                    size="small"
                    title={ReportsConstants.SAVE}
                    pageClassName="mr-1"
                    type={"submit"}
                  ></ButtonComponent>
                  <ButtonComponent
                    color="secondary"
                    size="small"
                    title={ReportsConstants.CANCEL}
                    handleClick={handleClose}
                  ></ButtonComponent>
                </Grid>
              </Grid>
            </ValidatorForm>
          </Grid>
        </Grid>
      </Popover>
    </React.Fragment>
  );
}
// default props
InsightSaveReport.defaultProps = {
  classes: {},
  reportType: "Dashboard Report",
};

// prop types
InsightSaveReport.propTypes = {
  classes: PropTypes.object,
  reportType: PropTypes.string,
};
export default withStyles(ReportsStyles)(InsightSaveReport);
