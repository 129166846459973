import { reportConstant } from "../constants";

const tableDatas = (sortName) => {
  return {
    data: [],
    reportName: [],
    page: {
      fieldFilters: {
        headerCols: [],
        fieldCols: [sortName],
        searchCols: []
      }
    }
  };
};

const reportDatas = () => {
  const data = {
    surveys: [],
    date: {
      type: "",
      startDate: "",
      endDate: "",
      pulseDate: "",
    },
    demographics: {},
    reportType: "",
    selectedDemographic: "",
    selectType: "",
    chosensurveytype: "",
    name: "",
    emailIds: [],
    frequency: "",
    isPulse: false,
    chartOrder: [],
    dashboardPreference: {
      hls: {
        type: "category",
        rg: "",
        sortBy: "asc",
        sort: "",
        selectedSummaryWidgetColumnsDropdown: []
      },
      yesno: {
        rg: "no_breakout"
      },
      gridChart: {
        yAxisSort: {
          sortField: 1,
          sortBy: "desc"
        },
      }

    },
    hideDashWidgets: {
      participation: false,
      overallFavorability: false,
      favorableByCategory: false,
      overalleNPS: false,
      engagedEmployee: false,
      keydriver: false,
      suggestedAreaAction: false,
      engagementBenchMark: false,
      likelyToStay: false,
      LikelyToReturn: false,
      turnOver: false,
      projectedTurnover: false,
      highLowRatedItems: false,
    },
    download_type: 1,
    commentAnalysis: {
      type: "Topics",
      word: "",
      baseQuesId: ""
    }
  }
  return JSON.parse(JSON.stringify(data))
}
const initReportDatas = () => {
  const data = {
    surveys: [],
    date: {
      type: "",
      startDate: "",
      endDate: "",
      pulseDate: "",
    },
    demographics: {},
    reportType: "",
    selectedDemographic: "",
    chosensurveytype: "",
    selectType: "",
    name: "",
    emailIds: [],
    frequency: "",
    isPulse: false,
    subject_name: "",
    selectedSubject: null, // for Individual report
    chartOrder: [],
    download_type: 1,
    hideDashWidgets: {
      participation: false,
      overallFavorability: false,
      favorableByCategory: false,
      overalleNPS: false,
      engagedEmployee: false,
      keydriver: false,
      suggestedAreaAction: false,
      engagementBenchMark: false,
      likelyToStay: false,
      LikelyToReturn: false,
      turnOver: false,
      projectedTurnover: false,
      highLowRatedItems: false,
    }
  }
  return JSON.parse(JSON.stringify(data))
}

const customReportDatas = () => {
  const data = {
    id: "",
    surveys: [],
    date: {
      type: "",
      startDate: "",
      endDate: "",
      pulseDate: "",
    },
    demographics: {},
    reportType: "",
    selectedDemographic: "",
    selectType: "",
    chosensurveytype: "",
    name: "",
    emailIds: [],
    frequency: "",
    isPulse: false,
    chartOrder: [],
    dashboardPreference: {
      historical: {},
      hls: {},
      hotspot: {},
      EI: {},
      yesno: {},
      commentAnalysis: {
        type: "Topics",
        word: "",
        baseQuesId: ""
      },
      tot: {},
    },
    includeDashboard: false,
    hideDashWidgets: {
      participation: true,
      overallFavorability: true,
      favorableByCategory: true,
      overalleNPS: true,
      engagedEmployee: true,
      keydriver: true,
      suggestedAreaAction: true,
      engagementBenchMark: true,
      likelyToStay: true,
      LikelyToReturn: true,
      turnOver: true,
      projectedTurnover: true,
      highLowRatedItems: true,
    },
    commentQuestions: [],
    downloadType: "",
    is_sentiment: false,
    is_verbatim: false,
    keydriverdata: [],
    charts : []

  }
  return JSON.parse(JSON.stringify(data))
}
const initCustomReportDatas = () => {
  const data = {
    id: "",
    surveys: [],
    date: {
      type: "",
      startDate: "",
      endDate: "",
      pulseDate: "",
    },
    demographics: {},
    reportType: "",
    selectedDemographic: "",
    chosensurveytype: "",
    selectType: "",
    name: "",
    emailIds: [],
    frequency: "",
    isPulse: false,
    subject_name: "",
    selectedSubject: null, // for Individual report
    chartOrder: [],
    dashboardPreference: {
      // historical: {},
      hls: {},
      hotspot: {},
      EI: {},
      yesno: {},
      commentAnalysis: {
        type: "Topics",
        word: "",
        baseQuesId: ""
      },
      tot: {},
    },
    downloadType: "",
    includeDashboard: false,
    hideDashWidgets: {
      participation: true,
      overallFavorability: true,
      favorableByCategory: true,
      overalleNPS: true,
      engagedEmployee: true,
      keydriver: true,
      suggestedAreaAction: true,
      engagementBenchMark: true,
      likelyToStay: true,
      LikelyToReturn: true,
      turnOver: true,
      projectedTurnover: true,
      highLowRatedItems: true,
    },
    commentQuestions: [],
    is_sentiment: false,
    is_verbatim: false,
    keydriverdata: [],
    charts : []
  }
  return JSON.parse(JSON.stringify(data))
}
// Set Initial State
const initialState = {
  allSurveys: [],
  allDemographics: {},
  allDemographicsItems: {},
  rawData: {},
  reportData: reportDatas(),
  initReportData: initReportDatas(),
  customReportData: customReportDatas(),
  initCustomReportData: initCustomReportDatas(),
  customChartData: [],
  customDashboardData: [],
  commentQuestions: [],
  commentReport: {
    commentQuestions: []
  },
  savedReports: { ...tableDatas("name") },
  benchmarkIndustryList: [],
  subjects: [], // for ME Survey individual report
  loaders: {
    reportloader: false,
    Createreport: false,
  },
  loading_r: {},
  yesNo: {},
  dropdown: [],
  matrix: {},
  hlsDemographic: {},
};

export default function (state = initialState, action) {

  switch (action.type) {

    //get reports
    case reportConstant.GET_SAVED_REPORT_REQUEST:
      return {
        ...state,
        loaders: { ...state.loaders, Createreport: true }
      };
    case reportConstant.GET_SAVED_REPORT_SUCCESS:
      return {
        ...state,
        savedReports: {
          data: action.data.data,
          page: action.data.page,
          reportName: action.data.reportName
        },
        loaders: { ...state.loaders, Createreport: false }
      };
    case reportConstant.GET_SAVED_REPORT_FAILURE:
      return {
        ...state,
        savedReports: initialState.savedReports,
        loaders: { ...state.loaders, Createreport: false }

      };

    //get Survy  List
    case reportConstant.GET_SURVEYS_REQUEST:
      return {
        ...state,
        loaders: { ...state.loaders, reportloader: true }

      };
    case reportConstant.GET_SURVEYS_SUCCESS:
      return {
        ...state,
        allSurveys: action.data.data,
        loaders: { ...state.loaders, reportloader: false }
      };
    case reportConstant.GET_SURVEYS_FAILURE:
      return {
        ...state,
        allSurveys: initialState.allSurveys,
        loaders: { ...state.loaders, reportloader: false }
      };

    case reportConstant.GET_KEY_DRIVER_SUCCESS:
      let keyDriverData = action.data?.data?.splice(0, 10) || []
      return {
        ...state,
        customReportData: { ...state.customReportData, keyDriverData: keyDriverData }
      };

    // Get Demographic 
    case reportConstant.GET_DEMOGRAPHICS_REQUEST:
      return {
        ...state,
        loaders: { ...state.loaders, reportloader: true }
      };
    case reportConstant.GET_DEMOGRAPHICS_SUCCESS:
      return {
        ...state,
        allDemographics: action.data.data || {},
        loaders: { ...state.loaders, reportloader: false }
      };
    case reportConstant.GET_DEMOGRAPHICS_FAILURE:
      return {
        ...state,
        allDemographics: initialState.demographics,
        loaders: { ...state.loaders, reportloader: false }
      };

    // Get Demographic items 
    case reportConstant.GET_DEMOGRAPHICS_BY_NAME_REQUEST:
      return {
        ...state,
        loaders: { ...state.loaders, reportloader: true }
      };
    case reportConstant.GET_DEMOGRAPHICS_BY_NAME_SUCCESS:
      return {
        ...state,
        allDemographicsItems: { ...state.allDemographicsItems, ...action.data.data },
        loaders: { ...state.loaders, reportloader: false }
      };
    case reportConstant.GET_DEMOGRAPHICS_BY_NAME_FAILURE:
      return {
        ...state,
        allDemographicsItems: initialState.allDemographicsItems,
        loaders: { ...state.loaders, reportloader: false }
      };

    // Get Demographic items 
    case reportConstant.GET_COMMENT_QUESTIONS_REQUEST:
      return {
        ...state,
      };
    case reportConstant.GET_COMMENT_QUESTIONS_SUCCESS:
      return {
        ...state,
        commentQuestions: action.data.data,
      };
    case reportConstant.GET_COMMENT_QUESTIONS_FAILURE:
      return {
        ...state,
        commentQuestions: initialState.commentQuestions
      };


    //Save or update Report 
    case reportConstant.SAVE_REPORT_REQUEST:
      return {
        ...state,
      };
    case reportConstant.SAVE_REPORT_SUCCESS:
      return {
        ...state,
      };
    case reportConstant.SAVE_REPORT_FAILURE:
      return {
        ...state,
      };


    //Save or update Report 
    case reportConstant.SAVE_CUSTOM_REPORT_REQUEST:
      return {
        ...state,
      };
    case reportConstant.SAVE_CUSTOM_REPORT_SUCCESS:
      return {
        ...state, customReportData: { ...state.customReportData, id: action.data.data._id }
      };
    case reportConstant.SAVE_CUSTOM_REPORT_FAILURE:
      return {
        ...state,
      };

    //Save or update Report 
    case reportConstant.UPDATE_CUSTOM_REPORT_REQUEST:
      return {
        ...state,
      };
    case reportConstant.UPDATE_CUSTOM_REPORT_SUCCESS:
      return {
        ...state,
      };
    case reportConstant.UPDATE_CUSTOM_REPORT_FAILURE:
      return {
        ...state,
      };

    //Save or update Report 
    case reportConstant.GET_CUSTOM_DASHBOARD_REQUEST:
      return {
        ...state
      };
    case reportConstant.GET_CUSTOM_DASHBOARD_SUCCESS:
      return {
        ...state, customDashboardData: action.data.data
      };
    case reportConstant.GET_CUSTOM_DASHBOARD_FAILURE:
      return {
        ...state,
      };
    //Save or update Report 
    case reportConstant.GET_CUSTOM_CHART_REQUEST:
      return {
        ...state
      };
    case reportConstant.GET_CUSTOM_CHART_SUCCESS:
      return {
        ...state, customChartData: action.data.data
      };
    case reportConstant.GET_CUSTOM_CHART_FAILURE:
      return {
        ...state,
      };

    //Save or update Report 
    case reportConstant.GET_CUSTOM_YESNO_CHART_REQUEST:
      return {
        ...state
      };
    case reportConstant.GET_CUSTOM_YESNO_CHART_SUCCESS:
      let yesNoData = action.data.data && Object.keys(action.data.data).length ? action.data.data : initialState.yesNo
      return {
        ...state,
        yesNo: { ...yesNoData },

      };
    case reportConstant.GET_CUSTOM_YESNO_CHART_FAILURE:
      return {
        ...state,
      };
    //Save or update Report 
    case reportConstant.GET_CUSTOM_DROPDOWN_CHART_REQUEST:
      return {
        ...state
      };
    case reportConstant.GET_CUSTOM_DROPDOWN_CHART_SUCCESS:
      let dropdownData = action.data.data && (action.data.data).length > 0 ? action.data.data : initialState.dropdown
      return {
        ...state,
        dropdown: [...dropdownData],

      };
    case reportConstant.GET_CUSTOM_DROPDOWN_CHART_FAILURE:
      return {
        ...state,
      };
    //Save or update Report 
    case reportConstant.GET_CUSTOM_MATRIX_CHART_REQUEST:
      return {
        ...state
      };
    case reportConstant.GET_CUSTOM_MATRIX_CHART_SUCCESS:
      let matrixData = action.data.data && Object.keys(action.data.data).length > 0 ? action.data.data : initialState.matrix
      return {
        ...state,
        matrix: { ...matrixData },

      };
    case reportConstant.GET_CUSTOM_MATRIX_CHART_FAILURE:
      return {
        ...state,
      };
    //Save or update Report 
    case reportConstant.GET_CUSTOM_DEMOGRAPHIC_REQUEST:
      return {
        ...state
      };
    case reportConstant.GET_CUSTOM_DEMOGRAPHIC_SUCCESS:
      let demographicData = action.data.data && Object.keys(action.data.data).length ? action.data.data : initialState.hlsDemographic
      return {
        ...state,
        hlsDemographic: { ...demographicData },

      };
    case reportConstant.GET_CUSTOM_DEMOGRAPHIC_FAILURE:
      return {
        ...state,
      };
    //update report data 
    case reportConstant.UPDATE_CUSTOM_REPORT_DATA:
      return {
        ...state,
        customReportData: { ...initialState.customReportData, ...action.data }
      };

    //reset report data 
    case reportConstant.RESET_CUSTOM_REPORT_DATA:
      return {
        ...state,
        customReportData: { ...initialState.initCustomReportData }
        // commentReport: { commentQuestions: [] },

      };


    //Remove Report 
    case reportConstant.REMOVE_REPORT_REQUEST:
      return {
        ...state,
      };
    case reportConstant.REMOVE_REPORT_SUCCESS:
      return {
        ...state,
      };
    case reportConstant.REMOVE_REPORT_FAILURE:
      return {
        ...state,
      };


    //get selected report data Report 
    case reportConstant.GET_REPORT_REQUEST:
      return {
        ...state,
      };
    case reportConstant.GET_REPORT_SUCCESS:
      const data = action.data.data;
      let commentQuestions = []
      if (data.reportType === "Comment Report") {
        commentQuestions = data.commentQuestions ? data.commentQuestions : []
      }
      return {
        ...state,
        reportData: { ...initialState.reportData, ...action.data.data },
        commentReport: { ...initialState.commentReport, commentQuestions: commentQuestions }
      };
    case reportConstant.GET_REPORT_FAILURE:
      return {
        ...state,
        reportData: { ...initialState.reportData },
        commentReport: { ...initialState.commentReport }
      };

    //get selected report data Report 
    case reportConstant.GET_CUSTOM_REPORT_REQUEST:
      return {
        ...state,
      };
    case reportConstant.GET_CUSTOM_REPORT_SUCCESS:
      const customdata = action.data.data;
      return {
        ...state,
        customReportData: { ...initialState.customReportData, ...action.data.data },

      };
    case reportConstant.GET_CUSTOM_REPORT_FAILURE:
      return {
        ...state,
        customReportData: { ...initialState.customReportData },

      };


    //update report data 
    case reportConstant.UPDATE_REPORT_DATA:
      return {
        ...state,
        reportData: { ...initialState.reportData, ...action.data }
      };

    //reset report data 
    case reportConstant.RESET_REPORT_DATA:
      return {
        ...state,
        reportData: { ...initialState.initReportData, emailIds: [] },
        commentReport: { commentQuestions: [] },

      };

    //update commnet report data 
    case reportConstant.UPDATE_COMMENT_REPORT_DATA:
      return {
        ...state,
        commentReport: { ...initialState.commentReport, ...action.data }
      };

    // Raw Data
    case reportConstant.GET_RAW_DATA_REQUEST,
      reportConstant.GET_RAW_DATA_FAILURE:
      return {
        ...state,
        rawData: initialState.getOne
      };
    case reportConstant.GET_RAW_DATA_SUCCESS:
      return {
        ...state,
        rawData: action.data.data
      };

    // Get Benchmark Industry List 
    case reportConstant.GET_BENCHMARK_INDUSTRY_LIST_REQUEST:
      return {
        ...state,
        loading_r: {
          ...state.loading_r,
          'industry': true
        }
      };
    case reportConstant.GET_BENCHMARK_INDUSTRY_LIST_SUCCESS:
      return {
        ...state,
        benchmarkIndustryList: action.data.data,
        loading_r: {
          ...state.loading_r,
          'industry': false
        }
      };
    case reportConstant.GET_BENCHMARK_INDUSTRY_LIST_FAILURE:
      return {
        ...state,
        benchmarkIndustryList: initialState.benchmarkIndustryList,
        loading_r: {
          ...state.loading_r,
          'industry': false
        }
      };
    case reportConstant.REPORT_LOADING_TRUE:
      return {
        ...state,
        loaders: { ...state.loaders, reportloader: true }
      };
    case reportConstant.REPORT_LOADING_FALSE:
      return {
        ...state,
        loaders: { ...state.loaders, reportloader: false }
      };
    // Get Subjects list for ME IR 
    case reportConstant.GET_IR_ME_SUBJECTS_LIST_REQUEST:
      return {
        ...state
      };
    case reportConstant.GET_IR_ME_SUBJECTS_LIST_SUCCESS:
      return {
        ...state,
        subjects: action.data.data || []
      };
    case reportConstant.GET_IR_ME_SUBJECTS_LIST_FAILURE:
      return {
        ...state,
        subjects: []
      };
    //retturn default state
    default:
      return state;
  }
}
