import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import { IconButton } from "@mui/material";
import { Tooltip } from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import { ValidatorForm } from "react-material-ui-form-validator";
import Button from "@mui/material/Button";

// Import Styles and Image
import { DashboardClose, Expand, Download } from "../../../../assets/images/svgComponents/ImageComponent";
import ReportsStyles from "../../ReportsStyles";

// Import Actions
import { updateStateReducerAction, excelAction } from "../../../../redux/actions";

// Import Component
import TypographyComponent from "../../../../components/TypographyComponent/TypographyComponent";
import DialogComponent from "../../../../components/DialogComponent/DialogComponent";
import DropdownTable from "./DropdownTable";
import Autocomplete from '@mui/material/Autocomplete';
import { TextField } from "@mui/material";

// Import Constants
import { ReportsConstants, excelConstants } from "../../../../config/constants";

// Import Reducer Constant
import { dashboardConstant } from '../../../../redux/constants';


function Dropdown(props) {

  // Define Dispatch
  const dispatch = useDispatch();

  // Get Props
  const { classes, handleClose, DragHandle, isUpdate, updateReportPreference, preference, stopChartReload } = props;

  // Set Statue
  const [selectedOption, setSelectedOption] = useState(preference.dropdown.sortField);
  // Get Reducer Values
  let { dropdown, loading, showPopup } = useSelector((state) => state.dashboard.dropDown);
  const [dropdownData, setDropdownData] = useState(dropdown);


  const handleSorting = (e, val) => {
    setSelectedOption(val?.value)
    if (dropdownData?.length > 0) {
      if (val?.value == "2") {
        (dropdown || []).map((item) => item?.response?.sort((a, b) => (b?.selection > a?.selection) ? 1 : ((a?.selection > b?.selection) ? -1 : 0)))
        setDropdownData(dropdown)
      } else if (val?.value == "1") {
        (dropdown || []).map((item) => item?.response?.sort((a, b) => (a?.selection > b?.selection) ? 1 : ((b?.selection > a?.selection) ? -1 : 0)))
        setDropdownData(dropdown)
      } else if (val?.value == "6") {
        (dropdown || []).map((item) => item?.response?.sort((a, b) => a?.percentage - b?.percentage))
        setDropdownData(dropdown)
      } else {
        (dropdown || []).map((item) => item?.response?.sort((a, b) => b?.percentage - a?.percentage))
        setDropdownData(dropdown)
      }
    }
    const data = {
      ...preference,
      dropdown: {
        ...preference?.dropdown,
        sortField: val?.value
      },
    };
    // Update Preferences
    updateReportPreference(data)


  }

  // get display name for sortby options
  const getSelectedSortOption = (options, value) => {
    let sortOptionSelected = options?.filter((item) => item?.value === value)[0] || ""
    return sortOptionSelected
  }


  /**
 * Open Popup Event
 */
  const openPopup = () => {
    dispatch(updateStateReducerAction.update(dashboardConstant.SHOW_DROPDOWN_POPUP, !showPopup))
  };

  /**
   * Close Popup Event
   */
  const onClose = () => {
    dispatch(updateStateReducerAction.update(dashboardConstant.SHOW_DROPDOWN_POPUP, !showPopup))
  };

  //excel Download Function
  const excelDownload = () => {
    const excelData = {
      data: dropdownData,
      excelType: excelConstants.DROPDOWN_EXCEL,

    };
    dispatch(excelAction.download(excelData, true));
  };


  return (

    <Grid item xs={12} className={"mb-14"}>
      <Grid item className={classes.insightTile}>
        <Grid container alignItems="center" className="mb-1">
          {
            !loading && <Grid item> <DragHandle /> </Grid>
          }

          <Grid item>
            <TypographyComponent variant="h4" className="txtBold fontSize16 ml-1">
            {ReportsConstants.DROPDOWN}
            </TypographyComponent>
          </Grid>

          <Grid item className={classes.viewActionButton}>
            {
              !loading && <Grid container>
                {dropdownData && dropdownData?.length > 0 && (<Grid className={classes.IconContainer}>
                  <Tooltip title={"Excel Download"} arrow>
                    <IconButton
                      className={classes.IconContainer}
                      onClick={() => {
                        excelDownload();
                      }}
                      size="large"
                    >
                      <Grid className={classes.IconContainer}>
                        {Download()}
                      </Grid>
                    </IconButton>
                  </Tooltip>
                </Grid>)}
                <Grid className={classes.IconContainer}>
                  <Tooltip title={"Expand"} arrow >
                    <IconButton onClick={() => { openPopup(); }} size="large" className={classes.IconContainer}>
                      {Expand()}
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid className={classes.IconContainer}>
                  <Tooltip title={"Close"} arrow >
                    <IconButton onClick={() => handleClose('8')} size="large" className={classes.IconContainer}>
                      {DashboardClose()}
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            }
          </Grid>
        </Grid>



        <Grid item md={5}>
          <Grid container>
            <Grid md={2} className={"pt-1"}>
              <TypographyComponent
                className={"txtBold txtcenter"}
                title={ReportsConstants.SORT_BY}
                variant={"h6"}
              ></TypographyComponent>
            </Grid>
            <Grid md={7} className={"pr-1"}>
              <Autocomplete
                id={"dropdown"}
                disableClearable
                autoHighlight
                blurOnSelect
                options={ReportsConstants.DROPDOWN_SORT_OPTIONS}
                value={getSelectedSortOption(ReportsConstants.DROPDOWN_SORT_OPTIONS, selectedOption)}
                className={`input-value-editor autocomplete heightone  ${classes.boxMaxWidth}`}
                getOptionLabel={(option) => option.name || ""}
                onChange={(e, val) => { handleSorting(e, val) }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant={"outlined"}
                    className={`${"height28 conversionAutoComplete boderRadius4"}`}
                    placeholder="Select Field"
                  />
                )}
              />
            </Grid>
          </Grid>

        </Grid>


        <Grid container>
          <Grid item sm={12}>

            <DropdownTable data={dropdownData} loading={loading} popup={false} />
          </Grid>
        </Grid>

      </Grid>
      {showPopup && (
        <DialogComponent open={showPopup} fullScreen={true}>
          <Grid container spacing={4} className="p15">
            <Grid item xs={6}>
              <TypographyComponent variant="h4" className="txtBold fontSize16  ml-1">
              {ReportsConstants.DROPDOWN}
              </TypographyComponent>
            </Grid>
            <Grid item xs={6} className={classes.dialog_close}>
              <Button variant="text" size={"small"} onClick={onClose}>
                {DashboardClose()}
                <TypographyComponent variant="h5" className="pl5">
                  {"Close"}
                </TypographyComponent>
              </Button>
            </Grid>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Grid container>
                  <Grid md={1} className={"pt-1"}>
                    <TypographyComponent
                      className={"txtBold txtcenter"}
                      title={ReportsConstants.SORT_BY}
                      variant={"h6"}
                    ></TypographyComponent>
                  </Grid>
                  <Grid md={2}>
                    <Autocomplete
                      id={"dropdown"}
                      disableClearable
                      autoHighlight
                      blurOnSelect
                      options={ReportsConstants.DROPDOWN_SORT_OPTIONS}
                      value={getSelectedSortOption(ReportsConstants.DROPDOWN_SORT_OPTIONS, selectedOption)}
                      className={`input-value-editor autocomplete heightone  ${classes.boxMaxWidth}`}
                      getOptionLabel={(option) => option.name || ""}
                      onChange={(e, val) => { handleSorting(e, val) }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant={"outlined"}
                          className={`${"height28 conversionAutoComplete boderRadius4"}`}
                          placeholder="Select Field"
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid container className={"ml-3"}>


              <Grid container>
                <Grid item sm={12}>

                  <DropdownTable data={dropdownData} popup={true} />
                </Grid>
              </Grid>
            </Grid>

          </Grid>
        </DialogComponent>
      )}
    </Grid>




  );
}
// default props
Dropdown.defaultProps = {
  classes: {},
  isUpdate: false,
  handleClose: () => { },
  preference: {},
  updateReportPreference: () => { },
  stopChartReload: () => { }
};

// prop types
Dropdown.propTypes = {
  classes: PropTypes.object,
  isUpdate: PropTypes.bool,
  handleClose: PropTypes.func,
  preference: PropTypes.object,
  updateReportPreference: PropTypes.func,
  stopChartReload: PropTypes.func
};

export default withStyles(ReportsStyles)(Dropdown);