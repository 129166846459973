import * as apihelper from "../../helpers/apiHelper";

/**
 * Export All dashboard Service Functions
 */
export const reportService = {
  getSurveys,
  getDemographics,
  demographicsByName,
  getCommentQuestions,
  saveOrUpdateReport,
  saveCustomReport,
  editCustomReport,
  getCustomReportDashboard,
  getCustomReportChart,
  getCustomYesnoData,
  getCustomDropdownData,
  getCustomMatrixData,
  getCustomDemographicData,
  copy,
  getSavedReports,
  getReportData,
  getCustomReportData,
  remove,
  getBenchmarkIndustryList,
  getKeyDriverData,
  postKeyDriverData
};

/**
 * GET RESPONSE SURVEYS
 */
async function getSurveys(search) {
  const response = await apihelper.getRequest('filter/getSurvey/' + search);
  return response;
}

/**
 * GET Demographics
 */
async function getDemographics(filterData) {
  const response = await apihelper.postRequest("filter/demographic", filterData);
  return response;
}

/**
 * Get Demographics by name
 */
async function demographicsByName(filterData) {
  const response = await apihelper.postRequest("filter/demographic/name", filterData);
  return response;
}

/**
 * Get Comment Questions
 */
async function getCommentQuestions(filterData) {
  const response = await apihelper.postRequest("report/getCommentQuestions", filterData);
  return response;
}

/**
 * SAve or update report
 */
async function saveOrUpdateReport(filterData) {
  const response = await apihelper.postRequest("report/saveReport", filterData);
  return response;
}

/**
 * Copy a survey
 * @param id
 */
async function copy(data) {
  const response = await apihelper.postRequest("report/copy", data);
  return response;
}

/**
 * get report data
 */
async function getReportData(id) {
  const response = await apihelper.postRequest("report/getReport", id);
  return response;
}

/**
 * get report data
 */
async function remove(id) {
  const response = await apihelper.deleteRequest("report", id);
  return response;
}

/**
 * Get Saved Reports
 */
async function getSavedReports(skip, limit, sortBy, sort, sFields, sValues) {
  const response = await apihelper.postRequest(`report/getSavedReports/search?skip=${skip}&limit=${limit}&sortBy=${sortBy}&sort=${sort}`, { sFields, sValues });
  return response;
}

/**
 * Get Benchmark Indsutry List
 */
async function getBenchmarkIndustryList() {
  const response = await apihelper.getRequest("report/getBenchmarkIndustryList");
  return response;
}


/**
 * SAve or update custom report
 */
async function saveCustomReport(filterData) {
  const response = await apihelper.postRequest("report/saveCustomReport", filterData);
  return response;
}

/**
 * SAve or update custom report
 */
async function editCustomReport(filterData) {
  const response = await apihelper.putRequest("report/updateCustomReport", filterData);
  return response;
}

/**
 * get report data
 */
async function getCustomReportData(id) {
  const response = await apihelper.postRequest("report/getCustomReport", id);
  return response;
}

/**
 * get report data
 */
async function getCustomReportDashboard(data) {
  const response = await apihelper.postRequest("report/getCustomDashboardData", {filters:data});
  return response;
}



/**
 * get report data
 */
async function getCustomReportChart(data) {
  const response = await apihelper.postRequest("report/getCustomReportChartData", {filters:data});
  return response;
}

/**
 * get report data
 */
async function getCustomYesnoData(data) {
  const response = await apihelper.postRequest("report/getFilterYesnodata", {filters:data});
  return response;
}

/**
 * get report data
 */
async function getCustomDropdownData(data) {
  const response = await apihelper.postRequest("report/getFilterDropdowndata", {filters:data});
  return response;
}

/**
 * get report data
 */
async function getCustomMatrixData(data) {
  const response = await apihelper.postRequest("report/getFilterMatrixdata", {filters:data});
  return response;
}

/**
 * get report data
 */
async function getCustomDemographicData(data) {
  const response = await apihelper.postRequest("report/getFilterSummarydata", {filters:data});
  return response;
}

/**
 * Get Key Driver Data
 */
 async function getKeyDriverData() {
  const response = await apihelper.getRequest('api/dashboard/key-driver-analysis',false,'pyserver_url');
  return response;
}


async function postKeyDriverData(data) {
  const response = await apihelper.postRequest('postKeyDriverData', {filters:data});
  return response;
}