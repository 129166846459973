import * as apihelper from "../../helpers/apiHelper";

/**
 * Export All Profile Service Functions
 */
export const rawdataAutomationService = {
    getSurveys,
    getDemographics,
    updateFolderName,
    createandupdate,
    search,
    getOne,
    remove
};

/**
 * GET RESPONSE SURVEYS
 */
async function getSurveys(search) {
    const response = await apihelper.getRequest('filter/getSurvey/' + search);
    return response;
  }

async function getDemographics(filterData) {
const response = await apihelper.postRequest("filter/demographic", filterData);
return response;
}

async function updateFolderName(data) {
  let payload = {
    rawdata_folder : data
  }
  const response = await apihelper.postRequest("rawdata_automation/sftp_foldername_update", payload);
  return response;
}

async function createandupdate(payload) {
  const response = await apihelper.postRequest("rawdata_automation/create_and_update", payload);
  return response;
}

async function search(skip, limit, sortBy, sort, sFields, sValues) {
  const response = await apihelper.postRequest(`rawdata_automation/search?skip=${skip}&limit=${limit}&sortBy=${sortBy}&sort=${sort}`, { sFields, sValues });
  return response;
}

async function getOne(id) {
  const response = await apihelper.getRequest(`rawdata_automation/getone/${id}`);
  return response;
}

async function remove(id) {
  const response = await apihelper.deleteRequest("rawdata_automation/", { _id: id });
  return response;
}