import { alertConstant, clientConstant, uiConstant } from "../constants";
import { clientService } from "../services";
import { baseAction } from "./baseAction";

// Import History
// import history from "../../config/history";

const { request, success, failure } = baseAction;

// Export All Auth Actions Functions
export const clientAction = {
  getOne,
  create,
  getAll,
  update,
  checkSurveys,
  remove,
  getSurveyPermission,
  getIndustry,
  updateDemographics,
  getLanguageList
};

/**
 * Get Client
 */
function getOne(successCallback = () => { }) {
  return (dispatch) => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(clientConstant.CLIENTS_GET_ONE_REQUEST));
    clientService.getOne().then(
      (data) => {
        dispatch(success(clientConstant.CLIENTS_GET_ONE_SUCCESS, data));
        dispatch(request(uiConstant.LOADING_FALSE));
        successCallback()
      },
      (error) => {
        dispatch(failure(clientConstant.CLIENTS_GET_ONE_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(uiConstant.LOADING_FALSE));
      }
    );
  };
}


/**
 * Get language list
 */
function getLanguageList(data, successCallback) {
  return (dispatch) => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(clientConstant.CLIENTS_GET_LANGUAGE_LIST_REQUEST));
    clientService.getLanguageList(data).then(
      (data) => {
        dispatch(success(clientConstant.CLIENTS_GET_LANGUAGE_LIST_SUCCESS, data));
        dispatch(request(uiConstant.LOADING_FALSE));
        successCallback()
      },
      (error) => {
        dispatch(failure(clientConstant.CLIENTS_GET_LANGUAGE_LIST_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(uiConstant.LOADING_FALSE));
      }
    );
  };
}

/**
 * All Clients details
 */
function getAll() {
  return (dispatch) => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(clientConstant.CLIENTS_GETALL_REQUEST));
    clientService.getAll().then(
      (data) => {
        dispatch(success(clientConstant.CLIENTS_GETALL_SUCCESS, data));
        dispatch(request(uiConstant.LOADING_FALSE));
      },
      (error) => {
        dispatch(failure(clientConstant.CLIENTS_GETALL_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(uiConstant.LOADING_FALSE));
      }
    );
  };
}

/**
 * Particular Client Details
 */
function create(data, successCallback = () => { }) {
  return (dispatch) => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(clientConstant.CLIENTS_CREATE_REQUEST));
    clientService.create(data).then(
      (data) => {
        clientService.getAll().then(
          (client) => {
            dispatch(success(clientConstant.CLIENTS_GETALL_SUCCESS, client));
            dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
            dispatch(request(uiConstant.LOADING_FALSE));
            successCallback();
          },
          (error) => {
            dispatch(failure(clientConstant.CLIENTS_GETALL_FAILURE, error));
            dispatch(failure(alertConstant.ALERT_ERROR, error));
            dispatch(request(uiConstant.LOADING_FALSE));
          }
        );
      },
      (error) => {
        dispatch(failure(clientConstant.CLIENTS_CREATE_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(uiConstant.LOADING_FALSE));
      }
    );
  };
}

/**
 * A service method to create hash for the particular email user
 *
 * @param {*} id  client id to update
 * @param {*} updateData data to be updated
 * @returns
 */
function update(updateData, successCallback = () => { }) {
  return (dispatch) => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(clientConstant.CLIENTS_UPDATE_REQUEST, updateData));

    clientService.update(updateData).then(
      (data) => {
        clientService.getAll().then(
          (client) => {
            if (data.status === "failed") {
              dispatch(success(clientConstant.CLIENTS_UPDATE_SUCCESS, data.status));
              dispatch(request(uiConstant.LOADING_FALSE));
              // successCallback();
            }
            else {
              dispatch(success(clientConstant.CLIENTS_GETALL_SUCCESS, client));
              dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
              dispatch(request(uiConstant.LOADING_FALSE));
              successCallback();
            }
            // // Redirect to roles based screen
            // history.push({
            //   pathname: "/" + redirectPage
            // });
          },
          (error) => {
            dispatch(failure(clientConstant.CLIENTS_GETALL_FAILURE, error));
            dispatch(failure(alertConstant.ALERT_ERROR, error));
            dispatch(request(uiConstant.LOADING_FALSE));
          }
        );
      },
      (error) => {
        dispatch(failure(clientConstant.CLIENTS_UPDATE_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(uiConstant.LOADING_FALSE));
      }
    );
  };
}

/**
 * Delete particular client
 */
function remove(id) {
  return dispatch => {
    clientService.remove(id)
      .then(
        data => {
          dispatch(success(clientConstant.CLIENTS_REMOVE_SUCCESS, data));
          dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
          dispatch(request(uiConstant.LOADING_FALSE));
        },
        error => {
          dispatch(failure(clientConstant.CLIENTS_REMOVE_FAILURE, error));
          dispatch(failure(alertConstant.ALERT_ERROR, error));
          dispatch(request(uiConstant.LOADING_FALSE));
        }
      );
  };
}

/*
* GET ME survey details
*/
function checkSurveys() {
  return (dispatch) => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(clientConstant.CLIENTS_CHECK_SURVEYS_REQUEST));
    clientService.checkSurveys().then(
      (data) => {
          dispatch(success(clientConstant.CLIENTS_CHECK_SURVEYS_SUCCESS, data));
          dispatch(request(uiConstant.LOADING_FALSE));
      },
      (error) => {
        dispatch(failure(clientConstant.CLIENTS_CHECK_SURVEYS_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(uiConstant.LOADING_FALSE));
      }
    );
  };
}

/**
 * GET CLIENT PERMISSION
 */
function getSurveyPermission() {
  return (dispatch) => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(clientConstant.SURVEY_CLIENT_PERMISSION_GET_REQUEST));
    clientService.getSurveyPermission().then(
      (data) => {
        dispatch(success(clientConstant.SURVEY_CLIENT_PERMISSION_GET_SUCCESS, data));
        dispatch(request(uiConstant.LOADING_FALSE));
      },
      (error) => {
        dispatch(failure(clientConstant.SURVEY_CLIENT_PERMISSION_GET_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(uiConstant.LOADING_FALSE));
      }
    );
  };
}

/**
 * Update Demographics
 */
function updateDemographics(updateData, successCallback = () => { }) {
  return (dispatch) => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(clientConstant.METADATA_UPDATE_REQUEST));
    clientService.updateDemographics(updateData).then(
      (data) => {
        dispatch(success(clientConstant.METADATA_UPDATE_SUCCESS));
        dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
        dispatch(request(uiConstant.LOADING_FALSE));
        successCallback()
      },
      (error) => {
        dispatch(failure(clientConstant.METADATA_UPDATE_FAILURE));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(uiConstant.LOADING_FALSE));
      }
    );
  };
}

/**
 * All Clients Industry
 */
function getIndustry(successCallback = () => { }) {
  return (dispatch) => {
    // dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(clientConstant.CLIENTS_GETINDUSTRY_REQUEST));
    clientService.getIndustry().then(
      (data) => {
        dispatch(success(clientConstant.CLIENTS_GETINDUSTRY_SUCCESS, data));
        // dispatch(request(uiConstant.LOADING_FALSE));
        successCallback();
      },
      (error) => {
        dispatch(failure(clientConstant.CLIENTS_GETINDUSTRY_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
      }
    );
  };
}