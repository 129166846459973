/* eslint-disable quote-props */
/* eslint-disable no-dupe-keys */
/* eslint-disable prefer-const */
export const errorsConstants = {
  required: "This is a required field",
  isEmail: "Invalid email",
  isPasswordMatch: "The passwords do not match",
  passwordPolicy: "Password requirements not met",
  isSameCustomName: "Custom name already exists",
  isEmpty: "Custom name cannot be empty",
  requiredEmployee: "Employee ID is required",
  isEnddategreater: "End date cannot precede the start date",
  surveyNameCharLimit: "Character limit exceeded. Survey name must be 60 characters or less.",
  surveyNameEmpty: "Please enter a survey name and select a template option",
  isPhone: "Invalid phone",
  isFileName: `Report name cannot contain: \ / : * ? " < > |`,
  notAnNumber: "Report Id must be a number",
  isNumber: "Number required",
  Obj_key_valid: "Invalid Key",
  anonymousNameExists: "Short name already exists",
  kioskNameExists: "Survey short name already exists",
  specialCharacters: "Invalid URL",
  specialCharacterskiosk: "Invalid URL",
  errorsConstants: "Maximum 100 characters only",
  reponseWindowCheckPulse: "Response window exceeds pulse frequency",
  reponseWindowCheck: "Date exceeds survey end date",
  // profile password
  isSixChar: "At least 6 characters",
  isUpperCase: "At least 1 uppercase letter",
  isLowerCase: "At least 1 lowercase letter",
  isSymbol: "At least 1 number or symbol [!@#$^&*()_]",
  isMaxed: "At most 50 characters only",
  confirmPasswordMismatch: "The new passwords do not match",
  pulsevalidation: "Pulse frequency cannot exceed survey dates. Check your start and end dates.",
  invalidDateRange: "Invalid Date Range!",
  invalidImageFormat: "Invalid format PNG and JPEG formats supported.",
  onlyNumberCanEmpty: "Must be a Number",
  invalidDateFormat: "Date must be in MM/DD/YYYY",
  // anonymous survey
  anonymousRequired: "",
  //IE browser not supported
  IE_NOT_SUPPORTED: "This site does not support Internet Explorer.\nPlease try a different browser.",
  folderName: "Allowed characters: [A-Z, a-Z, 0-9, _, -,  ]",
  isDuplicateMERaterGroup: "Duplicate Rater Group / Rater ID"
};

export const excelConstants = {

  // Tooltip texts
  DOWNLOAD_CONVERSION: "Export conversions",
  UPLOAD_CONVERSION: "Import conversions",
  ADD_CONVERSION: "Add another conversion",
  DELETE_CONVERSION: "Delete conversion",

  PARTICIPANT_DOWNLOAD: "participantDownload",
  PARTICIPANT_UPLOAD: "participantUpload",
  CONVERSION_DOWNLOAD: "conversionDownload",
  CONVERSION_UPLOAD: "conversionUpload",
  ADMIN_DOWNLOAD: "adminDownload",
  REMOVE_SURVEY_UPLOAD: "removeSurveyUpload",
  REMOVE_SURVEY_DOWNLOAD: "removeSurveyDownload",
  ADMIN_UPLOAD: "adminUpload",
  OVERRIDE_DEMOGRAPHICS_UPLOAD: "overrideDemographicsUpload",
  OVERRIDE_DEMOGRAPHICS_DOWNLOAD: "overrideDemographicsDownload",
  ACTION_PLAN_DOWNLOAD: "actionplanDownload",
  PARTICIPATION_REPORT: "participantionReport",
  RAW_DATA_REPORT: 'download_raw',
  RAW_DATA_REPORT_AUTOMATION: 'download_raw_automation',
  CALLER_METRIC: 'CALLER_METRIC',
  SURVEY_METRIC: 'SURVEY_METRIC',
  RED_FLAG_REPORT: 'RED_FLAG_REPORT',
  REHIRE_POTENTIAL_REPORT: 'REHIRE_POTENTIAL_REPORT',
  HOT_SPOT_EXCEL: 'HOT_SPOT_EXCEL',
  HLS_DASHBOARD_EXCEL: 'HLS_DASHBOARD_EXCEL',
  DROPDOWN_EXCEL: 'DROPDOWN_EXCEL',
  SURVEY_ASSIGNED_DATE_REPORT: "SURVEY_ASSIGNED_DATE_REPORT",
  PARTICIPANT_EXPORT_DOWNLOAD_ALERT_TEXT: "Download started.  You will receive a notification once complete",
  EXPORT_ALERT_TEXT_DEFAULT_DURATION: 3000,
  PARTICIPANT_EXPORT_ALERT_TEXT_DURATION: 20000,
  ME_PARTICIPANT_UPLOAD: "me_participantUpload",
  BULK_DELETE_PARTICIPANTS_UPLOAD: "bulkDeleteUpload",
  ARCHIVE_PARTICIPANTS_UPLOAD: "archiveParticipantsUpload",
  BULK_DELETE_PARTICIPANTS_DOWNLOAD: "bulkDeleteDownload",
  ARCHIVE_PARTICIPANTS_DOWNLOAD: "archiveParticipantsDownload",
  ARCHIVED_DATA_PARTICIPANTS_DOWNLOAD: "archivedDataParticipantsDownload",
  COMMENT_EXCEL_REPORT: "commentExcelReportDownload",
  COMMENT_EXCEL_REPORT_NLP: "commentExcelReportNlpDownload"
};

export const pdfConstants = {
  COMMENT_REPORT: "commentReport",
  COMMENT_REPORT_NLP: "commentReportNLP",
  BENCHMARK_REPORT: "Benchmark Report",
  DASHBOARD_REPORT: "Dashboard Report",
  DASHBOARD_SAVED_REPORT: "Dashboard Saved Report",
  INDIVIDUAL_REPORT: "Individual Report",
  GROUP_REPORT: "Group Report",
  CUSTOM_REPORT: "Custom Report",
}

export const pptConstants = {
  COMMENT_REPORT: "commentReport",
  COMMENT_REPORT_NLP: "commentReportNLP",
  BENCHMARK_REPORT: "Benchmark Report",
  DASHBOARD_REPORT: "Dashboard Report",
  DASHBOARD_SAVED_REPORT: "Dashboard Saved Report",
  INDIVIDUAL_REPORT: "Individual Report",
  GROUP_REPORT: "Group Report",
  CUSTOM_REPORT: "Custom Report"
}


export const BENCHMARK_LIST =
{
  "New Hire": ["New Hire", "Onboarding (Week 2)", "Onboarding (Week 5)"],
  "Current Population": ["Engagement", "Diversity & inclusion", "Stay", "Well-being", "Other"],
  "Exit": ["Exit"],
  "Multi-rater": ["Manager Effectiveness"]
}

export const languagesList = [{
  "code": "en",
  "name": "English"
}, {
  "code": "es",
  "name": "Spanish"
}, {
  "code": "zh-Hans",
  "name": "Simplified Chinese"
}, {
  "code": "ja",
  "name": "Japanese"
}, {
  "code": "zh",
  "name": "Mandarin"
}, {
  "code": "ko",
  "name": "Korean"
}, {
  "code": "de",
  "name": "German"
}, {
  "code": "da",
  "name": "Danish"
}, {
  "code": "uk",
  "name": "Ukrainian"
}, {
  "code": "tr",
  "name": "Turkish"
}, {
  "code": "fr",
  "name": "French"
}, {
  "code": "VI",
  "name": "Vietnamese"
}];

export const authenticationConstants = {
  BANNER_TEXT: "Listen. Engage. Inspire.",
  BANNER_SUB_TEXT: "Understand Your People Like Never Before",
  COPYRIGHT: `Copyright © ${new Date().getFullYear()} People Element`,
  SIGNIN_TEXT: "Sign in",
  SIGNIN_SUB_TEXT: "Enter your details below",
  SIGNIN_EMAIL_ID: "Email",
  SIGNIN_PASSWORD: "Password",
  SIGNIN_BTN_TEXT: "Sign in",
  SIGNIN_FORGOT_PASSWORD: "Forgot password?",
  FORGOT_PASSWORD_SUB_TEXT: "Enter your email address below",
  FORGOT_PASSWORD_BTN_TEXT: "Send",
  RESET_PASSWORD_TEXT: "Reset password",
  RESET_PASSWORD_SUB_TEXT: "Enter your reset password details below",
  RESET_CONFIRM_PASSWORD: "Confirm password",
  RESET_PASSWORD_BTN_TEXT: "Reset",
  BACK_TO_SIGNIN: "Back to sign in"
};

export const surveyConstants = {
  WELCOME_SURVEY_TEXT: "Welcome ",
  CREATE_NEW_SURVEY: "Create new survey",
  CREATE_A_NEW_SURVEY: "Create a new survey",
  CREATE_A_CUSTOM_SURVEY: "Custom survey",
  EDIT_THE_SURVEY: "Edit the survey",
  EMPTY_SURVEY: "Survey Name should not be Empty",
  CREATE_NEW_SURVEY_HELP_TEXT: "Choose one of our ready-to-go survey templates or customize your own.",
  MANAGE_SURVEY_HELP_TEXT: "Choose your settings and modify your existing surveys. ",
  BUILD_SURVEY_TEXT: "Build a survey",
  MANAGE_SURVEY_TEXT: "Manage surveys",
  LIST_BUILD_SURVEY_TEXT: "Build a survey",
  NAME_YOUR_SURVEY: "Name your survey",
  CREATE_A_NEW_SURVEY_HELP_TEXT: "Choose one of our ready-to-go survey templates or customize your own.",
  MY_SURVEYS: "My surveys",
  CONTINUE_BUTTON: "Save and continue",
  SAVE_BUTTON: "Save",
  PREVIEW_BUTTON: "Preview",
  CANCEL_BUTTON: "Cancel",
  CLOSE_BUTTON: "Close",
  OK_BUTTON: "Ok",
  YES_BUTTON: "Yes",
  NO_BUTTON: "No",
  APPLY_TO_ALL_BUTTON: "Apply to all",
  CANCEL_BUTTON_DIALOG_MESSAGE_1: "Do you want to cancel?",
  CANCEL_BUTTON_DIALOG_MESSAGE_2: "You are about to exit the survey and will lose any changes made. Do you want to save your changes?",
  DELETE_CONTENT: "Are you sure you want to delete the survey?",
  CONFIRM_UNSAVED_CONTENT: "There is unsaved information. Do you want to leave?",
  DROPDOWN_QUESTION_MESSAGE: "Responses to a Dropdown question will not appear in any chart.  This question type should only be used for mapping responses to a demographic, or obtaining via raw data export.",
  AUTOMATIC_TRIGGER_EDIT_MESSAGE: `Are you sure you would like to update your Automatic Triggers? Doing so may cause participants to be unassigned from the survey, which cannot be undone. Those unassigned will not be removed from the total invited number in reporting.`,
};

export const surveyTypes = {
  ENGAGEMENT: "Engagement",
  EXIT: "Exit",
  ONBOARDING_WEEK_TWO: "Onboarding (Week 2)",
  ONBOARDING_WEEK_FIVE: "Onboarding (Week 5)",
  NEW_HIRE: "New Hire",
  DIVERSITY_INCLUSION: "Diversity & inclusion",
  MANAGER_EFFECTIVENESS: "Manager 360",
  STAY: "Stay",
  WELLBEING: "Well-being",
  MULTI_RATER_FEEDBACK: "Multi-rater feedback",
  CUSTOM_SURVEY: "Other"
};

export const surveyTypesArray = [
  {
    key: surveyTypes.ENGAGEMENT,
    value: surveyTypes.ENGAGEMENT
  },
  {
    key: surveyTypes.EXIT,
    value: surveyTypes.EXIT
  },
  {
    key: surveyTypes.NEW_HIRE,
    value: surveyTypes.NEW_HIRE
  },
  {
    key: surveyTypes.DIVERSITY_INCLUSION,
    value: surveyTypes.DIVERSITY_INCLUSION
  },
  {
    key: surveyTypes.MULTI_RATER_FEEDBACK,
    value: surveyTypes.MULTI_RATER_FEEDBACK
  },
  {
    key: surveyTypes.STAY,
    value: surveyTypes.STAY
  },
  {
    key: surveyTypes.WELLBEING,
    value: surveyTypes.WELLBEING
  },
  {
    key: surveyTypes.CUSTOM_SURVEY,
    value: surveyTypes.CUSTOM_SURVEY
  }
];

// DragDrop
export const dragDrop = {
  QUESTIONS_DRAG_DROP: "Drag and drop the category/question here",
  QUESTIONS_DRAG_DROP_COMP: "Drag and drop the competency/question here"
};

// BaseQues Ids of questions to Disable Question Text on survey builder
export const surveyQuestionDeleteOptionDisable = ["16"];
export const surveyIntentionQuesTextDisable = ["52"];
export const surveyEngagementQuesTextDisable = ["21", "33", "39", "52", "64", "106"];
export const surveyWorkFromHomeQuesTextDisable = ["229"];

// survey settings page constants
export const surveySettingsConstants = {

  CHECKED_TRUE: true,
  CHECKED_FALSE: false,
  WEEKS: "Weeks",
  MONTHS: "Months",
  EMAIL_DISABLED_MESSAGE: "Please reach out to your People Element contact to enable email notifications",
  EMAIL_SUBJECT_NON_ME: "We want to know what you think!",
  EMAIL_SUBJECT_ME: "You’ve been asked to provide feedback!",
  EMAIL_BODY_ME: "You’ve been identified as someone who can give meaningful feedback for people participating in our Manager 360 process. We encourage you to be objective, candid, and comprehensive in responding. At the end of the process, those participating will receive a comprehensive report of the feedback outlining their strengths and areas for professional growth. Thank you in advance for your time. \n \nThis link is unique to you, please do not share with others.",
  EMAIL_BODY_NON_ME: "We’re looking for ways to improve as a company and would love to hear from you! We’ve developed a short survey and would greatly appreciate you taking a few minutes to provide your candid feedback. Your survey can be submitted using Chrome, Firefox, Edge, and Safari browsers. Thank you in advance for your time. \n \nThis link is unique to you, please do not share with others.",
  SMS_DISABLED_MESSAGE: "Please reach out to your People Element contact to enable sms notifications",
  TRANSFLO_DISABLED_MESSAGE: "Please reach out to your People Element contact to enable transflo notifications",
  PHONE_DISABLED_MESSAGE: "Please reach out to your People Element contact to enable phone data collection",
  WEEK_DAYS: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"],
  WEEK_DAYS_CHECK: { "Sunday": 0, "Monday": 1, "Tuesday": 2, "Wednesday": 3, "Thursday": 4, "Friday": 5, "Saturday": 6 },
  MONTH_DATE: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31],
  GREETINGS_CONSTANT_NAME: "<First name><Last name>",
  URL_CONSTANT_NAME: "<URL>",
  PLACEHOLDER_GREETINGS: "Greeting message",
  PLACEHOLDER_MESSAGE: "Type your message here.",
  PLACEHOLDER_SUBJECT: "Subject",
  PLACEHOLDER_EMAIL_URL: "Please click the link to complete your survey:",
  PLACEHOLDER_SMS_URL: "Click here to take your survey:",
  REMOVE_MATRIX_YESNO_PULSE: "Pulse surveys cannot include Matrix or Yes/No questions. Do you want to remove these questions from your survey? If no, you cannot save as a pulse survey.",
  PULSE_DATE_DIALOG_MESSAGE: "Some months have fewer days than selected. For those months, the survey will be sent on the last day of the month.",
  CLOSED_STATUS_DIALOG_MESSAGE: "If there are any scheduled notifications, they will still go out today",
  COMMUNICATION_SURVEY_DESC: "The survey instructions will display in the participant's survey underneath the survey name. This can be seen in your survey preview.",
  WELCOME_DEC: "Your feedback is requested for the people below, if you have a self evaluation listed, please complete it first",

  // setuppanel
  SURVEY_FREQUENCY: ["Always on", "Point in time", "Pulse"],
  SURVEY_FREQUENCY_ALWAYSON: "Always on",
  SURVEY_FREQUENCY_POINTINTIME: ["Point in time"],
  SURVEY_FREQUENCY_PULSE: "Pulse",
  PULSE_FREQUENCYTYPE: ["Weeks", "Months"],
  SURVEY_STATUS: ["Pending", "Open", "Closed"],
  SURVEY_STATUS_OPEN: "Open",
  SURVEY_STATUS_CLOSED: "Closed",
  SETUP_PANEL_TITLE: "Set Up",
  SETUP_PANEL_MESSAGE: "Set dates and survey frequency",
  SETUP_PANEL_MESSAGE_ME: "Set dates and Select frequency",
  SELECT_FREQUENCY_TYPE: "Select type of survey frequency *",
  SELECT_START_DATE: "Select start date *",
  SELECT_END_DATE: "Select end date *",
  SURVEY_STATUS_FIELD: "Survey status",
  SURVEY_STATUS_INFO: "Your survey will not be sent to participants until the distribution date(s) selected under 'survey distribution options'",
  SURVEY_PULSE_TO_BEGIN: "Pulse to begin every *",
  START_NEXT_PULSE: "Start next pulse on *",
  PULSE_MONTH_TEXT_TO_DISP: "* Be sure to set up reminder options under Email and SMS notifications",
  DAY: "Day",
  DATE: "Date",

  // audiencepanel
  AUDIENCE_PANEL_TITLE: "Assign Participants",
  AUDIENCE_PANEL_MESSAGE: "Choose how to assign participants",
  AUTOMATIC_TRIGGER: "Automatic triggers",
  MANUALLY_ASSIGN: "Manually assign participants",
  ANONYMOUS: "Anonymous",
  ANONYMOUS_SURVEY_URL: "Anonymous survey URL",
  ANONYMOUS_SURVEY_URL_MESSAGE: "/PE/",
  MULTIPLE_RESPONSE: "Multiple response",
  PASSWORD_PROTECTIONIMP: "Password protection",
  PASSWORD_PROTECTION: "Password protection",
  RADIOGROUP_TRIGGER: "trigger",
  RADIOGROUP_MANUAL: "manual",
  RADIOGROUP_ANONYMOUS: "anonymous",
  SURVEY_SENT_ON_DATE: "Survey will only be sent on the day or dates selected below",
  AUTOMATIC_TRIGGER_HELP: "Create rules to automatically assign and unassign your survey to participants. Please reach out to People Element for assistance if you need to change your trigger mid-data collection.",
  MANUALLY_ASSIGN_HELP: "Manually assign your survey to participants in the Manage Participants section of the People tab.",
  ANONYMOUS_HELP: "Collect feedback using a general survey link. An easy way to collect feedback if you don’t need to connect people to their demographics or responses.",
  AUTOMATIC_TRIGGER_VIDEO_LINK: "for automatic trigger examples and set-up instructions.",
  ME_HELP_LINK: "Assign your Subjects and Raters in the Participants section of the People tab ",
  ME_DATA_FILTER: "Filter your participants by the demographics below. If no demographics are selected, all data is shown.",
  ANONYMOUS_SURVEY_URL_HELP: "Customize the end of your survey’s URL. You can use any combination of letters, numbers, and underscores up to 100 characters. Your full survey link will display below.",
  PASSWORD_PROTECTION_HELP_ON: "Restrict access to this survey by requiring a password ",
  PASSWORD_PROTECTION_HELP_OFF: "No password is required to take this survey",
  ASSIGN_SURVEY_HELP: "Assign your survey immediately based on the trigger rules you created. Otherwise, the trigger will default to run tomorrow morning. ",
  ASSIGN_SURVEY_NOW_POPUP: "Assign Survey Now will assign participants who meet the conditions of the automatic trigger to the survey and will count towards the survey's total invited number in reporting. Are you sure you would like to assign the survey now?",
  ASSIGN_SURVEY_AUTO_POPULATE: `"Your automatic triggers have been pre-populated to your data level access. The DLA demographics cannot be deleted but may be edited. Each DLA demographic must include one selection. You may add additional demographic triggers."`,
  ASSIGNED_SURVEY_DISABLE_AUTOMATIC_TRIGGERS: "Your data level access restrictions do not permit you to change these automatic triggers. Please check with your administrator if you wish to make changes.",
  // Tooltip
  MULTIPLE_RESPONSE_TOOLTIP_ON: "Allow the survey to be taken multiple times from the same device. Helpful for kiosks or shared computers. Lock responses must be turned on in next section.",
  MULTIPLE_RESPONSE_TOOLTIP_OFF: "Allow the survey to be taken only once",

  // responsepanel
  RESPONSE_PANEL_TITLE: "Response Options",
  RESPONSE_PANEL_MESSAGE: "Configure response settings and survey limits",
  EDIT_RESPONSE_TITLE: "Lock responses",
  EDIT_RESPONSE_TITLE_HELP_ON: "Participants cannot edit survey responses after submitting their survey. If anonymous audience with Multiple response on, this is the only option.",
  EDIT_RESPONSE_TITLE_HELP_OFF: "Allows participants to edit survey responses after submitting their survey.",
  EDIT_RESPONDE_MESSAGE: "Make your responses editable",
  DISPLAY_NATIVE_LANGUAGE: "Display comments in native language for reports",
  DISPLAY_NATIVE_LANGUAGE_MESSAGE_ON: "Displays comments in reporting as entered by respondents.",
  DISPLAY_NATIVE_LANGUAGE_MESSAGE_OFF: "Displays comments in reporting translated to English.",
  RESPONSE_WINDOW: "Response Window",
  RESPONSE_WINDOW_HELP: "Limit the length of time a participant \n can access their survey.\n 1 Day = Assigned date + 1 day \n 1 Week = Assigned date + 7 days \n 1 Month = Assigned date + 30 days",
  NONPULSE_DROPDOWN: ["Weeks", "Months"],
  PULSE_DROPDOWN: ["Days", "Weeks", "Months"],
  SURVEY_THRESHOLD_TITLE: "Minimum Data Threshold *",
  SURVEY_THRESHOLD_HELP: "Results will display in reports when the response count is at or above this number",
  SURVEY_THRESHOLD_MIN: 3,
  SURVEY_THRESHOLD_MIN_CLIENTPAGE: 1,
  SURVEY_THRESHOLD_MAX: 15,

  // distributionpanel
  DISTRIBUTION_PANEL_TITLE: "Email/Text/Kiosk",
  DISTRIBUTION_PANEL_TITLE_ME: "Email",
  DISTRIBUTION_PANEL_MESSAGE: "Schedule and customize email and text messages or set up kiosk",
  DISTRIBUTION_PANEL_MESSAGE_ME: "Schedule and customize email",
  DISTRIBUTION_RADIOGRP_NAME1: "Select day of week",
  DISTRIBUTION_RADIOGRP_NAME2: "Option for custom date",
  RADIOGROUP_CUSTOM_DATE: "custom_date",
  RADIOGROUP_CUSTOM_DAY: "custom_day",
  SELECT_A_DAY: "Select a day",
  SELECT_DATES: "Select dates",
  SELECT_DATES_HELP: "Notifications are placed in the queue at this time however may be sent later depending on volume.",
  SELECT_TIME_MST: "Select time (MST)",
  SELECT_TIME_MST_HELP: "Notifications are placed in the queue at this time however may be sent later depending on volume.",
  KIOSK_ANONYMOUS_SURVEY_URL_HELP: "Customize the end of your survey’s URL. Your full survey link will display to the right.",

  SMS_EMAIL_TRANSFLO_SEND_ERROR: "Survey status should be open",
  // email
  EMAIL_TOGGLE_TITLE: "Email",
  EMAIL_TOGGLE_MESSAGE: "Send survey invitations and reminders through Email",
  EMAIL_REPLYTO: "Reply to email *",
  EMAIL_SUBJECT: "Email subject *",
  EMAIL_MESSAGE: "Email message",
  EMAIL_SEND_BUTTON: "Send email now",
  EMAIL_SEND_BUTTON_HELP: "Send email notifications now to all assigned participants that haven't completed the survey.",

  // sms
  ME_TOGGLE_TITLE: "Action Coach",
  ME_TOGGLE_MESSAGE: "Share tips, resources and best practices with managers via Email",
  ME_SELECT_ACTION_ALERT_TOPIC: "Select Action Alert Topic",
  ME_SELECT_ACTION_ALERT_SYSTEMID: "Select Subject ID",
  ME_OPTIONAL_ATTACHMENT: "Optional Attachment",
  ME_ADD_BUTTON: "+ Add Another Date",
  ME_ACTION_COACH_SELECT_CONTENT: "Select Content *",
  ME_ACTION_COACH_SELECT_CONTENT_OPTIONS: [
    {
      value: "subject_pdf_report",
      name: "Subject PDF Report"
    },
    {
      value: "getting_started",
      name: "Getting Started"
    },
    {
      value: "accountability",
      name: "Accountability"
    },
    {
      value: "communication_styles",
      name: "Communication Styles "
    },
    {
      value: "wellbeing_checks",
      name: "Wellbeing Checks"
    },
    {
      value: "celebrating_wins",
      name: "Celebrating Wins"
    },
    {
      value: "resilience",
      name: "Resilience"
    },
    {
      value: "your_wellbeing",
      name: "Your Wellbeing"
    },
    {
      value: "avoiding_distractions",
      name: "Avoiding Distractions"
    },
    {
      value: "maintaining_your_momentum",
      name: "Maintaining Your Momentum"
    },
  ],

  // sms
  SMS_TOGGLE_TITLE: "SMS",
  SMS_TOGGLE_MESSAGE: "Send survey invitations and reminders through SMS",
  SMS_TEXT: "SMS text",
  SMS_SEND_BUTTON: "Send sms now",
  SMS_SELECT_TIME_MST_HELP: "Notifications are placed in the queue at this time however may be sent later depending on volume. ",
  SMS_SELECT_TIME_MST2_HELP: "Notifications are placed in the queue at this time however may be sent later depending on volume.",
  SMS_SEND_BUTTON_HELP: "Send SMS notifications now to all assigned participants that haven't completed the survey.",

  // kiosk
  KIOSK_TOGGLE_TITLE: "Kiosk",
  KIOSK_TOGGLE_MESSAGE: "Contact user through kiosk",
  KIOSK_SURVEY_URL: "Survey short name",
  KIOSK_SURVEY_URL_MESSAGE: "Kiosk Survey URL",
  KIOSK_SURVEY_URL_MESSAGE_URL: "/kiosk/",

  // transflo
  TRANSFLO_TOGGLE_TITLE: "Transflo",
  TRANSFLO_TOGGLE_MESSAGE: "Send notification through transflo",
  TRANSFLO_SEND_BUTTON: "Send via app now",
  TRANSFLO_SUBJECT: "Transflo subject *",
  TRANSFLO_MESSAGE: "Transflo message",
  TRANSFLO_SEND_BUTTON_HELP: "Send Transflo app notifications to any assigned participant that hasn't completed. ",

  // communicationpanel
  COMMUNICATION_PANEL_TITLE: "Survey Messaging",
  COMMUNICATION_PANEL_MESSAGE: "Customize welcome and thank you messaging and survey instructions",
  SURVEY_DESCRIPTION: "Survey instructions (optional)",
  SURVEY_THANK_YOU_TITLE: "Thank you Message",
  SURVEY_WELCOME_TITLE: "Welcome Message",
  SURVEY_THANK_YOU_HEADER: "Header",
  SURVEY_THANK_YOU_DESC: "Description",

  // Welcome page
  SURVEY_WELCOME_TITLE: "Welcome Message",
  SURVEY_WELCOME_HEADER: "Header",
  SURVEY_WELCOME_DESC: "Description",

  // phonepanel
  PHONE_PANEL_TITLE: "Phone Collection",
  PHONE_PANEL_MESSAGE: "Calling options if selected in your services",
  PHONE_SELECT_TITLE: "Phone",
  PHONE_SELECT_MESSAGE: "Contact user through phone",
  MAX_ATTEMPT: "Max attempts",
  CALLER_DETAIL: "Caller details",
  CALLER_DETAIL_HELP: "Work with your people element contact to create additional notes for our interviewers.",
  ATTEMPT_DROPDOWN: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30],

  APPLY_TRANSLATION_BUTTON: "Apply Translation",
  APPLY_TRANSLATION_INFO: "Auto translates questions, answer sets, category names, and category descriptions that are blank. Once translated, they will appear highlighted in orange. You still have the ability to edit the text if desired.",
  APPLY_TRANSLATION_INFO_COMP: "Auto translates questions, answer sets, competency names, and competency descriptions that are blank. Once translated, they will appear highlighted in orange. You still have the ability to edit the text if desired.",

  APPLY_TRANSLATION_INFO_ANSWERSET: "Auto translates answer sets that are blank. Once translated, they will appear highlighted in orange. You still have the ability to edit the text if desired.",
  APPLY_TRANSLATION_INFO_DISTRIBUTION: "Auto translates survey distribution communications that are blank. Once translated, they will appear highlighted in orange. You still have the ability to edit the text if desired.",
  APPLY_TRANSLATION_INFO_COMMUNICATION: "Auto translates survey description and thank you messages that are blank. Once translated, they will appear highlighted in orange. You still have the ability to edit the text if desired.",

  LANGUAGE_NOT_ENABLED_MESSAGE: "Your default language is English. Please reach out to People Element to enable language translations.",

  APPLY_TRANSLATION_RESOURCE_LINK_MESSAGE: "for detailed survey translation instructions"

};

//Content Library constants
export const contentLibraryConstants = {
  SURVEY_TYPES: [
    "Engagement",
    "Exit",
    "Onboarding (Week 2)",
    "Onboarding (Week 5)",
    "New Hire",
    "Diversity & inclusion",
    "Manager Effectiveness",
    "Stay",
    "Well-being",
    "Other"
  ],
  RECOMMENDATIONS_TYPES: [
    "In PDF only",
    "In web Only",
    "Both"
  ]
}

// survey questions page constants
export const surveyQuestionsConstants = {

  // field names
  DESCRIPTION_TEXT: "Modify your answer choices. Zero values are not calculated in your scores and should stay as NA or Does Not Apply type responses.",
  EXCLUDE_FROM_DASHBOARD: "Exclude from dashboard",
  EXCLUDE_FROM_REPORTS: "Exclude response data from reports",
  REQUIRE_THIS_QUEST: "Require this question",
  APPLY_DEMOGRAPHIC_CONVERSION: "Apply demographic conversion",
  NON_EDITABLE_QUEST: "This is not an editable question.",

  // Tooltip
  EDIT_CAT_NAME: "Edit category name",
  CATEGORY_CLOSE: "Delete category",
  EDIT_COMP_NAME: "Edit competency name",
  COMPETENCY_CLOSE: "Delete competency",

  // help text
  EXCLUDE_FROM_DASHBOARD_HELP: "Remove a question to exclude responses from your scores and dashboard. Commonly used for demographic and informational questions.",
  APPLY_DEMOGRAPHIC_CONVERSION_HELP: "If a demographic is selected for your question, this allows you to create a conversion. You’ll need to go to the demographic settings page in the People tab to create your conversion.",
  LINK_TO_DEMOGRAPHIC_HELP: "This will automatically create reportable demographic items based on participant responses. For more information,",

  // language
  DEFAULT_LANG_CODE: "en",

  // Category questions preview
  PREVIEW_QUESTION: "Click and drag questions into your survey.",
  PREVIEW_QUESTIONS_HELP: "Questions will appear in the current category you are viewing.\n  You can multi-select questions and drag them all at once into your survey. \n A question that is greyed out already exists in your survey. \n Question Types:\n SS: Single select \n YN: Yes/No \n M: Matrix \n D: Dropdown \n OE: Open Ended",
  EXISTING_QUESTION: "Question already exists in survey",
  // Competency question preview
  PREVIEW_QUESTIONS_HELP_COMP: "Questions will appear in the current competency you are viewing.\n  You can multi-select questions and drag them all at once into your survey. \n A question that is greyed out already exists in your survey. \n Question Types:\n SS: Single select \n YN: Yes/No \n M: Matrix \n D: Dropdown \n OE: Open Ended",

  // question data
  QUESTIONS_TYPE: ["Single Select", "Matrix", "Yes/No", "Dropdown", "Open Ended"],
  ME_QUESTIONS_TYPE: ["Single Select", "Open Ended"],
  DEFAULT_SINGLE_SEL_ANS_SET: {
    "0": "No Answer",
    "1": "Strongly Disagree",
    "2": "Disagree",
    "3": "Somewhat Agree / Disagree",
    "4": "Agree",
    "5": "Strongly Agree"
  },
  OLD_DEFAULT_SINGLE_SEL_ANS_SET: {
    "0": "No Answer/Does Not Apply",
    "1": "Strongly Disagree",
    "2": "Disagree",
    "3": "Somewhat Agree / Somewhat Disagree",
    "4": "Agree",
    "5": "Strongly Agree"
  },
  DEFAULT_YES_NO_ANS_SET: {
    "0": "Yes",
    "1": "No"
  },
  DEFALULT_MATRIX_ANS_SET: {
    "0": "No Impact",
    "1": "Some Impact",
    "2": "Strong Impact",
    "3": "Major Impact"
  },
  ME_CUSTOM_ANSWERSET_DATA: {
    "en": {
      "0": "Not Observed",
      "1": "Strongly Disagree",
      "2": "Disagree",
      "3": "Neutral",
      "4": "Agree",
      "5": "Strongly Agree"
    },
    "es": {
      "0": "No observado",
      "1": "Muy en desacuerdo",
      "2": "En desacuerdo",
      "3": "Neutral",
      "4": "De acuerdo",
      "5": "Totalmente de acuerdo"
    }
  },
  OLD_CUSTOM_DEFAULT_ANSWERSET: {
    "sets": {
      "1-5 agreement scale": {
        "en": {
          "0": "No Answer/Does Not Apply",
          "1": "Strongly Disagree",
          "2": "Disagree",
          "3": "Somewhat Agree / Somewhat Disagree",
          "4": "Agree",
          "5": "Strongly Agree"
        },
        "es": {
          "0": "Sin respuesta / No se aplica",
          "1": "Muy en desacuerdo",
          "2": "En desacuerdo",
          "3": "Algo de acuerdo / Algo en desacuerdo",
          "4": "De acuerdo",
          "5": "Totalmente de acuerdo"
        }
      }
    },
    "selected": "1-5 agreement scale",
    "tabarray": ["1-5 agreement scale"]
  },
  ME_CUSTOM_ANSWERSET: {
    "sets": {
      "1-5 agreement scale": {
        "en": {
          "0": "Not Observed",
          "1": "Strongly Disagree",
          "2": "Disagree",
          "3": "Neutral",
          "4": "Agree",
          "5": "Strongly Agree"
        },
        "es": {
          "0": "No observado",
          "1": "Muy en desacuerdo",
          "2": "En desacuerdo",
          "3": "Neutral",
          "4": "De acuerdo",
          "5": "Totalmente de acuerdo"
        }
      }
    },
    "selected": "1-5 agreement scale",
    "tabarray": ["1-5 agreement scale"]
  },
  DEFAULT_ME_OUES_SET: {
    "baseQusId": "",
    "qusType": "Single Select",
    "excludeInDashboard": false,
    "isRequired": false,
    "demographicConversion": false,
    "respMap": {},
    "actionItem": [],
    "ansSet": {
      "en": {
        "0": "Not Observed",
        "1": "Strongly Disagree",
        "2": "Disagree",
        "3": "Neutral",
        "4": "Agree",
        "5": "Strongly Agree"
      }
    },
    "category": "",
    "text": {
      "en": ""
    }
  },
  DEFAULT_OUES_SET: {
    "baseQusId": "",
    "qusType": "Single Select",
    "excludeInDashboard": false,
    "isRequired": false,
    "demographicConversion": false,
    "respMap": {},
    "actionItem": [],
    "ansSet": {
      "en": {
        "0": "No Answer",
        "1": "Strongly Disagree",
        "2": "Disagree",
        "3": "Somewhat Agree / Disagree",
        "4": "Agree",
        "5": "Strongly Agree"
      }
    },
    "category": "",
    "text": {
      "en": ""
    }
  },
  OLD_DEFAULT_OUES_SET: {
    "baseQusId": "",
    "qusType": "Single Select",
    "excludeInDashboard": false,
    "isRequired": false,
    "demographicConversion": false,
    "respMap": {},
    "actionItem": [],
    "ansSet": {
      "en": {
        "0": "No Answer/Does Not Apply",
        "1": "Strongly Disagree",
        "2": "Disagree",
        "3": "Somewhat Agree / Somewhat Disagree",
        "4": "Agree",
        "5": "Strongly Agree"
      }
    },
    "category": "",
    "text": {
      "en": ""
    }
  },

  // Question type
  SINGLE_SELECT: "Single Select",
  MATRIX: "Matrix",
  YES_NO: "Yes/No",
  DROPDOWN: "Dropdown",
  OPEN_ENDED: "Open Ended",

  // VALIDATIONS IN QUESTIONS
  // Dempgraphics
  DUPLICATE_DEMOGRAPHICS: "This demographic is already mapped to a question. You cannot map the same demograhaphic to more than one question.",

  // Category
  EMPTY_CATEGORY_NAME: "A category name or category translation field is empty",
  PULSE_SURVEY_WITH_MATRIX: "Pulse surveys cannot include Matrix or Yes/No questions.",
  CATEGORY_EXISTS: "Category already exists",
  CATEGORY_DELETE_DIALOG_MESSSAGE: "Are you sure you want to delete this category?",
  REORDER_CATEGORY: "Reordering categories",
  REORDER_CATEGORY_TEXT: "Click and drag to reorder the categories in your survey.",

  // Competency
  EMPTY_COMPETENCY_NAME: "A competency name or competency translation field is empty",
  COMPETENCY_EXISTS: "Competency already exists",
  COMPETENCY_DELETE_DIALOG_MESSSAGE: "Are you sure you want to delete this competency?",
  REORDER_COMPETENCY: "Reorder competencies",
  REORDER_COMPETENCY_TEXT: "Click and drag to reorder the competencies in your survey.",

  // Question
  SAVE_QUESTION: "Please save questions in current category",
  NO_QUESTIONS: "Please add questions",
  DUPLICATE_QUESTION: "Question text should not be empty or duplicate",
  EMPTY_QUESTION: "Question text for English or other language is empty",
  QUESTION_DELETE_DIALOG_MESSSAGE: "Do you want to delete this question?",
  LANG_DELETE_DIALOG_MESSSAGE: "Do you want to delete this language?",
  RESPONSE_QUESTION_DELETE_DIALOG_MESSSAGE: "There are responses to this question.Do you really want to delete?",
  REMOVING_THIS_QUESTION_MAY_ALTER_REPORTING: "This question is tied directly to reporting. Removing this question may alter reporting.",
  QUESTION_EXIST_DND: "Question already exists",
  SOME_QUESTION_EXIST_DND: "Questions in this category already exist in your survey",
  SOME_QUESTION_EXIST_DND_COMP: "Questions in this competency already exist in your survey",
  DUPLICATE_BASE_QUES_ID: "Base question id should not be duplicate",
  SAVE_QUESTION: "Please save or exit the edited question",
  SELECT_ENG_LANG: "Select english language to add questions",
  TRANSLATE_CONFIRMATION_DIALOG_MESSAGE: "This action will auto-translate questions, answer sets, category names, and category descriptions with blank translations into the selected language. Are you sure you want to proceed?",
  TRANSLATE_CONFIRMATION_DIALOG_MESSAGE_COMP: "This action will auto-translate questions, answer sets, competency names, and competency descriptions with blank translations into the selected language. Are you sure you want to proceed?",
  TRANSLATE_CONFIRMATION_DIALOG_MESSAGE_ANSWERSET: "This action will auto-translate answer sets with blank translations into the selected language.\n  Are you sure you want to proceed?",
  TRANSLATE_CONFIRMATION_DIALOG_MESSAGE_DISTRIBUTE: "This action will auto-translate survey distribution communications with blank translations into the selected language. Are you sure you want to proceed? ",
  TRANSLATE_CONFIRMATION_DIALOG_MESSAGE_COMMUNICATION: "This action will auto-translate survey description and thank you messages with blank translations into the selected language. Are you sure you want to proceed?",

  // Sub question
  DUPLICATE_SUB_QUESTION: "Subquestion already exists",
  EMPTY_SUB_QUESTION: "Please add subquestions",
  ADD_SUB_QUESTIONS: "Please add subquestions",
  DUPLICATE_EMPTY_SUB_QUES: "Duplicate or empty sub quesions are not allowed",

  // Answer set
  ANSWER_SET_DELETE_DIALOG_MESSSAGE: "Are you sure you want to delete this answer set?",
  ADD_ANSWER_CHOICES: "Answer choices",
  ADD_ZERO_ANSWER_CHOICE: "Add 0/NA value",
  CUSTOMIZE_ANSWER_SET: "Customize answer set",
  EMPTY_ANS_CHOICES: "Answer choices should not be empty",
  ANS_SCALE_NOT_MATCH: "Default answer requires a 5-point scale",
  CUSTOMIZE_ANSWER_SET_TEXT: "Create a customized 5-point answer scale for any question or your entire survey.",
  ANS_SET_LENGTH_EXCEEDS: "Answer set should not be greater than 10 in single select",
  ADD_ANSWER_CHOICES_HELP: "Modify your answer choices. Zero values are not calculated in your scores and should stay as NA or Does Not Apply type responses.",

  EMPTY_ANS_CHOICE: "Answer choice for English or other language is empty for a question",
  DUPLICATE_ANS_CHOICE: "Duplicate answer choice found",
  DUPLICATE_EMPTY: "Duplicate or empty answer sets are not allowed",
  ANS_SET_EMPTY: "Answer set should not be empty in all languages"

};

// People Page
export const peopleConstants = {
  WELCOME_TEXT: "Manage your people",
  WELCOME_SUB_TEXT: "Select an option below",
  MANAGE_PATICIPANTS: "Participants",
  MANAGE_PATICIPANTS_BTN: "Manage participants",
  MANAGE_PATICIPANTS_TEXT: "View, add, and modify your participant information.",
  SETUP_DEMOGRAPHY: "Demographics",
  SETUP_DEMOGRAPHY_BTN: "Set up demographics",
  SETUP_DEMOGRAPHY_TEXT: "Create, select, and modify your reporting demographics.",
  MANAGE_USER_ROLES: "User roles",
  MANAGE_USER_ROLES_BTN: "Manage user roles",
  MANAGE_USER_ROLES_TEXT: "View, add, and modify user roles and permissions.",
  CONTINUE_BUTTON: "Continue",
  SAVE_BUTTON: "Save",
  CANCEL_BUTTON: "Cancel"
};

// Admin
export const adminConstants = {
  MANAGE_USER_ROLES: "Manage user roles",
  IMPORT: "Import",
  EXPORT: "Export",
  FIRST_NAME: "First name",
  LAST_NAME: "Last name",
  EMAIL: "Email *",
  ROLES: "Roles",
  ACTIVE_STATUS: "Active status",
  ACTIVE_STATUS_HELP: "Changing users to inactive suspends their account access without deleting them from your user list.",
  DEFAULT: "Default",
  DLA: "Data Level Access",
  SURVEY_LEVEL_ACCESS: "Survey Level Access",
  DLA_HELP: "Define the reporting you would like this user to view",
  SELECT_EMPLOYEE_EXPERIENCE_SURVEY: "Select Employee Experience Survey",
  SELECT_MULTIRATER_FEEDBACK_SURVEY: "Select Multi-rater Feedback Survey",
  ACTIVE: [{
    "value": true,
    "name": "Active"
  }, {
    "value": false,
    "name": "Inactive"
  }],
  DELETE_CONTENT: "Do you want to delete this user?",
  CLEAR_ALL: "Clear all",
  CANCEL_CONTENT: "You are about to exit the user detail page. Do you want to save your changes?",
  PREFERENCE_INITIAL: {
    surveys: [],
    surveysCpy: [],
    demographics: {},
    date: {
      type: "Last 3 months",
      startDate: '',
      endDate: ''
    },
    hotspot: {
      type: "",
      rg: "",
    },
    EI: {
      rg: ""
    },
    yesno: {
      rg: "tenure_groupings",
    },
    dropdown: {
      sortField: 5
    },
    commentAnalysis: {
      baseQuesId: "",
    },
    hls: {
      type: "category",
      rg: "tenure_groupings",
      selectedSummaryWidgetColumnsDropdown: [],
    },
    historical: {
      type: "Last 3 months",
      survey: {}
    },
    tot: {
      surveys: []
    },
    // competency chart
    competency: {
      viewBy: "category"
    },
    // Dashboard Widgets show / hide Preference
    hideDashWidgets: {
      participation: false,
      overallFavorability: false,
      favorableByCategory: false,
      overalleNPS: false,
      engagedEmployee: false,
      keydriver: false,
      suggestedAreaAction: false,
      engagementBenchMark: false,
      likelyToStay: false,
      LikelyToReturn: false,
      turnOver: false,
      projectedTurnover: false,
      highLowRatedItems: false,
    },
  }
};

// Client Page
export const clientConstants = {
  PERMISSIONS: [{
    name: "smsAccess",
    label: "SMS"
  }, {
    name: "transfloAccess",
    label: "Transflo"
  }, {
    name: "phoneAccess",
    label: "Phone"
  }, {
    name: "emailAccess",
    label: "Email"
  }, {
    name: "samlssoAccess",
    label: "SAML/ SSO"
  }, {
    name: "rawDataAccess",
    label: "Raw Data"
  }, {
    name: "languageTranslation",
    label: "Language Translation"
  }, {
    name: "managerEffectiveness",
    label: "Manager 360"
  }, {
    name: "externalApiAccess",
    label: "External API"
  },
  {
    name: "engagementComparison",
    label: "Engagement Comparison"
  },
  {
    name: "rawDataAutomationAccess",
    label: "Raw Data Automation"
  }
    // {
    //   name: "internalApiAccess",
    //   label: "PeopleElement API"
    // }
  ],
  CLIENT_NAME: "Client name *",
  SHARE_FILE: "SFTP/ShareFile folder name",
  INDUSTRY: "Client industry *",
  THRESHOLD: "Minimum data threshold *",
  THRESHOLD_HELP: "Results will display in reports when the response count is at or above this number. You can change the minimum threshold for a survey to be higher than this number, if desired. If the threshold number needs to be lower or higher than this number, please reach out to your People Element contact.",
  RECIPIENT_ID: "Recipientid *",
  AUTH_TOKEN: "Auth token *",
  SSO_IDENTIFIER: "SSO identifier(Optional)",
  THRESHOLD_VALIDATION: "There are survey(s) set below this threshold.  The thresholds in those surveys must be changed before the client level threshold can be changed.",
  SSO_IDENTIFIER_HELP: "Work with your People Element contact to set up an identifier that links our platform with your single sign on portal.",
  THRESHOLDS: () => {
    let thresholds = [];
    for (let i = 0; i < 16; i++) {
      thresholds.push(i.toString());
    }
    return thresholds;
  }
};

// Profile Constants
export const profileConstants = {
  NAME: "Name *",
  FIRST_NAME: "First name",
  LAST_NAME: "Last name",
  EMAIL_ID: "Email *",
  EMAIL_ID_WITHOUT_MANDATORY: "Email",
  PASSWORD: "Password *"
};

// Participants Constants
export const participantConstants = {

  // add participant
  DEFAULT: "Default",
  DEFAULT_HELPTEXT: "Commonly used demographics ready for your use",
  DEFAULT_EMPLOYEE_ID: "Employee ID *",
  DEFAULT_FIRST_NAME: "First name",
  DEFAULT_LAST_NAME: "Last name",
  DEFAULT_EMAIL: "Email *",
  ALT_EMAIL: "Alt Email",
  DEFAULT_PHONE: "Phone *",
  ALT_PHONE: "Alt Phone",
  DEFAULT_ACTIVE_STATUS: "Active Status *",
  DEFAULT_EMPLOYEE_ID_HELP: "Employee ID is the unique identifier in this system. If using an excel upload sheet to make updates, the Employee ID needs to match exactly to what is already in the system or duplicate people will be created. (For example: The Employee ID 00123 is considered different from the Employee ID 123)",
  DEFAULT_STATUS_HELP: "Changing participant to inactive suspends their account access without deleting them from your participants list.",
  DEFAULT_STATUS_HELP_TWO: "ON:  Demographic will appear in the participant list and reporting if Display in report is enabled    OFF:  Demographic is archived and will not appear in the participant list or reporting",
  DEFAULT_KIOSK_USERNAME_HELP: "The username for your participants",
  DEFAULT_KIOSK_PASSWORD_HELP: "The password for your participants",
  OTHER_COUNTRY_HELP: "OTHER_COUNTRY_HELP",
  OTHER_DEPARTMENT_CODE_HELP: "OTHER_DEPARTMENT_CODE_HELP",
  OTHER_REGION_HELP: "OTHER_REGION_HELP",
  OTHER_STATE_HELP: "OTHER_STATE_HELP",
  OTHER_TENURE_GROUPINGS_HELP: "OTHER_TENURE_GROUPINGS_HELP",
  OTHER_JOB_CLASS_HELP: "OTHER_JOB_CLASS_HELP",
  DEFAULT_DRIVER_ID_HELP: "This must match the Driver ID in the Transflo application for a notification to be sent. If you don't have a Driver ID, this field can be left blank and email can be used to send notifications. If Driver ID and Email are both blank, no notification can be sent via their Transflo app.",
  DEFAULT_EMAIL_HELP: "Email notifications will be sent to this address",
  DEFAULT_ALTEMAIL_HELP: "Email notifications will be sent to this address",
  DEFAULT_PHONE_HELP: "SMS notifications will be sent to this phone number.  Additionally, outbound calls will be made to this number, when applicable",
  DEFAULT_ALTPHONE_HELP: "Outbound calls will be made to this number, when applicable.  No SMS notifications are sent to this phone number.",
  DEFAULT_STATUS_INFO_HELP: "This field designates who's “active” to participate in surveys and does not reflect employment status with your company. Active means they can participate, inactive means they are unable to complete a survey.",
  AUTOMATED_MANAGERID_HELP: "Manager ID identifies the reporting structure for Subjects and Raters in Manager 360 surveys. You must manually assign this ID to automatically generate the Manager Role demographic.",
  AUTOMATED_MANAGERID_DISABLE_HELP: "360+ Manager Effectiveness toggle has to be enabled for Manager ID",
  AUTOMATED_MANAGERROLE_HELP: "Manager Role identifies participants as either “Manager” or “Non-Manager” based on the Manager ID you provide for Subjects and Raters in Manager 360 surveys.",
  AUTOMATED_MANAGERROLE_DISABLE_HELP: "360+ Manager Effectiveness toggle has to be enabled for Manager Role",

  // Tooltip
  ADD_PARTICIPANTS: "Add participant",

  // Assined survey
  ASSIGNED_SURVEY: "Assigned survey",
  ASSIGNED_SURVEY_HELPTEXT: "Assign surveys to this participant",
  ALERT_SURVEY_EXIST: "Survey already assigned!",
  NO_SURVEYS: "There are no surveys created yet.",

  // Automated
  AUTOMATED_DEMOGRAPHY: "Automated demographics",
  AUTOMATED_DEMOGRAPHY_HELP: "Automated demographics",
  AUTOMATED_DEMOGRAPHY_HELPTEXT: "Demographics that are automatically calculated or necessary for calculations",
  AUTOMATED_BIRTHDATE: "Birth date",
  AUTOMATED_BIRTHDATE_HELP: "Birth date",
  AUTOMATED_HIREDATE: "Hire date",
  AUTOMATED_HIREDATE_HELP: "Hire date",
  AUTOMATED_TERMDATE: "Term date",
  AUTOMATED_TERMDATE_HELP: "Term date",
  AUTOMATED_AGECALCULATION: "Age calculation",
  AUTOMATED_AGECALCULATION_HELP: "Age calculation",
  AUTOMATED_TENURECALCULATION: "Tenure calculation",
  AUTOMATED_TENURECALCULATION_HELP: "Tenure calculation",
  AUTOMATED_AGEGROUPINGS: "Age groupings",
  AUTOMATED_AGEGROUPINGS_DROPDOWN: ["Under 30", "30-39", "40-49", "50-59", "60 and over"],
  AUTOMATED_AGEGROUPINGS_HELP: "Age groupings",
  AUTOMATED_TENUREGROUPINGS: "Tenure Groupings",
  AUTOMATED_TENUREGROUPINGS_HELP: "Tenure Groupings",
  AUTOMATED_GENERATION: "Generation",
  AUTOMATED_GENERATION_HELP: "Generation",

  // other demography
  OTHER_DEMOGRAPHY: "Other demographics",
  OTHER_DEMOGRAPHY_HELPTEXT: "Your custom demographics",
  OTHER_AGE: "Age",
  OTHER_COUNTRY: "Country",
  OTHER_DEPT_DESCR: "Dept descr",
  OTHER_DEPARTMENT_CODE: "Department code",
  OTHER_ETHNIC_GROUP: "Ethnic group",
  OTHER_GENDER: "Gender",
  OTHER_INTENTIONTO_STAY: "Intention to stay",
  OTHER_PRIOR_HEALTHCARE: "Prior healthcare",
  OTHER_REGION: "Region",
  OTHER_STATE: "State",
  OTHER_TENURE_GROUPINGS: "Tenure groupings",
  OTHER_TERM_MONTH: "Term month",
  OTHER_TERMINATION_REASON: "Termination reason",
  OTHER_JOB_CLASS: "Job class",

  QUERYBUILDER_DATE_INTEGER_HELP: "Enter date or number values for the date demographics.  A negative number represents the number of days after your selected demographic.  A positive number represents the number of days before your selected demographic.  Click the resource link above for examples.",
  // DLA QueryBuilder
  DLA_QUERYBUILDER_ANDOR_HELP: "Determine how data should be combined.  When AND is applied, all conditions must be met.  When OR is applied, any of the conditions may be met",
  DLA_QUERYBUILDER_ADDRULE_HELP: 'To apply to multiple demographics, use the ADD RULE option. If you need to use both AND / OR rules together, use the ADD GROUP option. For example, you may want to include the job title "analyst" AND in the department "human resources", or anyone with the job title "data scientist" AND in the department "administration".',

  // Manage Participant
  MANAGE_PATICIPANTS: "Manage participants",
  EMPLOYEE_ID_HELP: "Employee ID is a required field and used as the unique identifier of our platform. When updating participants information using an excel upload sheet, Employee ID needs to exactly match what’s already in the system or duplicate people will be created. (For example: Employee ID 00123 is considered different from Employee ID 123)",
  PARTICIPANT_IMPORT: "Import",
  PARTICIPANT_EXPORT: "Export",
  PARTICIPANTS_NOT_FOUND: "No participants found",

  // Select Demography
  SELECT_TITLE: "Select demographics to view",
  SELECT_COLUMN_DEFAULT: "Default",
  SELECT_COLUMN_AUTOMATED_DEMOGRAPHICS: "Automated demographics",
  EXCLUDED_AUTOMATED_DEMOGRAPHICS: ["IntentiontoStay", "Engagement_Index"],
  SELECT_DEMOGRAPICS_AUTOMATED_CLEAR: "Clear All",
  SELECT_COLUMN_OTHER_DEMOGRAPICS: "Other demographics",
  SELECT_DEMOGRAPICS_CLEAR: "Clear all",
  SELECT_DEMOGRAPICS_SEARCH: "Search",

  // Send individual mail
  SEND_SURVEY: "Send a Survey",
  NO_OPEN_SURVEY: "No open surveys assigned",
  SEND_SURVEY_HELP: "Select a survey to send an invitation. If there is only one survey, it will be selected for you. If Email and SMS are enabled for the survey, both options will display in blue. If not a survey option, it will display in grey. You can toggle off an option if you don’t want to use both. Hit Send to send out the survey.",
  SEND_VIA: "Send via",

  // New Participant
  PARTICIPANT_NEW: {
    firstName: "",
    lastName: "",
    empId: "",
    email: "",
    phone: "",
    altPhone: "",
    country: "",
    status: true,
    state: "",
    tenure_groupings: "",
    region: "",
    department: "",
    job_class: null,
    IntentionToStay: null,
    PriorHealthcareExperience: null,
    TermMonth: null,
    Age: "",
    TerminationReason: null,
    Gender: "",
    Ethnicity: "",
    DepartmentCode: null,
    JobTitle: "",
    Ethnciity: null,
    surveyDetails: [],
    survey: []
  },

  // Options Text
  COLUMN_SETTINGS: "Column settings",
  UNASSIGN_SURVEY: "Unassign survey",
  ASSIGNED_DATE_REPORT: "Survey assigned date",
  DELETE_PARTICIPANTS: "Delete participants",
  OVERRIDE_REPORT_DEMOGRAPHICS: "Override report demographics",
  PARTICIPANT_EXPORTS: "View Exports",
  BULK_DELETE: "Delete participants",
  BULK_ARCHIVE: "Archive participants",
  BULK_ARCHIVE_RESTORE: "Archive/Restore participants",
  DEMOGRAPHIC_CONVERSIONS: "Demographic conversion",
  ADMIN_UPLOAD: "Upload users",
  SETUP_ME: "Set up Manager 360",

  // Upload Participangts
  UPLOAD_PARTICIPANTS: "Upload participants",
  UPLOAD_PARTICIPANTS_SUBMIT_SUBTITLE: "Click submit to upload participants sheet",

  // Add/Edit Participant
  SAVE_BUTTON: "Save",
  SUBMIT_BUTTON: "Submit",
  CANCEL_BUTTON: "Cancel",
  CLOSE_BUTTON: "Close",
  DELETE_BUTTON: "Delete",
  EXPORT_BUTTON: "Export",
  EXPORT_BUTTON_ARCHIVE: "Export Archive",
  IMPORT_BUTTON: "Import",
  UPLOAD_BUTTON: "Import",
  DEFAULT_COUNTRY: "Country",
  DEFAULT_STATE: "State",
  DEFAULT_LANGUAGE: "Language",
  UPLOAD_BUTTON: "Upload",
  PARTICIPANT_ADD: "Add participant",
  PARTICIPANT_STATUS: [{ name: "Active" }, { name: "Inactive" }],
  PARTICIPANT_STATUS_PL_HOLDER: "Select status",
  PARTICIPANT_AGE: [{ name: "Under 30" }],
  PARTICIPANT_AGE_PL_HOLDER: "Select age",
  PARTICIPANT_COUNTRY: [{ name: "US" }],
  PARTICIPANT_COUNTRY_PL_HOLDER: "Select country",
  PARTICIPANT_ETHNICITY: [{ name: "BLACK" }],
  PARTICIPANT_ETHNICITY_PL_HOLDER: "Select ethnicity",
  PARTICIPANT_GENDER: [{ name: "Male" }, { name: "Female" }],
  PARTICIPANT_GENDER_PL_HOLDER: "Select gender",
  PARTICIPANT_REGION: [{ name: "South" }],
  PARTICIPANT_REGION_PL_HOLDER: "Select region",
  PARTICIPANT_STATE: [{ name: "Texas" }],
  PARTICIPANT_STATE_PL_HOLDER: "Select state",

  // Participant Delete
  DELETE_ALERT: "You are about to delete this participant from the system. Do you want to delete?",
  DELETE_SURVEY_WARN: "Survey(s) are assigned to this participant. Unassign survey(s) to delete participant.",
  REMOVE_SURVEY_WARN: "Responses are associated with this survey and cannot be deleted",
  DELETE_MESSAGE: "Participant deleted successfully",
  EMAIL_ALT_EMAIL_EQUAL_WARN: "Email & Alt Email should not be same",

  // Participant Cancel
  CANCEL_ALERT: "You are about to exit the participant detail page. Do you want to save your changes?",
  UNASSIGN_SURVEY_DIALOG: "Are you sure to unassign survey?",

  // Setup Demographics
  SETUP_DEMOGRAPHICS: "Set up demographics",
  SETUP_DEMOGRAPHICS_DESC: "Create, modify, and select your reporting demographics",
  DEMOGRAPH_DEFAULT: "Default",
  DEMOGRAPH_DEFAULT_HELP: "Commonly used demographics ready for your use",
  DEMOGRAPH_DEFAULT_DESC: "Description message goes here",
  DEMOGRAPH_AUTOMATED: "Automated demographics",
  DEMOGRAPH_AUTOMATED_HELP: "Demographics that are automatically calculated or necessary for calculations",
  DEMOGRAPH_AUTOMATED_DESC: "Description message goes here",
  DEMOGRAPH_OTHERDEMOGRAPHICS: "Other demographics",
  DEMOGRAPH_OTHERDEMOGRAPHICS_HELP: "Add your custom demographics",
  DEMOGRAPH_OTHERDEMOGRAPHICS_DESC: "Description message goes here",
  DEMOGRAPHY_ALERT: "You are about to exit the set up demographics page. Do you want to save your changes?",
  DEMOGRAPHY_AUTOMATED_ALERT: "You are about to archive or inactivate this demographic which will remove it from participants and reporting.  Are you sure you want to archive?",

  DEMOGRAPHY_DELETE_ONE: "You are about to delete",
  DEMOGRAPHY_DELETE_TWO: "demographic which will remove it from participants and reporting.  Are you sure you want to delete?",
  DEMOGRAPHY_SYSTEM_NAME: "System name",
  DEMOGRAPHY_TYPE: "Type",
  DEMOGRAPHY_TYPES: ["String", "Date", "Number"],
  DEMOGRAPHY_CUSTOM_NAME: "Custom name",
  API_CUSTOM_NAME: "API key name",
  DEMOGRAPHY_CUSTOM_NAME_HELP: "Your custom name must exactly match what appears in your list uploads for information to upload",
  DEMOGRAPHY_API_KEY_NAME_HELP: "Your API Key name must exactly match what appears in your list uploads for information to upload",
  DEMOGRAPHY_DISPLAYIN_REPORT: "Display in report",
  DEMOGRAPHY_VISIBLE: "Active status",
  DEMOGRAPHY_ACTION: "Action",
  DEMOGRAPHY_CONVERSION: "Conversion",
  DEMOGRAPHY_EMPLOYEEID: "Employee id",
  DEMOGRAPHY_FIRSTNAME: "First name",
  DEMOGRAPHY_LASTNAME: "Last name",
  DEMOGRAPHY_EMAIL: "Email",
  DEMOGRAPHY_ALTEMAIL: "Alt email",
  DEMOGRAPHY_PHONE: "Phone",
  DEMOGRAPHY_ALTPHONE: "Alt phone",
  DEMOGRAPHY_STATE: "State",
  DEMOGRAPHY_COUNTRY: "Country",
  DEMOGRAPHY_LANGUAGE: "Language",
  DEMOGRAPHY_BIRTHDATE: "Birth date",
  DEMOGRAPHY_HIREDATE: "Hire date",
  DEMOGRAPHY_TERMDATE: "Term date",
  DEMOGRAPHY_AGECALCULATION: "Age calculation",
  DEMOGRAPHY_AGEGROUPINGS: "Age groupings",
  DEMOGRAPHY_TENURECALCULATION: "Tenure calculation",
  DEMOGRAPHY_TERNUREGROUPINGS: "Tenure groupings",
  DEMOGRAPHY_GENERATION: "Generation",
  DEMOGRAPHY_REGION: "Region",
  DEMOGRAPHY_DEPARTMENT: "Department",
  DEMOGRAPHY_JOBCLASS: "Job class",
  DEMOGRAPHY_ADDANITEM: "Add an item ",

  // Demographic Conversion
  DEMOGRAPHIC_CONVERSION: "Generation",
  DEMOGRAPHIC_CONVERSION_TEXT: "Create conversions for demographic items you want in your reporting that are not available in your HRIS (i.e. tenure, generation, etc.)",
  DEMOGRAPHIC_CONVERSION_RUNBTN: "Run conversion now",

  // overrode demographics
  OVERRIDE_DEMOGRAPHICS_TITLE: "Override participants demographics in survey",
  OVERRIDE_DEMOGRAPHICS_SUB_TEXT: "Select a survey to download a full list of participants that have completed and follow instructions in the Excel file to change their demographics in reporting.  Changes will only be made in the Reports tab.  We suggest you also make these changes in the People tab",
  OVERRIDE_SUBMIT_SUBTITLE: "Click submit to upload override demographics sheet",

  // Unassign Survey
  SELECT_SURVEY: "Please select a survey",
  UNASSIGN_SURVEY: "Unassign survey",
  UNASSIGN_SURVEY_TITLE: "Remove participants assigned to a survey",
  UNASSIGN_SURVEY_SUBTEXT: "Select a survey to download a full list of participants assigned and follow instructions in the Excel file to unassign participants from your survey. Once a participant is removed, they will no longer be able to provide feedback to this survey. If they have already submitted responses, the participant cannot be removed.  Click import when ready to upload your file.",
  UNASSIGN_SELECTASURVEY: "Select a survey to export or click import to upload your file",
  UNASSIGN_IMPORT_CONFIRMATION_ONE: "Important, please read! You’re about to unassign these participants from this survey. All records of being invited to this survey will be removed for anyone that hasn’t responded. This action cannot be undone. Do you want to unassign these participants?",
  UNASSIGN_IMPORT_CONFIRMATION_TWO: "Are you certain you want to unassign these participants from this survey?  This cannot be undone.",
  BULK_DELETE_IMPORT_CONFIRMATION_ONE: "You’re about to permanently remove people from the platform. Do you want to proceed?",
  BULK_DELETE_IMPORT_CONFIRMATION_TWO: "Are you certain you want to remove these people? This cannot be undone.",
  ARCHIVE_PARTICIPANTS_IMPORT_CONFIRMATION_ONE: "You’re about to archive/restore people. Archiving will remove them from the Participant table. Restoring will add them back to the Participant table. Do you want to proceed?",
  ARCHIVE_PARTICIPANTS_IMPORT_CONFIRMATION_TWO: "Are you certain you want to make these changes?",

  // Bulk Delete Participants
  BULK_DELETE_SUBTEXT: "You can remove people from the platform if you don’t want them included in your survey(s) or if you want them permanently removed.\n Your reports are not affected when people are removed; any responses already collected are still available in your reports.\nTo remove people, click <b>Export</b> and follow the instructions. If someone is assigned to a pending or open survey, you’ll need to unassign them before they can be removed (this can be done using the <b>Unassign Survey</b> function under <b>More Options</b>). To complete your removal, import your file.",

  // Archive Delete Participants
  ARCHIVE_SUBTEXT: "If you don’t want certain people to participate in your survey or be in the participants table, you can archive them rather than delete them. This is helpful if you want to keep record of contacted people or plan to invite them to future surveys. Your reports are not affected when people are archived. \n To archive people, click <b>Export</b> and follow the instructions. Archived people can be restored as active participants if imported via the <b>Export Archive</b> worksheet. If a person is assigned to a pending or open survey, they cannot be archived until they are unassigned from a survey (this can be done using the <b>Unassign Survey</b> function under <b>More Options</b>). Import your file when ready to archive people. \n To view archived people, select <b>Export Archive</b>; this worksheet can also be used to restore archived people.",

  // Import
  UNASSIGN_IMPORT: "Drop files here or click to",
  ADMIN_SUBMIT_SUBTITLE: "Click submit to upload admin sheet",

  // Submit
  UNASSIGN_SUBMIT_TITLEONE: "Groot_remove-surveyStay",
  UNASSIGN_SUBMIT_TITLETWO: ".xlsx has been uploaded successfully",
  UNASSIGN_SUBMIT_SUBTITLE: "Click submit to unassign survey from these participants",

  // Survey Assigned Date Report
  SURVEY_ASSIGNED_DATE: "Survey assigned date",
  SURVEY_ASSIGNED_DATE_TITLE: "Download assignment dates for participants",
  SURVEY_ASSIGNED_DATE_TEXT: "Select your survey(s) and date range to download a report that provides the dates participants were assigned the survey(s).",
  SURVEY_ASSIGNED_DATE_LABEL_SELECT_SURVEY: "Select a survey to view participant",
  SURVEY_ASSIGNED_VIEW_PARTICIPANTS: "Select a survey to view participants",
  SURVEY_ASSIGNED_DEFAULT_PLACEHOLDER: "Select a survey",
  SURVEY_ASSIGNED_DATE_LABEL_START_DATE: "Select start date*",
  SURVEY_ASSIGNED_DATE_LABEL_END_DATE: "Select end date*",
  SURVEY_ASSIGNED_DATE_INVALID_DATE_VALIDATION: "Invalid date range",
  SURVEY_ASSIGNED_DATE_DATE_VALIDATION: "Date range must be within 2 years of today",
  SURVEY_ASSIGNED_DATE_FUTURE_DATE_VALIDATION: "Cannot use future date",
  SURVEY_ASSIGNED_DATE_EMPTY_MESSAGE: "No participants assigned to the surveys selected and no report will be exported",

  // Success Page
  UPLOAD_TITLE: "File uploaded successfully",
  // UPLOAD_SUBTITLE_LEFT:'Processed records 75/80 from clientparticipantupload.csv',
  UPLOAD_SUCCUESSCHIP: "Records uploaded successfully",
  UPLOAD_FAILCHIP: "Records failed to update",

  UPLOAD_DELETE_SUCCUESSCHIP: "Records deleted successfully",
  UPLOAD_DELETE_FAILCHIP: "Records failed to update",

  UPLOAD_ARCHIVE_SUCCUESSCHIP: "Records archived successfully",
  UPLOAD_RESTORE_SUCCUESSCHIP: "Records restored successfully",
  UPLOAD_ARCHIVE_FAILCHIP: "Records failed to update",

  // Failed Page
  FAILED_TITLE: "File upload failed",
  FAILED_SUBTITLE: "A internal server error has occurred! Please contact the System Admin",
  PARTICIPANT_GENERATION_GROUP: [
    {
      "value": "Traditionalists (1925-1945)",
      "min": 1924,
      "max": 1945
    },
    {
      "value": "Baby Boomers (1946-1964)",
      "min": 1946,
      "max": 1964
    },
    {
      "value": "Gen X (1965-1979)",
      "min": 1965,
      "max": 1979
    },
    {
      "value": "Millennials (1980-1996)",
      "min": 1980,
      "max": 1996
    },
    {
      "value": "Gen Z (1997-2012)",
      "min": 1997,
      "max": 2012
    },
    {
      "value": "Gen Alpha (2013-present)",
      "min": 2013,
      "max": 2050
    }
  ],
  PARTICIPANT_AGE_GROUP: [
    {
      "value": "Under 30",
      "min": 0,
      "max": 30
    },
    {
      "value": "30 - 39",
      "min": 30,
      "max": 40
    },
    {
      "value": "40 - 49",
      "min": 40,
      "max": 50
    },
    {
      "value": "50 - 59",
      "min": 50,
      "max": 60
    },
    {
      "value": "60 and Over",
      "min": 60,
      "max": 200
    }
  ],
  PARTICIPANT_TENURE_GROUP: [
    {
      "min": 0,
      "max": 0.2499,
      "value": "Less than 3 months"
    },
    {
      "min": 0.25,
      "max": 0.4999,
      "value": "3 months - less than 6 months"
    },
    {
      "min": 0.5,
      "max": 0.9999,
      "value": "6 months - less than 1 year"
    },
    {
      "min": 1,
      "max": 2.9999,
      "value": "1 year - less than 3 years"
    },
    {
      "min": 3,
      "max": 4.9999,
      "value": "3 years - less than 5 years"
    },
    {
      "min": 5,
      "max": 9.9999,
      "value": "5 years - less than 10 years"
    },
    {
      "min": 10,
      "max": 14.9999,
      "value": "10 years - less than 15 years"
    },
    {
      "min": 15,
      "max": 9999,
      "value": "15 years or more"
    }
  ]
};

// Take Surveys Page
export const TakeSurveyConstants = {
  // Take Survey Kiosk Auth
  KIOSK_SIGNIN_TITLE: "Kiosk user login",
  KIOSK_SIGNIN_SUB_TEXT: "Enter your details below",
  KIOSK_SIGNIN_EMAIL_ID: "Kiosk survey username",
  KIOSK_SIGNIN_PASSWORD: "Kiosk survey password",
  KIOSK_SIGNIN_BTN_TEXT: "Sign in",

  SIGNIN_PASSWORD_INFO: "Password is case sensitive",

  // Take Survey Anonymous Auth
  ANONYMOUS_SIGNIN_TITLE: "This survey requires a password. If you don't have a password, please see your survey administrator for further assistance",
  ANONYMOUS_SIGNIN_PASSWORD: "Password",
  ANONYMOUS_SIGNIN_BTN_TEXT: "Submit",

  // TakeSurvey
  TAKESURVEY_TITLE: "",
  TAKESURVEY_COMPLETED: "Completed",
  TAKESURVEY_DESCRIPTION: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco",
  POWEREDBY_TEXT: "Powered by People Element",
  THANKS_MESSAGE_HEADER: "Thank You",
  THANKS_MESSAGE_DESCRIPTION: "Your feedback has been received.",
  WELCOME_MESSAGE_HEADER: "Welcome",
  WELCOME_MESSAGE_DESCRIPTION: "Your feedback is requested for the people below, if you have a self evaluation listed, please complete it first",

  // Preview
  PREVIEW_OPTION: "Preview options",
  SEND_TESTMAIL_BTN: "Send Test Mail",
  PRINTSURVEY_BTN: "Print Survey",
  PREVIEW_LANGUAGE: "Languages",
  PREVIEW_DEMOGRAPHY_FILTER: "Demographic filter",
  PREVIEW_DEMOGRAPHY_FILTER_HELP: "These demographics do not appear on the respondent’s screen. You can use these to view what a person’s survey will look like based on their demographics in the system. If no items are selected, all questions are shown.",
  PREVIEW_CONFIRM_BUTTON: "OK",
  PREVIEW_CONFIRM_TITLE: "SURVEY PREVIEW",
  PREVIEW_CONFIRM_TEXT: ["You can preview your survey to make sure everything is working as expected and see how the survey looks to participants.", "In the preview mode, you have the option to send a test survey link, print the survey, and apply demographic filters if using question coding. The survey will default to show all questions unless filters are applied. These options are only available during the preview mode and will not be accessible with live survey links. You can answer questions in the preview survey, however responses are not saved."],
  PREVIEW_SEND_TEST_MAIL_TITLE: "Send Test Mail",
  PREVIEW_SEND_TEST_MAIL_EMAIL_LABEL: "Enter email(s). Multiple emails may be separated with a comma, semicolon, or space",
  PREVIEW_SEND_TEST_MAIL_EMAIL_PLACEHOLDER: "",
  PREVIEW_SEND_TEST_MAIL_MESSAGE_LABEL: "Message",
  PREVIEW_SEND_TEST_MAIL_MESSAGE_DEFAULT: "You have been invited to preview a survey. Click the link to view. If you have any questions, please send feedback.",
  PREVIEW_SEND_TEST_MAIL_EMPTY_ERROR: "Please enter an email",
  PREVIEW_SEND_TEST_MAIL_VALID_ERROR: "Invalid email",

  //Errors
  DEFAULT_REQUIRED_ERROR_MESSAGE: "This question requires an answer",
  ANSWER_ALL_REQUIRED_ERROR_MESSAGE: "Please answer all the required questions in previous categories.",
  ANSWER_ANY_ONE_QUES_ERROR_MESSAGE: "No answers have been added",
  ERROR_SURVEY_EXPIRED: "This Survey No Longer Valid.",
  ERROR_SURVEY_URL_INVALID: "Invalid Url Found.",
  ERROR_SURVEY_NOT_STARTED_YET: "This survey has not started yet",
  ERROR_SURVEY_CLOSED: "This survey is closed.",
  INVALID_PASSWORD: "Invalid password",
  KIOSK_SIGNIN_FAILED: "Please  enter a valid username and password",
  SURVEY_NOT_STARTED: "This survey has not started yet",
  PREVIEW_INVALID_EMAIL: "Survey preview not sent.  Invalid email provided.",
  INVALID_SURVEY_URL: "Invalid Survey URL",
  //Thank You Page
  SURVEY_THANKS_PAGE_MESSAGE: "Thank You",
  SURVEY_THANKS_PAGE_DESCRIPTION: "Your feedback has been received",

  //Preview Survey Feed Back Page
  SURVEY_FEED_BACK_PAGE_MESSAGE: "Thank You for completing the survey",
  SURVEY_FEED_BACK_PAGE_DESCRIPTION: "Please enter feedback",

  //Preveiw Survey Thank You Page
  PRE_SURVEY_THANKS_PAGE_MESSAGE: "Thank You",

  //IE browser not supported
  IE_NOT_SUPPORTED: "This site does not support Internet Explorer. Please try a different browser.",


  //Engage Type Survey Variable
  ENGAGEMENT_BASE_QUESTIONS: [21, 33, 39, 52, 64, 106],
  ENGAGEMENT_REPORT_GROUPS: [
    { group: "Actively Disengaged", min_index: 0, max_index: 59 },
    { group: "Opportunity Group", min_index: 60, max_index: 75 },
    { group: "Engaged/Contributor", min_index: 76, max_index: 90 },
    { group: "Actively Engaged", min_index: 91, max_index: 100 },
  ],

  WELCOME_TEXT: "Welcome ",
  WELCOME_DESC_TEXT: "Your feedback is requested for the people below, if you have a self evaluation listed, please complete it first",
}

// Take Surveys Page
export const MeSurveyConstants = {
  WELCOME_TEXT: "Welcome ",
  WELCOME_DESC_TEXT: "Your feedback is requested for the people below, if you have a self evaluation listed, please complete it first",
}

export const FilterConstants = {
  TITLE: "Data filters",
  CLEAR_ALL: "Clear all",
  DEMOGRAPHIC_FILTER: "Data filter",
  SURVEYNAMES: "SURVEY",
  DATES: "DATES",
  DEMOGRAPHICS: "DEMOGRAPHICS",
  DEMOGRAPHICS_HELP: "Filter your report by the demographics below. If no demographics are selected, all data is shown.",
  SELECT_TIMEFRAMES: "Select time frames",
  START_DATE: "Start date",
  END_DATE: "End date",
  TIME_FRAMES: [
    "Last month",
    "Last 3 months",
    "Last 6 months",
    "Last year",
    "Custom dates"
  ],
  COMBINE_ALERT: 'Pulse surveys cannot be combined with other survey types',
  COMBINE_ME_ALERT: 'Multi-rater Surveys cannot be combined with other Survey types.',
  SELECT_SURVEY: "Please select a survey",
  DATE_REQUIRED: "Start Date & End Date are Required",
  INVALID_DATE_FORMAT: "Invalid date range",
  MORE_OPTIONS: "More options",
}

// Reports Page
export const ReportsConstants = {

  SAVE: "Save",
  DOWNLOAD: "Download",
  CANCEL: "Cancel",
  CLOSE: "Close",
  EXPORT: "Export",
  CLEAR_ALL: "Clear all",
  EXPORT_PDF: "Export PDF",
  EXPORT_CSV: "Export CSV",

  GET_SURVEYS_SUCCESS: 'GET_SURVEYS_SUCCESS',

  // Dashboard
  TOTAL_INVITE: 'Total Invited',
  TOTAL_RESPONSE: 'Total Responses',
  SURVEY_QUESTIONS: 'Survey Questions',
  TOTAL_COMMENTS: 'Total Comments',
  TOTAL_SUBJECTS: 'Total Subject Responses',
  TOTAL_RATERS: 'Total Rater Responses',
  TOTAL_REVIEW_ITEMS: 'Total Review Items',

  OVERALL_FAVORABLITYl: "Overall Favorability",
  SUGGESTED_AREAACTION: "Suggested Areas of Action",
  KEY_DRIVER: "Top Drivers of Engagement",
  VIEW_ACTIONPLAN: "View Action Plans",
  VIEW_ALL: "View All",
  TOPALERTS: "Top alerts",
  HIGHESTAND_LOWESTITEMS: "Highest and Lowest Rated Items",
  Highest: 'Highest Rated Items',
  Lowest: 'Lowest Rated Items',
  CATEGORY_OPTIONS: ["Single Select", "Matrix", "Yes/No", "Dropdown", "Open Ended"],
  LIKELY_TO_RETURN: "Likely To Return",
  ENGAGED_EMPLOYEE: "Engaged Employees",
  LIKELY_TO_STAY: "Likely To Stay",
  OVERALL_ENPS_TITLE: "Employee Net Promoter Score (eNPS)",
  OVERALL_ENPS_SUB_TITLE: "People who recommend the organization as a good place to work",
  OVERALL_ENPS_INFO: "Your score is calculated by subtracting % Detractors from % Promoters, ranging from -100 to +100. As a guide, a score >0 is good; >30 is very good; >50 is excellent.",
  TURNOVER: "Turnover",
  TURNOVER_SUB_TITLE: "Total exits per month",
  PROJECTED_TURNOVER: "Projected Turnover",
  PROJECTED_TURNOVER_SUB_TITLE: "Number of people likely to leave based on their survey responses",
  ENGAGEMENT_BENCHMARK: "Engagement Benchmark",

  // Insight
  ACCESSLEVEL_TITLE: "Your access level: ",
  ACCESSLEVEL_TEXT: "RN in RN, BusinessUnit in HM Corp, HMB, HMCCH, HMCL, HMH, HMMOB, HMPCG, or job_class in AdministrativeClerical, All Others, Clinical SpecialistsProfs, Clinical Technicians, LPN LVN Direct Care, Management & Leadership, Medical Assistant, Nursing, Patient Care Support, Pharmacist, Practitioners, Professional, Service, TechnicalTechnicians... Therapists, Gender in Female...",
  SAVEREPORT: "Save report",
  DOWNLOADREPORT: "Download report",
  SAVEREPORT_TEXT: `Your report will be saved in the Report Tab under “Favorites”.`,
  DOWNLOADREPORT_TEXT: "Select how you would like to download your report",
  DOWNLOADREPORT_TEXT_ME: "This report will be downloaded as pdf",
  ENTERREPORT_NAME: "Enter report name",
  INCLUDE_DASHBOARD: "Include dashboard",
  CHARTS: {
    '0': 'Summary',
    '1': 'Comment Analysis',
    '2': 'Yes/No Question',
    '3': 'Matrix Questions',
    '4': 'Hotspot',
    '5': 'Engagement',
    '6': 'Trends Over Time',
    '8': 'Dropdown',
  },
  CHARTS_ME: {
    '7': 'Competency Summary'
  },
  ADDITIONAL_REPORTS: 'Report Widgets',
  DASHBOAARD_REPORTS: 'Dashboard Widgets',
  ADDITIONAL_EXPORTS: 'Additional Exports',
  TOT_SURVEY_EXCEEDED: "Can not select more than 10 surveys",

  // Dashboard
  VIEW_BY: "View By",
  SORT_BY: "Sort By",

  // EI Chart
  ENGAGEMENT_INFO: "Engagement is calculated based on the six engagement index questions with a 1-5 Likert scale. See the knowledge base for full calculation.",

  //EI Chart sort options
  EICHART_SORT_OPTIONS: [
    { name: "A - Z", value: 1, sortBy: "desc" },
    { name: "Z - A", value: 2, sortBy: "asc" },
    // {name: "Engaged - Disengaged", value: 3, sortBy: "desc"},
    // {name: "Disengaged - Engaged", value: 4, sortBy: "asc"},
    { name: "0% - 100%", value: 6, sortBy: "desc" },
    { name: "100% - 0%", value: 5, sortBy: "asc" },
  ],

  //EI Chart sort options
  DROPDOWN_SORT_OPTIONS: [
    { name: "A - Z", value: 1, sortBy: "desc" },
    { name: "Z - A", value: 2, sortBy: "asc" },
    { name: "0% - 100%", value: 6, sortBy: "desc" },
    { name: "100% - 0%", value: 5, sortBy: "asc" },
  ],

  //EI Chart sort options
  DROPDOWN_SORT_OPTIONS: [
    { name: "A - Z", value: 1, sortBy: "desc" },
    { name: "Z - A", value: 2, sortBy: "asc" },
    { name: "0% - 100%", value: 6, sortBy: "desc" },
    { name: "100% - 0%", value: 5, sortBy: "asc" },
  ],

  //Matrix chart sort options
  MATRIX_SORT_OPTIONS: [
    { name: "A - Z", value: 1, sortBy: "desc" },
    { name: "Z - A", value: 2, sortBy: "asc" },
    { name: "0% - 100%", value: 4, sortBy: "desc" },
    { name: "100% - 0%", value: 3, sortBy: "asc" },
  ],
  //Custom Report
  CREATE_CUSTOM_REPORT: "Custom Report",
  CUSTOM_DEMOGRAPHICS: "Demographics",
  CUSTOM_ADD_CHART_TEXT: "Once you add a chart to your report, you may add another.",
  CUSTOMIZE_COLUMN_TEXT: "Customize view",
  CUSTOM_VIEW_BY: "View by",
  CUSTOM_SORT_BY: "Sort by",
  CUSTOM_COMPARE_DATE_OR_SURVEY: "Comparison to Date or Survey",
  CUSTOM_VIEW_BY_X: "View by x-axis",
  CUSTOM_VIEW_BY_Y: "View by y-axis",
  CUSTOM_QUESTION_INCLUDE_TEXT: "Select the questions to include in your report",
  CUSTOM_TOT: "Check additional surveys if you want to display on the chart alongside the current survey (shown in grey)",
  CUSTOM_CLEAR_ALL: "Clear all",
  CUSTOM_DATE_RANGE: "Date Range",
  CUSTOM_SELECT_SURVEY: "Select Survey",
  CUSTOM_BACK: "Back to Reports",
  CUSTOM_CANCEL_DIALOG: "Do you want to cancel?",
  CUSTOM_CANCEL: "Cancel",
  CUSTOM_YES: "Yes",
  CUSTOM_NO: "No",
  CUSTOM_SAVE: "Save Report",
  CUSTOM_SAVE_AND_CONTINUE: "Save and Continue",
  CUSTOM_CHART_TEXT: "You can include up to 15 charts in your report, with the option to add the same chart type multiple times with different views.",
  CUSTOM_SELECT_CHART: "Select a chart to add to your report",
  CUSTOM_REPORT_CHARTS: "You can include up to 15 charts in your report, with the option to add the same chart type multiple times with different views.",
  CUSTOM_SELECT_CHART: "Select a chart to add to your report",
  CUSTOM_CHART_LIMIT: "You have reached the maximum number of charts on your report (15).To add another you will need to remove one first.",
  CUSTOM_CUSTOMIZE_CHART: "Customize your chart view",
  CUSTOM_PDF_STATS: "Choose which metrics to include on the dashboard. If none, the dashboard page will be excluded.",
  CUSTOM_CUSTOMIZATION: "Customization",
  CUSTOM_SELECT_DATES: "Choose the time frame for your report data. For always-on surveys, we recommend using the “last” date type.",
  CUSTOM_SELECT_COMMENT: "Use the toggles to include a comment analysis and/or verbatim comments in your report.",
  CUSTOM_SENTIMENT: "Include comment word cloud and sentiment analysis",
  CUSTOM_VERBATIM: "Include verbatim comments (does not display in PowerPoint)",
  CUSTOM_COMMENT_BREAKOUT: "Breakout verbatim comments by...",
  CUSTOM_CONTENTS: "Your Custom Report Contents",
  CUSTOM_TITLE: "Title",
  CUSTOM_SURVEY: "Survey",
  CUSTOM_DATE: "Dates",
  CUSTOM_DEMOGRAPHICS: "Filters",
  CUSTOM_CHART: "Charts",
  CUSTOM_DASHBOARD: "Dashboard",
  CUSTOM_INCLUDE: "Included",
  CUSTOM_COMMENT: "Comments",
  CUSTOM_SELECT_DEMOGRAPHIC: "Apply demographic filters to narrow the focus of your report. If no filters are applied, all data will be included.",
  CUSTOM_NO_DEMOGRAPHIC: "No demographics found!",
  CUSTOM_ORGANIZE: "Drag and drop charts in the order you want displayed in your report.",
  CUSTOM_ORGANIZE_NOTE: "PDF displays the dashboard on one page. PowerPoint displays as individual slides. Any comments included will display together in a single section.",
  CUSTOM_SCHEDULE: "Save your report and schedule it for automatic delivery to email on a recurring basis in the selected format.",
  CUSTOM_REPORT_DOWNLOAD: "Select a report format",
  CUSTOM_REPORT_FREQUENCY: "To schedule this report, select the frequency",
  CUSTOM_REPORT_EMAIL: "Add email addresses for those you want to receive this report",
  CUSTOM_EMAIL_TOOLTIP: "Multiple emails may be separated with a comma, semicolon, or space.",
  CUSTOM_SURVEY_SELECT: "Select the survey you wish to create the reports from",
  CUSTOM_SELECT_COMMENT_QUESTION: "Select the questions to include in the comment summary",
  DROPDOWN_SELECT_ANSWER: "Select Answer",

  // Custom Report Validations
  CUSTOM_VALID_REPORT_NAME: "Report name is required",
  CUSTOM_VALID_SURVEY: "Please select a survey",
  CUSTOM_VALID_COMBINE_PULSE: "Cannot combine Pulse surveys with other survey types",
  CUSTOM_VALID_COMBINE_MULTIRATER: "Cannot combine Multi-Rater Feedback surveys with other survey types",
  CUSTOM_VALID_NAME_EXIST: "Report name exists",
  CUSTOM_VALID_SELECT_DATE: "Please select date type",
  CUSTOM_VALID_START_DATE: "Please select start date",
  CUSTOM_VALID_END_DATE: "Please select end date",
  CUSTOM_VALID_DATE: "End date must be after start date",
  CUSTOM_VALID_QUESTION: "Please select at least one question for comments",
  CUSTOM_VALID_FREQUENCY: "Please select frequency when adding email recipients",
  CUSTOM_VALID_EMAIL: "One or more email addresses are invalid",


  // Report
  HAVENT_SAVED: "You have no reports saved",
  HAVENT_SAVED_TEXT: "You can save your report as favorite or click below to create a new report",
  CREATE_REPORT: "Additional reports",
  MYREPORTS: "My reports",
  CREATEA_REPORT: "Create a report",
  EDITA_REPORT: "Edit a report",
  CREATEA_REPORT_TEXT: "Your custom report can be downloaded now or scheduled to be sent in an email at the time of your choosing.",
  // Create Report
  PULSE_SURVEY: "Pulse survey",
  DATE_TYPE: "Date type",
  REPORT_TYPE: "Report",
  START_DATE: "Start date",
  PULSE_DATE : "Pulse date",
  SELECT_QUESTIONS: "Select questions",
  DEMOGRAPHIC: "Demographics",
  END_DATE: "End date",
  // Save Report
  ENTER_REPORTNAME: "Name your report",
  EMAILOPTIONAL: "Email (optional). Multiple emails may be separated with a comma, semicolon, or space",
  // EMAILOPTIONAL_INFO: 'Multiple emails may be separated with a comma, semicolon, or space',
  FREQUENCYOF_REPORT: "Frequency of report",
  FREQUENCYOF_REPORT_HELP: "Select how often you want this report sent to the email(s) listed above.",
  FREQUENCYOF_REPORT_HELP_WEEKLY: "Weekly reports will be sent every Monday",
  FREQUENCYOF_REPORT_HELP_MONTHLY: "Monthly reports will be sent the first day of each month",
  FREQUENCYOF_REPORT_HELP_QUARTERLY: "Quarterly reports will be sent the first day of each quarter ",

  // Select Report
  RED_FLAG_INFO: 'Demographics will be reported as confidential if the data threshold is not met',

  // Demographics
  DEMOGRAPHIC_FILTER: "Data filter",
  SURVEYNAMES: "SURVEY NAMES",
  DATES: "DATES",
  DEMOGRAPHICS: "DEMOGRAPHICS",
  SELECT_TIMEFRAMES: "Select time frames",

  // High Level Summary
  HIGHLEVEL_SUMMARY: "Summary",
  DROPDOWN: "Dropdown",
  HLS_INFO: `Your comparison is based on your filtered start date.  For example, if July 1 is your start date and you choose a 1 month comparison, the trend is based on your data from June 1 – June 30.  Surveys listed under “Select Survey” show data for the entire survey selected.`,
  COMPARISON_TO_COMP_INFO: `This column shows the number of % favorable points above or below the company favorability score.`,
  HLS_TABLE_HEADERS: ["N", "Mean", "% Favorable", "Comparison to company"],
  DROPDOWN_TABLE_HEADERS: ["Selection", "Frequency", "%", "Count"],
  HISTORICAL_DATES: ["Last month", "Last 3 months", "Last 6 months", "Last 9 months", "Last 12 months"],
  VIEW_BY_NAME: [{ name: "View By Category", value: "category" }, { name: "View By Question", value: "question" }, { name: "View By Demographic", value: "demographic" }, { name: "View By Key Drivers", value: "keyDrivers" }],
  DELETE_REPORT: 'Do you want to delete this report?',
  KEY_DRIVER_INFO: "Items marked with a blue key refer to your top engagement drivers based on key driver analysis results for the entire engagement survey. Click here to learn more.",

  HIGH_LEVEL_SUMMARY_CHRONOLOGICAL_PRIORITY: {
    Engagement_Index: {
      "Actively Engaged": 0,
      "Engaged/Contributor": 1,
      "Opportunity Group": 2,
      "Actively Disengaged": 3
    },
    IntentiontoStay: {
      "Highly Likely to Stay": 0,
      "Likely to Stay": 1,
      "Unsure": 2,
      "Likely to Leave": 3
    },
    generation: {
      "Gen Alpha (2013-present)": 0,
      "Gen Z (1997-2012)": 1,
      "Millennials (1980-1996)": 2,
      "Gen X (1965-1979)": 3,
      "Baby Boomers (1946-1964)": 4,
      "Traditionalists (1925-1945)": 5
    },
    age_groupings: {
      "Under 30": 0,
      "30 - 39": 1,
      "40 - 49": 2,
      "50 - 59": 3,
      "60 and Over": 4
    },
    tenure_groupings: {
      "Less than 3 months": 0,
      "3 months - less than 6 months": 1,
      "6 months - less than 1 year": 2,
      "1 year - less than 3 years": 3,
      "3 years - less than 5 years": 4,
      "5 years - less than 10 years": 5,
      "10 years - less than 15 years": 6,
      "15 years or more": 7
    }
  },

  //NO data
  NO_DATA_CHART: "Not enough responses for the selected content",
  NO_COMMENT_DATA_CHART: "No Open-ended questions available",
  NO_RESPONSE_DATA_CHART: " Not enough responses available to display",

  // Select Subject
  SELECT_SUBJECT: "Select Subject",
  COMMENT_REPORT: "Comment Report",

  // User Guide
  USER_GUIDE: "User Guide",
  DOWNLOAD_PDF: "Download as PDF",
  OPEN_NEW_TAB: "Open in Knowledge Base",

  // Dashboard More Options Widgets
  MORE_OPTIONS: {
    participation: "",
    overallFavorability: "",
    favorableByCategory: "",
    overalleNPS: "",
    engagedEmployee: "",
    keydriver: "",
    suggestedAreaAction: "",
    engagementBenchMark: "",
    likelyToStay: "",
    LikelyToReturn: "",
    projectedTurnover: "",
    turnOver: "",
    highLowRatedItems: "",
  }
};
ReportsConstants.MORE_OPTIONS.overallFavorability = ReportsConstants.OVERALL_FAVORABLITYl;
ReportsConstants.MORE_OPTIONS.overalleNPS = ReportsConstants.OVERALL_ENPS_TITLE;
ReportsConstants.MORE_OPTIONS.engagedEmployee = ReportsConstants.ENGAGED_EMPLOYEE;
ReportsConstants.MORE_OPTIONS.keydriver = ReportsConstants.KEY_DRIVER;
ReportsConstants.MORE_OPTIONS.suggestedAreaAction = ReportsConstants.SUGGESTED_AREAACTION;
ReportsConstants.MORE_OPTIONS.engagementBenchMark = ReportsConstants.ENGAGEMENT_BENCHMARK;
ReportsConstants.MORE_OPTIONS.likelyToStay = ReportsConstants.LIKELY_TO_STAY;
ReportsConstants.MORE_OPTIONS.LikelyToReturn = ReportsConstants.LIKELY_TO_RETURN;
ReportsConstants.MORE_OPTIONS.projectedTurnover = ReportsConstants.PROJECTED_TURNOVER;
ReportsConstants.MORE_OPTIONS.turnOver = ReportsConstants.TURNOVER;
ReportsConstants.MORE_OPTIONS.highLowRatedItems = ReportsConstants.HIGHESTAND_LOWESTITEMS;

export const summaryColumnField = {
  "keyDriverValue": "Key Driver",
  "categoryName": "Category",
  "participantCount": "N",
  "mean": "Mean",
  "percentfavorable": "% Favorable",
  "companyComparison": "Comparison to company",
  "historicalData": "Comparison to date or survey"
}

// SP CALLER
export const SpcallerConstants = {

  ASSIGN: "Assign",
  CANCEL: "Cancel",

  // spcaller
  SPCALLER: "SP Caller",
  SEARCH_PARTICIPANT: "Search Participant",

  SELECT_COLUMN: "Select column",
  GLOBAL_QUEUE: "Global Queue",
  SELECT_COLUMN_TITLE: "Select column to view",

  SURVEY_QUEUE: "Survey queue",
  SURVEY_QUEUE_TEXT: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor",
  SURVEY_QUEUE_HELP: "Survey queue",
  CALLER_QUEUE: "Caller queue",
  CALLER_QUEUE_TEXT: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor",
  CALLER_METRICS: "Caller metrics",
  CALLER_METRICS_TEXT: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor",
  CALLER_METRICS_HELP: "Caller metrics",
  SURVEY_METRICS: "Survey metrics",
  SURVEY_METRICS_TEXT: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor",
  SURVEY_METRICS_HELP: "Survey metrics",

  ASSIGNED_CALLS: "Assigned calls",
  ASSIGNED_CALLS_HELP: "Assigned calls",

  SEARCH: "Search",

  // caller detail
  CALLER_DETAIL: "Caller detail",
  CALLER_PHONE: "Phone",
  ALTPHONE: "Alt phone",
  EMAIL: "Email",
  STATE: "State",
  LANGUAGE: "Language",
  CLIENT_NAME: "Client name",
  SURVEY: "Survey",
  FREQUENCY: "Frequency",
  STATUS: "Status",
  NOTES: "Notes",
  SELECT_DATETIME: "Select date & time",
  CALLER: "Caller history",
  CALLER_STATUS: ["Busy", "Left Message", "Open",
    "Assigned", "Completed", "Max Attempts", "Refused", "Unreachable", "Do not call"],

  /**
   * validation constants
   */
  NO_PARTICIPANTS: 'There are no more participants in the call queue.',
  SEND_SURVEY_CALL_COMPLETE: 'Email will not be sent as the call status is Completed',
  SEND_SURVEY_CALL_REFUSE: 'Email will not be sent as the call status is Refused',
  SEND_SURVEY_MAX_ATTEMPTS: 'Email will not be sent as the call status is max attempts. Change status to send email.',
  INVALID_PAST_DATE: 'Assigned date must be a current or future date',
  INVALID_FUTURE_DATE: 'Date cannot be in the future',
  INVALID_DATE: 'Invalid date range!',
  EMAIL_SENT: 'Email sent successfully'

};

// Action
export const ActionConstants = {

  // Oprtunity Plans
  ACCESSLEVEL_TITLE: "Your access level: ",
  ACCESSLEVEL_TEXT: "RN in RN, BusinessUnit in HM Corp, HMB, HMCCH, HMCL, HMH, HMMOB, HMPCG, or job_class in AdministrativeClerical, All Others, Clinical SpecialistsProfs, Clinical Technicians, LPN LVN Direct Care, Management & Leadership, Medical Assistant, Nursing, Patient Care Support, Pharmacist, Practitioners, Professional, Service, TechnicalTechnicians... Therapists, Gender in Female...",

  OPURTUNITY_DESCRIPTION: "Based on your results, opportunity areas are listed below. Using the arrow on the right, expand an opportunity area to see detailed suggested action items to positively impact change. Opportunity areas are in order of potential impact to your group and the Company. The order is based on an algorithm that considers multiple variables including quartile scores, favorability, delta to Company benchmark, and change in scores over time.",
  OPURTUNITY_DESCRIPTION_KEY_DRIVER_1: "The order is based on an algorithm that considers multiple variables including quartile scores, favorability, delta to company benchmark, change in scores over time, and key drivers of engagement.",
  OPURTUNITY_DESCRIPTION_KEY_DRIVER_2: "to learn more about key drivers",

  // Add Plan
  ADD_PLAN: "Add a plan",
  EDIT_PLAN: "Edit a plan",

  ADD_PLAN_TEXT: "Complete the required fields below. You can return to your plan at any time to make updates and add notes.",
  PLAN_NAME: "Plan name*",
  ITEM_SCORE: "Item score",
  SURVEY_NAME: "Survey name",
  ACTION_PLAN_CREATOR: "Action plan creator",
  PLAN_CREATOR: "Plan creator",
  AREAOF_FOCUS: "Area of focus",
  ITEMOF_FOCUS: "Item of focus",
  ACTION_ITEM: "Action item(s)",
  START_DATE: "Start date*",
  DUE_DATE: "End date*",
  PLAN_STATUS: "Plan status*",
  NOTES: "Notes",
  DELETE_CONTENT: "Are you sure you want to delete this action plan?",
  CLEAR_ALL: "Clear all",
  DELETE_BUTTON: "X",
  CLOSE: "X Close",
  ADD_ACTION: "+ Add an action",
  CLEAR_ALL: "Clear all",


  statusDetails: [
    {
      "_id": "PENDING",
      "name": "Pending"
    },
    {
      "_id": "OPEN",
      "name": "Open"
    },
    {
      "_id": "COMPLETE",
      "name": "Complete"
    }
  ]
};

export const REPORT_TYPE_CONSTANTS = {
  COMMENT_REPORT: "Comment Report",
  RED_FLAG_REPORT: "Red Flag Report",
  REHIRE_REPORT: "Rehire Report",
  PARTICIPATION_REPORT: "Participation",
  BENCHMARK_REPORT: "Benchmark Report",
  RAW_DATA: "Raw Data",
  DASHBOARD_REPORT: "Dashboard Report",
  CUSTOM_REPORT: "Custom Report",
  INDIVIDUAL_REPORT: "Individual Report",
  GROUP_REPORT: "Group Report",
  COMMENT_EXCEL_REPORT: "Comment Excel Report"
};

export const STEPS_WITHOUT_COMMENTS = [
  "Survey", "Dates", "Filters", "Dashboard",
  "Charts", "Organize", "Schedule"
];

export const STEPS_WITH_COMMENTS = [
  "Survey", "Dates", "Filters", "Dashboard",
  "Charts", "Comments", "Organize", "Schedule"
];


export const REPORT_TYPES = [
  REPORT_TYPE_CONSTANTS.COMMENT_REPORT,
  REPORT_TYPE_CONSTANTS.PARTICIPATION_REPORT,
  REPORT_TYPE_CONSTANTS.BENCHMARK_REPORT
];

export const translateLanguagesListRGI = ["Afrikaans", "Albanian", "Amharic", "Arabic", "Armenian", "Azerbaijani", "Basque", "Belarusian", "Bengali", "Bosnian", "Bulgarian", "Catalan", "Cebuano", "Chinese (Simplified)", "Chinese (Traditional)", "Corsican", "Croatian", "Czech", "Danish", "Dutch", "English", "Esperanto", "Estonian", "Finnish", "French", "Frisian", "Galician", "Georgian", "German", "Greek", "Gujarati", "Haitian Creole", "Hausa", "Hawaiian", "Hebrew", "Hindi", "Hmong", "Hungarian", "Icelandic", "Igbo", "Indonesian", "Irish", "Italian", "Japanese", "Javanese", "Kannada", "Kazakh", "Khmer", "Kinyarwanda", "Korean", "Kurdish", "Kyrgyz", "Lao", "Latin", "Latvian", "Lithuanian", "Luxembourgish", "Macedonian", "Malagasy", "Malay", "Malayalam", "Maltese", "Maori", "Marathi", "Mongolian", "Myanmar (Burmese)", "Nepali", "Norwegian", "Nyanja (Chichewa)", "Odia (Oriya)", "Pashto", "Persian", "Polish", "Portuguese (Portugal, Brazil)", "Punjabi", "Romanian", "Russian", "Samoan", "Scots Gaelic", "Serbian", "Sesotho", "Shona", "Sindhi", "Sinhala (Sinhalese)", "Slovak", "Slovenian", "Somali", "Spanish", "Sundanese", "Swahili", "Swedish", "Tagalog (Filipino)", "Tajik", "Tamil", "Tatar", "Telugu", "Thai", "Turkish", "Turkmen", "Ukrainian", "Urdu", "Uyghur", "Uzbek", "Vietnamese", "Welsh", "Xhosa", "Yiddish", "Yoruba", "Zulu"]


export const translateLanguagesList = [
  {
    "name": "Afrikaans",
    "code": "af"
  },
  {
    "name": "Albanian",
    "code": "sq"
  },
  {
    "name": "Amharic",
    "code": "am"
  },
  {
    "name": "Arabic",
    "code": "ar"
  },
  {
    "name": "Armenian",
    "code": "hy"
  },
  {
    "name": "Azerbaijani",
    "code": "az"
  },
  {
    "name": "Basque",
    "code": "eu"
  },
  {
    "name": "Belarusian",
    "code": "be"
  },
  {
    "name": "Bengali",
    "code": "bn"
  },
  {
    "name": "Bosnian",
    "code": "bs"
  },
  {
    "name": "Bulgarian",
    "code": "bg"
  },
  {
    "name": "Catalan",
    "code": "ca"
  },
  {
    "name": "Cebuano",
    "code": "ceb"
  },
  {
    "name": "Simplified Chinese",
    "code": "zh-CN"
  },
  {
    "name": "Traditional Chinese",
    "code": "zh-TW"
  },
  {
    "name": "Corsican",
    "code": "co"
  },
  {
    "name": "Croatian",
    "code": "hr"
  },
  {
    "name": "Czech",
    "code": "cs"
  },
  {
    "name": "Danish",
    "code": "da"
  },
  {
    "name": "Dutch",
    "code": "nl"
  },
  {
    "name": "English",
    "code": "en"
  },
  {
    "name": "Esperanto",
    "code": "eo"
  },
  {
    "name": "Estonian",
    "code": "et"
  },
  {
    "name": "Finnish",
    "code": "fi"
  },
  {
    "name": "French",
    "code": "fr"
  },
  {
    "name": "Frisian",
    "code": "fy"
  },
  {
    "name": "Galician",
    "code": "gl"
  },
  {
    "name": "Georgian",
    "code": "ka"
  },
  {
    "name": "German",
    "code": "de"
  },
  {
    "name": "Greek",
    "code": "el"
  },
  {
    "name": "Gujarati",
    "code": "gu"
  },
  {
    "name": "Haitian Creole",
    "code": "ht"
  },
  {
    "name": "Hausa",
    "code": "ha"
  },
  {
    "name": "Hawaiian",
    "code": "haw"
  },
  {
    "name": "Hebrew",
    "code": "he"
  },
  {
    "name": "Hindi",
    "code": "hi"
  },
  {
    "name": "Hmong",
    "code": "hmn"
  },
  {
    "name": "Hungarian",
    "code": "hu"
  },
  {
    "name": "Icelandic",
    "code": "is"
  },
  {
    "name": "Igbo",
    "code": "ig"
  },
  {
    "name": "Indonesian",
    "code": "id"
  },
  {
    "name": "Irish",
    "code": "ga"
  },
  {
    "name": "Italian",
    "code": "it"
  },
  {
    "name": "Japanese",
    "code": "ja"
  },
  {
    "name": "Javanese",
    "code": "jv"
  },
  {
    "name": "Kannada",
    "code": "kn"
  },
  {
    "name": "Kazakh",
    "code": "kk"
  },
  {
    "name": "Khmer",
    "code": "km"
  },
  {
    "name": "Kinyarwanda",
    "code": "rw"
  },
  {
    "name": "Korean",
    "code": "ko"
  },
  {
    "name": "Kurdish",
    "code": "ku"
  },
  {
    "name": "Kyrgyz",
    "code": "ky"
  },
  {
    "name": "Lao",
    "code": "lo"
  },
  {
    "name": "Latin",
    "code": "la"
  },
  {
    "name": "Latvian",
    "code": "lv"
  },
  {
    "name": "Lithuanian",
    "code": "lt"
  },
  {
    "name": "Luxembourgish",
    "code": "lb"
  },
  {
    "name": "Macedonian",
    "code": "mk"
  },
  {
    "name": "Malagasy",
    "code": "mg"
  },
  {
    "name": "Malay",
    "code": "ms"
  },
  {
    "name": "Malayalam",
    "code": "ml"
  },
  {
    "name": "Maltese",
    "code": "mt"
  },
  {
    "name": "Maori",
    "code": "mi"
  },
  {
    "name": "Marathi",
    "code": "mr"
  },
  {
    "name": "Mongolian",
    "code": "mn"
  },
  {
    "name": "Myanmar (Burmese)",
    "code": "my"
  },
  {
    "name": "Nepali",
    "code": "ne"
  },
  {
    "name": "Norwegian",
    "code": "no"
  },
  {
    "name": "Nyanja (Chichewa)",
    "code": "ny"
  },
  {
    "name": "Odia (Oriya)",
    "code": "or"
  },
  {
    "name": "Pashto",
    "code": "ps"
  },
  {
    "name": "Persian",
    "code": "fa"
  },
  {
    "name": "Polish",
    "code": "pl"
  },
  {
    "name": "Portuguese (Portugal, Brazil)",
    "code": "pt"
  },
  {
    "name": "Punjabi",
    "code": "pa"
  },
  {
    "name": "Romanian",
    "code": "ro"
  },
  {
    "name": "Russian",
    "code": "ru"
  },
  {
    "name": "Samoan",
    "code": "sm"
  },
  {
    "name": "Scots Gaelic",
    "code": "gd"
  },
  {
    "name": "Serbian",
    "code": "sr"
  },
  {
    "name": "Sesotho",
    "code": "st"
  },
  {
    "name": "Shona",
    "code": "sn"
  },
  {
    "name": "Sindhi",
    "code": "sd"
  },
  {
    "name": "Sinhala (Sinhalese)",
    "code": "si"
  },
  {
    "name": "Slovak",
    "code": "sk"
  },
  {
    "name": "Slovenian",
    "code": "sl"
  },
  {
    "name": "Somali",
    "code": "so"
  },
  {
    "name": "Spanish",
    "code": "es"
  },
  {
    "name": "Sundanese",
    "code": "su"
  },
  {
    "name": "Swahili",
    "code": "sw"
  },
  {
    "name": "Swedish",
    "code": "sv"
  },
  {
    "name": "Tagalog (Filipino)",
    "code": "tl"
  },
  {
    "name": "Tajik",
    "code": "tg"
  },
  {
    "name": "Tamil",
    "code": "ta"
  },
  {
    "name": "Tatar",
    "code": "tt"
  },
  {
    "name": "Telugu",
    "code": "te"
  },
  {
    "name": "Thai",
    "code": "th"
  },
  {
    "name": "Turkish",
    "code": "tr"
  },
  {
    "name": "Turkmen",
    "code": "tk"
  },
  {
    "name": "Ukrainian",
    "code": "uk"
  },
  {
    "name": "Urdu",
    "code": "ur"
  },
  {
    "name": "Uyghur",
    "code": "ug"
  },
  {
    "name": "Uzbek",
    "code": "uz"
  },
  {
    "name": "Vietnamese",
    "code": "vi"
  },
  {
    "name": "Welsh",
    "code": "cy"
  },
  {
    "name": "Xhosa",
    "code": "xh"
  },
  {
    "name": "Yiddish",
    "code": "yi"
  },
  {
    "name": "Yoruba",
    "code": "yo"
  },
  {
    "name": "Zulu",
    "code": "zu"
  }
]

export const meSurveyConstants = {
  ME_SURVEY_MENU: 'Set up Manager 360',
  ME_SURVEY: 'Set up Manager 360',
  ME_PARTICIPANTS_ASSIGN: 'Manger Effectiveness Participants',
  ME_PARTICIPANTS_ASSIGN_SAVE: 'Save',
  ME_PARTICIPANTS_ASSIGN_CANCEL: 'Cancel',
  RELATIONSHIP: [
    {
      _id: 'Direct',
      name: 'Direct'
    },
    {
      _id: 'Supervisor',
      name: 'Supervisor'
    }
  ],
  PENDING: () => 'Subjects and raters are being assigned to the survey. This can take several minutes. You may leave this page and will receive a notification once completed. If you log out, the assignment process will continue processing.',
  ERROR: () => 'An error occurred. Please try again.',
  DONE: () => 'Subjects and raters successfully assigned to the survey.',
  PENDING_FILE: () => 'Your file is being uploaded.  This can take several minutes.  You may leave this page and will receive a notification once your import is complete.  If you log out, your file will continue processing.',
  ERROR_FILE: () => 'There is an Error occured while uploading file. Please try again or Contact PE Help Support',
  DONE_FILE: (d) => `Your file ${d.fileName}, uploaded on ${d.cdt} processed ${d.total}/${d.total} records`,
  ME_PARTICIPANT_DOWNLOAD: "ME_PARTICIPANT_DOWNLOAD",
  SUBJECT_SAME_EMAIL: "The selected subjects cannot have the same email address",
}

// External API Integration Page
export const internalAPIConstants = {
  SETUP_LABEL: "PeopleElement API Integration",
  TABS_LIST: ["Configuration", "Generate Token", "API Docs"],
  SURVEY_QUESTIONS_PANEL: {
    PANEL_LABEL: "Survey Questions",
    SURVEY_INPUT_LABEL: "Select Survey",
  },
  RESPONSE_DEMOGRAPHICS_PANEL: {
    PANEL_LABEL: "Response Demographics",
    DEMOGRAPHY_CUSTOM_NAME: "System Name",
    ALLOW_TO_API_NAME: "Allow to API",
    ALLOW_TO_API_HELP: "You will be granted access to the selected demographics"
  },
  GENERATE_TOKEN_PANEL: {
    DOMAIN_WISHLIST_LABEL: "Wishlist Domain URL",
    API_HIT_LIMIT_PANEL: {
      LABEL: "API Hit Limit",
      LABEL_INFO: "",
      START_DATE_LABEL: "Start Date",
      END_DATE_LABEL: "End Date",
      END_DATE_LABEL_INFO: "",
      REPEAT_LABEL: "Repeat",
      REPEAT_DROPDOWN: ["Day", "Week", "Month"],
    }
  }
}

export const externalApiConstants = {
  SETUP_LABEL: "External API Integration",
  TABS_LIST: ["Data Integrations", "Configuration", "History/Logs"],
  CONFIG_PANEL: {
    PANEL_LABEL: "Authorization",
    API_KEY: "API key",
    SUB_DOMAIN: "Sub Domain",
    REPORT_ID: "Report Id"
  },
  DATA_INTEGRATION: {
    TITLE: 'Connect your HRIS platform',
    DESCRIPTION: 'Integrate your HRIS to People Element to automatically sync / transfer data so that employee data is updated daily.',
    HELP_TEXT: 'To begin, choose your HRIS from the list below.',
    ERROR_MESSAGE: 'Please select HRIS from the list below'
  },
  GENERAL_PANEL: {
    PANEL_LABEL: "General",
    PATH_TO_RECORDS: "PathToRecords"
  },
  API_ENDPOINT_PANEL: {
    PANEL_LABEL: "API End point URL",
    API_METHOD: "API Method",
    END_POINT_URL: "End Point URL"
  },
  HEADER_PANEL: {
    PANEL_LABEL: "Header"
  },
  COMMON_PANEL: {
    TYPE: "Type",
    KEY: "Key",
    VALUE: "Value",
    ADD_ITEM: "Add Item"
  },
  AUTHORIZATION_PANEL: {
    PANEL_LABEL: "Authorization"
  },
  QUERY_PARAMS_PANEL: {
    PANEL_LABEL: "Query Params"
  },
  POST_PARAMS_PANEL: {
    PANEL_LABEL: "Post Params"
  },
  SKIP_LIMIT_PANEL: {
    PANEL_LABEL: "Skip & Limit",
    SKIP: "Skip",
    LIMIT: "Limit",
    DEFAULT_LIMIT: "Default Limit",
    ADD_TO: "Add to"
  },
  HRIS_TITLES: {
    bamboohr: "BambooHR",
    workDay: "Workday",
    adp: "ADP",
    ukg: "UKG",
    paylocity: "Paylocity",
    paycom: "Paycom"
  },
  HRIS_DESCRIPTION: {
    bamboohr: `People Element can import all your employee data directly from BambooHR using the`
  }
}

// dashboard page
export const dashboardConstants = {
  PARTICIPANTS: {
    TITLE: 'Participation',
    ROLLING_PARTICIPATION: 'Rolling Participation',
    ROLLING_PARTICIPATION_HELPER_TEXT: 'Percentage of responses out of people invited to the survey based on the last 12 months of data from the selected end date.',
  },
  FAVOURABLE_BY_CATEGORY: {
    TITLE: 'Favorability by Category',
  },
  OVERALL_FAVORABILITY: {
    POSITIVE: 'Positive',
    NEUTRAL: 'Neutral',
    NEGATIVE: 'Negative',
  },
  OVERALL_ENPS: {
    PROMOTERS: 'Promoters',
    PASSIVE: 'Passives',
    DETRACTORS: 'Detractors'
  },
  ENGAGEMENT_BENCHMARK: {
    BOTTOM_QUARTILE: 'Bottom Quartile',
    MEDIAN: 'Median',
    TOP_QUARTILE: 'Top Quartile',
    YOUR_SCORE: 'Your Score',
    ZERO_PERCENT: '0%',
    TWENTY_FIVE_PERCENT: '49%',
    FIFTY_PERCENT: '58%',
    SEVENTY_FIVE_PERCENT: '66%',
    HUNDREAD_PERCENT: '100%',
  }
}
ReportsConstants.MORE_OPTIONS.participation = dashboardConstants.PARTICIPANTS.TITLE;
ReportsConstants.MORE_OPTIONS.favorableByCategory = dashboardConstants.FAVOURABLE_BY_CATEGORY.TITLE;