// dashboard reducer types
export const dashboardConstant = {
  // reset dashboard loader
  RESET_DASHBOARD_LOADER: 'RESET_DASHBOARD_LOADER',

  // add dashboard request count
  ADD_DASHBOARD_REQUEST_COUNT: 'ADD_DASHBOARD_REQUEST_COUNT',

  // update dashboard loader
  UPDATE_DASHBOARD_LOADER: 'UPDATE_DASHBOARD_LOADER',

  // increment dashboard request count
  INCREMENT_DASHBOARD_REQUEST_COUNT: 'INCREMENT_DASHBOARD_REQUEST_COUNT',

  // High Level Summary
  GET_HLS_REQUEST: 'GET_HLS_REQUEST',
  GET_HLS_SUCCESS: 'GET_HLS_SUCCESS',
  GET_HLS_UPDATE: 'GET_HLS_UPDATE',
  GET_HLS_FAILURE: 'GET_HLS_FAILURE',
  SHOW_HIGH_LEVEL_SUMMERY_POPUP: 'SHOW_HIGH_LEVEL_SUMMERY_POPUP',
  UPDATE_SUMMARY_WIDGET_COLUMNS: 'UPDATE_SUMMARY_WIDGET_COLUMNS',
  UPDATE_SELECTED_SUMMARY_WIDGET_COLUMNS_IN_DASHBOARD: "UPDATE_SELECTED_SUMMARY_WIDGET_COLUMNS_IN_DASHBOARD",

  // Comment Questions
  GET_COMMENT_QUESTION_REQUEST: 'GET_COMMENT_QUESTION_REQUEST',
  GET_COMMENT_QUESTION_SUCCESS: 'GET_COMMENT_QUESTION_SUCCESS',
  GET_COMMENT_QUESTION_FAILURE: 'GET_COMMENT_QUESTION_FAILURE',
  UPDATE_COMMENT_QUESTION: 'UPDATE_COMMENT_QUESTION',

  // Comment Analysis 
  GET_COMMENT_ANALYSIS_REQUEST: 'GET_COMMENT_ANALYSIS_REQUEST',
  GET_COMMENT_ANALYSIS_SUCCESS: 'GET_COMMENT_ANALYSIS_SUCCESS',
  GET_COMMENT_ANALYSIS_FAILURE: 'GET_COMMENT_ANALYSIS_FAILURE',

  //update nlp selected type
  UPDATE_NLP_SELECTED_DATA: "UPDATE_NLP_SELECTED_DATA",

  //Reset to initial state when filter changes
  UPDATE_CHART_DATAS: "UPDATE_CHART_DATAS",

  //update ei data
  UPDATE_EI_KEYS_DATA: "UPDATE_EI_KEYS_DATA",

  //update grid
  UPDATE_GRID_KEYS_DATA: "UPDATE_GRID_KEYS_DATA",

  SHOW_GRIDCHART_POPUP: "SHOW_GRIDCHART_POPUP",

  // Yes No chart Constants
  GET_YESNO_REQUEST: 'GET_YESNO_REQUEST',
  GET_YESNO_SUCCESS: 'GET_YESNO_SUCCESS',
  GET_YESNO_FAILURE: 'GET_YESNO_FAILURE',
  SHOW_YESNO_POPUP: 'SHOW_YESNO_POPUP',

  // Dropdown chart Constants
  GET_DROPDOWN_REQUEST: 'GET_DROPDOWN_REQUEST',
  GET_DROPDOWN_SUCCESS: 'GET_DROPDOWN_SUCCESS',
  GET_DROPDOWN_FAILURE: 'GET_DROPDOWN_FAILURE',
  SHOW_DROPDOWN_POPUP: 'SHOW_DROPDOWN_POPUP',

  //EI overall data
  GET_EI_OVERALL_REQUEST: 'GET_EI_OVERALL_REQUEST',
  GET_EI_OVERALL_SUCCESS: 'GET_EI_OVERALL_SUCCESS',
  GET_EI_OVERALL_FAILURE: 'GET_EI_OVERALL_FAILURE',

  SHOW_EI_POPUP: 'SHOW_EI_POPUP',

  //EI overall data
  GET_EI_RGI_REQUEST: 'GET_EI_RGI_REQUEST',
  GET_EI_RGI_SUCCESS: 'GET_EI_RGI_SUCCESS',
  GET_EI_RGI_FAILURE: 'GET_EI_RGI_FAILURE',

  // Historical
  GET_HISTORICAL_REQUEST: 'GET_HISTORICAL_REQUEST',
  GET_HISTORICAL_SUCCESS: 'GET_HISTORICAL_SUCCESS',
  GET_HISTORICAL_FAILURE: 'GET_HISTORICAL_FAILURE',

  // trends over time constant
  GET_TOT_REQUEST: 'GET_TOT_REQUEST',
  GET_TOT_SUCCESS: 'GET_TOT_SUCCESS',
  GET_TOT_FAILURE: 'GET_TOT_FAILURE',

  // update tot custom preference surveys
  UPDATE_TOT_PREFERENCE: 'UPDATE_TOT_PREFERENCE',


  //Hotspot chart constant
  GET_HOTSPOT_REQUEST: 'GET_HOTSPOT_REQUEST',
  GET_HOTSPOT_SUCCESS: 'GET_HOTSPOT_SUCCESS',
  GET_HOTSPOT_FAILURE: 'GET_HOTSPOT_FAILURE',
  UPDATE_HOTSPOT_DATA: "UPDATE_HOTSPOT_DATA",
  SHOW_HOTSPOT_POPUP: 'SHOW_HOTSPOT_POPUP',

  // dashboard action widget
  GET_DASHBOARD_SUCCESS: 'GET_DASHBOARD_SUCCESS',
  GET_DASHBOARD_REQUEST: 'GET_DASHBOARD_REQUEST',
  GET_DASHBOARD_FAILURE: 'GET_DASHBOARD_FAILURE',

  // participant invite widget
  GET_PARTICIPANT_INVITE_REQUEST: 'GET_PARTICIPANT_INVITE_REQUEST',
  GET_PARTICIPANT_INVITE_SUCCESS: 'GET_PARTICIPANT_INVITE_SUCCESS',
  GET_PARTICIPANT_INVITE_FAILURE: 'GET_PARTICIPANT_INVITE_FAILURE',

  // Grid chart Constants
  GET_GRID_REQUEST: 'GET_GRID_REQUEST',
  GET_GRID_SUCCESS: 'GET_GRID_SUCCESS',
  GET_GRID_FAILURE: 'GET_GRID_FAILURE',

  // Chart Visiblity
  CHART_VISIBILITY_REQUEST: 'CHART_VISIBILITY_REQUEST',
  CHART_VISIBILITY_SUCCESS: 'CHART_VISIBILITY_SUCCESS',
  CHART_VISIBILITY_FAILURE: 'CHART_VISIBILITY_FAILURE',

  // download dashboard report
  DOWNLOAD_DASHBOARD_SUCCESS: 'DOWNLOAD_DASHBOARD_SUCCESS',
  DOWNLOAD_DASHBOARD_REQUEST: 'DOWNLOAD_DASHBOARD_REQUEST',
  DOWNLOAD_DASHBOARD_FAILURE: 'DOWNLOAD_DASHBOARD_FAILURE',

  // Dashboard over all widget
  GET_DASHBOARD_OVERALL_SUCCESS: 'GET_DASHBOARD_OVERALL_SUCCESS',
  GET_DASHBOARD_OVERALL_REQUEST: 'GET_DASHBOARD_OVERALL_REQUEST',
  GET_DASHBOARD_OVERALL_FAILURE: 'GET_DASHBOARD_OVERALL_FAILURE',

  // chart reload
  CHARTS_INSINGT_RELOAD: 'CHARTS_INSINGT_RELOAD',

  //Initial reload set
  UPDATE_INITIAL_RELOAD: 'UPDATE_INITIAL_RELOAD',

  // Competency Summary cahrt
  GET_COMPETENCY_REQUEST: 'GET_COMPETENCY_REQUEST',
  GET_COMPETENCY_SUCCESS: 'GET_COMPETENCY_SUCCESS',
  GET_COMPETENCY_FAILURE: 'GET_COMPETENCY_FAILURE',

  // Reset Chart to Initial state
  RESET_CHART_DATA_REQUEST: 'RESET_CHART_DATA_REQUEST',

  // Reset Data Filters Applied Flag
  RESET_DATA_FILTERS_APPLIED: 'RESET_DATA_FILTERS_APPLIED',

  // Update Report Tab Name
  UPDATE_TAB_NAME: 'UPDATE_TAB_NAME',

  RESET_COMMENT_CHART: 'RESET_COMMENT_CHART',

  GET_KEY_DRIVER_REQUEST: 'GET_KEY_DRIVER_REQUEST',
  GET_KEY_DRIVER_SUCCESS: 'GET_KEY_DRIVER_SUCCESS',
  GET_KEY_DRIVER_FAILURE: 'GET_KEY_DRIVER_FAILURE',

  UPDATE_COMMENT_CHART_STATUS: 'UPDATE_COMMENT_CHART_STATUS',

  // Get favourable by category
  GET_FAVOURABLE_CATEGORY_REQUEST: 'GET_FAVOURABLE_CATEGORY_REQUEST',
  GET_FAVOURABLE_CATEGORY_SUCCESS: 'GET_FAVOURABLE_CATEGORY_SUCCESS',
  GET_FAVOURABLE_CATEGORY_FAILURE: 'GET_FAVOURABLE_CATEGORY_FAILURE',

  SAOA_CACHE_UPDATE: "SAOA_CACHE_UPDATE",

  //Empty historical Data
  ENGAGEMENT_HISTORICAL_UPDATE: "ENGAGEMENT_HISTORICAL_UPDATE",

  // apply report filters
  APPLY_HLS_FILTERS: 'APPLY_HLS_FILTERS',
  APPLY_HOTSPOT_FILTERS: 'APPLY_HOTSPOT_FILTERS',

};
