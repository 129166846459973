import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import TypographyComponent from "../../components/TypographyComponent/TypographyComponent";
import { useDispatch, useSelector } from "react-redux";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Link } from "react-router-dom";
// Import Helpers
import { addEllipsis } from "../../helpers";
// import constants
import { surveyTypes } from "../../config/constants";
// Import images
import PulseImg from "../../assets/images/svg/pulseicon.svg";
//Import Actions
import { rawdataAutomationAction } from "../../redux/actions";
import SelectFieldComponent from "../../components/SelectFieldComponent/SelectFieldComponent";

const SurveySelection = (props) => {
  // Define Dispatch
  const dispatch = useDispatch();

  // get props
  const { classes } = props;

  // Get surveys Data
  let { allSurveys, surveys, date } = useSelector(
    (state) => state.rawdataAutomation
  );

  // update surveys
  const updateSurveys = (e) => {
    // Ensure only one survey is selected
    surveys = [e];

    allSurveys.map((eachSurvey) => {
      const surveydetails = surveys?.filter(
        (obj) => obj._id === eachSurvey._id
      );
      if (surveydetails.length > 0) {
        date.type = "Custom dates";
        date.startDate = eachSurvey.startDate;
        date.endDate = eachSurvey.endDate;
      }
    });

    const updatedReportData = {
      surveys: surveys,
    };
    dispatch(rawdataAutomationAction.updateSurveys(updatedReportData));
  };

  // Get Demographics Data
  const getDemographics = (dateUpdated) => {
    dispatch(
      rawdataAutomationAction.getDemographics({
        metadata: [],
        surveys: surveys,
        date: dateUpdated,
      })
    );
  };

  useEffect(() => {
    if (date?.type !== "") {
      getDemographics(date);
    }
  }, [date]);

  // Get Survey Data
  useEffect(() => {
    dispatch(
      rawdataAutomationAction.getSurveys("", (getSurveysRes) => {
        //default selecet first survey
        if (surveys && "surveys".length === 0 && getSurveysRes.length > 0) {
          let surveyData = {
            name: getSurveysRes[0].name,
            _id: getSurveysRes[0]._id,
            frequency: getSurveysRes[0].frequency,
            type: getSurveysRes[0].type,
            hasRehireBaseQues: getSurveysRes[0].hasRehireBaseQues,
            hasComplianceBaseQues: getSurveysRes[0].hasComplianceBaseQues,
            startDate: getSurveysRes[0].startDate,
            endDate: getSurveysRes[0].endDate,
          };
          updateSurveys(surveyData);
        }
      })
    );
  }, [dispatch]);


  return (
    <React.Fragment>
      <Grid container>
        <Grid xs={10}>
          <TypographyComponent
            title="Survey*"
            variant="body1"
            style={{ fontSize: "14px" }}
            align={"Left"}
          ></TypographyComponent>
        </Grid>
      </Grid>
      <Grid container>
      <Grid xs={12}>
      <SelectFieldComponent
        select
        name="surveys"
        validators={["required"]}
        showErrorMessages={true}
        value={surveys?.[0]?._id}
        variant="outlined"
        fullWidth={true}
        handleChange={(e) =>{
          let selectedsurvey = allSurveys.find(s=>s._id == e.value);
          updateSurveys({
            name: selectedsurvey.name,
            _id: selectedsurvey._id,
            frequency: selectedsurvey.frequency,
            hasRehireBaseQues: selectedsurvey.hasRehireBaseQues,
            hasComplianceBaseQues:
              selectedsurvey.hasComplianceBaseQues,
            startDate: selectedsurvey.startDate
          })
         }
        }
        options={allSurveys}
      />
      </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default SurveySelection;
