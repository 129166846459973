import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import {
  Grid,
  Box,
  Chip,
  RadioGroup,
  Radio,
  FormControlLabel,
  Tooltip
} from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import ChipInput from '@jansedlon/material-ui-chip-input';

// Import Styles
import ReportsStyles from "../../ReportsStyles";

// Import helpers
import { IsEmail } from "../../../../helpers/appHelper";

// Import Components
import TypographyComponent from "../../../../components/TypographyComponent/TypographyComponent";
import SelectFieldComponent from "../../../../components/SelectFieldComponent/SelectFieldComponent";
import TextFieldComponent from "../../../../components/TextFieldComponent/TextFieldComponent";
import InformationComponent from "../../../../components/InformationComponent/InformationComponent";

// Import constants
import { surveyTypes, ReportsConstants } from "../../../../config/constants";

// Import Actions
import { reportAction } from "../../../../redux/actions";

function SaveReport({ classes, isDrawer }) {
  const dispatch = useDispatch();
  const { customReportData } = useSelector((state) => state.report);
  const { filterData } = useSelector(state => state.filter);
  const hasMESurvey = (filterData.surveys || [])?.some(s => s.type === surveyTypes.MULTI_RATER_FEEDBACK);
  const [downloadOption, setDownloadOption] = useState("pdf");

  const getReportSchedules = () => {
    const baseSchedules = [
      { displayName: "Weekly", name: "Weekly" },
      { displayName: "Monthly", name: "Monthly" },
      { displayName: "Quarterly", name: "Quarterly" }
    ];

    const specialReportTypes = [
      "Participation",
      "Rehire Report",
      "Red Flag Report"
    ];

    return specialReportTypes.includes(customReportData?.reportType)
      ? [{ displayName: "Daily", name: "Daily" }, ...baseSchedules]
      : baseSchedules;
  };

  const reportSchedules = getReportSchedules();

  const [reportFrequency, setreportFrequency] = useState(reportSchedules || "");

  useEffect(() => {
    const updatedReportData = {
      ...customReportData,
      downloadType: customReportData.downloadType || downloadOption
    };
    dispatch(reportAction.updateCustomReport(updatedReportData));

  }, []);

  const updateEmailIds = (newEmails) => {
    let allemails = [
      ...(customReportData?.emailIds || []),
      ...newEmails
    ].filter(e => e && e.trim() !== "");

    const updatedReportData = {
      ...customReportData,
      emailIds: allemails
    };
    dispatch(reportAction.updateCustomReport(updatedReportData));
  };

  const handleAddChipOnBlur = (event) => {
    const chip = event.target.value;
    if (chip?.trim()) {
      const emailIdArr = chip.split(/[\s,;]+/).filter(Boolean);
      updateEmailIds(emailIdArr);
    }
  };

  const handleAddChip = (chip) => {
    if (chip?.trim()) {
      const emailIdArr = chip.split(/[\s,;]+/).filter(Boolean);
      updateEmailIds(emailIdArr);
    }
  };

  const getEmailChips = (emailArr) => {
    if (!emailArr) return [];

    return emailArr.map(i => {
      const email = typeof i === 'string'
        ? i.trim().toLowerCase()
        : i.email || '';

      return {
        email,
        isValid: IsEmail(email)
      };
    });
  };

  const handleDeleteChip = (chip, index) => {
    const updatedEmails = [...customReportData.emailIds];
    updatedEmails.splice(index, 1);
    const updatedReportData = {
      ...customReportData,
      emailIds: updatedEmails
    };
    dispatch(reportAction.updateCustomReport(updatedReportData));
  };

  const getHelpText = () => {
    const frequencyHelpMap = {
      [reportSchedules[0].name]: ReportsConstants.FREQUENCYOF_REPORT_HELP_WEEKLY,
      [reportSchedules[1].name]: ReportsConstants.FREQUENCYOF_REPORT_HELP_MONTHLY,
      [reportSchedules[2].name]: ReportsConstants.FREQUENCYOF_REPORT_HELP_QUARTERLY
    };

    return frequencyHelpMap[customReportData.frequency] || ReportsConstants.FREQUENCYOF_REPORT_HELP;
  };

  const handleDownloadOptionChange = (value) => {
    setDownloadOption(value);
    const updatedReportData = {
      ...customReportData,
      downloadType: value
    };
    dispatch(reportAction.updateCustomReport(updatedReportData));

  };

  const changeReportSchedule = (e) => {
    const updatedReportData = {
      ...customReportData,
      frequency: e.value
    };
    dispatch(reportAction.updateCustomReport(updatedReportData));
  };

  return (
    <Grid container style={{ maxHeight: "50vh" }}>
      <Grid container style={{ justifyContent: 'left' }}>
        <Grid container className={classes.lightOrangeBox}>
          <Box>
            <p>{ReportsConstants.CUSTOM_SCHEDULE}</p>
          </Box>
        </Grid>
      </Grid>

      <Grid container>
        <Grid container direction="column" spacing={1} className="mb-2">
          <Grid item>
            <TypographyComponent variant="body1" className="txtBold">
              {!hasMESurvey ? ReportsConstants.CUSTOM_REPORT_DOWNLOAD : ReportsConstants.CUSTOM_REPORT_DOWNLOAD}
            </TypographyComponent>
          </Grid>

          {!hasMESurvey && (
            <Grid item>
              <RadioGroup
                row
                value={customReportData.downloadType || downloadOption}
                onChange={(e) => handleDownloadOptionChange(e.target.value)}
              >
                <Grid container direction="row" spacing={3}>
                  <Grid item>
                    <FormControlLabel value="pdf" control={<Radio />} label="PDF" />
                  </Grid>
                  <Grid item>
                    <FormControlLabel value="ppt" control={<Radio />} label="PowerPoint" />
                  </Grid>
                </Grid>
              </RadioGroup>
            </Grid>
          )}
        </Grid>

        <Grid container className="mb-2">
          <Grid item sm={8}>
            <TypographyComponent variant="h6" className="txtBold">
              {ReportsConstants.CUSTOM_REPORT_FREQUENCY}
              <InformationComponent>
                <TypographyComponent variant="tooltipbody">
                  {getHelpText()}
                </TypographyComponent>
              </InformationComponent>
            </TypographyComponent>
          </Grid>
          <Grid item sm={8}>
            <Grid className={classes.frequencyWidth}>
              <SelectFieldComponent
                select
                name="RGConversion"
                value={customReportData.frequency || ""}
                fullWidth
                handleChange={changeReportSchedule}
                options={reportSchedules}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid container>
          <TypographyComponent variant="h6" className="txtBold">
            {ReportsConstants.CUSTOM_REPORT_EMAIL}
            <InformationComponent>
              <TypographyComponent variant="tooltipbody">
                {ReportsConstants.CUSTOM_EMAIL_TOOLTIP}
              </TypographyComponent>
            </InformationComponent>
          </TypographyComponent>

          <Grid item sm={12} className={classes.saveReportEmail}>
            <ChipInput
              className={`${classes.chipInput} ${classes.frequencyWidth} chipboderRadius10 ${isDrawer ? 'drawer' : ''}`}
              variant="outlined"
              color="primary"
              value={getEmailChips(customReportData.emailIds)}
              onBlur={handleAddChipOnBlur}
              onAdd={handleAddChip}
              onDelete={handleDeleteChip}
              dataSource={getEmailChips(customReportData.emailIds)}
              chipRenderer={({ value, handleDelete }, key) => (
                <Chip
                  key={key}
                  label={value.email}
                  onClick={(e) => e.stopPropagation()}
                  onDelete={handleDelete}
                  className={value.isValid ? "maxWidth100" : `${classes.errorChipReport} maxWidth100`}
                />
              )}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

SaveReport.defaultProps = {
  classes: {},
  isDrawer: false
};

SaveReport.propTypes = {
  classes: PropTypes.object,
  isDrawer: PropTypes.bool
};

export default withStyles(ReportsStyles)(SaveReport);