import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
    Grid,
    Box,
    Paper,
    TextField,
    Checkbox,
    FormControlLabel,
    Tooltip
} from "@mui/material";

import TypographyComponent from "../../../../../components/TypographyComponent/TypographyComponent";
import AutoCompleteComponent from "../../../../../components/AutoCompleteComponent/AutoCompleteComponent";
import ButtonComponent from "../../../../../components/ButtonComponent/ButtonComponent";
import { ReportsConstants } from "../../../../../config/constants";
import { reportAction, alertAction } from "../../../../../redux/actions";
import SearchInputComponent from '../../../../../components/SearchInputCompoment/SearchInputComponent';

const DropdownQuestions_Custom = () => {
    const dispatch = useDispatch();
    const { customReportData } = useSelector((state) => state.report);
    const dropdown_custom = useSelector((state) => state.report.dropdown);

    // Fetch dropdown data when custom report data is available
    useEffect(() => {
        if (customReportData?.id) {
            dispatch(reportAction.getCustomDropdownData(customReportData));
        }
    }, [customReportData]);

    // Initialize state
    const [dropdownData, setDropdownData] = useState(dropdown_custom);
    const [searchText, setSearchText] = useState("");

    // Prepare question texts
    const questionTexts = Object.values(dropdownData || {})?.length > 0
        ? Object.values(dropdownData || {})?.map(({ basequsid, question }) => ({
            id: basequsid,
            text: question,
        }))
        : [];

    // Filter questions based on search text
    const filteredQuestions = questionTexts.filter(question =>
        question.text.toLowerCase().includes(searchText.toLowerCase())
    );

    // Initialize selected questions to all questions by default
    const [selectedQuestionIds, setSelectedQuestionIds] = useState(
        customReportData?.dashboardPreference?.dropdown?.selectedQuestions || filteredQuestions.map(q => q.id) || []
    );

    const [sortOption, setSortOption] = useState(
        customReportData?.dashboardPreference?.dropdown?.sortField || 1
    );

    // Toggle individual question selection
    const handleQuestionToggle = (questionId) => {
        setSelectedQuestionIds(prev =>
            prev.includes(questionId)
                ? prev.filter(id => id !== questionId)
                : [...prev, questionId]
        );
    };

    // Check if a specific question is selected
    const isQuestionSelected = (questionId) => {
        return selectedQuestionIds.includes(questionId);
    };

    // Check if all filtered questions are selected
    const areAllFilteredQuestionsSelected = () => {
        return filteredQuestions.every(question =>
            selectedQuestionIds.includes(question.id)
        );
    };

    // Select or deselect all filtered questions
    const handleSelectAllQuestions = (event) => {
        const allFilteredQuestionIds = filteredQuestions.map(q => q.id);

        setSelectedQuestionIds(prev =>
            event.target.checked
                ? [...new Set([...prev, ...allFilteredQuestionIds])]
                : prev.filter(id => !allFilteredQuestionIds.includes(id))
        );
    };

    // Clear all selected questions
    const handleClearAll = () => {
        setSelectedQuestionIds([]);
    };

    // Handle sorting option change
    const handleSorting = (sortObject) => {
        setSortOption(sortObject.sortField);
    };

    const checkDuplicateConfigs = (DropdownPreferences) => {
        const isDuplicate = (existingConfigs, newPreferences) => {
            const normalizeObject = (obj) => JSON.stringify(obj, Object.keys(obj).sort());
            const normalizedNewPreferences = normalizeObject(newPreferences);
            return Object.values(existingConfigs).some(config =>
                normalizeObject(config) === normalizedNewPreferences
            );
        };

        const existingDropdownConfigs = customReportData?.dashboardPreference?.dropdown || {};

        const isDuplicateDropdown = isDuplicate(existingDropdownConfigs, DropdownPreferences);

        if (isDuplicateDropdown) {
            dispatch(alertAction.error('A chart with identical configuration already exists'));
            return true; // Return true if duplicates are found
        }

        return false; // Return false if no duplicates are found
    };

    // Add summary chart to the report
    const handleAddChart = () => {
        try {
            if (!selectedQuestionIds || selectedQuestionIds.length === 0) {
                dispatch(alertAction.error(ReportsConstants.CUSTOM_QUESTION_INCLUDE_TEXT));
                return;
            }
            const chartNumber = 'Dropdown';
            const updatedChartOrder = customReportData.chartOrder || [];

            // Generate unique chart number
            let count = 1;
            let uniqueChartNumber = chartNumber;
            while (updatedChartOrder.includes(uniqueChartNumber)) {
                uniqueChartNumber = `${chartNumber} ${count}`;
                count++;
            }

            // Prepare new chart preferences
            const newChartPreferences = {
                sortField: sortOption,
                selectedQuestions: selectedQuestionIds
            };
            const hasDuplicates = checkDuplicateConfigs(newChartPreferences)
            if (hasDuplicates) {
                return;
            }

            // Update dashboard preference
            const updatedDashboardPreference = {
                ...customReportData?.dashboardPreference,
                dropdown: {
                    ...customReportData?.dashboardPreference?.dropdown,
                    // sortField: sortOption,
                    // selectedQuestions: selectedQuestionIds,
                    [uniqueChartNumber]: newChartPreferences
                }
            };

            // Update report data
            const updatedReportData = {
                ...customReportData,
                chartOrder: [...updatedChartOrder, uniqueChartNumber],
                dashboardPreference: updatedDashboardPreference
            };

            dispatch(reportAction.updateCustomReport(updatedReportData));

        } catch (error) {
            console.error("Error in handleAddChart:", error);
        }
    };

    return (
        <Grid display={'flex'} gap={'2rem'}>
            <Grid item flex={'1'}>
                <Grid item xs>
                    <TypographyComponent variant="h6" className="txtBold">
                        {ReportsConstants.CUSTOM_QUESTION_INCLUDE_TEXT}
                    </TypographyComponent>
                </Grid>

                <Grid item xs style={{ paddingTop: '16px' }}>
                    <Paper elevation={1}>
                        <Box p={2}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <SearchInputComponent
                                        id="DropDownSearch"
                                        placeholder="Search"
                                        value={searchText}
                                        field="search"
                                        showClearIcon={true}
                                        fullWidth={true}
                                        onInputChangeRequest={(searchField, searchKey) => setSearchText(searchKey)}
                                        handleClearSearch={() => setSearchText("")}
                                    />
                                </Grid>

                                {/* Questions List */}
                                <Grid item xs={12}>
                                    {!searchText &&
                                        <Grid container justifyContent="space-between" alignItems="center">
                                            <Grid item>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={areAllFilteredQuestionsSelected()}
                                                            onChange={handleSelectAllQuestions}
                                                            color="primary"
                                                        />
                                                    }
                                                    label="Select all"
                                                />
                                            </Grid>
                                            <Grid item>
                                                <Box
                                                    component="span"
                                                    onClick={handleClearAll}
                                                    sx={{
                                                        cursor: 'pointer',
                                                        color: 'primary.main',
                                                        textDecoration: 'underline'
                                                    }}
                                                >
                                                    {ReportsConstants.CUSTOM_CLEAR_ALL}
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    }
                                    <Box sx={{ maxHeight: '300px', overflowY: 'auto' }}>
                                        <Grid container spacing={1}>
                                            {filteredQuestions.map(({ id, text }) => (
                                                <Grid item xs={12} key={id}>
                                                    <Tooltip title={text} arrow>
                                                        <FormControlLabel
                                                            sx={{ ml: 0 }}
                                                            control={
                                                                <Checkbox
                                                                    checked={isQuestionSelected(id)}
                                                                    onChange={() => handleQuestionToggle(id)}
                                                                    color="primary"
                                                                    sx={{ ml: 1 }}
                                                                />
                                                            }
                                                            label={text}
                                                        />
                                                    </Tooltip>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Paper>
                </Grid>

                {/* Sorting Options */}
                <Grid item xs>
                    <Grid container alignItems="center" spacing={2}>
                        <Grid item>
                            <TypographyComponent
                                className="txtBold"
                                title={ReportsConstants.CUSTOM_SORT_BY}
                                variant="h6"
                            />
                        </Grid>
                        <Grid item>
                            <AutoCompleteComponent
                                name="gridSort"
                                suggestions={ReportsConstants.DROPDOWN_SORT_OPTIONS}
                                handleAutoCompolete={(val) => {
                                    handleSorting({
                                        sortField: val.value,
                                        sortBy: val.sortBy
                                    });
                                }}
                                suggestionType="gridSortOptions"
                                value={ReportsConstants.DROPDOWN_SORT_OPTIONS.find(
                                    option => option.value === sortOption
                                )}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {/* Add Chart Button */}
            <Grid item display={'inline-flex'} flexDirection={'column'} flexBasis={'200px'} justifyContent={'flex-end'}>
                <Grid display={'flex'} flexDirection={'column'} gap={'10px'}>
                    <ButtonComponent
                    color="primary"
                    pageClassName="buttonOrange fontSize14"
                    title="+ Add chart to report"
                    handleClick={handleAddChart}
                    />
                    {/* <TypographyComponent variant="h6">
                    {ReportsConstants.CUSTOM_ADD_CHART_TEXT}
                    </TypographyComponent> */}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default DropdownQuestions_Custom;