import React from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import withStyles from '@mui/styles/withStyles';
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import CircularProgress from '@mui/material/CircularProgress';

// Import Styles and Image
import NewReportStyles from "./NewReportStyles"
import PlusImg from "../../../../assets/images/svg/plus_grey.svg";
import MinusImg from "../../../../assets/images/svg/minus_blue.svg";

// Import Component
import TypographyComponent from "../../../../components/TypographyComponent/TypographyComponent";


//Import Actions
import { reportAction } from "../../../../redux/actions";

function DemographicList(props) {
  const dispatch = useDispatch();


  // get props
  const {
    classes,
    currentDemographic,
    currentDemographicCount,
    allDemographicsItems,
    customReportData,
    metadata,
    search,
    reportloader
  } = props;


  const [expanded, setExpanded] = React.useState(false);

  // Get Demographic Items from DB 
  const getDemograpicsItemsReq = () => {
    dispatch(
      reportAction.demographicsByName({
        metadata: [],
        surveys: customReportData.surveys,
        date: customReportData.date,
        demographic: currentDemographic,
        search: search ? search : "",
      })
    );
  };

  // Handle panel open/close
  const handlePanelChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
    if (newExpanded) {
      getDemograpicsItemsReq();
    }
  };

  // Get Demographic Name
  const selData = (metadata || {})?.filter((e) => e.key === currentDemographic);
  const demographicName = selData?.[0] && selData?.[0]?.value ? selData?.[0]?.value : "";

  // Get items for current demographic
  const currentDemographItems = allDemographicsItems[currentDemographic] || [];

  // Update reducer on checkbox change
  const updateDemographics = (e, item) => {
    if (e?.target?.checked) {
      if (customReportData?.demographics && customReportData?.demographics?.[currentDemographic]) {
        customReportData?.demographics?.[currentDemographic].push(item?._id);
      } else {
        customReportData.demographics = {
          ...customReportData.demographics,
          [currentDemographic]: [item?._id],
        };
      }
    } else {
      customReportData.demographics[currentDemographic].splice(customReportData.demographics[currentDemographic].indexOf(item._id), 1);
    }
    if (customReportData?.demographics && customReportData.demographics[currentDemographic].length === 0) {
      delete customReportData.demographics[currentDemographic];
    }
    dispatch(reportAction.updateCustomReport(customReportData));
  };

  // Generate demographics items
  const generateDemographicItems = () => {
    return currentDemographItems.sort().map((item) => (
      <Grid item xs={12} key={item._id} className={`${classes.expansionDetail} pl-2`}>
        <Grid item className={classes.demographyCheckbox}>
          <FormControlLabel
            control={
              <Checkbox
                name={item._id}
                color="primary"
                checked={
                  customReportData.demographics && customReportData.demographics[currentDemographic] &&
                    customReportData.demographics[currentDemographic].includes(item._id) ? true : false
                }
                onChange={(e) => updateDemographics(e, item)}
              />
            }
            label={`${item._id} (${item.data})`}
          />
        </Grid>
      </Grid>
    ));
  };

  return (
    <React.Fragment>
      <Accordion
        classes={{ root: classes.peaccordion_nocolor_demography }}
        expanded={expanded === "demographyoptions-panel"}
        onChange={handlePanelChange("demographyoptions-panel")}
      >
        <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
          <TypographyComponent
            variant={"h6"}
            className={`${expanded === "demographyoptions-panel" ? "panel-active" : ""}`}
          >
            <Grid container alignItems="center">
              <img className={`${classes.expansionPlus} ${"expansionPlus pr-1"}`} src={PlusImg} alt="expand" />
              <img className={`${classes.expansionMinus} ${"expansionMinus pr-1"}`} src={MinusImg} alt="collapse" />
              {`${demographicName} (${currentDemographicCount})`}
            </Grid>
          </TypographyComponent>
        </AccordionSummary>
        <AccordionDetails>
          {reportloader
            ? <Grid item xs={12} className={`txtcenter p-3`}>
              <CircularProgress size={30} />
            </Grid>
            :
            <Grid key={currentDemographic}>
              {generateDemographicItems()}
            </Grid>
          }
        </AccordionDetails>
      </Accordion>
    </React.Fragment>
  );
}

// Default props
DemographicList.defaultProps = {
  classes: {},
  currentDemographic: "",
  currentDemographicCount: 0,
  allDemographicsItems: {},
  customReportData: {},
  metadata: []
};

// Prop types
DemographicList.propTypes = {
  classes: PropTypes.object,
  currentDemographic: PropTypes.string,
  currentDemographicCount: PropTypes.number,
  allDemographicsItems: PropTypes.object,
  customReportData: PropTypes.object,
  metadata: PropTypes.array,
};

export default withStyles(NewReportStyles)(DemographicList);
