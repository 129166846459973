import * as apihelper from "../../helpers/apiHelper";

/**
 * Export All dashboard Service Functions
 */
export const dashboardService = {
  EIOverall,
  EIWithRgi,
  getDashboardData,
  getDashboardActionData,
  getParticipantInvite,
  getHighLevelSummaryData,
  getHistoricalData,
  gethighCommentQuestions,
  getTrendsOverTimeData,
  getYesNoData,
  getDropdownData,
  getHotspotData,
  getCommentAnalysis,
  getGridChartData,
  updateChartVisiblity,
  downloadDashboardReport,
  downloadDashboardReportPPT,
  getCompetencySummary,
  getKeyDriverData
};

/**
 * GET Engagement IndexOverall Data
 */
async function EIOverall(filterData) {
  const response = await apihelper.postRequest("dashboard/EIOverall", filterData);
  return response;
}

/**
 * GET Engagement Index for demographics Data
 */
async function EIWithRgi(filterData) {
  const response = await apihelper.postRequest("dashboard/EIWithRgi", filterData);
  return response;
}

/**
 * GET Dashboard Data
 */
async function getDashboardData(type, diffData) {
  const response = await apihelper.postRequest("dashboard/dashboardData", { type, diffData });
  return response;
}

/**
 * GET Dashboard Data
 */
async function getDashboardActionData() {
  const response = await apihelper.postRequest("actions/opportunities");
  return response;
}

/**
 * GET Participant Invite Data
 */
async function getParticipantInvite() {
  const response = await apihelper.postRequest("dashboard/participantInvite");
  return response;
}
/*
 * Get High Level Summary data
 */
async function getHighLevelSummaryData(isMS=true) {
  const response = await apihelper.postRequest('dashboard/hlsApData', {}, false, "", isMS);
  return response;
}

/**
 * Get Historical data
 */
async function getHistoricalData() {
  const response = await apihelper.postRequest('dashboard/historicalData');
  return response;
}

/*
 * Get Comment Analysis question
 */
async function gethighCommentQuestions() {
  const response = await apihelper.postRequest('dashboard/getCommentQuestion');
  return response;
}

/*
 * Get Comment Analysis data
 */
async function getCommentAnalysis(quesId) {
  const response = await apihelper.postRequest('dashboard/getCommentAnalysis', quesId);
  return response;
}

/**
 * Get trends over time data
 */
async function getTrendsOverTimeData(surveys = null) {
  let surveyData = "";
  if (surveys && surveys.length > 0) {
    surveyData = { tot_surveys: surveys }
  }
  else {
    surveyData = []
  }
  const response = await apihelper.postRequest('dashboard/totData', surveyData);
  return response;
}

/**
 * Get Yes No data
 */
async function getYesNoData() {
  const response = await apihelper.postRequest('dashboard/yesnoData');
  return response;
}

/**
 * Get Yes No data
 */
async function getDropdownData() {
  const response = await apihelper.postRequest('dashboard/dropdownData');
  return response;
}


/**
 * Get Hotspot Data
 */
async function getHotspotData(isMS=true) {
  const response = await apihelper.postRequest('dashboard/hotspot',{}, false, "", isMS);
  return response;
}

/**
 * Get Grid Data
 */
async function getGridChartData() {
  const response = await apihelper.postRequest('dashboard/gridchart');
  return response;
}

/**
 * Update Chart Visiblity
 */
async function updateChartVisiblity(charts) {
  const response = await apihelper.putRequest('dashboart/update-chart')
  return response
}

/**
 * download dashboard report
 */
async function downloadDashboardReport(data) {
  try {
    const response = await apihelper.postRequest('pdf/export', data, false, 'json', true);
    if (response.data && response.data.filePath) {
      const responseOne = await apihelper.postRequestExcel('pdf/exportPdf', response.data, false, "arraybuffer", true);
      return responseOne;
    } else {
      return response;
    }
  } catch (e) {
    console.error(e)
  }
}

/**
 * download dashboard report PPT
 */
async function downloadDashboardReportPPT(data) {
  try {
    const response = await apihelper.postRequest('ppt/export', data, false, 'json', true);
    if (response.data && response.data.filePath) {
      const responseOne = await apihelper.postRequestExcel('ppt/exportppt', response.data, false, "arraybuffer", true);
      return responseOne;
    } else {
      return response;
    }
  } catch (e) {
    console.error(e)
  }
}

/**
 * Get Competency Chart Data
 */
 async function getCompetencySummary() {
  const response = await apihelper.postRequest('dashboard/competency-summary');
  return response;
}

/**
 * Get Key Driver Data
 */
 async function getKeyDriverData() {
  const response = await apihelper.getRequest('api/dashboard/key-driver-analysis',false,'pyserver_url');
  return response;
}