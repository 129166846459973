import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import { useDispatch, useSelector } from "react-redux";
import withStyles from '@mui/styles/withStyles';
import { reportAction } from '../../redux/actions'

import ReportsStyles from "./ReportsStyles";

import NewReport from "./Report/CustomReportBuilder/NewReport"
import { getTableRowSize } from "../../config/layout";
const tableRowSize = getTableRowSize();

// Import App Helpers
import { getCustomReportPrevHistory, setCustomReportPrevHistory } from "../../helpers/appHelper";

function CreateCustomReportPopup(props) {
  // Define Dispatch
  const dispatch = useDispatch();


  // get props
  const { closePopup, isSaveReport, match, step } = props;
  const { id } = match.params;

  useEffect(() => {
    dispatch(reportAction.getSavedReports(1, tableRowSize, "name", "asc", [], []));
    if (!isSaveReport) {
      dispatch(reportAction.resetCustomReportData())
    } else {

      if (isSaveReport && id) {
        // Fetch the existing report data for editing
        dispatch(reportAction.getCustomReportData({ id: id }));
      }
    }
  }, [id, isSaveReport]);

  const routerDomHistory = useHistory();
  const [previousHistory, setPreviousHistory] = useState('Unknown');
  
  useEffect(() => {
    const fromHistory = routerDomHistory.location.state?.from || getCustomReportPrevHistory();
    if (fromHistory) {
      setPreviousHistory(fromHistory);
      setCustomReportPrevHistory(fromHistory);
    }
  }, []);

  //get reducer data
  const { customReportData, commentReport } = useSelector((state) => state.report);

  return (
    <React.Fragment>
      <Grid container>
        <NewReport
          closePopup={closePopup}
          reportData={customReportData}
          commentReport={commentReport}
          isSaveReport={isSaveReport}
          step={step}
          previousHistory={previousHistory}
        />
      </Grid>
    </React.Fragment>
  );
}
// default props
CreateCustomReportPopup.defaultProps = {
  classes: {},
  handleCancel: () => { },
  step: 0,
};

// prop types
CreateCustomReportPopup.propTypes = {
  classes: PropTypes.object,
  handleCancel: PropTypes.func,
  step: PropTypes.number,
};
export default withStyles(ReportsStyles)(CreateCustomReportPopup);