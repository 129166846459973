import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import withStyles from '@mui/styles/withStyles';
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import Grid from "@mui/material/Grid";
import { Divider } from "@mui/material";
import Box from '@mui/material/Box';

// Import History
import history from "../../config/history";

// Import Style
import HeaderStyle from "./HeaderStyle";

// Import Components
import LinearProgressComponent from "../../components/LinearProgressComponent/LinearProgressComponent";
import TypographyComponent from "../../components/TypographyComponent/TypographyComponent";

// Import Containter
import HeaderClients from "./HeaderClients";
import HeaderNavigations from "./HeaderNavigations";

// Import Logo
import ProfileImg from "../../assets/images/default_profile.svg";
import { IconLogo, IconResource } from "../../assets/images/svgComponents/ImageComponent";

// Import Actions
import { authAction } from "../../redux/actions";

// Import constants
import { userRoles } from "../../config/pageAccess";

const disable_loader = []

function Header(props) {
  const dispatch = useDispatch();
  const { classes, match, user } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const clientLogo = useSelector(state => state.profile.user.client_id.logo)
  const userRole = useSelector(state => state.profile.user.role);
  const internalApiAccess = useSelector(state => state.client.getOne.internalApiAccess);
  const externalApiAccess = useSelector(state => state.client.getOne.externalApiAccess);  
  const rawDataAutomationAccess = useSelector(state => state.client.getOne.rawDataAutomationAccess); 

  // Handle Profilie Menu Open Event
  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Handle Menu Close Event
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // Handle Menu
  const handleMenu = (event) => {
    setAnchorEl(null);
    const { myValue } = event.currentTarget.dataset;

    /**
     * Redirect Component Based on Profile Menu Selection
     */
    switch (myValue) {
      case "MyProfile":
        // Redirect to My Profile Page
        history.push({
          pathname: "/profile"
        });
        break;
      case "EditClient":
        // Redirect to Edit Client Page
        history.push({
          pathname: "/client/edit"
        });
        break;
      case "AddClient":
        // Redirect to Add Client Page
        history.push({
          pathname: "/client/new"
        });
        break;

      case "externalAPI":
        // Redirect to External API Integration Page
        history.push({
          pathname: "/external-api-integration"
        });
        break;
      
      case "rawdatasetup":
        // Redirect to External API Integration Page
        history.push({
          pathname: "/raw-data/list"
        });
        break;

      // case "internalAPI":
      //   // Redirect to Internal API Integration Page
      //   history.push({
      //     pathname: "/internal-api-integration"
      //   });
      //   break;

      default:
        dispatch(authAction.logout(false));
        // Redirect to Login Page
        // history.push({
        //   pathname: "/login"
        // });
    }
  };

  const menuId = "profileMenu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenu} data-my-value={"MyProfile"}>My Profile</MenuItem>

      {/* Show Edit Client only for Super Admin and Client Admin */}
      {(user.role === userRoles.superadmin || (user.role === userRoles.clientadmin && (user.page.length > 0 && user.page.indexOf("people") > -1))) &&
        <MenuItem onClick={handleMenu} data-my-value={"EditClient"}>Edit Client</MenuItem>
      }

      {/* Show Add Client only for Super Admin */}
      {user.role === userRoles.superadmin &&
        <MenuItem onClick={handleMenu} data-my-value={"AddClient"}>Add Client</MenuItem>
      }

      {user.role === userRoles.superadmin &&
        <React.Fragment>
          <Divider />
          <Box px={"16px"} py={"8px"} sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}>
            <TypographyComponent variant={"subtitle2"} title="API Setup"></TypographyComponent>
          </Box>
          <MenuItem onClick={handleMenu} data-my-value={"externalAPI"} disabled={!externalApiAccess}>External API Integration</MenuItem>
          {/* <MenuItem onClick={handleMenu} data-my-value={"internalAPI"} disabled={!internalApiAccess}>PeopleElement API Integration</MenuItem> */}
          <Divider />
        </React.Fragment>
      }
      {user.role === userRoles.superadmin &&
        <React.Fragment>
          <MenuItem onClick={handleMenu} data-my-value={"rawdatasetup"} disabled={!rawDataAutomationAccess}>Raw Data Setup</MenuItem>
          <Divider />
        </React.Fragment>
      }
      <MenuItem onClick={handleMenu} data-my-value={"Sign Out"}>Sign Out</MenuItem>
    </Menu>
  );

  const pathname = window.location.href.split("/").slice(-2).join("/")

  useEffect(() => {
    let history_url = history.location.pathname;
    window._hsq.push(['setPath', history_url]);
    if (window.HubSpotConversations && window.HubSpotConversations.widget) {
      window.HubSpotConversations.widget.refresh();
    }
  }, [history.location.pathname]);

  return (
    <Grid className={classes.root} aria-live="polite" aria-relevant="text">
      {disable_loader.indexOf(pathname) === -1 && <LinearProgressComponent />}
      <AppBar position="static" color="default" className={classes.rootBar}>
        <Toolbar variant="dense" className={classes.rootToolBar}>
          <Grid className={classes.logo}>
            {clientLogo && userRole === "clientadmin" ? <img src={clientLogo} className="logo_img" alt="Logo" /> : IconLogo()}
          </Grid>

          <HeaderNavigations match={match} classes={classes} user={user} />

          <Grid className={classes.root} />

          <HeaderClients match={match} classes={classes} user={user} />
          <Tooltip arrow title="Knowledge Base">
            <IconButton
              href='https://20709424.hs-sites.com/'
              target='_blank'
              color="inherit"
              className={"ml5 p5"}
              size="large">
              {/* <Avatar alt="Resource" src={IconResource} /> */}
              {IconResource()}
            </IconButton>
          </Tooltip>
          <IconButton
            edge="end"
            aria-label=""
            aria-controls={menuId}
            aria-haspopup="true"
            onClick={handleProfileMenuOpen}
            className={"ml5 p5"}
            color="inherit"
            size="large">
            <Avatar alt="Resource" src={user.img ? user.img : ProfileImg} className={classes.profileImg} />
          </IconButton>
        </Toolbar>
      </AppBar>
      {renderMenu}
    </Grid>
  );
}

// default props
Header.defaultProps = {
  classes: {},
  match: {},
  user: {}
};

// prop types
Header.propTypes = {
  classes: PropTypes.object,
  match: PropTypes.object,
  user: PropTypes.object
};

/**
 * Export Container
 */
export default withStyles(HeaderStyle)(Header);
