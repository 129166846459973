import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import withStyles from '@mui/styles/withStyles';
import Popover from "@mui/material/Popover";
import Menu from "@mui/material/Menu";
import MenuItem from '@mui/material/MenuItem';
import IconButton from "@mui/material/IconButton";

// Import Styles
import ReportsStyles from "./ReportsStyles";

// import images
import { Option } from "../../assets/images/svgComponents/ImageComponent";

// Import History
import CustomReportIcon from './Insight/CustomReportIcon'

// Import Page Component
import Container from "../Containers/Container";

// Import Constants
import { FilterConstants, surveyTypes as surveyTypeConstants, ReportsConstants } from "../../config/constants";

// Import Components
import TypographyComponent from "../../components/TypographyComponent/TypographyComponent";
import TooltipComponent from "../../components/ToolTipComponent/ToolTipComponent";
import ButtonComponent from "../../components/ButtonComponent/ButtonComponent";
import { downloadPdf, openNewTab } from "../../../src/assets/images/svgComponents/ImageComponent";

// Import report router
import ReportsRouter from "./Reports.Router"

// Import redux actions
import { adminsAction, dashboardAction } from "../../redux/actions"

// Import Child Containers
import ReportsTabs from "./Reports.Tabs"
import ReportsActions from "./Reports.Actions"
import Filter from '../Filter/Filter';
import { useSelector, useDispatch } from 'react-redux';
import InsightDownloadReport from './Insight/InsightDownloadReport';
import InsightSaveReport from './Insight/InsightSaveReport';

// Tab Panel Function
function TabPanel(props) {
	const { children, value, index, ...other } = props;
	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}>
			{value === index && (
				//Tab Panel
				<Grid>
					<TypographyComponent variant="h6">{children}</TypographyComponent>
				</Grid>
			)}
		</div>
	);
}

// Tab Panel Props
TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

function ReportsPanels(props) {
	// Selectors
	const preference = useSelector(state => state.admin.preference);

	// Define Dispatch
	const dispatch = useDispatch();
	let { dashboardActionLoader, dashBoardLoaderQuestion, dashBoardLoaderCount, keydriver, surveyTypes, hasMESurvey, ...dashboardDataRest } = useSelector(state => state?.dashboard?.dashboardData);
    const { queryDLA } = useSelector(state => state.profile.user)
	const { filterData } = useSelector(state => state.filter)
	const isKeyDriver = keydriver && keydriver.length > 0;
	const isProjected = surveyTypes.indexOf('Exit') === -1;

	const routerDomHistory = useHistory();
  	const routerDomLocation = useLocation();

	// const hasMESurvey =
	// 	preference &&
	// 	preference?.surveys &&
	// 	preference?.surveys.filter(
	// 		(val) => val.type === surveyTypes.MULTI_RATER_FEEDBACK
	// 	).length > 0;

	// While copying from Dashboard.jsx, make sure to change constants from surveyTypes to surveyTypeConstants and make sure to update the changes here as well in Dashboard.jsx
	const callDashboardApi = () => {
		const dashboardSurveys = filterData?.surveys || [];
		const surveyTypesArr = dashboardSurveys.map(survey => survey.type);
		const surveyFrequencyArr = dashboardSurveys.map(survey => survey.frequency);
		let requestCount = 6;
		
		// Participation widget & Overall Favorability widget
		dispatch(dashboardAction.getDashboardData(() => { }, 'count'));
		dispatch(dashboardAction.getDashboardData(() => { }, 'rollingParticipant'));
		dispatch(dashboardAction.getParticipantInvite(false));

		// Favorability by Category widget
		dispatch(dashboardAction.getDashboardData(() => { }, 'favourableCategory'));

		// Overall eNPS widget
		dispatch(dashboardAction.getDashboardData(() => { }, 'overalleNPS'));

		// Highest & Low Highest Rated Items widget
		dispatch(dashboardAction.getDashboardData(() => { }, 'questions'));


		if ((dashboardSurveys.length === 1 && dashboardSurveys?.[0]?.type !== surveyTypeConstants.ENGAGEMENT) || (dashboardSurveys.length === 1 && dashboardSurveys?.[0]?.type === surveyTypeConstants.ENGAGEMENT && !_.isEmpty(queryDLA)) ||
		(dashboardSurveys.length > 1)) {
			requestCount += 1;
			// Suggested Area of Actions widget
			dispatch(dashboardAction.getDashboardActionData(() => { }, false));
		} else {
			requestCount += 1;
			dispatch(dashboardAction.getDashboardActionData(() => { }, false, false))
		}

		if (dashboardSurveys.length === 1) {
			const currentSurveyType = dashboardSurveys[0]?.type;

			if (currentSurveyType === surveyTypeConstants.ENGAGEMENT) {
				requestCount += 1;
				// Engagement Benchmark widget
				dispatch(dashboardAction.getDashboardData(() => { }, 'engagementBenchmark'));
				if (!queryDLA || queryDLA === "") {
					requestCount += 1;
					// Top Keydrivers Engagement
					dispatch(dashboardAction.getKeyDriverData(() => { }, false));
				}
			} else if (currentSurveyType === surveyTypeConstants.EXIT) {
				// Turnover widget
				if (!surveyFrequencyArr.includes("Pulse")) {
					requestCount += 1;
					dispatch(dashboardAction.getDashboardData(() => { }, 'turnOver'));
				}
				requestCount += 1;
				// Likely to Return widget
				dispatch(dashboardAction.getDashboardData(() => { }, 'likelyToReturn'));
			} else if (currentSurveyType === surveyTypeConstants.STAY || currentSurveyType === surveyTypeConstants.NEW_HIRE) {
				// Turnover widget
				if (!surveyFrequencyArr.includes("Pulse")) {
					requestCount += 1;
					dispatch(dashboardAction.getDashboardData(() => { }, 'turnOver'));
				}
				requestCount += 1;
				// Likely to Stay widget
				dispatch(dashboardAction.getDashboardData(() => { }, 'likelyToStay'));
			}
		} else {
			if (
				surveyTypesArr.length === 1 && surveyTypesArr.indexOf(surveyTypeConstants.STAY) !== -1 ||
				surveyTypesArr.length === 2 && _.isEqual([surveyTypeConstants.ENGAGEMENT, surveyTypeConstants.NEW_HIRE], surveyTypesArr.sort())
			) {
				requestCount += 1;
				// Likely to Stay widget
				dispatch(dashboardAction.getDashboardData(() => { }, 'likelyToStay'));
			}

			if (
				surveyTypesArr.indexOf(surveyTypeConstants.CUSTOM_SURVEY) === -1 &&
				(
					surveyTypesArr.indexOf(surveyTypeConstants.STAY) !== -1 ||
					(
						surveyTypesArr.length === 2 &&
						(
							_.isEqual([surveyTypeConstants.ENGAGEMENT, surveyTypeConstants.NEW_HIRE], surveyTypesArr.sort()) ||
							_.isEqual([surveyTypeConstants.EXIT, surveyTypeConstants.NEW_HIRE], surveyTypesArr.sort()) 
						)
					)
				)
			) {
				// Turnover widget
				if (!surveyFrequencyArr.includes("Pulse")) {
					requestCount += 1;
					dispatch(dashboardAction.getDashboardData(() => { }, 'turnOver'));
				}
			}
		}

		dispatch(dashboardAction.addDashboardRequestCount(requestCount));
	}


	// Update User Report Preferences
	const updateReportPreference = (data) => {
		dispatch(adminsAction.updateReportPreference(data, () => {
			// dispatch(dashboardAction.EIWithRgi());
			dispatch(dashboardAction.resetDashboardLoader());
			callDashboardApi();
			dispatch(dashboardAction.updateCommentChartStatus(true));
		}));
	}

	const filterSaveEvent = (data) => {
		updateReportPreference(data);
	}

	const adminPreference = { ...preference }
	const { surveys, reportVisibility } = adminPreference
	//Get props
	const { classes, match, location } = props;

	//Set Tabs State
	const [value, setValue] = React.useState(0);

	//open close save popup
	const [anchorEl, setAnchorEl] = React.useState(null);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);
	const id = open ? "simple-popover" : undefined;

	const [openDashWidgets, setOpenDashWidgets] = useState(null);
	const [showMoreOptions, setShowMoreOptions] = useState(false);

	useEffect(() => {
		const condition = preference?.hideDashWidgets && Object.values(preference?.hideDashWidgets).length > 0 && Object.values(preference?.hideDashWidgets).some(val => val);
		setShowMoreOptions(condition);
	}, [preference?.hideDashWidgets]);

	const zero_check = 0;
	let commonConditions = {
		participation: dashboardDataRest?.totalInvite > zero_check || dashboardDataRest?.totalresponse > zero_check,
		overallFavorability: (dashboardDataRest?.positivepercentfavorable > zero_check) || (dashboardDataRest?.negativepercentfavorable > zero_check) || (dashboardDataRest?.neturalpercentfavorable > zero_check),
		favorableByCategory: dashboardDataRest?.favourable_category && Object.keys(dashboardDataRest?.favourable_category).length >= 2,
		suggestedAreaAction: (dashboardDataRest?.saoa && dashboardDataRest?.saoa.length > zero_check) && !isKeyDriver,
		keydriver: (keydriver && keydriver.length > zero_check) && isKeyDriver,
		overalleNPS: dashboardDataRest?.overalleNPS?.responseCount > zero_check,
		engagedEmployee: dashboardDataRest?.engagementBenchMark?.total > zero_check,
		engagementBenchMark: dashboardDataRest?.engagementBenchMark?.total > zero_check,
		likelyToStay: dashboardDataRest?.likelyToStay?.responseCount > zero_check,
		LikelyToReturn: dashboardDataRest?.likelyToReturn?.responseCount > zero_check,
		turnOver: dashboardDataRest?.turnOver?.chartData.length > zero_check && !dashboardDataRest?.surveyFrequency?.includes('Pulse'),
		projectedTurnover: dashboardDataRest?.turnOver?.chartData.length > zero_check && !dashboardDataRest?.surveyFrequency?.includes('Pulse'),
		highLowRatedItems: dashboardDataRest?.questions.length > zero_check
	}
	if (surveyTypes.length > 1) {
		commonConditions = {
			...commonConditions,
			overalleNPS: commonConditions['overalleNPS'] &&
				(surveyTypes.indexOf('Other') === -1 &&
					['New Hire', 'Exit', 'Engagement', 'Stay'].some(type => surveyTypes.indexOf(type) !== -1)),
			likelyToStay: commonConditions['likelyToStay'] && (dashboardDataRest.likelyToStay?.responseCount > zero_check && (
				surveyTypes.length === 1 && surveyTypes.indexOf('Stay') !== -1 ||
				surveyTypes.length === 2 && _.isEqual(['Engagement', 'New Hire'], surveyTypes.sort())
			)),
			turnOver: 
			commonConditions['turnOver'] &&
			surveyTypes.indexOf('Other') === -1 &&
			(
				surveyTypes.indexOf("Stay") !== -1 ||
				(
					surveyTypes.length === 2 &&
					(
						_.isEqual(['Engagement', 'New Hire'], surveyTypes.sort()) ||
						_.isEqual(['Exit', 'New Hire'], surveyTypes.sort())
					)
				)
			),
			projectedTurnover: false
		}
	}

	const hidedDashWidgets = Object.entries(preference?.hideDashWidgets || {}).filter(([key, value]) => value === true).reduce((acc, [widgetKey, widgetValue]) => {
		// Filter hideDashWidgets based on surveyType
		if (surveyTypes.length === 1) {
			const surveyType = surveyTypes?.[0];
			if (surveyType === surveyTypeConstants.ENGAGEMENT) {
				if (commonConditions[widgetKey] && widgetKey !== 'likelyToStay' && widgetKey !== 'LikelyToReturn' && widgetKey !== 'turnOver' && widgetKey !== 'projectedTurnover') {
					acc.push(widgetKey);
				}
			} else if (surveyType === surveyTypeConstants.EXIT) {
				if (commonConditions[widgetKey] && widgetKey !== 'engagedEmployee' && widgetKey !== 'engagementBenchMark' && widgetKey !== 'likelyToStay' && widgetKey !== 'projectedTurnover') {
					acc.push(widgetKey);
				}
			} else if (surveyType === surveyTypeConstants.NEW_HIRE || surveyType === surveyTypeConstants.ONBOARDING_WEEK_TWO || surveyType === surveyTypeConstants.ONBOARDING_WEEK_FIVE) {
				if (commonConditions[widgetKey] && widgetKey !== 'engagementBenchMark' && widgetKey !== 'engagedEmployee' && widgetKey !== 'LikelyToReturn' && widgetKey !== 'turnOver') {
					acc.push(widgetKey);
				}
			}else if(surveyType === surveyTypeConstants.STAY && widgetKey === 'projectedTurnover'){
				acc.push(widgetKey);
			} else {
				// Push only Common conditions
				if (commonConditions[widgetKey] && (widgetKey === 'participation' || widgetKey === 'overallFavorability' || widgetKey === 'favorableByCategory' || widgetKey === 'suggestedAreaAction' || widgetKey === 'keydriver' || widgetKey === 'overalleNPS' || widgetKey === 'highLowRatedItems')) {
					acc.push(widgetKey);
				}
			}
		} else {
			if (commonConditions[widgetKey] && widgetKey !== 'engagementBenchMark' && widgetKey !== 'engagedEmployee' && widgetKey !== 'LikelyToReturn' && widgetKey !== 'projectedTurnover') {
				acc.push(widgetKey);
			}
		}

		return acc;
	}, []);

	const openMoreOptionMenuItem = (widget) => {
		if (adminPreference?.hideDashWidgets?.[widget]) {
			setOpenDashWidgets(false);
			adminPreference['hideDashWidgets'][widget] = false;
			dispatch(adminsAction.updateDashWidgetPreference(adminPreference));
		}
	}
	const isCustomReportLocation = location.pathname.includes('reports/custom_report')

	return (
		<>
			<Grid container className={classes.reports_Container} style={{ alignSelf: "flex-start" }}>
			{!isCustomReportLocation && 
				<Grid container alignItems="center" justifyContent="space-between" className={classes.reports_header}>

					{/* Bind Reports Tabs */}
						<ReportsTabs classes={classes} match={match} location={location} />
					

					{location.pathname !== "/reports/report" && location.pathname !== "/reports" &&
						<ReportsActions classes={classes} match={match} location={location} />
					}
					{location.pathname === "/reports" &&
						<Grid item display="flex" alignItems="center" gap={'8px'}>
							<ReportsActions classes={classes} match={match} location={location} />
							{/* User Guide Button */}
							{
								!hasMESurvey &&
								<Grid item>
									<Grid item display={'flex'} alignItems={'center'} className={'cursor-pointer'}>
										<ButtonComponent
											title={ReportsConstants.USER_GUIDE}
											color={'primary'}
											handleClick={handleClick}
										>
										</ButtonComponent>
									</Grid>
								</Grid>
							}
							{/* Filters */}
							<Grid item className={classes.dashboardDataFilterIcon}>
								<Filter
									filterSaveEvent={filterSaveEvent}
									classes={classes}
								/>
							</Grid>
							{/* Save Report Icon */}
							{!hasMESurvey &&
								<Grid item className={classes.saveReportIcon}>
									<InsightSaveReport reportType={"Dashboard Report"} favReport={true} />
								</Grid>
							}
							{/* Download Report Icon */}
							
							<InsightDownloadReport reportType={"Dashboard Report"} dashboardDownload={true} isLoading={dashboardActionLoader || dashBoardLoaderQuestion || dashBoardLoaderCount} />
							{!hasMESurvey &&
							(<Grid item>
                             	<CustomReportIcon onClick={
									() => {
										routerDomHistory.push(`${match.url}/custom_report/new`, { from: routerDomLocation.pathname })
							  		}
								} /> 
                            </Grid>)
						    }
							{/* More Options Icon */}
							{(showMoreOptions && hidedDashWidgets.length > 0) &&
								<TooltipComponent title={FilterConstants.MORE_OPTIONS}>
									<IconButton color="inherit" onClick={(e) => { setOpenDashWidgets(e.currentTarget) }} size="large" sx={{ padding: '8px 0' }}>
										{Option()}
									</IconButton>
								</TooltipComponent>
							}
						</Grid>
					}
				</Grid>
			}
				<TabPanel value={value} index={0} className="width100">
				{isCustomReportLocation ? (
					<ReportsRouter {...props} />
				) : (
					<Container page="HeaderTabs" align="baseline">
						<ReportsRouter {...props} />
					</Container>
				)}
				</TabPanel>

			</Grid>
		
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
			>
				<Grid className={`pt-2 pb-2`} minWidth={'215px'}>
					<MenuItem component="a" href="https://pe-client-doc.s3.us-west-2.amazonaws.com/Download+User+Guide.pdf" download="Download User Guide" target="_blank" s className='pt-0 pb-0'>
						<Grid item display={'flex'} alignItems={'center'} gap={1.25} paddingTop={1.25} paddingBottom={1.25}>
							{downloadPdf()}
							<TypographyComponent variant="h6">
								{ReportsConstants.DOWNLOAD_PDF}
							</TypographyComponent>
						</Grid>
					</MenuItem>
					<MenuItem component="a" href="https://20709424.hs-sites.com/dashboard-overview" target='_blank' className='pt-0 pb-0 mt4'>
						<Grid item display={'flex'} alignItems={'center'} gap={1.25} paddingTop={1.25} paddingBottom={1.25}>
							{openNewTab()}
							<TypographyComponent className={'pl4'} variant="h6">
								{ReportsConstants.OPEN_NEW_TAB}
							</TypographyComponent>
						</Grid>
					</MenuItem>
				</Grid>
			</Popover>

			{/* More Options Popup */}
			<Menu
				id="dashboard-more-options-menu"
				anchorEl={openDashWidgets}
				keepMounted
				open={Boolean(openDashWidgets)}
				onClose={() => { setOpenDashWidgets(false) }}
				transformOrigin={{ horizontal: 'right', vertical: 'top' }}
				anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
			>
				<MenuItem className="menu-header">
					{ReportsConstants.DASHBOAARD_REPORTS}
				</MenuItem>
				{
					hidedDashWidgets.map(widgetKey => {
						return <MenuItem
							key={`${widgetKey}_widget`}
							onClick={() => openMoreOptionMenuItem(widgetKey)}
							value={widgetKey}
						>
							{ReportsConstants.MORE_OPTIONS?.[widgetKey]}
						</MenuItem>
					})
				}

			</Menu>
		</>
	)
}
// default props
ReportsPanels.defaultProps = {
	classes: {},
	match: {},
	location: {}
};

// prop types
ReportsPanels.propTypes = {
	classes: PropTypes.object,
	match: PropTypes.object,
	location: PropTypes.object
};
export default withStyles(ReportsStyles)(ReportsPanels);