import React, { useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Grid, Typography, Button } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CommentQuestions from "./CommentQuestions"; // Import ComentQuestions
import ReportsStyles from "../../ReportsStyles";
// Constants
import { ReportsConstants } from "../../../../config/constants";

// SelectCommentReport

function SelectCommentReport(props) {

  const {
    classes,
    commentQuestions,
    commentReport,
    handleClear,
    customquestions
  } = props

  const [expanded, setExpanded] = useState(customquestions?.length > 0 || true);

  // if (customquestions)

  const handlePanelChange = (event, newExpanded) => {
    setExpanded(newExpanded);
  };

  return (
    <React.Fragment>
      <Grid container spacing={2} style={{ maxHeight: '100%' }}>
        <Grid item sm={12} style={{ maxHeight: '100%' }}>
          <Accordion
            expanded={expanded}
            onChange={handlePanelChange}
            className="pe-accordion"
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon className="expandicon" />}
              className="actionCard"
            >
              <Grid item sm={12} style={{ display: "flex" }}>
                <Grid item>
                  <Typography variant="h6" style={{ color: expanded ? '#ffff' : '#292929' }}>
                    {ReportsConstants.CUSTOM_SELECT_COMMENT_QUESTION}
                  </Typography>
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails style={{ overflowY: "auto", maxHeight: '30vh' }}>
              {/* {expanded && */}
                <CommentQuestions
                  customquestions={customquestions}
                  classes={classes}
                  commentQuestions={commentQuestions}
                  commentReport={commentReport}
                  handleClear={handleClear}
                  expanded={expanded} />
               {/* }  */}
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default SelectCommentReport;
