import React, { useState, useCallback, useMemo, useEffect } from 'react';
import {
  FormControlLabel,
  Checkbox,
  Grid,
  Radio,
  RadioGroup,
  IconButton
} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';

// Import custom components
import TypographyComponent from '../../../../../components/TypographyComponent/TypographyComponent';
import SelectFieldComponent from '../../../../../components/SelectFieldComponent/SelectFieldComponent';
import ButtonComponent from "../../../../../components/ButtonComponent/ButtonComponent";
import SurveySelector from './SurveySelector';
import ViewBySelectionPopup from "./ViewBySelectionPopup";

// Import icons and constants
import { Filter } from '../../../../../assets/images/svgComponents/ImageComponent';
import { summaryColumnField, ReportsConstants, surveyTypes } from '../../../../../config/constants';
import { reportAction, alertAction } from '../../../../../redux/actions';
import { generateKey } from '../../../../../helpers';

// Default configurations
const DEFAULT_COLUMNS = [
  "participantCount",
  "mean",
  "percentfavorable",
  "companyComparison",
  "historicalData"
];

const DEFAULT_HISTORICAL = {
  type: "Last 3 months",
  survey: {}
};

const HighLevelSummary = ({ originalSurveys }) => {
  const dispatch = useDispatch();

  // Selectors to get data from Redux store
  const {
    allSurveys,
    customReportData,
    allDemographicsItems,
    allDemographics,
    hlsDemographic,
  } = useSelector((state) => state.report);
  const keydriver = useSelector(state => state.dashboard.dashboardData.keydriver);

  const isKeyDriver = keydriver && keydriver?.length > 0;
  const demooptions = Object.entries(hlsDemographic?.metadataKeyname || {})
    .filter(([key]) => allDemographics?.hasOwnProperty?.(key))
    .map(([, value]) => value)
    .sort((a, b) => a.localeCompare(b));

  // Get preferences from custom report data
  const preferences = customReportData?.dashboardPreference?.hls;

  // Memoized data transformations
  const surveydata = useMemo(() =>
    originalSurveys.filter((s) => s.type !== surveyTypes.MULTI_RATER_FEEDBACK),
    [originalSurveys]
  );

  const viewByOptions = useMemo(() =>
    ReportsConstants.VIEW_BY_NAME.filter(item =>
      isKeyDriver || item.value !== "keyDrivers"
    ),
    [isKeyDriver]
  );

  const filteredColumns = useMemo(() =>
    Object.fromEntries(
      Object.entries(summaryColumnField).filter(
        ([key]) => key !== "keyDriverValue" && key !== "categoryName"
      )
    ),
    []
  );

  // State management
  const [historicalPreferences, setHistoricalPreferences] = useState({
    type: customReportData?.dashboardPreference?.historical?.type || DEFAULT_HISTORICAL.type,
    survey: customReportData?.dashboardPreference?.historical?.survey || {}
  });

  const [popupOpen, setPopupOpen] = useState(false);
  const [activeFilter, setActiveFilter] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [tempSelectedColumns, setTempSelectedColumns] = useState(
    preferences?.selectedSummaryWidgetColumnsDropdown?.length
      ? preferences.selectedSummaryWidgetColumnsDropdown
      : DEFAULT_COLUMNS
  );

  const [selectView, setSelectView] = useState(ReportsConstants.VIEW_BY_NAME[0]?.name);
  const [sortByOptions, setSortByOptions] = useState([]);
  const [selectedSort, setSelectedSort] = useState('');
  const [sortOrder, setSortOrder] = useState('asc');
  const [surveySearchName, setSurveySearchName] = useState('');
  const [selectHistorical, setSelectHistorical] = useState(
    customReportData?.dashboardPreference?.historical?.type || DEFAULT_HISTORICAL.type
  );
  const [filtersApplied, setFiltersApplied] = useState({});
  const [selectedValue, setselectedValue] = useState(ReportsConstants.VIEW_BY_NAME.find(view => view.name === selectView)?.value || '')

  // Demographic selection state
  const [selectedDemographic, setSelectedDemographic] = useState(demooptions?.some((option) => option === 'Tenure Groupings') ? 'Tenure Groupings' : demooptions?.[0] || '');

  // Memoized view by selection
  const view_by_selection = useMemo(() => {

    if (selectedValue === 'demographic') {
      const metadataDispname = hlsDemographic?.metadataDispname?.[selectedDemographic];

      const demographicData = allDemographicsItems && allDemographicsItems?.[metadataDispname] ? allDemographicsItems?.[metadataDispname].map(item => item._id.trim()) : []
      const demographicObject = {};
      demographicData?.forEach(demo => {
        const key = generateKey(demo); // Generate a unique key for each question
        demographicObject[key] = demo;   // Map the key to the question
      });

      return demographicObject
    }

    switch (selectedValue) {
      case "category":
        const category = hlsDemographic?.categoryResult?.category;
        const categoryObject = {};
        category?.forEach(cat => {
          const key = generateKey(cat); // Generate a unique key for each question
          categoryObject[key] = cat;   // Map the key to the question
        });
        return categoryObject
      case "question":
        const questions = hlsDemographic?.questionResult?.question;
        // Transform the array into a single object with unique keys
        const questionObject = {};
        questions?.forEach(question => {
          const key = generateKey(question); // Generate a unique key for each question
          questionObject[key] = question;   // Map the key to the question
        });
        return questionObject;
      case "keyDrivers":
        const keyDriversObject = {};
        keydriver?.forEach(driver => {
          const key = generateKey(driver.question); // Generate a unique key for each question
          keyDriversObject[key] = driver.question;  // Map the key to the question
        });
        return keyDriversObject
      default:
        return [];
    }
  }, [hlsDemographic, selectView, selectedDemographic, allDemographicsItems]);

  // Calculate sort by options
  useMemo(() => {
    const columnOptions = tempSelectedColumns.sort().reverse().map(col => filteredColumns[col]);
    let uniqueOptions = [selectView, ...columnOptions].filter(Boolean);
    if (selectedValue === "question") {
      uniqueOptions = [
        "View By Category",
        selectView,
        ...columnOptions,
      ].filter(Boolean);
    } else if (selectedValue === "keyDrivers") {
      uniqueOptions = [
        selectView,
        'Key Driver',
        'Category',
        ...columnOptions,
      ].filter(Boolean);
    }
    setSortByOptions(uniqueOptions);
    if (!uniqueOptions.includes(selectedSort)) {
      setSelectedSort(selectView);
    }
  }, [tempSelectedColumns, selectView, filteredColumns]);

  // Event Handlers
  const handleFilterClick = useCallback((filterType, event) => {
    setActiveFilter(filterType);
    setAnchorEl(event.currentTarget);
    setPopupOpen(true);
  }, []);

  const handlePopupClose = useCallback(() => {
    setPopupOpen(false);
    setAnchorEl(null);
    setActiveFilter(null);
  }, []);

  const categoryViewSelection = useMemo(() => {
    return hlsDemographic?.categoryResult?.category?.reduce((obj, cat) => ({
      ...obj,
      [generateKey(cat)]: cat
    }), {});
  }, [hlsDemographic?.categoryResult?.category]);


  const renderFilterSection = () => {
    return (
      <>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <SelectFieldComponent
            select
            name="viewBy"
            value={selectView}
            fullWidth={false}
            handleChange={handleViewByChange}
            options={viewByOptions.map(option => option.name)}
            validators={['required']}
            errorMessages={['Required']}
          />
          <IconButton
            aria-describedby={'hlsViewByPopover'}
            onClick={(e) => handleFilterClick(selectedValue, e)}
            className={`icon-hover ${filtersApplied?.[selectedValue] ? 'active' : ''}`}
          >
            {Filter()}
          </IconButton>
        </div>

        {(selectedValue === 'question' || selectedValue === 'keyDrivers') && (
          <>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <TypographyComponent variant="h6" className="txtBold">
                Category filter
              </TypographyComponent>
              <IconButton
                aria-describedby={'hlsCategoryPopover'}
                onClick={(e) => handleFilterClick('category', e)}
                className={`icon-hover ${filtersApplied?.['category'] ? 'active' : ''}`}
              >
                {Filter()}
              </IconButton>
            </div>
          </>
        )}

        {/* Single ViewBySelectionPopup instance */}
        <ViewBySelectionPopup
          open={popupOpen}
          anchorEl={anchorEl}
          onClose={handlePopupClose}
          selectedDemographic={selectedDemographic}
          selectedValue={activeFilter || selectedValue}
          viewBySelection={
            activeFilter === 'category'
              ? categoryViewSelection
              : view_by_selection
          }
          filtersApplied={filtersApplied}
          onApply={(appliedFilters) => {
            setFiltersApplied(appliedFilters);
          }}
          type={"hls"}
        />
      </>
    );
  };


  const handleColumnToggle = useCallback((columnName) => {
    setTempSelectedColumns(prev =>
      prev.includes(columnName)
        ? prev.filter(col => col !== columnName)
        : [...prev, columnName]
    );
  }, []);

  const handleHistoricalTypeChange = useCallback((newType) => {

    setSelectHistorical(newType);
    if (newType.includes("Last")) {
      setHistoricalPreferences({
        type: newType,
        survey: {}
      });
    }

  }, []);

  const handleSurveyChange = useCallback((selectedSurvey) => {
    setHistoricalPreferences({
      type: null,
      survey: selectedSurvey
    });
  }, []);

  const checkDuplicateConfigs = (hlsPreferences, historicalPreferences) => {
    const isDuplicate = (existingConfigs, newPreferences) => {
      const normalizeObject = (obj) => JSON.stringify(obj, Object.keys(obj).sort());
      const normalizedNewPreferences = normalizeObject(newPreferences);
      return Object.values(existingConfigs).some(config =>
        normalizeObject(config) === normalizedNewPreferences
      );
    };


    const existingHlsConfigs = customReportData?.dashboardPreference?.hls || {};
    const existingHistoricalConfigs = customReportData?.dashboardPreference?.historical || {};

    const isDuplicateHls = isDuplicate(existingHlsConfigs, hlsPreferences);
    const isDuplicateHistorical = isDuplicate(existingHistoricalConfigs, historicalPreferences);

    if (isDuplicateHls && isDuplicateHistorical) {
      dispatch(alertAction.error('A chart with identical configuration already exists'));
      return true; // Return true if duplicates are found
    }

    return false; // Return false if no duplicates are found
  };

  const defaultvalues = () => {
    setTempSelectedColumns(DEFAULT_COLUMNS)
    setSelectView(ReportsConstants.VIEW_BY_NAME[0]?.name)
    setselectedValue('category' || '')
    setSortOrder('asc')
    setFiltersApplied({})
    setSelectHistorical(DEFAULT_HISTORICAL.type)
  };

  const handleAddChart = useCallback(() => {
    try {
      const currentViewOption = ReportsConstants.VIEW_BY_NAME.find(
        option => option.name === selectView
      )?.value || '';
      let summaryWidgetColumnsDropdown = []
      if (currentViewOption === 'category' || currentViewOption === 'demographic') {
        summaryWidgetColumnsDropdown = tempSelectedColumns
      } else {
        if (isKeyDriver) {
          summaryWidgetColumnsDropdown = ['keyDriverValue', 'categoryName', ...tempSelectedColumns]
        }
        else {
          summaryWidgetColumnsDropdown = ['categoryName', ...tempSelectedColumns]
        }
      }

      const view = currentViewOption === 'demographic' ? `by ${selectedDemographic}`
        : currentViewOption === 'category' ? `by Category`
          : currentViewOption === 'question' ? `by Question`
            : currentViewOption === 'keyDrivers' ? `by Key Drivers`
              : `by ${selectView}`;

      const chartNumber = `Summary ${view}`;
      const updatedChartOrder = customReportData.chartOrder || [];

      const uniqueChartNumber = updatedChartOrder.includes(chartNumber)
        ? `${chartNumber} ${updatedChartOrder.filter(chart => chart.startsWith(chartNumber)).length + 1}`
        : chartNumber;
      let sortBy = '';
      if (selectedSort === 'View By Category' || selectedSort === 'View By Demographic') {
        sortBy = "keytext"
      } else if (selectedSort === 'View By Question') {
        sortBy = "questionText"
      } else if (selectedSort === 'Key Driver Correlation') {
        sortBy = "corelation"
      } else {
        sortBy = selectedSort;
      }
      const hlsPreferences = {
        type: currentViewOption,
        rg: selectView !== 'View By Demographic' ? '' : hlsDemographic?.metadataDispname?.[selectedDemographic],
        sortBy: sortBy || ReportsConstants?.VIEW_BY_NAME?.find(option => option?.name === selectedSort)?.value ||
          Object.entries(summaryColumnField).find(([_, value]) => value === selectedSort)?.[0] || selectedSort,
        sort: sortOrder,
        selectedSummaryWidgetColumnsDropdown: summaryWidgetColumnsDropdown,
        summaryWidgetColumnsDropdown: summaryWidgetColumnsDropdown,
        filtersApplied: filtersApplied
      };
      const hasDuplicates = checkDuplicateConfigs(hlsPreferences, historicalPreferences)
      if (hasDuplicates) {
        return;
      }


      const updatedDashboardPreference = {
        ...customReportData?.dashboardPreference,
        historical: {
          ...customReportData?.dashboardPreference?.historical,
          [uniqueChartNumber]: historicalPreferences
        },
        hls: {
          ...customReportData?.dashboardPreference?.hls,
          [uniqueChartNumber]: hlsPreferences,
        }
      };

      const updatedReportData = {
        ...customReportData,
        chartOrder: [...updatedChartOrder, uniqueChartNumber],
        dashboardPreference: updatedDashboardPreference
      };

      dispatch(reportAction.updateCustomReport(updatedReportData));
      defaultvalues()

    } catch (error) {
      console.error("Error in handleAddChart:", error);
    }
  }, [
    selectView,
    selectedValue,
    selectedDemographic,
    customReportData,
    filtersApplied,
    sortOrder,
    selectedSort,
    tempSelectedColumns,
    historicalPreferences,
    hlsDemographic,
    isKeyDriver
  ]);

  const handleViewByChange = useCallback((value) => {
    setSelectView(value?.value);
    setselectedValue(ReportsConstants.VIEW_BY_NAME.find(view => view.name === value?.value)?.value)
  }, []);

  const handleSortByChange = useCallback((value) => {
    setSelectedSort(value?.value);
  }, []);

  const handleSortOrderChange = useCallback((event) => {
    setSortOrder(event.target.value);
  }, []);

  useEffect(() => {
    if (selectedValue === 'demographic') {
      dispatch(
        reportAction.demographicsByName({
          metadata: [],
          surveys: customReportData.surveys,
          date: customReportData.date,
          demographic: hlsDemographic?.metadataDispname?.[selectedDemographic],
          search: "",
        })
      );
    }
  }, [selectedValue, selectedDemographic, hlsDemographic]);

  const handleDemographicChange = (value) => {
    setSelectedDemographic(value?.value)
    dispatch(
      reportAction.demographicsByName({
        metadata: [],
        surveys: customReportData.surveys,
        date: customReportData.date,
        demographic: hlsDemographic?.metadataDispname?.[value?.value],
        search: "",
      })
    );
  };

  const renderColumnOption = (key, label) => {
    if (key === "historicalData") {
      return (
        <div key={key} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '16px' }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={tempSelectedColumns.includes(key)}
                onChange={() => handleColumnToggle(key)}
                color="primary"
              />
            }
            label={String(label)}
          />
          {tempSelectedColumns.includes("historicalData") && (
            <div style={{ width: '150px' }}>
              <SurveySelector
                surveySearchName={surveySearchName}
                setSurveySearchName={setSurveySearchName}
                selectHistorical={selectHistorical}
                onHistoricalTypeChange={handleHistoricalTypeChange}
                onSurveyChange={handleSurveyChange}
                surveyData={surveydata}
                historicalSurvey={historicalPreferences.survey?.name}
              />
            </div>
          )}
        </div>
      );
    }

    return (
      <FormControlLabel
        key={key}
        control={
          <Checkbox
            checked={tempSelectedColumns.includes(key)}
            onChange={() => handleColumnToggle(key)}
            color="primary"
          />
        }
        label={String(label)}
      />
    );
  };

  return (
    <div style={{ padding: '0 20px' }}>
      <Grid display={'flex'} justifyContent={'space-between'} gap={'2rem'}>
        <Grid item style={{ flex: 1, maxWidth: "40vh" }}>
          <div>
            <TypographyComponent variant="h6" className="txtBold">
              {ReportsConstants.CUSTOM_VIEW_BY}
            </TypographyComponent>
            {renderFilterSection()}
            {selectedValue === 'demographic' && (
              <SelectFieldComponent
                select
                name="viewBydemographic"
                value={selectedDemographic || demooptions?.[0]}
                fullWidth={true}
                handleChange={(value) => handleDemographicChange(value)}
                options={demooptions}
                validators={['required']}
                errorMessages={['Required']}
              />
            )}
          </div>


          <div style={{ marginTop: '5px' }}>
            <TypographyComponent variant="h6" className="txtBold">
              {ReportsConstants.CUSTOM_SORT_BY}
            </TypographyComponent>
            <SelectFieldComponent
              select
              name="sortBy"
              value={selectedSort}
              fullWidth={true}
              handleChange={handleSortByChange}
              options={sortByOptions}
              validators={['required']}
              errorMessages={['Required']}
            />
          </div>
          {selectedSort && (
            <div style={{ marginTop: '10px', paddingLeft: '20px' }}>
              <RadioGroup
                row
                value={sortOrder}
                onChange={handleSortOrderChange}
              >
                <Grid container direction="column" spacing={1}>
                  <FormControlLabel
                    value="asc"
                    control={<Radio color="primary" />}
                    label="Ascending"
                  />
                  <FormControlLabel
                    value="desc"
                    control={<Radio color="primary" />}
                    label="Descending"
                  />
                </Grid>
              </RadioGroup>
            </div>
          )}
        </Grid>

        <Grid item xs={12} md={7} style={{ maxWidth: "40vh" }}>
          <div>
            <TypographyComponent variant="h6" className="txtBold">
              {ReportsConstants.CUSTOMIZE_COLUMN_TEXT}
            </TypographyComponent>
            <div style={{ display: 'grid', gap: '1px', maxWidth: "40vh", paddingLeft: "20px" }}>
              {Object.entries(filteredColumns).map(([key, label]) =>
                renderColumnOption(key, label)
              )}
            </div>
          </div>
        </Grid>
        <Grid item display={'inline-flex'} flexDirection={'column'} flexBasis={'200px'} justifyContent={'flex-end'}>
          <Grid display={'flex'} flexDirection={'column'} gap={'10px'}>
            <ButtonComponent
              color="primary"
              pageClassName="buttonOrange fontSize14"
              title="+ Add chart to report"
              handleClick={handleAddChart}
            />
             {/* <TypographyComponent variant="h6">
              {ReportsConstants.CUSTOM_ADD_CHART_TEXT}
             </TypographyComponent> */}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default HighLevelSummary;